const RateLabel = ({
  size,
  text,
}: {
  size?: 'lg' | 'md' | 'sm';
  text: string;
}) => {
  const fontSize = size === 'lg' ? 24 : size === 'sm' ? 12 : 20;

  return (
    <tspan fontSize={fontSize} dy="1rem">
      {text}
    </tspan>
  );
};

export default RateLabel;
