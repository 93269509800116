import { useMutation, UseMutationResult } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { CertificationType } from 'apis/meet/certificate/api';
import { ApiError } from 'types/api';

export interface DownloadCertificatesRequest {
  // Path
  meetSeq: number;

  // Request Body
  certificateType: CertificationType;
  fileSeqs: number[];
}

export type DownloadCertificatesResponse = Blob;

export const useFileDownloadCertificates = (): UseMutationResult<
  DownloadCertificatesResponse,
  ApiError,
  DownloadCertificatesRequest
> =>
  useMutation({
    mutationFn: async (variables) => {
      const { meetSeq, ...params } = variables;
      const { data } = await axiosInstance.post(
        `/v1/meets/${meetSeq}/certificates/download`,
        {
          ...params,
        },
        { responseType: 'blob' },
      );

      return data;
    },
  });
