import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Input,
  Link,
  PatternFormatAdapter,
  Radio,
  RadioGroup,
  Sheet,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TextField,
  Typography,
} from '@wooriga/design-system';
import * as _ from 'lodash-es';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { RegistrationAddress } from 'apis/types/common';
import {
  UnionRegisterUpdateMutationParams,
  useUnionRegisterQuery,
  useUnionRegistersDeleteMutation,
  useUnionRegisterUpdateMutation,
} from 'apis/union/register/apis';
import { useHasRepresentativeMutation } from 'apis/union/register/has-representative/apis';
import CommonCode from 'components/CommonCode';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import AddressModalTrigger from 'components/pages/posts/AddressModalTrigger';
import LocalAddressConfirmModal, {
  LocalAddressConfirmModalProps,
} from 'components/pages/registers/modal/LocalAddressConfirmModal';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import registerModifyAuthsPage from 'pages/main/union-management/registers/modify/auths';
import { REGISTERS_MODIFY_TABS } from 'pages/main/union-management/registers/modify/constants';
import { CustomRouteObject } from 'types/route';
import { deepFind } from 'utils/common';
import { formatDateTime } from 'utils/format';

export interface UnionRegisterModifyFieldValues {
  unionRegisterSeq: number;
  unionRegisterNoMain?: number;
  unionRegisterNoSub?: number;
  name?: string;
  nameClass?: string;
  companyNo?: string;
  birth?: string;
  memo?: string;
  gender?: string; // UNION_REGISTER_SHARE_TYPE
  position?: string; // UNION_REGISTER_USER_DIVIDE
  positionDescription?: string;
  shareType: string;
  ownerType?: string;
  isRepresentative?: 'Y' | 'N';
  hasVotingRight?: 'Y' | 'N';
  hasSalesRight?: 'Y' | 'N';
  salesCount?: number;
  mainPhone: {
    phoneNo: string;
    isContactAllowed?: 'Y' | 'N';
  };
  subPhones: {
    phoneSeq?: number;
    phoneNo: string;
    isContactAllowed?: 'Y' | 'N';
    memo?: string;
  }[];
  localAddresses: {
    localAddressSeq?: number;
    city: string;
    gu: string;
    dong: string;
    jibun: string;
    zoneRoad: string;
    building?: string;
    apartmentDong?: string;
    apartmentFloor?: string;
    apartmentHosu?: string;
    checkStatus: 'Y' | 'N';
    registrationAddresses: {
      registrationAddressSeq?: number;
      registrationAddress: string;
      registrationNo: string;
      registrationType: string; // REGISTRATION_TYPE
      registrationState: '현행' | '폐쇄' | '현행+폐쇄';
    }[];
  }[];
  realAddress?: {
    city?: string;
    gu?: string;
    dong?: string;
    address?: string;
    detailAddress?: string;
  };
  postAddress?: {
    address?: string;
    detailAddress?: string;
    zipNo?: string;
  };
  etcAddress?: string;
}

const schema = yup.object().shape({
  unionRegisterSeq: yup.number().required(),
  unionRegisterNoMain: yup.number().when('shareType', {
    is: (value: string) => value === 'SHARE',
    then: (schema) =>
      schema
        .typeError('본번을 입력해주세요.')
        .required('본번을 입력해주세요.')
        .min(1, '본번을 입력해주세요.'),
    otherwise: (schema) =>
      schema.transform((value) => (isNaN(value) ? undefined : value)),
  }),
  unionRegisterNoSub: yup
    .number()
    .when('shareType', {
      is: (value: string) => value === 'SHARE',
      then: (schema) =>
        schema
          .typeError('부번을 입력해주세요.')
          .required('부번을 입력해주세요.')
          .min(1, '부번을 입력해주세요.'),
      otherwise: (schema) => schema.nullable(),
    })
    .transform((value) => (value !== '' ? value : null)),
  name: yup.string().max(30),
  nameClass: yup.string().max(20),
  companyNo: yup
    .string()
    .nullable()
    .matches(/^(\d{10}|\d{13})$/, '사업자등록번호를 확인해 주세요.')
    .transform((value) => (value !== '' ? value : null)),
  birth: yup.string().matches(/^(|\d{8})$/, '생년월일을 확인해 주세요.'),
  memo: yup.string().max(100),
  gender: yup
    .string()
    .transform((value) => (value !== 'NONE' ? value : undefined)),
  position: yup
    .mixed()
    .transform((value) => (value !== 'NONE' ? value : undefined)),
  positionDescription: yup
    .string()
    .max(8)
    .when('position', {
      is: (value: string) => value === 'EXECUTIVE',
      then: (schema) => schema.required('직책상세에 내용을 입력해 주세요.'),
      otherwise: (schema) => schema.nullable(),
    }),
  shareType: yup.string().required(),
  ownerType: yup
    .string()
    .transform((value) => (value !== 'NONE' ? value : undefined)),
  isRepresentative: yup.mixed().transform((value) => value === 'Y'),
  hasSalesRight: yup.mixed().transform((value) => value === 'Y'),
  hasVotingRight: yup.mixed().transform((value) => value === 'Y'),
  salesCount: yup
    .number()
    .when('hasSalesRight', {
      is: (value: boolean) => value,
      then: (schema) =>
        schema
          .typeError('분양권 개수를 입력해 주세요.')
          .required('분양권 개수를 입력해 주세요.'),
      otherwise: (schema) => schema.nullable(),
    })
    .transform((value) => (value !== '' ? value : null)),
  mainPhone: yup.object().shape({
    phoneNo: yup
      .string()
      .matches(/^(\d{9,11})$/, '연락처를 확인해 주세요.')
      .max(11)
      .required(),
    isContactAllowed: yup
      .mixed()
      .transform((value) => value === 'Y')
      .required(),
  }),
  subPhones: yup
    .array()
    .of(
      yup.object().shape({
        phoneSeq: yup.number(),
        phoneNo: yup
          .string()
          .matches(/^(\d{9,11})$/, '연락처를 확인해 주세요.')
          .required(),
        isContactAllowed: yup
          .mixed()
          .transform((value) => value === 'Y')
          .required(),
        memo: yup.string().max(30),
      }),
    )
    .max(4),
  localAddresses: yup
    .array()
    .of(
      yup.object().shape({
        localAddressSeq: yup.number(),
        city: yup.string(),
        gu: yup.string(),
        dong: yup.string(),
        jibun: yup.string(),
        zoneRoad: yup.string(),
        building: yup.string().max(40),
        apartmentDong: yup.string(),
        apartmentFloor: yup.string(),
        apartmentHosu: yup.string(),
        checkStatus: yup.string().oneOf(['Y', 'N']),
        registrationAddresses: yup.array().of(
          yup.object().shape({
            registrationAddressSeq: yup.number(),
            registrationAddress: yup.string(),
            registrationNo: yup.string(),
            registrationType: yup.string(),
            registrationState: yup.string(),
          }),
        ),
      }),
    )
    .test({
      name: 'required-registration-addresses',
      message: '소재지 정보를 입력해주세요.',
      test: (values) =>
        Array.isArray(values) &&
        values.length > 0 &&
        values.findIndex(
          ({ city, gu, dong, jibun, registrationAddresses }) =>
            !city ||
            !gu ||
            !dong ||
            !jibun ||
            !registrationAddresses ||
            registrationAddresses.length === 0,
        ) < 0,
    }),
  realAddress: yup.object().shape({
    city: yup.string(),
    gu: yup.string(),
    dong: yup.string(),
    address: yup.string(),
    detailAddress: yup.string().max(35),
  }),
  postAddress: yup.object().shape({
    address: yup.string(),
    detailAddress: yup.string().max(35),
    zipNo: yup.string().matches(/^(\d{0,5})$/),
  }),
  etcAddress: yup.string().max(100),
});

const RegisterModifyPage = () => {
  const { snackbar, confirmDialog } = useFeedback();
  const navigate = useNavigate();
  const params = useParams();
  const unionSeq = Number(params.unionSeq);
  const unionRegisterSeq = Number(params.unionRegisterSeq);

  const { pageContext } = useLayoutContext();
  const { unionBasename } = pageContext || {};

  const {
    data: unionRegisterReturnData,
    isLoading,
    isPending,
    refetch,
    isError,
    error,
  } = useUnionRegisterQuery({
    unionSeq,
    unionRegisterSeq,
  });

  const { mutate: updateMutation } = useUnionRegisterUpdateMutation();
  const { mutate: unionRegistersDeleteMutate } =
    useUnionRegistersDeleteMutation();
  const { mutate: checkHasRepresentativeMutation } =
    useHasRepresentativeMutation();

  const unionRegister = useMemo(
    () => unionRegisterReturnData?.data,
    [unionRegisterReturnData?.data],
  );

  const [localAddressConfirmModalOption, setLocalAddressConfirmModalOption] =
    useState<
      | { open: boolean; onSubmit: LocalAddressConfirmModalProps['onSubmit'] }
      | undefined
    >(undefined);

  const [isRepresentativeConfigurable, setIsRepresentativeConfigurable] =
    useState<boolean | undefined>(undefined);

  const { getGroupCode } = useCommonCodes();

  const { ownerTypeCodeGroup, shareTypeCodeGroup, positionTypeCodeGroup } =
    useMemo(
      () => ({
        ownerTypeCodeGroup: getGroupCode('UNION_REGISTER_USER_DIVIDE'),
        shareTypeCodeGroup: getGroupCode('UNION_REGISTER_SHARE_TYPE'),
        positionTypeCodeGroup: getGroupCode('UNION_REGISTER_POSITION'),
      }),
      [getGroupCode],
    );

  const defaultValues: UnionRegisterModifyFieldValues = useMemo(
    () => ({
      unionRegisterSeq,
      unionRegisterNoMain: unionRegister?.unionRegisterNoMain || 0,
      unionRegisterNoSub: unionRegister?.unionRegisterNoSub,
      name: unionRegister?.name?.name || '',
      nameClass: unionRegister?.name?.nameClass || '',
      companyNo: unionRegister?.companyNo || '',
      birth: unionRegister?.birth || '',
      memo: unionRegister?.memo || '',
      gender: unionRegister?.gender || 'NONE',
      position: unionRegister?.position || 'NONE',
      positionDescription: unionRegister?.positionDescription || '',
      shareType: unionRegister?.shareType || 'SINGLE',
      ownerType: unionRegister?.ownerType || 'NONE',
      isRepresentative: unionRegister?.isRepresentative ? 'Y' : 'N',
      hasSalesRight: unionRegister?.hasSalesRight ? 'Y' : 'N',
      hasVotingRight: unionRegister?.hasVotingRight ? 'Y' : 'N',
      salesCount: unionRegister?.salesCount || 0,
      mainPhone: {
        phoneNo: unionRegister?.mainPhone?.phoneNo || '',
        isContactAllowed: unionRegister?.mainPhone?.isContactAllowed
          ? 'Y'
          : 'N',
      },
      subPhones:
        unionRegister?.subPhones?.map(({ isContactAllowed, ...other }) => ({
          isContactAllowed: isContactAllowed ? 'Y' : 'N',
          ...other,
        })) || [],
      localAddresses: unionRegister?.localAddresses || [],
      realAddress: unionRegister?.realAddress || {
        city: '',
        gu: '',
        dong: '',
        address: '',
        detailAddress: '',
      },
      postAddress: unionRegister?.postAddress || {
        address: '',
        detailAddress: '',
        zipNo: '',
      },
      etcAddress: unionRegister?.etcAddress || '',
    }),
    [
      unionRegister?.birth,
      unionRegister?.companyNo,
      unionRegister?.etcAddress,
      unionRegister?.gender,
      unionRegister?.hasSalesRight,
      unionRegister?.hasVotingRight,
      unionRegister?.isRepresentative,
      unionRegister?.localAddresses,
      unionRegister?.mainPhone?.isContactAllowed,
      unionRegister?.mainPhone?.phoneNo,
      unionRegister?.memo,
      unionRegister?.name?.name,
      unionRegister?.name?.nameClass,
      unionRegister?.ownerType,
      unionRegister?.position,
      unionRegister?.positionDescription,
      unionRegister?.postAddress,
      unionRegister?.realAddress,
      unionRegister?.salesCount,
      unionRegister?.shareType,
      unionRegister?.subPhones,
      unionRegister?.unionRegisterNoMain,
      unionRegister?.unionRegisterNoSub,
      unionRegisterSeq,
    ],
  );

  const { reset, handleSubmit, setValue, control, watch } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const shareType = watch('shareType');
  const ownerType = watch('ownerType');
  const gender = watch('gender');
  const hasSalesRight = watch('hasSalesRight');
  const unionRegisterNoMain = Number(watch('unionRegisterNoMain')) || 0;

  const {
    fields: subPhonesFields,
    append: appendSubPhones,
    remove: removeSubPhones,
  } = useFieldArray({
    control,
    name: 'subPhones',
    shouldUnregister: true,
  });

  const {
    fields: localAddressesFields,
    append: appendLocalAddresses,
    remove: removeLocalAddresses,
    update: updateLocalAddresses,
  } = useFieldArray({
    control,
    name: 'localAddresses',
    shouldUnregister: true,
  });

  const handleDebouncedHasRepresentative = useCallback(
    _.debounce((unionSeq, unionRegisterNoMain, unionRegisterSeq) => {
      checkHasRepresentativeMutation(
        {
          unionSeq,
          unionRegisterNoMain,
          unionRegisterSeq,
        },
        {
          onSuccess: (data) => {
            const value = data?.data;
            setIsRepresentativeConfigurable(value);
          },
        },
      );
    }, 500),
    [],
  );

  const handleLocalAddressConfirm = useCallback(
    (data: {
      city: string;
      gu: string;
      dong: string;
      jibun: string;
      zoneRoad: string;
      building: string;
      apartmentDong: string;
      apartmentFloor: string;
      apartmentHosu: string;
      checkStatus: 'Y';
      registrationAddresses: RegistrationAddress[];
    }) => {
      localAddressConfirmModalOption &&
        localAddressConfirmModalOption.onSubmit?.(data);
    },
    [localAddressConfirmModalOption],
  );

  const enhancedHandleSubmit = handleSubmit(
    (data) => {
      confirmDialog('해당 내용을 저장하시겠습니까?', {
        message: '확인',
        onConfirm: () => {
          const body = {
            unionRegisterSeq: data.unionRegisterSeq,
            unionRegisterNoMain:
              data?.unionRegisterNoMain && data?.unionRegisterNoMain > 0
                ? data.unionRegisterNoMain
                : undefined,
            unionRegisterNoSub:
              data?.unionRegisterNoSub && data?.unionRegisterNoSub > 0
                ? data.unionRegisterNoSub
                : undefined,
            name: data.name,
            nameClass: data.nameClass,

            ...(shareType === 'SHARE' &&
              isRepresentativeConfigurable && {
                isRepresentative: data.isRepresentative,
              }),

            ...(ownerType === 'PERSON' && {
              birth: data.birth,
              gender: data.gender,
            }),
            ...(ownerType === 'COMPANY' && {
              companyNo: data.companyNo,
            }),

            memo: data.memo,

            position: data.position,
            positionDescription: data.positionDescription,
            shareType: data.shareType,
            ownerType: data.ownerType,
            hasSalesRight: data.hasSalesRight,
            hasVotingRight: data.hasVotingRight,
            salesCount: data.hasSalesRight ? data.salesCount : undefined,
            mainPhone: data.mainPhone,
            subPhones:
              data.subPhones?.map((sub) => ({
                ...sub,
                phoneSeq:
                  sub?.phoneSeq && sub?.phoneSeq > 0 ? sub.phoneSeq : undefined,
              })) || [],
            localAddresses: data.localAddresses,
            realAddress: data.realAddress,
            postAddress: data.postAddress,
            etcAddress: data.etcAddress,
          };

          updateMutation(
            { unionSeq, ...body } as UnionRegisterUpdateMutationParams,
            {
              onSuccess: () => {
                snackbar('저장이 완료되었습니다.', { color: 'success' });
                refetch();
              },
              onError: (error) => {
                const message = error.response?.data.message as ReactNode;
                console.log(error);
                snackbar(message, { color: 'danger' });
              },
            },
          );
        },
      });
    },
    (errors) => {
      const message = deepFind('message', errors);
      console.log(errors);
      snackbar(message, { color: 'danger' });
    },
  );

  const handleClickRegistersDelete = useCallback(() => {
    confirmDialog('선택하신 조합원 정보를 삭제하시겠습니까?', {
      onConfirm: () => {
        unionRegistersDeleteMutate(
          {
            unionSeq,
            unionRegisterSeqs: [unionRegisterSeq],
          },
          {
            onSuccess: () => {
              snackbar('삭제가 완료되었습니다.', { color: 'success' });
              navigate('..');
            },
            onError: (error) => {
              snackbar(error.message, { color: 'danger' });
            },
          },
        );
      },
      message: '확인',
    });
  }, [
    confirmDialog,
    navigate,
    snackbar,
    unionRegisterSeq,
    unionRegistersDeleteMutate,
    unionSeq,
  ]);

  const loading = useMemo(() => isLoading || isPending, [isLoading, isPending]);

  useEffect(() => {
    if (defaultValues) {
      setTimeout(() => reset(defaultValues), 0);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!unionSeq || !unionRegisterNoMain) return;

    setIsRepresentativeConfigurable(undefined);
    handleDebouncedHasRepresentative(
      unionSeq,
      unionRegisterNoMain,
      unionRegister?.unionRegisterSeq,
    );
  }, [
    handleDebouncedHasRepresentative,
    unionRegister?.unionRegisterSeq,
    unionRegisterNoMain,
    unionSeq,
    shareType,
  ]);

  if (isError) throw error;

  return (
    <>
      <Tabs
        color="neutral"
        selectedTabVariant="plain"
        selectedTabColor="primary"
        tabIndicatorInset
        defaultValue={0}
      >
        <TabList disableUnderline>
          {REGISTERS_MODIFY_TABS.map(({ label, path }, index) => (
            <Tab
              key={`tab_${path}`}
              component={Link}
              value={index}
              href={`${unionBasename}/union-management/registers/${unionRegisterSeq}/${path}`}
            >
              {label}
            </Tab>
          ))}
        </TabList>

        <TabPanel value={0}>
          {!loading && (
            <Stack
              pt={1}
              gap={2}
              component="form"
              onSubmit={enhancedHandleSubmit}
            >
              <Stack gap={1} mb={1}>
                <Typography
                  level="h3"
                  fontSize="lg"
                  fontWeight="xl"
                  lineHeight="xl"
                >
                  조합원 정보
                </Typography>

                <Sheet
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                    borderRadius: 6,
                    gap: 4,
                  }}
                >
                  <Stack flexDirection="row" flexWrap="wrap">
                    <Stack
                      flex={1}
                      flexDirection="row"
                      flexWrap="wrap"
                      alignItems="baseline"
                      gap={2}
                    >
                      <Grid container columnGap={10} rowGap={3}>
                        <Grid maxWidth={712} width="100%">
                          <Controller
                            control={control}
                            name="ownerType"
                            defaultValue=""
                            render={({ field }) => (
                              <RadioGroup
                                label="구분"
                                orientation="horizontal"
                                color="neutral"
                                {...field}
                              >
                                <Radio label="미지정" value="NONE" />
                                {ownerTypeCodeGroup?.items.map(
                                  ({ code, name }) => (
                                    <Radio
                                      key={code}
                                      label={name}
                                      value={code}
                                    />
                                  ),
                                )}
                              </RadioGroup>
                            )}
                          />
                        </Grid>

                        <Grid maxWidth={316} width="100%">
                          <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                label="이름/법인명"
                                placeholder="이름/법인명 입력"
                                validateOptions={{ maxLength: 30 }}
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        <Grid maxWidth={316} width="100%">
                          <Controller
                            name="nameClass"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                label="이름 구분"
                                placeholder="이름 구분 입력"
                                validateOptions={{ maxLength: 20 }}
                                {...field}
                              />
                            )}
                          />
                        </Grid>

                        <Grid maxWidth={316} width="100%">
                          <Typography
                            fontSize="sm"
                            fontWeight="md"
                            lineHeight="sm"
                            mb={0.75}
                          >
                            소유 구분
                          </Typography>

                          <Controller
                            name="shareType"
                            control={control}
                            defaultValue=""
                            render={({
                              field: { value, onChange, ...other },
                            }) => (
                              <Stack flexDirection="row" alignItems="center">
                                <RadioGroup
                                  orientation="horizontal"
                                  color="neutral"
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    if (event.target.value === 'SINGLE') {
                                      setValue('unionRegisterNoSub', 0);
                                      setValue('isRepresentative', false);
                                    }
                                    onChange(event);
                                  }}
                                  value={value}
                                  {...other}
                                >
                                  {shareTypeCodeGroup?.items.map(
                                    ({ code, name }) => (
                                      <Radio
                                        key={code}
                                        label={name}
                                        value={code}
                                      />
                                    ),
                                  )}
                                </RadioGroup>
                                {shareType === 'SHARE' &&
                                  isRepresentativeConfigurable && (
                                    <Controller
                                      name="isRepresentative"
                                      control={control}
                                      defaultValue=""
                                      render={({ field }) => (
                                        <Checkbox
                                          label="대표"
                                          sx={{ ml: 2 }}
                                          name={field.name}
                                          checked={field.value === 'Y'}
                                          onChange={(event) => {
                                            const checked =
                                              event.target.checked;
                                            field.onChange(checked ? 'Y' : 'N');
                                          }}
                                        />
                                      )}
                                    />
                                  )}
                              </Stack>
                            )}
                          />
                        </Grid>

                        <Grid maxWidth={316} width="100%">
                          <Typography
                            fontSize="sm"
                            fontWeight="md"
                            lineHeight="sm"
                            mb={0.75}
                          >
                            연번
                          </Typography>

                          <Stack
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                          >
                            <Controller
                              name="unionRegisterNoMain"
                              control={control}
                              defaultValue={0}
                              render={({ field: { value, ...other } }) => (
                                <Input
                                  type="number"
                                  placeholder="본번 입력"
                                  slotProps={{ input: { min: 1 } }}
                                  value={value || ''}
                                  {...other}
                                />
                              )}
                            />
                            <Typography>-</Typography>
                            <Controller
                              name="unionRegisterNoSub"
                              defaultValue={0}
                              control={control}
                              disabled={shareType !== 'SHARE'}
                              render={({ field: { value, ...other } }) => (
                                <Input
                                  type="number"
                                  placeholder="부번 입력"
                                  slotProps={{ input: { min: 1 } }}
                                  value={value || ''}
                                  {...other}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>

                        <Controller
                          name="position"
                          control={control}
                          defaultValue=""
                          render={({ field: positionField }) => (
                            <>
                              <Grid maxWidth={316} width="100%">
                                <RadioGroup
                                  label="직책"
                                  orientation="horizontal"
                                  color="neutral"
                                  {...positionField}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    const value = event.target?.value;

                                    switch (value) {
                                      case 'EXECUTIVE':
                                        setValue(
                                          'positionDescription',
                                          '조합임원',
                                        );
                                        break;
                                      case 'MEMBER':
                                        setValue(
                                          'positionDescription',
                                          '조합원',
                                        );
                                        break;
                                      case 'NONE':
                                        setValue('positionDescription', '');
                                        break;
                                    }
                                    positionField.onChange(event);
                                  }}
                                >
                                  <Radio label="미지정" value="NONE" />
                                  {positionTypeCodeGroup?.items.map(
                                    ({ code, name }) => (
                                      <Radio
                                        key={code}
                                        label={name}
                                        value={code}
                                      />
                                    ),
                                  )}
                                </RadioGroup>
                              </Grid>

                              <Grid maxWidth={316} width="100%">
                                <Controller
                                  name="positionDescription"
                                  control={control}
                                  defaultValue=""
                                  disabled={positionField.value === 'NONE'}
                                  render={({ field }) => (
                                    <TextField
                                      label="직책상세"
                                      placeholder="직책상세 입력"
                                      readOnly={
                                        positionField.value === 'MEMBER'
                                      }
                                      validateOptions={{
                                        maxLength: 8,
                                      }}
                                      {...field}
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                        />

                        {ownerType === 'PERSON' && (
                          <>
                            <Grid maxWidth={316} width="100%">
                              <Controller
                                name="gender"
                                disabled={ownerType !== 'PERSON'}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <RadioGroup
                                    label="성별"
                                    orientation="horizontal"
                                    color="neutral"
                                    {...field}
                                  >
                                    <Radio label="미지정" value="NONE" />
                                    <Radio label="남" value="M" />
                                    <Radio label="여" value="F" />
                                  </RadioGroup>
                                )}
                              />
                            </Grid>

                            <Grid maxWidth={316} width="100%">
                              <Controller
                                name="birth"
                                disabled={gender === 'NONE'}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    label="생년월일"
                                    placeholder="숫자만 입력"
                                    validateOptions={{
                                      regex: /^[0-9]*$/,
                                      maxLength: 8,
                                    }}
                                    {...field}
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}

                        {ownerType === 'COMPANY' && (
                          <Grid maxWidth={316} width="100%">
                            <Controller
                              name="companyNo"
                              control={control}
                              defaultValue=""
                              shouldUnregister
                              render={({
                                field: { value, onChange, ...other },
                              }) => (
                                <TextField
                                  label="사업자등록번호"
                                  placeholder="숫자만 입력"
                                  slotProps={{
                                    input: {
                                      component: PatternFormatAdapter,
                                      format:
                                        !value || value.length < 11
                                          ? '###-##-######'
                                          : '######-#######',
                                      value: value || '',
                                      onValueChange: (values: {
                                        value: string;
                                      }) => {
                                        onChange(values.value);
                                      },
                                    },
                                  }}
                                  {...other}
                                />
                              )}
                            />
                          </Grid>
                        )}

                        <Grid maxWidth={712} width="100%">
                          <Controller
                            name="memo"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="비고"
                                placeholder="비고 입력"
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid container gap={2}>
                        <Grid maxWidth={316} width="100%">
                          <Controller
                            name="hasVotingRight"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <RadioGroup
                                label="의결권 여부"
                                color="neutral"
                                orientation="horizontal"
                                {...field}
                              >
                                <Radio label="없음" value="N" />
                                <Radio label="있음" value="Y" />
                              </RadioGroup>
                            )}
                          />
                        </Grid>

                        <Grid maxWidth={316} width="100%">
                          <Typography
                            fontSize="sm"
                            fontWeight="md"
                            lineHeight="sm"
                            mb={0.75}
                          >
                            분양권 여부
                          </Typography>

                          <Stack flexDirection="row">
                            <Controller
                              name="hasSalesRight"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, ...other } }) => (
                                <RadioGroup
                                  color="neutral"
                                  orientation="horizontal"
                                  sx={{ alignItems: 'center' }}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    const value = event.target.value;
                                    setValue(
                                      'salesCount',
                                      value === 'N' ? 0 : 1,
                                    );
                                    onChange(event);
                                  }}
                                  {...other}
                                >
                                  <Radio label="없음" value="N" />
                                  <Radio label="있음" value="Y" />
                                </RadioGroup>
                              )}
                            />

                            <Controller
                              name="salesCount"
                              control={control}
                              defaultValue={0}
                              disabled={hasSalesRight === 'N'}
                              render={({ field: { value, ...other } }) => (
                                <Input
                                  type="number"
                                  size="sm"
                                  placeholder="개수 입력"
                                  slotProps={{ input: { min: 1 } }}
                                  sx={{ ml: 2, width: 120 }}
                                  value={value || ''}
                                  {...other}
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>

                    {unionRegister?.modifiedAt && (
                      <Stack flexDirection="row" alignItems="baseline" gap={1}>
                        <Typography
                          textColor="text.secondary"
                          fontSize="sm"
                          fontWeight="md"
                          lineHeight="sm"
                          startDecorator={
                            <Typography
                              textColor="neutral.500"
                              fontSize="sm"
                              fontWeight="lg"
                              lineHeight="sm"
                            >
                              최근 수정일시
                            </Typography>
                          }
                        >
                          {formatDateTime(
                            unionRegister.modifiedAt,
                            'yyyy-MM-dd HH:mm:ss',
                          )}
                        </Typography>

                        {unionRegister?.modifiedBy && (
                          <Chip variant="outlined" size="sm">
                            {unionRegister.modifiedBy}
                          </Chip>
                        )}
                      </Stack>
                    )}
                  </Stack>

                  <Divider />

                  <Stack>
                    <Grid container gap={3}>
                      <Grid maxWidth={676} width="100%">
                        <Typography
                          fontSize="sm"
                          fontWeight="md"
                          lineHeight="sm"
                          mb={0.75}
                        >
                          조합원 연락처
                        </Typography>

                        <Stack flexDirection="row" flexWrap="wrap" gap={2}>
                          <Controller
                            name="mainPhone.phoneNo"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Input
                                sx={{ flex: 1, maxWidth: 456 }}
                                placeholder="연락처 입력"
                                validateOptions={{
                                  maxLength: 11,
                                  regex: /^[0-9]*$/,
                                }}
                                {...field}
                              />
                            )}
                          />

                          <Controller
                            name="mainPhone.isContactAllowed"
                            control={control}
                            render={({ field }) => (
                              <RadioGroup
                                orientation="horizontal"
                                color="neutral"
                                {...field}
                              >
                                <Radio label="수신" value="Y" />
                                <Radio label="수신거부" value="N" />
                              </RadioGroup>
                            )}
                          />
                        </Stack>
                      </Grid>

                      <Grid maxWidth={875} width="100%">
                        <Typography
                          fontSize="sm"
                          fontWeight="md"
                          lineHeight="sm"
                          mb={0.75}
                        >
                          기타 연락처
                        </Typography>

                        <Stack gap={2}>
                          {subPhonesFields.map((item, index) => (
                            <Stack
                              flexDirection="row"
                              flexWrap="wrap"
                              gap={1}
                              key={item.id}
                            >
                              <Controller
                                name={`subPhones.${index}.phoneNo`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Input
                                    sx={{ width: 176 }}
                                    placeholder="숫자만 입력"
                                    validateOptions={{
                                      maxLength: 11,
                                      regex: /^[0-9]*$/,
                                    }}
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name={`subPhones.${index}.memo`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Input
                                    sx={{ flex: 1 }}
                                    placeholder="메모 입력"
                                    validateOptions={{
                                      maxLength: 30,
                                    }}
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name={`subPhones.${index}.isContactAllowed`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <RadioGroup
                                    orientation="horizontal"
                                    color="neutral"
                                    {...field}
                                  >
                                    <Radio label="수신" value="Y" />
                                    <Radio label="수신거부" value="N" />
                                  </RadioGroup>
                                )}
                              />
                              <Button
                                variant="outlined"
                                color="danger"
                                onClick={() => removeSubPhones(index)}
                              >
                                삭제
                              </Button>
                            </Stack>
                          ))}

                          <Button
                            variant="outlined"
                            onClick={() =>
                              subPhonesFields.length < 4 &&
                              appendSubPhones({
                                phoneSeq: -1,
                                phoneNo: '',
                                isContactAllowed: 'Y',
                                memo: '',
                              })
                            }
                            disabled={subPhonesFields.length > 3}
                            sx={{ maxWidth: 176 }}
                          >
                            추가
                          </Button>
                        </Stack>
                      </Grid>

                      <Grid maxWidth={752} width="100%">
                        <Typography
                          fontSize="sm"
                          fontWeight="md"
                          lineHeight="sm"
                          mb={0.75}
                        >
                          거주지 주소
                        </Typography>

                        <Stack flexDirection="row" flexWrap="wrap" gap={1}>
                          <Controller
                            name="realAddress"
                            control={control}
                            render={({ field }) => (
                              <>
                                <AddressModalTrigger
                                  onComplete={(address) =>
                                    field.onChange({
                                      city: address.sido,
                                      gu: address.sigungu,
                                      dong: address.bname,
                                      jibun: address.jibunAddress
                                        .split(' ')
                                        .findLast(
                                          (part) =>
                                            part.match(/\d+-\d+/) ||
                                            part.match(/\d+/),
                                        ),
                                      address: address.address,
                                      detailAddress: '',
                                    })
                                  }
                                >
                                  <Input
                                    sx={{ width: 354 }}
                                    placeholder="주소"
                                    startDecorator={<SearchIcon />}
                                    readOnly
                                    name={field.name}
                                    value={field.value?.address || ''}
                                  />
                                </AddressModalTrigger>

                                <Input
                                  sx={{ width: 240 }}
                                  placeholder="상세주소 입력"
                                  name={`${field.name}.detailAddress`}
                                  value={field.value?.detailAddress || ''}
                                  onChange={(event) =>
                                    field.onChange({
                                      ...field.value,
                                      detailAddress: event.target.value,
                                    })
                                  }
                                  validateOptions={{
                                    maxLength: 35,
                                  }}
                                />
                              </>
                            )}
                          />
                        </Stack>
                      </Grid>

                      <Grid maxWidth={752} width="100%">
                        <Typography
                          fontSize="sm"
                          fontWeight="md"
                          lineHeight="sm"
                          mb={0.75}
                        >
                          우편물 수령지
                        </Typography>

                        <Stack flexDirection="row" flexWrap="wrap" gap={1}>
                          <Controller
                            name="postAddress"
                            control={control}
                            render={({ field }) => (
                              <>
                                <AddressModalTrigger
                                  onComplete={(address) =>
                                    field.onChange({
                                      address: address.address,
                                      detailAddress: '',
                                      zipNo: address.zonecode,
                                    })
                                  }
                                >
                                  <Input
                                    sx={{ width: 354 }}
                                    placeholder="주소"
                                    startDecorator={<SearchIcon />}
                                    readOnly
                                    name={field.name}
                                    value={field.value?.address || ''}
                                  />
                                </AddressModalTrigger>
                                <Input
                                  sx={{ width: 240 }}
                                  placeholder="상세주소 입력"
                                  name={`${field.name}.detailAddress`}
                                  value={field.value?.detailAddress || ''}
                                  onChange={(event) =>
                                    field.onChange({
                                      ...field.value,
                                      detailAddress: event.target.value,
                                    })
                                  }
                                  validateOptions={{
                                    maxLength: 35,
                                  }}
                                />
                                <Input
                                  sx={{ width: 142 }}
                                  placeholder="우편번호"
                                  readOnly
                                  name={`${field.name}.zipNo`}
                                  value={field.value?.zipNo || ''}
                                />
                              </>
                            )}
                          />
                        </Stack>
                      </Grid>

                      <Grid maxWidth={752} width="100%">
                        <Controller
                          name="etcAddress"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              label="기타 주소지"
                              fullWidth
                              placeholder="기타 주소지 입력"
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Sheet>
              </Stack>

              <Stack gap={1}>
                <Stack flexDirection="row" gap={1}>
                  <Typography
                    level="h3"
                    fontSize="lg"
                    fontWeight="xl"
                    lineHeight="xl"
                  >
                    소재지 정보
                  </Typography>

                  {localAddressesFields.length > 1 ? (
                    <Chip variant="outlined">다물권자</Chip>
                  ) : (
                    <Chip variant="outlined">단독</Chip>
                  )}
                </Stack>

                {localAddressesFields.map((item, index) => (
                  <Sheet
                    variant="outlined"
                    sx={{ display: 'flex', p: 2, borderRadius: 'md' }}
                    key={item.id}
                  >
                    <Grid flex={1} container gap={3}>
                      <Controller
                        name={`localAddresses.${index}`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <Grid width="100%">
                              <Stack
                                flexDirection="row"
                                flexWrap="wrap"
                                rowGap={2}
                                columnGap={1}
                              >
                                <TextField
                                  sx={{ width: 360 }}
                                  name={`${field.name}.address`}
                                  label="소재지 주소"
                                  placeholder="주소"
                                  startDecorator={<SearchIcon />}
                                  readOnly
                                  value={
                                    [
                                      field.value?.city,
                                      field.value?.gu,
                                      field.value?.dong,
                                      field.value?.jibun,
                                    ]
                                      .join(' ')
                                      .trim() || ''
                                  }
                                  onClick={() =>
                                    setLocalAddressConfirmModalOption({
                                      open: true,
                                      onSubmit: (data) =>
                                        updateLocalAddresses(index, data),
                                    })
                                  }
                                />

                                <TextField
                                  sx={{ width: 240 }}
                                  name={`${field.name}.dong`}
                                  label="법정동"
                                  placeholder="법정동"
                                  readOnly
                                  value={field.value?.dong || ''}
                                />
                                <TextField
                                  sx={{ width: 240 }}
                                  name={`${field.name}.jibun`}
                                  label="소유지번"
                                  placeholder="소유지번"
                                  readOnly
                                  value={field.value?.jibun || ''}
                                />
                              </Stack>
                            </Grid>

                            <Grid width="100%">
                              <Stack
                                flexDirection="row"
                                flexWrap="wrap"
                                rowGap={2}
                                columnGap={1}
                              >
                                <Controller
                                  name={`${field.name}.building`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field: buildingField }) => (
                                    <TextField
                                      sx={{ width: 360 }}
                                      label="공동주택(건물명)"
                                      placeholder="공동주택(건물명) 입력"
                                      validateOptions={{ maxLength: 40 }}
                                      {...buildingField}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`${field.name}.apartmentDong`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field: apartmentDongField }) => (
                                    <TextField
                                      sx={{ width: 116 }}
                                      label="동"
                                      placeholder="동 입력"
                                      validateOptions={{ maxLength: 20 }}
                                      {...apartmentDongField}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`${field.name}.apartmentFloor`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field: apartmentFloorField }) => (
                                    <TextField
                                      sx={{ width: 116 }}
                                      label="층"
                                      placeholder="층 입력"
                                      validateOptions={{ maxLength: 20 }}
                                      {...apartmentFloorField}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`${field.name}.apartmentHosu`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field: apartmentHosuField }) => (
                                    <TextField
                                      sx={{ width: 116 }}
                                      label="호수"
                                      placeholder="호수 입력"
                                      validateOptions={{ maxLength: 20 }}
                                      {...apartmentHosuField}
                                    />
                                  )}
                                />
                              </Stack>
                            </Grid>

                            <Grid maxWidth={752} width="100%">
                              <Typography
                                fontSize="sm"
                                fontWeight="md"
                                lineHeight="sm"
                                mb={0.75}
                              >
                                등기정보
                              </Typography>

                              <Stack flex={1} gap={1}>
                                {item.registrationAddresses?.map(
                                  (
                                    {
                                      registrationAddressSeq,
                                      registrationType,
                                      registrationAddress,
                                    },
                                    index,
                                  ) => (
                                    <Stack
                                      key={registrationAddressSeq || index}
                                      flexDirection="row"
                                      alignItems="center"
                                      gap={1}
                                    >
                                      {registrationType && (
                                        <Chip
                                          variant="outlined"
                                          color={
                                            registrationType === 'BUILDING'
                                              ? 'blue'
                                              : registrationType === 'LAND'
                                                ? 'warning'
                                                : undefined
                                          }
                                        >
                                          <CommonCode
                                            groupCode="REGISTRATION_TYPE"
                                            code={registrationType}
                                          />
                                        </Chip>
                                      )}
                                      <Input
                                        readOnly
                                        fullWidth
                                        value={registrationAddress}
                                      />
                                    </Stack>
                                  ),
                                )}
                              </Stack>
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>

                    {localAddressesFields.length > 1 && (
                      <Button
                        variant="outlined"
                        color="danger"
                        onClick={() => removeLocalAddresses(index)}
                        sx={{ alignSelf: 'flex-start' }}
                      >
                        삭제
                      </Button>
                    )}
                  </Sheet>
                ))}

                <Button
                  variant="outlined"
                  startDecorator={<AddIcon />}
                  sx={{ mt: 1 }}
                  onClick={() =>
                    appendLocalAddresses({
                      city: '',
                      gu: '',
                      dong: '',
                      jibun: '',
                      zoneRoad: '',
                      building: '',
                      apartmentDong: '',
                      apartmentFloor: '',
                      apartmentHosu: '',
                      checkStatus: 'N',
                      registrationAddresses: [],
                    })
                  }
                >
                  소재지 정보 추가
                </Button>
              </Stack>

              <Stack flexDirection="row" justifyContent="space-between">
                <Button
                  size="lg"
                  variant="outlined"
                  color="neutral"
                  component={Link}
                  href=".."
                >
                  목록
                </Button>

                <Stack flexDirection="row-reverse" gap={1}>
                  <Button
                    variant="outlined"
                    color="danger"
                    size="lg"
                    onClick={handleClickRegistersDelete}
                  >
                    삭제
                  </Button>

                  <Button type="submit" size="lg">
                    저장
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </TabPanel>
      </Tabs>

      <LocalAddressConfirmModal
        open={localAddressConfirmModalOption?.open || false}
        onClose={() => setLocalAddressConfirmModalOption(undefined)}
        onSubmit={handleLocalAddressConfirm}
      />
    </>
  );
};

const registerModifyPage: CustomRouteObject = {
  path: ':unionRegisterSeq/modify',
  children: [
    { index: true, element: <RegisterModifyPage /> },
    registerModifyAuthsPage,
  ],
};

export default registerModifyPage;
