import styled from '@mui/joy/styles/styled';

import type { DescriptionsOwnerState } from 'design-system/components/dataDisplay/descriptions/Descriptions';
import type { DescriptionsItemProps } from 'design-system/components/dataDisplay/descriptions/DescriptionsItem';

export type DescriptionsRowProps = {
  ownerState: DescriptionsOwnerState;
  row: DescriptionsItemProps[];
};

const Row = (props: DescriptionsRowProps) => {
  const { ownerState, row } = props;

  return (
    <Item ownerState={ownerState}>
      {row.map(({ label, span = 1, children }, index) => [
        <ItemLabel
          key={`item-label-${index}`}
          colSpan={1}
          ownerState={ownerState}
        >
          {label}
        </ItemLabel>,
        <ItemContent
          key={`item-content-${index}`}
          colSpan={span === 1 ? 1 : span - 1}
          ownerState={ownerState}
        >
          {children}
        </ItemContent>,
      ])}
    </Item>
  );
};

const Item = styled('tr', {
  name: 'JoyDescriptionsItem',
  slot: 'root',
})<{ ownerState: DescriptionsOwnerState }>`
  vertical-align: top;
`;

const ItemLabel = styled('th', {
  name: 'JoyDescriptionsItem',
  slot: 'label',
})<{ ownerState: DescriptionsOwnerState }>(({ theme, ownerState }) => ({
  textAlign: 'left',
  verticalAlign: 'middle',
  width: '7.5rem',
  maxWidth: 'max-content',
  minWidth: 'fit-content',
  wordBreak: 'keep-all',
  overflowWrap: 'break-word',
  backgroundColor: theme.palette.background.level1,

  fontWeight: theme.fontWeight.lg,

  ...(ownerState.size === 'sm' && {
    fontSize: theme.fontSize.sm,
    lineHeight: theme.lineHeight.sm,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),

  ...(ownerState.size === 'md' && {
    fontSize: theme.fontSize.sm,
    lineHeight: theme.lineHeight.sm,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  }),

  ...(ownerState.size === 'lg' && {
    fontSize: theme.fontSize.md,
    lineHeight: theme.lineHeight.md,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }),
}));

const ItemContent = styled('td', {
  name: 'JoyDescriptionsItem',
  slot: 'content',
})<{ ownerState: DescriptionsOwnerState }>(({ theme, ownerState }) => ({
  verticalAlign: 'middle',
  fontWeight: theme.fontWeight.md,

  ...(ownerState.size === 'sm' && {
    fontSize: theme.fontSize.sm,
    lineHeight: theme.lineHeight.sm,
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),

  ...(ownerState.size === 'md' && {
    fontSize: theme.fontSize.sm,
    lineHeight: theme.lineHeight.sm,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  }),

  ...(ownerState.size === 'lg' && {
    fontSize: theme.fontSize.md,
    lineHeight: theme.lineHeight.md,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }),

  ...(ownerState.variant !== 'solid' && {
    color: theme.palette.text.secondary,
  }),
}));

export default Row;
