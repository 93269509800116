import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  DateRangePicker,
  Descriptions,
  DescriptionsItem,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFileDownloadMutation } from 'apis/common/apis';
import {
  CreatePostSendBody,
  PostRecipientParams,
  useCreatePostResendMutation,
  usePostQuery,
  usePostRecipientsQuery,
} from 'apis/post/apis';
import { POST_DETAIL_RECORD_COLUMNS } from 'apis/post/fixtures';
import CommonCode from 'components/CommonCode';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import PostBalanceStep from 'components/pages/posts/stepper/PostBalanceStep';
import PostResendStep from 'components/pages/posts/stepper/PostResendStep';
import Search from 'components/Search';
import StepperDialog from 'components/StepperDialog';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber, formatDateTime, formatTelephone } from 'utils/format';

const DEFAULT_SEARCH_VALUES = {
  unionRegisterName: '',
  postAddress: '',
  searchFromTo: '',
};
const DEFAULT_SEARCH_PARAMS = {
  unionRegisterName: '',
  postAddress: '',
  searchFrom: '',
  searchTo: '',
};

const DEFAULT_SELECTED_UNION_STATE = {
  open: false,
  seq: 0,
};

interface SearchParamsValues {
  unionRegisterName: string;
  postAddress: string;
  searchFromTo: string;
}

const PostRecordDetailPage = () => {
  const { unionSeq, postSeq } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState<PostRecipientParams>({
    unionSeq: Number(unionSeq),
    postSeq: Number(postSeq),
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [selectedUnionState, setSelectedUnionState] = useState(
    DEFAULT_SELECTED_UNION_STATE,
  );
  const [isStepperOpen, setIsStepperOpen] = useState<boolean>(false);

  const { data: info } = usePostQuery({
    unionSeq: Number(unionSeq),
    postSeq: Number(postSeq),
  });
  const {
    data: recipients,
    isPending,
    isError,
    error,
  } = usePostRecipientsQuery(searchParams);

  const { mutate: downloadFile } = useFileDownloadMutation();
  const { mutate: resendPost, isPending: isSendPostPending } =
    useCreatePostResendMutation();

  const { snackbar, alertDialog } = useFeedback();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'post-records-detail',
    activeSaveSnapshot: true,
  });

  const { columns } = useCreateGridColumns({
    columns: POST_DETAIL_RECORD_COLUMNS,
    handlers: {
      onClickUnionMember: (seq: number | undefined) => {
        if (!seq) {
          alertDialog('조합원이 아니거나 조합원 정보가 없습니다.');
          return;
        }

        setSelectedUnionState({
          open: true,
          seq,
        });
      },
    },
  });

  const {
    sender,
    postType,
    colorType,
    flexType,
    isStapler,
    sendQuantity,
    requestCount,
    requestedAt,
    sentAt,
    postStatus,
    file,
  } = info?.data ?? {};

  const postResendDefaultValues = {
    ...sender?.address,
    name: sender?.name,
    phoneNo: sender?.phoneNo,
    fileSeq: file?.fileSeq,
    postType,
    colorType,
    flexType,
    isStapler,
    sendQuantity,
  };

  const selectedRows = useMemo(
    () =>
      recipients?.data?.filter(({ postRecipientSeq }) =>
        rowSelectionModel.includes(postRecipientSeq),
      ),
    [recipients, rowSelectionModel],
  );

  const isRowSelected = useMemo(
    () => !!(rowSelectionModel.length > 0),
    [rowSelectionModel],
  );

  const handleSearchParams = (values: SearchParamsValues) => {
    if (!values) {
      return;
    }

    const {
      searchFromTo: [searchFrom, searchTo],
      ...otherValues
    } = values;

    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...otherValues,
      searchFrom,
      searchTo,
    }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleMoveParentPage = () => {
    navigate('..', { relative: 'path' });
  };

  const handleDownloadPostDocument = async () => {
    downloadFile(
      {
        fileSeq: info?.data?.file.fileSeq || 0,
      },
      {
        onSuccess: (data) => {
          const url = URL.createObjectURL(data);

          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = info?.data?.file.originName ?? '';

          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);

          URL.revokeObjectURL(url);
        },
      },
    );
  };

  const handleSubmitResend = ({
    colorType,
    postType,
    recipients,
  }: CreatePostSendBody) => {
    resendPost(
      {
        unionSeq: Number(unionSeq),
        postSeq: Number(postSeq),
        colorType,
        postType,
        recipients,
      },
      {
        onSuccess: () => {
          snackbar('우편 발송 요청이 승인 되었습니다', { color: 'success' });

          setIsStepperOpen(false);
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  if (isError || !postSeq) {
    throw error;
  }

  return (
    <>
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
            상세 발송 내역
          </Typography>

          <Descriptions variant="soft" color="primary" size="md" columns={4}>
            <DescriptionsItem label="발송자명">{sender?.name}</DescriptionsItem>
            <DescriptionsItem label="발송자 주소">
              {`${sender?.address.address ?? ''} ${
                sender?.address.detailAddress ?? ''
              }`}
            </DescriptionsItem>
            <DescriptionsItem label="발송자 우편번호">
              {sender?.address.zipNo}
            </DescriptionsItem>
            <DescriptionsItem label="발송자 연락처">
              {formatTelephone(sender?.phoneNo)}
            </DescriptionsItem>
            <DescriptionsItem label="발송방식">
              <CommonCode groupCode="POST_SEND_DIVIDE" code={postType ?? ''} />
            </DescriptionsItem>
            <DescriptionsItem label="수신자 수">
              {commaizeNumber(requestCount || 0)} 명
            </DescriptionsItem>
            <DescriptionsItem label="발송매수">
              {commaizeNumber(sendQuantity || 0)} 장
            </DescriptionsItem>
            <DescriptionsItem label="출력색상">
              <CommonCode
                groupCode="POST_COLOR_DIVIDE"
                code={colorType ?? ''}
              />
            </DescriptionsItem>
            <DescriptionsItem label="출력형태">
              <CommonCode groupCode="POST_FLEX_DIVIDE" code={flexType ?? ''} />
            </DescriptionsItem>
            <DescriptionsItem label="스테이플러">
              {isStapler ? '예' : '아니요'}
            </DescriptionsItem>
            <DescriptionsItem label="신청일시">
              {requestedAt &&
                formatDateTime(requestedAt, 'yyyy-MM-dd HH:mm:ss')}
            </DescriptionsItem>
            <DescriptionsItem label="발송일시">
              {sentAt && formatDateTime(sentAt, 'yyyy-MM-dd HH:mm:ss')}
            </DescriptionsItem>
            <DescriptionsItem label="제작 발송 상태">
              <CommonCode groupCode="POST_STATUS" code={postStatus ?? ''} />
            </DescriptionsItem>
            <DescriptionsItem label="우편물">
              <Link fontSize="sm" onClick={handleDownloadPostDocument}>
                {file?.originName}
              </Link>
            </DescriptionsItem>
          </Descriptions>
        </Stack>

        <Stack gap={1.75}>
          <Search
            defaultValues={DEFAULT_SEARCH_VALUES}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    label="이름"
                    name="unionRegisterName"
                    placeholder="이름 입력"
                    fullWidth
                    slotProps={{
                      input: { maxLength: 30 },
                    }}
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    label="우편물 수령지"
                    name="postAddress"
                    placeholder="주소 입력"
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <DateRangePicker
                    label="배달 완료일시"
                    name="searchFromTo"
                    sx={{ field: { maxWidth: 200 } }}
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>
        </Stack>

        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(recipients?.pagination?.totalDataCount ?? 0)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(recipients?.pagination?.totalElements ?? 0)}
              </Typography>
            </Typography>
          </Stack>

          <Stack height={442}>
            <DataGrid
              apiRef={datagridApiRef}
              rows={recipients?.data ?? []}
              columns={columns}
              loading={isPending}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={setRowSelectionModel}
              getRowId={(row) => row.postRecipientSeq}
              isRowSelectable={({ row }) => row.deliveryStatus === '미배달'}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={1}>
              <Button
                variant="outlined"
                color="neutral"
                onClick={handleMoveParentPage}
              >
                목록
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => exportExcel()}
              >
                내역 다운
              </Button>
            </Stack>

            <Button
              disabled={!isRowSelected}
              onClick={() => setIsStepperOpen(true)}
            >
              우편 재발송
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {selectedUnionState.open && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq: Number(unionSeq),
            unionRegisterSeq: selectedUnionState.seq,
          }}
          open={selectedUnionState.open}
          onClose={() => setSelectedUnionState(DEFAULT_SELECTED_UNION_STATE)}
        />
      )}

      {isStepperOpen && (
        <StepperDialog
          title="우편 재발송"
          defaultLastButtonText="우편 재발송"
          defaultValues={postResendDefaultValues}
          open={isStepperOpen}
          onClose={() => {
            setIsStepperOpen(false);
          }}
        >
          <StepperDialog.Step name="주소 수정">
            <PostResendStep defaultRows={selectedRows} />
          </StepperDialog.Step>
          <StepperDialog.Step name="비용 조회">
            <PostBalanceStep
              unionSeq={Number(unionSeq)}
              isSendPostPending={isSendPostPending}
              onSubmit={handleSubmitResend}
            />
          </StepperDialog.Step>
        </StepperDialog>
      )}
    </>
  );
};

const postRecordDetailPage: CustomRouteObject = {
  path: ':postSeq',
  element: <PostRecordDetailPage />,
  handle: {
    getTitle: () => '상세 내역 관리',
  },
};

export default postRecordDetailPage;
