import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Stack,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import { useReadElectronicConsentTemplate } from 'apis/electronic-consents/apis';

export interface ConsentPreviewModalProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  url: string;
  onClose: (open: boolean) => void;
}

const ConsentPreviewModal = (props: ConsentPreviewModalProps) => {
  const { url, onClose, ...rest } = props;

  const { data: template, isLoading } = useReadElectronicConsentTemplate(url);

  const templatePreviews = useMemo(
    () =>
      template?.data.pages.map((page) => ({
        id: page.id,
        src: page.content.backgroundImage.src,
      })) ?? [],
    [template?.data],
  );

  return (
    <Modal {...rest} onClose={onClose}>
      <ModalOverflow>
        <ModalDialog sx={{ minHeight: '90vh', width: 650 }}>
          <DialogTitle>동의서 서식 미리보기</DialogTitle>

          {isLoading && (
            <Stack sx={{ margin: 'auto' }}>
              <CircularProgress />
            </Stack>
          )}
          {!isLoading && (
            <DialogContent sx={{ py: 1 }}>
              {templatePreviews.map(({ id, src }, index) => (
                <img
                  key={`preview_${id}`}
                  src={src}
                  alt={`${index + 1}번째 동의서 미리보기`}
                  width="100%"
                />
              ))}
            </DialogContent>
          )}

          <DialogActions>
            <Button onClick={() => onClose(false)}>닫기</Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default ConsentPreviewModal;
