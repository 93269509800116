import { DatePicker as XDatePicker } from '@mui/x-date-pickers-pro';
import type {
  DateValidationError,
  PickerChangeHandlerContext,
  DatePickerProps as XDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import { FormControlProps } from 'design-system/components/inputs/FormControl';
import DateField, {
  DateFieldProps,
} from 'design-system/components/pickers/date/DateField';
import { formatDateTime } from 'design-system/components/pickers/utils';

export interface DatePickerProps
  extends Omit<
    XDatePickerProps<Date, false>,
    'value' | 'defaultValue' | 'onChange' | 'minDate' | 'maxDate'
  > {
  variant?: DateFieldProps['variant'];
  color?: DateFieldProps['color'];
  size?: DateFieldProps['size'];
  error?: DateFieldProps['error'];
  helperText?: DateFieldProps['helperText'];
  fullWidth?: DateFieldProps['fullWidth'];
  required?: DateFieldProps['required'];
  minDate?: string | number | null | Date;
  maxDate?: string | number | null | Date;
  formControlSx?: FormControlProps['sx'];
  value?: string | number | null | Date;
  defaultValue?: string | number | null | Date;
  onChange?: (
    value: string | number | null | Date,
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => void;
}

const DatePicker = (
  props: DatePickerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    variant = 'outlined',
    color = 'neutral',
    size = 'md',
    error,
    helperText,
    slots,
    slotProps,
    value: valueProp,
    defaultValue: defaultValueProp,
    onChange,
    minDate: minDateProp,
    maxDate: maxDateProp,
    formControlSx,
    required,
    ...other
  } = props;

  const defaultValue = useMemo(
    () => (defaultValueProp ? new Date(defaultValueProp) : null),
    [defaultValueProp],
  );

  const value = useMemo(
    () => (valueProp ? new Date(valueProp) : null),
    [valueProp],
  );

  const minDate = useMemo(
    () => (minDateProp ? new Date(minDateProp) : undefined),
    [minDateProp],
  );

  const maxDate = useMemo(
    () => (maxDateProp ? new Date(maxDateProp) : undefined),
    [maxDateProp],
  );

  const handleChange = useCallback(
    (
      value: Date | null,
      context: PickerChangeHandlerContext<DateValidationError>,
    ) => {
      if (!value) {
        return onChange?.(null, context);
      }

      const formattedDateValue = formatDateTime(value, 'yyyy-MM-dd') || null;
      return onChange?.(formattedDateValue, context);
    },
    [onChange],
  );

  return (
    <XDatePicker
      ref={ref}
      slots={{ ...slots, field: DateField }}
      slotProps={{
        ...slotProps,
        field: {
          variant,
          color,
          size,
          error,
          helperText,
          ...slotProps?.field,
          formControlSx: {
            flexDirection: 'row',
            ...formControlSx,
          },
          required,
        } as never,
      }}
      defaultValue={defaultValue}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      onChange={handleChange}
      {...other}
    />
  );
};

export default forwardRef<HTMLDivElement, DatePickerProps>(DatePicker);
