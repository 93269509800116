import { formatDateTime } from '@wooriga/common-utils';
import { GridColDef, Button, Link, Chip } from '@wooriga/design-system';

import { Viewer } from 'apis/meet/live/api';
import { LocalAddress } from 'apis/types/common';
import { Participant } from 'lim/generalMeetingHistoryDetail/apis';
import { GridColumnsHandlers } from 'types/grid';
import { formatTimeDistanceHangul } from 'utils/format';

export interface OnlineGeneralMeetAttendeeColumns extends Participant {
  viewerAttendanceStatus: string;
  attendanceCategory: string;
  voteCategory: string;
  attendanceAt: string;
  localAddresses: LocalAddress[];
}

export interface OnlineGeneralMeetOpinionColumns {
  name: string;
  comment: string;
  commentAt: string;
  status: string;
  opinionSeq: number;
  viewer: Viewer;
}

export interface OnlineGeneralMeetSpeechColumns {
  viewer: Viewer;
  registeredAt: string;
  speechSeq: number;
  speechStatusType: string;
  speechCategory: string;
  viewerSeq: number;
  isAble: boolean;
}

// 참석자 현황
export const ONLINE_GENERAL_MEET_ATTENDEE_COLUMNS = ({
  onNameClick,
}: GridColumnsHandlers<{
  onNameClick: (viewerSeq: number) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },

    {
      field: 'name',
      headerName: '이름',
      valueGetter: (_, row) => {
        return row?.name?.name;
      },
      renderCell: (params) => {
        const memberName = params.row?.name?.name || '';

        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.row.unionRegisterSeq)}
          >
            {memberName || ''}
          </Link>
        );
      },
    },
    {
      field: 'localAddressJibun',
      headerName: '지번',
      valueGetter: (_, row) => {
        return row?.localAddresses[0]?.jibun || '';
      },
    },
    {
      field: 'localAddressBuilding',
      headerName: '건물명',
      flex: 1,
      valueGetter: (_, row) => {
        return row?.localAddresses[0]?.building || '';
      },
    },
    {
      field: 'localAddressApartmentDong',
      headerName: '동',
      valueGetter: (_, row) => {
        return row?.localAddresses[0]?.apartmentDong || '';
      },
    },
    {
      field: 'localAddressApartmentHosu',
      headerName: '호',
      valueGetter: (_, row) => {
        return row?.localAddresses[0]?.apartmentHosu || '';
      },
    },

    {
      field: 'agent',
      headerName: '대리인',
      type: 'boolean',
      valueGetter: (_, row) => {
        return (
          (row?.agent?.name?.name || '') + (row?.agent?.name?.nameClass || '')
        );
      },
    },
    {
      field: 'attendanceCategory',
      headerName: '참석 유형',
      valueGetter: (_, row) =>
        row?.attendanceCategory === 'ONLINE'
          ? '온라인'
          : row?.attendanceCategory === 'ONSITE'
            ? '현장'
            : row?.attendanceCategory === 'NOT_ATTEND'
              ? '미참석'
              : '',
      renderCell: ({ value }) => {
        if (value === '온라인')
          return (
            <Chip variant="outlined" color="success" size="lg">
              {value}
            </Chip>
          );
        if (value === '현장')
          return (
            <Chip variant="outlined" color="blue" size="lg">
              {value}
            </Chip>
          );
        if (value === '미참석')
          return (
            <Chip variant="outlined" color="neutral" size="lg">
              {value}
            </Chip>
          );
      },
    },
    {
      field: 'attendanceAt',
      headerName: '참석시간',
      valueGetter: (value) => value && formatDateTime(value, 'HH:mm:ss'),
    },
    {
      field: 'viewerAttendanceStatus',
      headerName: '온라인 상태',
      valueGetter: (_, row) =>
        row?.viewerAttendanceStatus === 'ATTEND'
          ? '접속중'
          : row?.viewerAttendanceStatus === 'LEAVE'
            ? '미접속'
            : '',
      renderCell: ({ value }) => {
        if (value === '접속중')
          return (
            <Chip variant="soft" color="success" size="lg">
              {value}
            </Chip>
          );
        if (value === '미접속')
          return (
            <Chip variant="soft" color="neutral" size="lg">
              {value}
            </Chip>
          );
      },
    },
  ] as GridColDef<OnlineGeneralMeetAttendeeColumns>[];

// 분리
export const ONLINE_GENERAL_MEET_OPINIONS_COLUMNS = ({
  onNameClick,
  onOpinionClick,
}: GridColumnsHandlers<{
  onNameClick: (viewerSeq: number) => void;
  onOpinionClick: (opinionSeq: number) => void;
}>) =>
  [
    {
      field: 'name',
      headerName: '이름',
      width: 150,
      renderCell: (params) => {
        const viewerName = params.row.viewer.name;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.row.viewer.viewerSeq || 0)}
          >
            {viewerName}
          </Link>
        );
      },
    },

    {
      field: 'comment',
      headerName: '내용',
      flex: 1,
      // width: 1,
    },
    {
      field: 'commentAt',
      headerName: '등록시간',
      width: 100,
      valueGetter: (_, value) => formatTimeDistanceHangul(value.commentAt),
    },
    {
      field: 'status',
      headerName: '상태',
      width: 120,
      valueGetter: (_, row) =>
        row?.status === 'PENDING'
          ? '답변대기중'
          : row?.status === 'COMPLETED'
            ? '답변완료'
            : '',
      renderCell: ({ value }) => {
        if (value === '답변대기중')
          return (
            <Chip variant="outlined" color="blue" size="lg">
              {value}
            </Chip>
          );
        if (value === '답변완료')
          return (
            <Chip variant="solid" color="neutral" size="lg">
              {value}
            </Chip>
          );
      },
    },
    {
      field: 'viewer',
      headerName: '',
      width: 106,
      renderCell: (params) => {
        const opinionStatus = params.row.status;
        return opinionStatus === 'PENDING' ? (
          <Button
            size="md"
            variant="outlined"
            color="neutral"
            onClick={() => {
              onOpinionClick(params.row.opinionSeq);
            }}
          >
            답변입력
          </Button>
        ) : (
          ''
        );
      },
    },
  ] as GridColDef<OnlineGeneralMeetOpinionColumns>[];

// 발언 요청 리스트
export const ONLINE_GENERAL_MEET_SPEECH_COLUMNS = ({
  onNameClick,
  onClickSpeech,
}: GridColumnsHandlers<{
  onNameClick: (viewerSeq: number) => void;
  onClickSpeech: (speechSeq: number, type: string) => void;
}>) =>
  [
    {
      field: 'viewerName',
      headerName: '이름',
      renderCell: (params) => {
        const viewerName = `${params?.row?.viewer?.name}`;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.row.viewer?.viewerSeq)}
          >
            {viewerName}
          </Link>
        );
      },
    },
    {
      field: 'content',
      headerName: '내용',
      flex: 1,
      valueGetter: (_, row) => {
        const speechCategoryType =
          row?.speechCategory === 'VOICE'
            ? '음성'
            : row?.speechCategory === 'PHONE'
              ? '전화'
              : '';
        const speechStatusType =
          row?.speechStatusType === 'REQUESTED'
            ? '발언요청'
            : row?.speechStatusType === 'SPEAKING'
              ? '발언중'
              : row?.speechStatusType === 'COMPLETED'
                ? '발언완료'
                : row?.speechStatusType === 'PENDING'
                  ? '연결대기'
                  : row?.speechStatusType === 'CANCELED'
                    ? '발언취소'
                    : '';

        const speechText = `${speechCategoryType} ${speechStatusType}`;

        return speechText;
      },
    },
    {
      field: 'registeredAt',
      headerName: '등록시간',
      valueGetter: (_, value) => formatTimeDistanceHangul(value?.registeredAt),
    },

    {
      field: 'speechCategory',
      headerName: '구분',
      width: 120,
      valueGetter: (_, row) =>
        row?.speechCategory === 'VOICE'
          ? '음성연결'
          : row?.speechCategory === 'PHONE'
            ? '전화연결'
            : '',
      renderCell: ({ value }) => {
        if (value === '음성연결')
          return (
            <Chip variant="outlined" color="success" size="lg">
              {value}
            </Chip>
          );
        if (value === '전화연결')
          return (
            <Chip variant="outlined" color="warning" size="lg">
              {value}
            </Chip>
          );
      },
      // renderCell: () => {
      //   const time2 = new Date();
      //   return time2;
      // },
    },
    {
      field: 'speechStatusType',
      headerName: '상태',
      width: 120,
      valueGetter: (_, row) =>
        row?.speechStatusType === 'REQUESTED'
          ? '발언요청'
          : row?.speechStatusType === 'SPEAKING'
            ? '발언중'
            : row?.speechStatusType === 'COMPLETED'
              ? '발언완료'
              : row?.speechStatusType === 'PENDING'
                ? '연결대기'
                : row?.speechStatusType === 'CANCELED'
                  ? '발언취소'
                  : '',
      renderCell: ({ value }) => {
        if (value === '발언요청')
          return (
            <Chip variant="outlined" color="blue" size="lg">
              {value}
            </Chip>
          );
        if (value === '발언중')
          return (
            <Chip variant="solid" color="success" size="lg">
              {value}
            </Chip>
          );
        if (value === '발언완료')
          return (
            <Chip variant="outlined" color="neutral" size="lg">
              {value}
            </Chip>
          );
        if (value === '연결대기')
          return (
            <Chip variant="solid" color="primary" size="lg">
              {value}
            </Chip>
          );
        if (value === '발언취소')
          return (
            <Chip variant="outlined" color="neutral" size="lg">
              {value}
            </Chip>
          );
      },
    },
    {
      field: 'speechSeq',
      headerName: '',
      width: 160,
      renderCell: (params) => {
        const speechStatus = params.row.speechStatusType;
        const speechSeq = params.row.speechSeq;
        return speechStatus === 'REQUESTED' ? (
          <Button
            size="md"
            variant="outlined"
            color="neutral"
            onClick={() => {
              onClickSpeech(speechSeq, 'APPROVED');
            }}
            disabled={!params.row.isAble}
          >
            발언승인
          </Button>
        ) : speechStatus === 'SPEAKING' ? (
          <Button
            size="md"
            variant="outlined"
            color="neutral"
            onClick={() => {
              onClickSpeech(speechSeq, 'DISCONNECTED');
            }}
          >
            연결종료
          </Button>
        ) : speechStatus === 'PENDING' ? (
          <Button
            size="md"
            variant="outlined"
            color="neutral"
            onClick={() => {
              onClickSpeech(speechSeq, 'CONNECTION_CANCEL');
            }}
          >
            연결취소
          </Button>
        ) : (
          ''
        );
      },
    },
  ] as GridColDef<OnlineGeneralMeetSpeechColumns>[];
