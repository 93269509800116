import { GridColDef } from '@mui/x-data-grid-premium';
import { Link, Button, Stack, Typography } from '@wooriga/design-system';

import { DocumentIssuanceInfo } from 'apis/types/document-issuance';
import { GridColumnsHandlers } from 'types/grid';

export interface DocumentIssuanceInfoColumnProps {
  urls: Record<string, string>;
  onClickUnionMember: (unionMemberSeq: number) => void;
  onClickDownload: (url: string) => void;
}

export const DOCUMENT_ISSUANCE_INFO_COLUMNS = ({
  urls,
  onClickUnionMember,
  onClickDownload,
  getCommonCode,
}: GridColumnsHandlers<DocumentIssuanceInfoColumnProps>) =>
  [
    // {
    //   field: 'no',
    //   headerName: 'No',
    //   width: 78,
    //   valueGetter: (_, row, __, apiRef) =>
    //     apiRef.current
    //       .getAllRowIds()
    //       .indexOf(`${row.documentCode}-${row.unionRegisterSeq}`) + 1 || '',
    // },
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'unionRegisterName',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      valueGetter: (value: DocumentIssuanceInfo['unionRegisterName'], row) =>
        row.unionRegisterSeq &&
        (value ? [value?.name, value?.nameClass].join('') : '이름없음'),
      renderCell: ({ value, row }) =>
        row?.unionRegisterSeq && (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickUnionMember(row.unionRegisterSeq)}
          >
            {value}
          </Link>
        ),
    },
    {
      field: 'issuanceType',
      headerName: '구분',
    },
    {
      field: 'unionRegisterAddress',
      headerName: '신청주소',
      width: 230,
    },
    {
      field: 'documentCode',
      headerName: '발급자료',
      width: 350,
      valueFormatter: (value) => getCommonCode('DOCUMENT_CODE', value)?.name,
      renderCell: (params) => {
        const { value, formattedValue, rowNode } = params;
        if (rowNode.type !== 'group') {
          return formattedValue;
        }

        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography level="body-md">
              {getCommonCode('DOCUMENT_CODE', value)?.name}
            </Typography>

            {!!urls[value] && (
              <Button
                variant="outlined"
                color="neutral"
                size="sm"
                onClick={() => onClickDownload(urls[value])}
              >
                다운로드
              </Button>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'issuanceStatus',
      headerName: '결과',
      valueGetter: (value) =>
        getCommonCode('DOCUMENT_ISSUANCE_STATUS', value)?.name,
    },
    { field: 'failReason', headerName: '오류내역', width: 100 },
  ] as GridColDef<DocumentIssuanceInfo & { no: ''; action: '' }>[];
