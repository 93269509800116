import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRowId,
  gridRowsLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';

import IconButton from 'design-system/components/inputs/IconButton';

const JoyDetailPanelToggleButton = () => {
  const apiRef = useGridApiContext();

  const expandedRowIds = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowIdsSelector,
  );
  const rowsWithDetailPanels = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector,
  );

  const noDetailPanelsOpen = expandedRowIds.length === 0;

  const expandOrCollapseAll = () => {
    const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
    const allRowIdsWithDetailPanels: GridRowId[] = Object.keys(
      rowsWithDetailPanels,
    ).map((key) => apiRef.current.getRowId(dataRowIdToModelLookup[key]));

    apiRef.current.setExpandedDetailPanels(
      noDetailPanelsOpen ? allRowIdsWithDetailPanels : [],
    );
  };

  const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;

  return (
    <IconButton
      size="sm"
      tabIndex={-1}
      onClick={expandOrCollapseAll}
      aria-label={noDetailPanelsOpen ? 'Expand All' : 'Collapse All'}
    >
      <Icon fontSize="inherit" />
    </IconButton>
  );
};

export default JoyDetailPanelToggleButton;
