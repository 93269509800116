import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useState } from 'react';

import useFeedback from 'hooks/useFeedback';
import {
  useAuthGroupMenusQuery,
  useAvailableMenusQuery,
  useUpdateAuthGroupMenusMutation,
} from 'lim/auth/apis';

interface AuthListTabProps {
  isAutoGenerated?: boolean;
  unionSeq: number;
  unionAuthGroupSeq: number;
}

const AuthListTab = ({
  isAutoGenerated,
  unionSeq,
  unionAuthGroupSeq,
}: AuthListTabProps) => {
  const { snackbar } = useFeedback();
  const { data, error, isError } = useAvailableMenusQuery(unionSeq);

  const { data: checkedData } = useAuthGroupMenusQuery(
    unionSeq,
    unionAuthGroupSeq,
  );

  const { mutate: updateMutate } = useUpdateAuthGroupMenusMutation(
    unionSeq,
    unionAuthGroupSeq,
  );

  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: '',
    content: <></>,
    onConfirm: () => {},
  });

  const handleOnChange = (menuSeq: number) => {
    const ids = new Set(checkedIds);
    if (ids.has(menuSeq)) {
      ids.delete(menuSeq);
    } else {
      ids.add(menuSeq);
    }

    setCheckedIds([...ids]);
  };

  const handleSubmit = () => {
    setModalInfo({
      show: true,
      title: '변경사항을 저장 하시겠습니까?',
      content: (
        <>
          <Typography textColor="text.tertiary">
            권한이 변경되면 권한이 부여된 권한자들이 접근할 수 있는
          </Typography>
          <Typography>메뉴와 권한이 변경 됩니다.</Typography>
        </>
      ),
      onConfirm: () => {
        updateMutate(
          { menuSeqs: checkedIds },
          {
            onSuccess: () => {
              snackbar('권한 변경이 완료되었습니다.', {
                color: 'success',
              });
              setModalInfo({
                ...modalInfo,
                show: false,
              });
            },
            onError: (error) => {
              snackbar(error?.response?.data.message ?? error.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  };

  useEffect(() => {
    setCheckedIds(checkedData?.data.menuSeqs ?? []);
  }, [checkedData?.data]);

  if (isError) throw error;

  return (
    <>
      <Stack>
        <Stack>
          {isAutoGenerated && (
            <Callout color="neutral" startDecorator={<InfoIcon />}>
              기본 권한 그룹은 권한 목록을 수정할 수 없습니다.
            </Callout>
          )}
          {data?.data?.map((auth, i) => {
            const { name, description, code, subMenus } = auth;
            const isLast = i === data.data.length - 1;
            return (
              <Stack key={code} gap={2}>
                <Stack>
                  <Typography level="title-lg" textColor="text.primary">
                    {name}
                  </Typography>
                  <Typography level="body-sm" textColor="text.tertiary">
                    {description}
                  </Typography>
                </Stack>

                <Stack flexDirection="row" gap="14px" flexWrap="wrap">
                  {subMenus.map((auth) => {
                    const { name, code, menuSeq } = auth;
                    return (
                      <Checkbox
                        key={menuSeq}
                        disabled={isAutoGenerated}
                        color="primary"
                        name={code}
                        label={name}
                        value={menuSeq}
                        checked={checkedIds.includes(menuSeq)}
                        onChange={() => handleOnChange(menuSeq)}
                      />
                    );
                  })}
                </Stack>

                {!isLast && <Divider sx={{ margin: '10px 0' }} />}
              </Stack>
            );
          })}
        </Stack>
        <Stack alignItems="end">
          <Button disabled={isAutoGenerated} onClick={handleSubmit}>
            저장
          </Button>
        </Stack>
      </Stack>

      <Modal
        open={modalInfo.show}
        onClose={() =>
          setModalInfo({
            ...modalInfo,
            show: false,
          })
        }
      >
        <ModalDialog>
          <DialogTitle>{modalInfo.title}</DialogTitle>
          <DialogContent>{modalInfo.content}</DialogContent>
          <DialogActions>
            <Button onClick={modalInfo.onConfirm}>확인</Button>
            <Button
              variant="outlined"
              color="neutral"
              onClick={() =>
                setModalInfo({
                  ...modalInfo,
                  show: false,
                })
              }
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default AuthListTab;
