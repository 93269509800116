import { RevealPortal } from '@wooriga/common-reveal';
import { Outlet } from 'react-router-dom';

import mainRouter from 'routes/mainRouter';
import publicRouter from 'routes/publicRouter';
import { useBoundStore } from 'stores';
import { CustomRouteObject } from 'types/route';

const children = [mainRouter, publicRouter] as CustomRouteObject[];

const rootRoute = {
  element: (
    <>
      <RevealPortal store={useBoundStore} />
      <Outlet />
    </>
  ),
  children,
} as CustomRouteObject;

export default rootRoute;
