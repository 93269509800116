import EventIcon from '@mui/icons-material/Event';
import { formatDate } from '@wooriga/common-utils';
import {
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
  LinearProgressProps,
} from '@wooriga/design-system';

export interface ElectronicConsentCardProps {
  seq: number;
  title: string;
  rate?: number;
  startedAt: string;
  endedAt: string;
  onClick?: (seq: number) => void;
  linearProgressProps?: Omit<LinearProgressProps, 'determine' | 'value'>;
}

const ElectronicConsentCard = ({
  seq,
  title,
  rate = 0,
  startedAt,
  endedAt,
  linearProgressProps,
  onClick,
}: ElectronicConsentCardProps) => {
  return (
    <Card
      sx={{ cursor: 'pointer', p: 3, bgcolor: 'white', boxShadow: 'md' }}
      onClick={() => onClick?.(seq)}
    >
      <CardContent sx={{ gap: 2 }}>
        <Typography fontSize="lg" fontWeight="xl" lineHeight="md">
          {title}
        </Typography>

        <Stack gap={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              fontSize="md"
              fontWeight="md"
              lineHeight="lg"
              textColor="neutral.500"
            >
              참여율
            </Typography>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              {rate}%
            </Typography>
          </Stack>

          <LinearProgress {...linearProgressProps} value={rate} determinate />
        </Stack>

        <Typography
          fontSize="md"
          fontWeight="md"
          lineHeight="lg"
          textColor="neutral.500"
          startDecorator={<EventIcon sx={{ fontSize: 18 }} />}
        >
          {formatDate(startedAt)} ~ {endedAt ? formatDate(endedAt) : '미정'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ElectronicConsentCard;
