import { Chip, ChipProps } from '@wooriga/design-system';

import CommonCode, { CommonCodeProps } from 'components/CommonCode';

const MeetAttendanceCategoryChip = ({
  code,
}: Pick<CommonCodeProps, 'code'>) => {
  const getVariant = (): {
    color?: ChipProps['color'];
  } => {
    switch (code) {
      case 'ONLINE':
        return {
          color: 'primary',
        };
      case 'ONSITE':
        return {
          color: 'blue',
        };
      case 'NOT_ATTEND':
        return {
          color: 'neutral',
        };
      default:
        return {};
    }
  };

  return (
    <Chip {...getVariant()} size="sm">
      <CommonCode groupCode="ATTENDANCE_CATEGORY" code={code} />
    </Chip>
  );
};

export default MeetAttendanceCategoryChip;
