import { Button, Link } from '@wooriga/design-system';

import { TypeGuardCol } from 'lim/_fixtures/type';
import { formatBirth, formatTelephone } from 'utils/format';

export type AuthTableProps = {
  id: number;
  nickname: string;
  // unionRegisterNo: string;
  name: string;
  birth: string;
  phoneNo?: string;
};

export const AUTH_ADD_TABLE_COLUMNS: TypeGuardCol<AuthTableProps>[] = [
  { field: 'nickname', headerName: '닉네임', width: 150 },
  // { field: 'unionRegisterNo', headerName: '연번', width: 150 },
  { field: 'name', headerName: '이름', width: 160, minWidth: 160 },
  { field: 'birth', headerName: '생년월일', width: 130, minWidth: 130 },
  { field: 'phoneNo', headerName: '연락처', width: 160, minWidth: 160 },
];

export const AUTH_TABLE_COLUMNS = (
  onNameClick: (id: number) => void,
  onButtonClick: (id: number, name: string) => void,
): TypeGuardCol<AuthTableProps & { delete: string }>[] => [
  {
    field: 'nickname',
    headerName: '닉네임',
    width: 150,
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onNameClick(Number(params.id))}
        >
          {params.value}
        </Link>
      );
    },
  },
  // { field: 'unionRegisterNo', headerName: '연번', width: 150 },
  {
    field: 'name',
    headerName: '이름',
    width: 160,
    minWidth: 160,
  },
  {
    field: 'birth',
    headerName: '생년월일',
    width: 130,
    minWidth: 130,
    valueGetter: (_, row) => formatBirth(row?.birth),
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 160,
    minWidth: 160,
    valueGetter: (_, row) => formatTelephone(row?.phoneNo) || '',
  },
  {
    field: 'delete',
    headerName: '',
    renderCell: (params) => {
      return (
        <Button
          variant="outlined"
          color="danger"
          onClick={() => onButtonClick(Number(params.id), params.row.name)}
        >
          삭제
        </Button>
      );
    },
  },
];
