import { GridColDef } from '@wooriga/design-system';

import { RegistrationChangeHistoryInfo } from 'apis/types/union';
import { formatDate, formatDateTime, parseDate } from 'utils/format/datetime';

export const REGISTRATION_CHANGES_GRID_COLUMNS = () =>
  [
    {
      field: 'registrationNo',
      headerName: '등기소 등록번호',
      width: 160,
      minWidth: 160,
    },
    {
      field: 'registrationPurpose',
      headerName: '등기 목적',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) => row.registrationReceipt.registrationPurpose,
    },
    {
      field: 'receiptStep',
      headerName: '처리 상태',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) => row.registrationReceipt.receiptStep,
    },
    {
      field: 'registrationOfficeName',
      headerName: '등기소 명',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) => row.registrationReceipt.registrationOfficeName,
    },
    {
      field: 'registrationOfficeDepartment',
      headerName: '부서 명',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) =>
        row.registrationReceipt.registrationOfficeDepartment,
    },
    {
      field: 'applyName',
      headerName: '신청 구분',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) => row.registrationReceipt.applyName,
    },
    {
      field: 'receivedAt',
      headerName: '접수일자',
      width: 110,
      minWidth: 110,
      valueGetter: (_, row) =>
        row.registrationReceipt.receivedAt &&
        formatDate(parseDate(row.registrationReceipt.receivedAt, 'yyyyMMdd')),
    },
    {
      field: 'createdAt',
      headerName: '등록일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd hh:mm:ss'),
    },
    {
      field: 'modifiedAt',
      headerName: '수정일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd hh:mm:ss'),
    },
  ] as GridColDef<RegistrationChangeHistoryInfo>[];
