import {
  legendClasses,
  PieChart as JoyPieChart,
  pieArcClasses,
} from '@mui/x-charts';
import type { PieChartProps as XPieChartProps } from '@mui/x-charts';
import { ForwardedRef, forwardRef } from 'react';

export interface PieChartProps extends XPieChartProps {}

const PieChart = (props: PieChartProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { sx, slotProps, ...other } = props;

  return (
    <JoyPieChart
      {...other}
      sx={{
        [`& .${legendClasses.root}`]: {
          [`& .${legendClasses.mark}`]: {
            rx: 7,
          },
        },
        [`& .${pieArcClasses.root}`]: {
          strokeWidth: 0,
        },
        ...sx,
      }}
      slotProps={{
        ...slotProps,
        legend: {
          itemMarkWidth: 14,
          itemMarkHeight: 14,
          itemGap: 8,
          markGap: 12,
          ...slotProps?.legend,
          labelStyle: {
            fontSize: 14,
            padding: 3,
            ...slotProps?.legend?.labelStyle,
          },
        },
      }}
      ref={ref}
    />
  );
};

export default forwardRef<HTMLDivElement, PieChartProps>(PieChart);
