import { Box, Stack, Typography } from '@wooriga/design-system';
import { memo } from 'react';

export interface ColorMapItemProps {
  color: string;
  title: string;
  value: string;
  label?: string;
  width?: number;
  height?: number;
}

const ColorMapItem = (props: ColorMapItemProps) => {
  const { color, title, value, label, width = 100, height = 84 } = props;

  return (
    <Box
      width={width}
      height={height}
      px={2}
      py={1}
      sx={{ backgroundColor: color }}
    >
      <Stack textAlign="center">
        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          {title}
        </Typography>
        <Typography fontSize="md" fontWeight="md" lineHeight="md">
          {value}
        </Typography>
        {label && (
          <Typography fontSize="sm" fontWeight="md" lineHeight="sm">
            {label}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default memo(ColorMapItem);
