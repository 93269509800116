import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { MeetResponse } from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

export interface ReadMeetsRequest {
  unionSeq: number;
  meetName?: string;
  moverName?: string;
  meetStatus?: string;
  openType?: string;
  meetMethod?: string;
  meetType?: string;
}

export interface ReadMeetsResponse extends ApiResponseData<MeetResponse[]> {}

export const useReadMeets = (
  params: ReadMeetsRequest,
): UseQueryResult<ReadMeetsResponse, ApiError> =>
  useQuery({
    queryKey: ['/v1/meets', params],
    // initialData: generateInitRealData(meetsData),
  });
