/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  DateRangePicker,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  TextField,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { MeetMethods } from 'apis/types/meet';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import Search from 'components/Search';
import { MeetsParticipantsParams } from 'lim/generalMeetingHistoryDetail/apis';

const defaultParams: MeetsParticipantsParams<'ONSITE'> = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  tendency: '',
  attendExpectedType: '',
  writtenSubmissionStatus: '',
  writtenSubmissionAtTo: '',
  writtenSubmissionAtFrom: '',
  writtenSubmissionType: '',
};

const OnsiteFilter = <T extends MeetMethods = 'ONSITE'>({
  onSearchSubmit,
}: {
  onSearchSubmit: (params: MeetsParticipantsParams<T>) => void;
}) => {
  const { getGroupCode } = useCommonCodes();

  const { writtenGroup, tendencyGroup, attendanceGroup } = useMemo(
    () => ({
      writtenGroup: getGroupCode('WRITTEN_SUBMIT_TYPE'),
      tendencyGroup: getGroupCode('TENDENCY_TYPE'),
      attendanceGroup: getGroupCode('ATTEND_EXPECTED_TYPE'),
    }),
    [getGroupCode],
  );

  const [selectParams, setSelectParams] = useState<
    Pick<
      MeetsParticipantsParams<'ONSITE'>,
      'writtenSubmissionType' | 'attendExpectedType' | 'tendency'
    >
  >({
    writtenSubmissionType: '',
    tendency: '',
    attendExpectedType: '',
  });

  const handleOnSearch = (params: MeetsParticipantsParams<T>) => {
    let newValue = {} as MeetsParticipantsParams<T>;

    Object.entries(params).forEach(([key, value]) => {
      if (key.includes('At') && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    onSearchSubmit({
      ...newValue,
      ...selectParams,
    });
  };

  const handleOnReset = () => {
    onSearchSubmit(defaultParams as MeetsParticipantsParams<T>);
    setSelectParams({
      writtenSubmissionType: '',
      tendency: '',
      attendExpectedType: '',
    });
  };

  const handleOnSelect = (
    key: 'writtenSubmissionType' | 'attendExpectedType' | 'tendency',
    value: string | boolean,
  ) => {
    setSelectParams({
      ...selectParams,
      [key]: value,
    });
  };

  return (
    <Search
      defaultValues={defaultParams}
      onSubmit={handleOnSearch}
      onReset={handleOnReset}
    >
      <Grid container gap={2}>
        <Stack flexDirection="row" flexWrap="wrap" gap={2}>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                label="연번"
                name="unionRegisterNo"
                placeholder='숫자 또는 "-"입력'
                validateOptions={{
                  maxLength: 11,
                  regex: /^(?!.*--)[0-9-]*$/,
                }}
                fullWidth
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="연락처를 입력하세요"
                label="연락처"
                name="phoneNo"
                fullWidth
                validateOptions={{
                  maxLength: 11,
                  regex: /^[0-9]*$/,
                }}
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="이름을 입력하세요"
                label="이름"
                name="name"
                fullWidth
                slotProps={{
                  input: { maxLength: 10 },
                }}
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>제출현황</FormLabel>
                <Select
                  fullWidth
                  name="writtenSubmissionType"
                  value={selectParams.writtenSubmissionType}
                  onChange={(_, value) =>
                    handleOnSelect(
                      'writtenSubmissionType',
                      value as unknown as string,
                    )
                  }
                >
                  <Option value="">전체</Option>

                  {writtenGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>총회참석예정</FormLabel>
                <Select
                  fullWidth
                  name="attendExpectedType"
                  value={selectParams.attendExpectedType}
                  onChange={(_, value) =>
                    handleOnSelect(
                      'attendExpectedType',
                      value as unknown as string,
                    )
                  }
                >
                  <Option value="">전체</Option>

                  {attendanceGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>우호도</FormLabel>
                <Select
                  fullWidth
                  name="tendency"
                  value={selectParams.tendency}
                  onChange={(_, value) =>
                    handleOnSelect('tendency', value as unknown as string)
                  }
                >
                  <Option value="">전체</Option>

                  {tendencyGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>
          {/* // 철회일 */}

          <Grid container gap={2}>
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <DateRangePicker
                    label="서면 제출일"
                    name="writtenSubmissionAt"
                    sx={{ field: { maxWidth: 200 } }}
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Search>
  );
};

export default OnsiteFilter;
