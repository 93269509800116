import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { ApiError, ApiResponseData } from 'types/api';

export interface UnionRegisterAgentParams {
  // Path
  unionSeq: number;
  unionRegisterAgentSeq: number;
}

export type UnionRegisterAgentReturnData = {
  unionRegisterAgentSeq: number;
  name: { name: string; nameClass?: string };
  birth?: string;
  gender: string;
  relationType: string; // UNION_REGISTER_USER_DIVIDE
  relationDescription?: string;
  phoneNo: string;
  linkedUnionRegister: {
    unionRegisterSeq: number;
    unionRegisterNo: string;
    unionRegisterNoMain: number;
    unionRegisterNoSub: number;
    name: {
      name: string;
      nameClass: string;
    };
  };
};

export const useUnionRegisterAgentQuery = ({
  unionSeq,
  unionRegisterAgentSeq,
}: UnionRegisterAgentParams): UseQueryResult<
  ApiResponseData<UnionRegisterAgentReturnData>,
  ApiError
> =>
  useQuery({
    queryKey: [
      `/v1/unions/${unionSeq}/register-agents/${unionRegisterAgentSeq}`,
    ],
  });

export interface UnionRegisterAgentCreateParams {
  // Path
  unionSeq: number;

  // Body
  unionRegisterSeq: number;
  name: string;
  nameClass?: string;
  birth?: string;
  gender: string;
  relationType: string; // UNION_REGISTER_USER_DIVIDE
  relationDescription?: string;
  phoneNo: string;
}

export const useUnionRegisterAgentCreateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  UnionRegisterAgentCreateParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;

      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/register-agents`,
        body,
      );

      return data;
    },
  });

export interface UnionRegisterAgentUpdateParams {
  // Path
  unionSeq: number;
  unionRegisterAgentSeq: number;

  // Body
  unionRegisterSeq: number;
  name: string;
  nameClass?: string;
  birth?: string;
  gender: string;
  relationType: string; // UNION_REGISTER_USER_DIVIDE
  relationDescription?: string;
  phoneNo: string;
}

export const useUnionRegisterAgentUpdateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  UnionRegisterAgentUpdateParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, unionRegisterAgentSeq, ...body } = request;

      const { data } = await axiosInstance.patch(
        `/v1/unions/${unionSeq}/register-agents/${unionRegisterAgentSeq}`,
        body,
      );

      return data;
    },
  });

export interface UnionRegisterAgentDeleteParams {
  // Path
  unionSeq: number;
  unionRegisterAgentSeq: number;
}

export const useUnionRegisterAgentDeleteMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  UnionRegisterAgentDeleteParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, unionRegisterAgentSeq } = request;

      const { data } = await axiosInstance.delete(
        `/v1/unions/${unionSeq}/register-agents/${unionRegisterAgentSeq}`,
      );

      return data;
    },
  });
