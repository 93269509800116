import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  DataGrid,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MeetMethods } from 'apis/types/meet';
import { UnionRegisterInfo } from 'apis/types/union';
import { UNION_REGISTER_TABLE_COLUMNS } from 'apis/union/register/fixtures/meet';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import VoterAddConfirmModal from 'components/pages/meet-management/history/detail/tabs/VoterManagementTab/VoterAddConfirmModal';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import { useCalculateCostMutation } from 'lim/meetOpen/apis';
import { commaizeNumber } from 'utils/format';

interface VoterAddModalProps {
  meetMethod: MeetMethods;
  rows: UnionRegisterInfo[];
  open: boolean;
  onClose: (open: false) => void;
  onSubmit: (ids: number[]) => void;
}

const VoterAddListModal = ({
  meetMethod,
  rows,
  open,
  onClose,
  onSubmit,
}: VoterAddModalProps) => {
  const params = useParams();
  const { snackbar } = useFeedback();

  const unionSeq = Number(params.unionSeq);
  const { mutate } = useCalculateCostMutation();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);

  const [clickedUnionId, setClickedUnionId] = useState(0);
  const [cost, setCost] = useState(0);

  const handleSubmit = () => {
    const unionRegisterSeqs = rows.map((row) => row.unionRegisterSeq);

    setShowConfirmModal(true);
    mutate(
      { unionSeq, meetMethod, unionRegisterSeqs },
      {
        onSuccess: (data) => {
          setCost(data.data.totalCost);
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleConfirm = () => {
    const unionRegisterSeqs = rows.map((row) => row.unionRegisterSeq);

    onClose(false);
    onSubmit(unionRegisterSeqs);
  };

  const handleNameClick = useCallback((id: string | number) => {
    setShowUnionInfoModal(true);
    setClickedUnionId(Number(id));
  }, []);

  const handlers = useMemo(
    () => ({ onLinkClick: handleNameClick }),
    [handleNameClick],
  );

  const { columns } = useCreateGridColumns({
    handlers,
    columns: UNION_REGISTER_TABLE_COLUMNS,
  });

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog>
          <DialogTitle>추가 선거인 목록</DialogTitle>
          <DialogContent>
            <Stack gap={1} marginBottom={3}>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                <span>
                  <strong>추가 된 조합원 정보만 불러오는 기능</strong> 입니다.
                  다시 한번 확인해 주세요.
                </span>
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                선거인을 불러오시려면 조합원 명부 관리에서 추가하시려는 선거인을
                조합원 명부에 등록해 주세요
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                빨간색으로 표시 항목은 누락 된 정보가 있는 조합원 입니다. 다시
                한번 확인해 주세요.
              </Typography>
              <Typography
                level="body-md"
                color="danger"
                startDecorator={<InfoIcon />}
              >
                이름, 성별, 연락처, 생년월일 정보가 누락 또는 오입력 된 경우
                전자투표의 수신 또는 열람이 불가합니다. 조합원 명부 관리
                페이지의 조합원 정보에서 수정해 주세요.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                전자투표인 경우 대리인을 선임하지 않은 법인의 경우 본인인증이
                불가하여 전자투표 진행이 어려울 수 있습니다.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                대리인이 등록된 조합원의 경우 대리인유무에 ‘○’ 가 표시됩니다.
              </Typography>
              <Typography level="body-md" startDecorator={<InfoIcon />}>
                기존 선거인 명부에 있는 연번과 중복이 되는 조합원은 파란색으로
                표시됩니다. 중복된 조합원 중 하나를 삭제하시고 다시
                진행해주세요.
              </Typography>
            </Stack>
            <Stack gap={2}>
              <Stack flexDirection="row" gap={1}>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  전체{' '}
                  <Typography color="primary">
                    {commaizeNumber(rows.length)}
                  </Typography>
                </Typography>

                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  조회 목록{' '}
                  <Typography color="primary">
                    {commaizeNumber(rows.length)}
                  </Typography>
                </Typography>
              </Stack>

              <Stack height={442}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.unionRegisterSeq}
                  disableRowSelectionOnClick
                  keepNonExistentRowsSelected
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button fullWidth type="submit" onClick={handleSubmit}>
              다음
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <VoterAddConfirmModal
        price={cost}
        open={showConfirmModal}
        onClose={setShowConfirmModal}
        onConfirm={handleConfirm}
      />

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: clickedUnionId }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </>
  );
};

export default VoterAddListModal;
