import { useMemo } from 'react';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import createGridColumns, {
  CreateGridColumns,
} from 'hooks/useCreateGridColumns/createGridColumns';
import { GridColumnsHandlers } from 'types/grid';

const useCreateGridColumns = <H>(
  columns: CreateGridColumns<GridColumnsHandlers<H>>,
  handlers?: H,
) => {
  const { getCode } = useCommonCodes();
  const handlersList = handlers ? Object.values(handlers) : [];

  const enhancedHandlers: GridColumnsHandlers<H> = useMemo(() => {
    return {
      getCommonCode: getCode,
      ...(handlers as H),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCode, ...handlersList]);

  return useMemo(
    () => createGridColumns(columns, enhancedHandlers),
    [columns, enhancedHandlers],
  );
};

export default useCreateGridColumns;
