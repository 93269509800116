import { useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  Descriptions,
  DescriptionsItem,
  Grid,
  Stack,
  TextField,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DocumentIssuanceInfoParams,
  useCreateDocumentIssuanceDownloadMutation,
  useDataIssuanceDetailRecordQuery,
  useDataIssuanceQuery,
} from 'apis/document-issuance/apis';
import { DOCUMENT_ISSUANCE_INFO_COLUMNS } from 'apis/document-issuance/fixtures';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const DEFAULT_SELECTED_UNION_STATE = {
  open: false,
  seq: 0,
};

const DEFAULT_SEARCH_PARAMS = {
  unionRegisterName: '',
  localAddress: '',
};

const DocumentIssuanceRecordDetailPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { getCode } = useCommonCodes();

  const unionSeq = Number(params.unionSeq);
  const documentIssuanceSeq = Number(params.documentIssuanceSeq);

  const [searchParams, setSearchParams] = useState<DocumentIssuanceInfoParams>({
    unionSeq: unionSeq,
    documentIssuanceSeq: documentIssuanceSeq,
    ...DEFAULT_SEARCH_PARAMS,
  });

  const [selectedUnionState, setSelectedUnionState] = useState(
    DEFAULT_SELECTED_UNION_STATE,
  );

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'document-issuance-records-detail',
    activeSaveSnapshot: false,
  });

  const dataGridInitialState = useKeepGroupedColumnsHidden({
    apiRef: datagridApiRef,
    initialState: { rowGrouping: { model: ['documentCode'] } },
  });

  const {
    data: records,
    isPending,
    isLoading,
    isError,
    error,
  } = useDataIssuanceDetailRecordQuery(searchParams);
  const { data: info } = useDataIssuanceQuery({
    unionSeq,
    documentIssuanceSeq,
  });

  const { mutate: downloadIssuances, isPending: isDownloading } =
    useCreateDocumentIssuanceDownloadMutation();

  const fileUrls = useMemo(() => {
    return (records?.data ?? []).reduce(
      (obj, { documentCode, fileUrl }) =>
        fileUrl ? { ...obj, [documentCode]: fileUrl } : obj,
      {} as Record<string, string>,
    );
  }, [records?.data]);

  const handleSearchParams = (values: Partial<DocumentIssuanceInfoParams>) => {
    if (!values) {
      return;
    }

    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...values }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleMoveParentPage = () => {
    navigate('..', { relative: 'path' });
  };

  const handleOpenUnionInfoModal = (seq: number) => {
    setSelectedUnionState({
      open: true,
      seq,
    });
  };

  const handleDownloadFile = useCallback(
    (fileUrls: string[]) => {
      const splitBySlash = fileUrls[0].split('/');
      const downloadFileName = splitBySlash[splitBySlash.length - 1];

      downloadIssuances(
        {
          unionSeq,
          documentIssuanceSeq,
          fileUrls,
        },
        {
          onSuccess: (response) => {
            const blob = new Blob([response]);
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = downloadFileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);
          },
        },
      );
    },
    [unionSeq, documentIssuanceSeq, downloadIssuances],
  );

  const handleClickDownloadIssuance = useCallback(
    (url: string) => {
      if (!url) {
        return;
      }

      handleDownloadFile([url]);
    },
    [handleDownloadFile],
  );

  const handleClickDownloadAllIssuance = () => {
    const urls = Object.values(fileUrls).filter((url) => url);
    handleDownloadFile(urls);
  };

  const rows = useMemo(() => records?.data || [], [records]);
  const { columns } = useCreateGridColumns({
    handlers: {
      urls: fileUrls,
      onClickUnionMember: handleOpenUnionInfoModal,
      onClickDownload: handleClickDownloadIssuance,
    },
    columns: DOCUMENT_ISSUANCE_INFO_COLUMNS,
  });

  if (isError || !documentIssuanceSeq) {
    throw error;
  }

  return (
    <>
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
            상세 발송 내역
          </Typography>

          <Descriptions variant="soft" color="primary" size="md" columns={4}>
            <DescriptionsItem label="신청주소 수">
              {commaizeNumber(info?.data?.addressCount || 0)} 건
            </DescriptionsItem>
            <DescriptionsItem label="총 신청건 수">
              {commaizeNumber(info?.data?.totalRequestCount || 0)} 건
            </DescriptionsItem>
            <DescriptionsItem label="발급/열람 완료">
              {commaizeNumber(info?.data?.issuedCount || 0)} 명
            </DescriptionsItem>
            <DescriptionsItem label="발급/열람 실패">
              {commaizeNumber(info?.data?.failCount || 0)} 명
            </DescriptionsItem>
          </Descriptions>
        </Stack>

        <Stack gap={1.75}>
          <Search
            defaultValues={DEFAULT_SEARCH_PARAMS}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    name="unionRegisterName"
                    label="조합원 이름"
                    placeholder="조합원 이름 검색"
                    slotProps={{
                      input: { maxLength: 30 },
                    }}
                    fullWidth
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <TextField
                    name="localAddress"
                    label="소재지"
                    placeholder="주소 입력"
                    fullWidth
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Search>
        </Stack>

        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(records?.pagination?.totalDataCount || 0)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(records?.pagination?.totalElements || 0)}
              </Typography>
            </Typography>
          </Stack>

          <Stack height={442}>
            <DataGrid
              disableRowSelectionOnClick
              apiRef={datagridApiRef}
              rows={rows}
              columns={columns}
              loading={isPending || isLoading}
              getRowId={(row) => `${row.documentCode}-${row.unionRegisterSeq}`}
              initialState={dataGridInitialState}
              groupingColDef={{
                minWidth: 320,
                hideDescendantCount: true,
                valueFormatter: (value) =>
                  getCode('DOCUMENT_CODE', value)?.name,
              }}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap={1}>
              <Button
                variant="outlined"
                color="neutral"
                onClick={handleMoveParentPage}
              >
                목록
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => exportExcel()}
              >
                내역 다운
              </Button>
            </Stack>

            <Button
              loading={isDownloading}
              onClick={handleClickDownloadAllIssuance}
            >
              전체 다운로드
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {selectedUnionState.open && (
        <UnionRegisterInfoDetailModal
          params={{
            unionSeq,
            unionRegisterSeq: selectedUnionState.seq,
          }}
          open={selectedUnionState.open}
          onClose={() => setSelectedUnionState(DEFAULT_SELECTED_UNION_STATE)}
        />
      )}
    </>
  );
};

const documentIssuanceInfoPage: CustomRouteObject = {
  path: ':documentIssuanceSeq',
  element: <DocumentIssuanceRecordDetailPage />,
};

export default documentIssuanceInfoPage;
