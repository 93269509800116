import { GridColDef } from '@mui/x-data-grid-premium';
import { Button, Input, Link } from '@wooriga/design-system';
import { ChangeEvent } from 'react';
import { Address } from 'react-daum-postcode';

import { MeetParticipantResponse } from 'apis/types/meet';
import { PostDocument, PostRecipient } from 'apis/types/post';
import AddressModalTrigger from 'components/pages/posts/AddressModalTrigger';
import { GridColumnsHandlers } from 'types/grid';
import { formatDateTime, formatTelephone } from 'utils/format';

export interface PostResenderColumnProps {
  onClickAddress: (address: Address, postRegisterSeq: number) => void;
}

export const POST_DOCUMENT_COLUMNS: GridColDef<PostDocument>[] = [
  {
    field: 'name',
    headerName: '파일명',
    flex: 1,
    valueGetter: (value, row) => value ?? row.file.originName,
  },
  {
    field: 'createdAt',
    headerName: '등록일시',
    width: 180,
    minWidth: 180,
    valueGetter: (_, row) =>
      formatDateTime(row.file.createdAt, 'yyyy-MM-dd HH:mm:ss'),
  },
];

export const POST_RESEND_COLUMNS = ({
  onClickAddress,
}: PostResenderColumnProps): GridColDef<PostRecipient & { action: '' }>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'positionDescription',
    headerName: '직책',
  },
  {
    field: 'name',
    headerName: '이름',
    width: 160,
    minWidth: 160,
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 160,
    minWidth: 160,
    valueGetter: (value) => formatTelephone(value),
  },
  {
    field: 'address',
    headerName: '우편물 수령지',
    width: 230,
    minWidth: 230,
    valueGetter: ({ address }) => address,
  },
  {
    field: 'detailAddress',
    headerName: '상세 우편물 수령지',
    width: 230,
    minWidth: 230,
    display: 'flex',
    editable: true,
    renderCell: ({ value, row }) => (
      <Input
        placeholder="상세주소 입력"
        value={value ?? row.address.detailAddress}
        readOnly
        fullWidth
      />
    ),
    renderEditCell: ({ id, row, value, api, field }) => {
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        api.setEditCellValue({ id, field, value: event.currentTarget.value });
      };

      return (
        <Input
          placeholder="상세주소 입력"
          value={value ?? row.address.detailAddress}
          onChange={handleChange}
          fullWidth
        />
      );
    },
  },
  {
    field: 'zipNo',
    headerName: '우편번호',
    width: 70,
    valueGetter: (_, row) => row.address.zipNo,
  },
  {
    field: 'action',
    headerName: '주소변경',
    renderCell: ({ row }) => (
      <AddressModalTrigger
        onComplete={(value) => onClickAddress(value, row.postRecipientSeq)}
      >
        <Button variant="outlined" color="neutral">
          검색
        </Button>
      </AddressModalTrigger>
    ),
  },
];

export const POST_ELECTOR_COLUMNS = ({
  isElectronic,
  onClickUnionMember,
  getCommonCode,
}: GridColumnsHandlers<{
  isElectronic: boolean;
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) => {
        return `${row?.name?.name ?? ''}${row?.name?.nameClass ?? ''}`;
      },
      renderCell: ({ value, row }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickUnionMember(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'positionDescription',
      headerName: '직책',
    },
    {
      field: 'agent',
      headerName: '대리인',
      type: 'boolean',
    },
    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 160,
      minWidth: 160,
      valueFormatter: (value) => formatTelephone(value),
    },
    {
      field: 'address',
      headerName: '우편물 수령지',
      width: 230,
      minWidth: 230,
      valueFormatter: (_, row) => row.postAddress?.address,
    },
    {
      field: 'detailAddress',
      headerName: '상세 우편물 수령지',
      width: 230,
      minWidth: 230,
      valueFormatter: (_, row) => row.postAddress?.detailAddress,
    },
    {
      field: 'zipNo',
      headerName: '우편번호',
      valueFormatter: (_, row) => row.postAddress?.zipNo,
    },
    {
      field: 'status',
      headerName: '제출상태',
      valueFormatter: (_, row) =>
        getCommonCode(
          isElectronic ? 'MEET_PARTICIPANT_VOTE_STATUS' : 'WRITTEN_SUBMIT_TYPE',
          isElectronic
            ? row.electronicVoteStatus.progressStatus
            : row.onsiteVoteStatus.writtenSubmissionType,
        )?.name,
    },
  ] as GridColDef<MeetParticipantResponse & { status: 'string' }>[];
