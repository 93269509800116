import { ForwardedRef, MutableRefObject, useEffect, useRef } from 'react';

const useForwardRef = <T>(ref: ForwardedRef<T>, initialValue?: T) => {
  const targetRef = useRef<T>(initialValue || null);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(targetRef.current);
      // } else if (ref.current !== undefined) {
    } else if (ref && 'current' in ref) {
      (ref as MutableRefObject<T | null>).current = targetRef.current;
    }
  }, [ref]);

  return targetRef as MutableRefObject<T | null>;
};

export default useForwardRef;
