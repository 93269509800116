import InfoIcon from '@mui/icons-material/Info';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  Box,
  Button,
  Callout,
  Chip,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  ModalDialog,
  ModalOverflow,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import ResultGrid, {
  DataGridValues,
} from 'components/pages/meet-management/history/detail/common/ResultGrid';
import { calculateTotalVoteCount } from 'components/pages/meet-management/history/detail/common/ResultGrid/utils';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import { useReveal } from 'hooks/useReveal';
import { OnInputChange } from 'lim/_fixtures/type';
import {
  MeetsAgendasQuery,
  MutateAgenda,
} from 'lim/generalMeetingHistoryDetail/apis';
import { MEETS_AGENDAS_TABLE_COLUMNS } from 'lim/generalMeetingHistoryDetail/fixtures';

type FormDataProps = Omit<MutateAgenda, 'candidates'> & {
  candidates: Partial<MutateAgenda['candidates'][number]>[];
};

interface VoteResultEnterModalProps {
  type: 'onsiteResult' | 'writtenSubmissionResult';
  meetsAgendasQuery: MeetsAgendasQuery;
  voterCount: number;
  registeredCount: number;
  open: boolean;

  onClose: (open: false) => void;
  onSubmit: (dataGridValues: DataGridValues) => void;
}

const VoteResultEnterModal = ({
  type,
  meetsAgendasQuery,
  voterCount,
  registeredCount,
  open,

  onClose,
  onSubmit,
}: VoteResultEnterModalProps) => {
  const meetName = type === 'onsiteResult' ? '현장투표' : '서면제출';
  const { data } = meetsAgendasQuery;

  const { openReveal } = useReveal();

  const [showDataGrid, setShowDataGrid] = useState(false);
  const [count, setCount] = useState(0);
  const [dataGridValues, setDataGridValues] = useState<DataGridValues>({});
  const [formData, setFormData] = useState<FormDataProps[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const rows = useMemo(() => {
    if (!showDataGrid) return [];

    return (
      data?.data.flatMap((voteResult) => {
        const {
          agendaSeq,
          order,
          name,
          voteForm,
          voteType,
          selectCount,
          description,
          candidates,
        } = voteResult;

        return candidates?.map((candidate) => {
          return {
            id: `${agendaSeq}-${candidate.no}`,
            agendaSeq,
            order,
            voteForm,
            voteType,
            selectCount,
            name,
            description,
            candidateNo: candidate.no,
            candidateName: candidate.name,
            yesCount: 0,
            noCount: 0,
            abstentionCount: 0,
            totalCount: 0,
          };
        });
      }) || []
    );
  }, [data?.data, showDataGrid]);

  const validSelectVoteCount = (
    id: string | number,
    newValue: DataGridValues,
  ) => {
    const agendaSeq = id.toString().split('-')[0];
    const agenda = data?.data.find((d) => d.agendaSeq === Number(agendaSeq));
    if (agenda?.voteForm === 'AGAINST') return true;

    const selectCount = agenda?.selectCount || 1;

    const currentAgendaIds = Object.keys(newValue).filter(
      (key) => key.indexOf(`${agendaSeq}-`) === 0,
    );

    const totalCount = currentAgendaIds
      .map((id) => newValue[id].yesCount)
      .reduce((acc, cur) => (acc += cur));

    if (count * selectCount < totalCount) return false;
    return true;
  };

  const validDataGridValueCount = (
    id: string | number,
    newValue: DataGridValues,
  ): boolean => {
    const total = Object.values(newValue[id]).reduce((acc, cur) => {
      return (acc = Number(acc) + Number(cur));
    });

    if (Number(total) > count) {
      openReveal('alert', {
        detail: `투표 입력값은 최종 ${meetName} 수보다 클 수 없습니다.`,
      });

      return false;
    }

    if (!validSelectVoteCount(id, newValue)) {
      openReveal('alert', {
        detail: `선택투표 안건별 후보 각각의 찬성/선택 값의 합은 최종 ${meetName} 수보다 클 수 없습니다.`,
      });

      return false;
    }

    return true;
  };

  const isFullField = (newValue: DataGridValues) => {
    const calculatedTotalVoteCount = calculateTotalVoteCount(count, data?.data);
    const totalValuesCount = Object.values(newValue).reduce(
      (acc, cur): number => {
        const valuesTotalCount = Object.values(cur).reduce(
          (childAcc, childCur) => {
            return (childAcc = Number(childAcc) + Number(childCur));
          },
        );
        return (acc = Number(acc) + Number(valuesTotalCount));
      },
      0,
    );

    if (totalValuesCount === calculatedTotalVoteCount) return true;

    return false;
  };

  const handleCount = (e: ChangeEvent<HTMLInputElement>) => {
    setCount(Number(e.target.value));
  };

  const handleDataGridValueChange: OnInputChange = (id, e) => {
    const { name, value } = e.target;

    const newValue = {
      ...dataGridValues,
      [id]: {
        ...dataGridValues[id],
        [name]: Number(value),
      },
    };

    if (!validDataGridValueCount(id, newValue)) return;

    setDataGridValues(newValue);

    if (isFullField(newValue)) {
      return setIsDisabled(false);
    }

    if (!isDisabled) {
      setIsDisabled(true);
    }
  };

  const handleAdjust = () => {
    if (registeredCount < count) {
      setShowDataGrid(false);
      setIsDisabled(true);

      return openReveal('alert', {
        maxWidth: 'sm',
        detail: `최종 ${meetName} 수는 총회관리 시스템에 등록된 ${meetName} 수인 ${registeredCount} 보다 클수 없습니다.`,
      });
    }

    if (registeredCount > 0) {
      setIsDisabled(true);
    }

    setDataGridValues({});
    setShowDataGrid(true);

    let dataGridValues: DataGridValues = {};

    if (formData.length) {
      formData.forEach((agenda) => {
        agenda.candidates.forEach((candidate) => {
          dataGridValues = {
            ...dataGridValues,
            [`${agenda.agendaSeq}-${candidate.no}`]: {
              abstentionCount: 0,
              noCount: 0,
              yesCount: 0,
            },
          };
        });
      });

      setDataGridValues(dataGridValues);
    }
  };

  const handleOnSubmit = () => {
    onSubmit(dataGridValues);
  };

  const handleClose = () => {
    openReveal('confirm', {
      detail: '개표 결과 입력을 취소하시겠습니까?',
      message: '입력한 내용은 저장되지 않습니다.',
      onSubmit: (result) => {
        if (result === 'Cancel') return;
        onClose(false);
      },
    });
  };

  const { columns } = useCreateGridColumns(MEETS_AGENDAS_TABLE_COLUMNS, {
    count,
    values: dataGridValues,
    onInputChange: handleDataGridValueChange,
  });

  useEffect(() => {
    const formData = data?.data.map((data) => {
      const { agendaSeq, order, candidates } = data;
      const formDataCandidates = candidates.map((candidate) => {
        const { no } = candidate;

        const defaultValue = {
          yesCount: 0,
          noCount: 0,
          abstentionCount: 0,
          totalCount: 0,
        };

        return {
          no,
          writtenSubmissionResult: defaultValue,
        };
      });

      return {
        agendaSeq,
        order,
        candidates: formDataCandidates,
      };
    });

    if (formData) setFormData(formData);
    setIsDisabled(true);
    setShowDataGrid(false);
    // }
  }, [data?.data, open]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalOverflow>
        <ModalDialog maxWidth="lg" sx={{ width: '100%' }}>
          <Stack flexDirection="row" justifyContent="space-between">
            <DialogTitle>{meetName} 개표 결과 입력</DialogTitle>

            {meetName === '서면제출' && (
              <Chip
                variant="soft"
                color="primary"
                size="lg"
                endDecorator={<NavigateNextIcon />}
              >
                다음 단계 : 현장투표 개표 결과 입력
              </Chip>
            )}
          </Stack>
          <DialogContent>
            <Stack gap={3}>
              <Callout color="warning" startDecorator={<InfoIcon />}>
                <Typography textColor="warning.700">
                  {meetName === '서면제출' ? (
                    <>
                      입력한 서면결의서 결과는 현장 투표 집계 결과와 합산되어
                      집계 결과를 확인할 수 있습니다.
                      <br />
                      찬성/선택, 반대, 기권/무효에 입력한 투표수의 합계가
                      자동으로 합산되어 표시됩니다.
                      <br />
                      입력하지 않은 값은 ‘0’으로 저장됩니다.
                      <br />
                      최종 서면제출 수는 총회 관리 시스템(VMS)에 등록된 수 보다
                      클수 없습니다.
                    </>
                  ) : (
                    <>
                      입력한 현장투표 집계 결과는 서면결의서 결과와 합산되어
                      집계 결과를 확인할 수 있습니다.
                      <br />
                      찬성/선택, 반대, 기권/무효에 입력한 투표수의 합계가
                      자동으로 합산되어 표시됩니다.
                      <br />
                      입력하지 않은 값은 ‘0’으로 저장됩니다.
                    </>
                  )}
                </Typography>
              </Callout>

              <Grid container spacing={2}>
                <Grid xs={6} alignSelf="flex-end">
                  <Descriptions legacy={false}>
                    <DescriptionsItem
                      label={
                        // 임시로 넣음
                        // Description 자체 개선 필요함
                        <Typography
                          fontSize="inherit"
                          fontWeight="inherit"
                          lineHeight="inherit"
                          sx={{ whiteSpace: 'nowrap' }}
                        >
                          총회 관리 시스템(VMS)에 등록된 {meetName} 수
                        </Typography>
                      }
                    >
                      {registeredCount}
                    </DescriptionsItem>
                  </Descriptions>
                </Grid>

                <Grid xs={6} gap={1} display="flex" flexDirection="row">
                  <Stack flex={1}>
                    <TextField
                      validateOptions={{
                        regex: /^[0-9]*$/,
                      }}
                      label={`최종 ${meetName} 수`}
                      name="count"
                      onChange={handleCount}
                    />
                  </Stack>

                  <Button onClick={handleAdjust} sx={{ alignSelf: 'flex-end' }}>
                    적용
                  </Button>
                </Grid>
              </Grid>

              <Box>
                <Typography
                  fontSize="md"
                  fontWeight="lg"
                  lineHeight="md"
                  mb={1}
                >
                  총 선거인{' '}
                  <Typography color="primary">
                    {commaizeNumber(voterCount || 0)}
                  </Typography>
                </Typography>

                <Box height={480}>
                  <ResultGrid rows={rows} columns={columns} />
                </Box>
              </Box>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={isDisabled}
              type="submit"
              onClick={handleOnSubmit}
            >
              {meetName === '현장투표' ? '확인' : '다음'}
            </Button>
            <Button variant="outlined" color="neutral" onClick={handleClose}>
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default VoteResultEnterModal;
