import JoyFormHelperText from '@mui/joy/FormHelperText';
import type { FormHelperTextProps as JoyFormHelperTextProps } from '@mui/joy/FormHelperText';
import { ForwardedRef, forwardRef } from 'react';

export type FormHelperTextProps = JoyFormHelperTextProps;

const FormHelperText = (
  props: FormHelperTextProps,
  ref: ForwardedRef<HTMLParagraphElement>,
) => {
  const { sx, ...other } = props;
  return (
    <JoyFormHelperText
      sx={{
        minHeight:
          'calc(var(--FormHelperText-lineHeight, var(--joy-lineHeight-sm)) * 1rem)',
        ...sx,
      }}
      {...other}
      ref={ref}
    />
  );
};

export default forwardRef<HTMLParagraphElement, FormHelperTextProps>(
  FormHelperText,
);
