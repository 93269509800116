import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { FileInfo } from 'apis/types/common';
import { ApiError, ApiResponseData } from 'types/api';
import { CommonCodeGroup } from 'types/grid';

export type CommonCodesReturnData = CommonCodeGroup[];

export const commonCodesQuery = () => ({
  queryKey: ['/v1/common-codes'],
  gcTime: Infinity,
  staleTime: Infinity,
});

export const useCommonCodesQuery = (): UseQueryResult<
  ApiResponseData<CommonCodesReturnData>,
  ApiError
> => useQuery(commonCodesQuery());

export interface FileUploadParams {
  divisionCode: string;
  files: FileList;
}
export interface RegistersFileUploadParams {
  unionSeq: number;
  file: File;
}

export type FileUploadReturnData = FileInfo[];

export const useFileUploadMutation = () =>
  useMutation<
    ApiResponseData<FileUploadReturnData>,
    ApiError,
    FileUploadParams
  >({
    mutationFn: async ({ divisionCode, files }) => {
      const formData = new FormData();

      formData.append('divisionCode', divisionCode);

      Array.from(files).forEach((el) => {
        formData.append('files', el);
      });

      const { data } = await axiosInstance.post('/v1/files', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: [() => formData],
      });

      return data;
    },
  });

export interface FileDownloadParams {
  fileSeq?: number;
  filePath?: string;
  fileCode?: string;
}

export const useFileDownloadMutation = () =>
  useMutation<Blob, ApiError, FileDownloadParams>({
    mutationFn: async (request) => {
      const { data } = await axiosInstance.get(`/v1/files`, {
        params: request,
        responseType: 'blob',
      });

      return data;
    },
  });

export interface ExternalSystemCheckParams {
  externalSystemType: string;
}

export const useExternalSystemCheckMutation = () =>
  useMutation<ApiResponseData, ApiError, ExternalSystemCheckParams>({
    mutationFn: async (body) => {
      const { data } = await axiosInstance.post(
        `/v1/check/external-system-maintenance`,
        body,
      );

      return data;
    },
  });

export const useRegistersFileUploadMutation = () =>
  useMutation<
    ApiResponseData<FileUploadReturnData>,
    ApiError,
    RegistersFileUploadParams
  >({
    mutationFn: async ({ unionSeq, file }) => {
      const formData = new FormData();

      // Array.from(files).forEach((el) => {
      //   formData.append('files', el);
      // });
      console.log('api FILE', file);
      formData.append('file', file);
      console.log('API FORMDATA', formData);

      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/registers/files`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          transformRequest: [() => formData],
        },
      );

      return data;
    },
  });
