import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  Button,
  DataGrid,
  Descriptions,
  DescriptionsItem,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import { useElectronicConsentCertificatesMutation } from 'apis/electronic-consents/apis';
import { CERTIFICATION_DETAIL_COLUMNS } from 'apis/electronic-consents/fixture';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import RequestModal from 'components/pages/consent-management/certifications/modals/RequestModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import useQueryControls from 'hooks/useQueryControls';
import IndexTemplate from 'templates/IndexTemplate';

const defaultSearchParams = {
  name: '',
  certificateIssuanceStatus: 'ALL',
};

export interface CertificationTabProps {
  unionSeq: number;
  consentSeq: number;
}

const CertificationTab = ({ unionSeq, consentSeq }: CertificationTabProps) => {
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [certificateIssuanceStatus, setCertificateIssuanceStatus] =
    useState('');

  const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false);

  const { snackbar, confirmDialog } = useFeedback();
  const { getGroupCode } = useCommonCodes();
  const { searchState, setSearchState, initState } = useQueryControls({
    defaultSearchParams: { unionSeq, ...defaultSearchParams },
    initSearchParams: { unionSeq, ...defaultSearchParams },
  });

  const { mutate: requestCertification } =
    useElectronicConsentCertificatesMutation();

  const handleOnSelect = (value: string) => {
    setCertificateIssuanceStatus(value);
  };

  const handleRowSelectedModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      setRowSelectionModel(rowSelectionModel);
    },
    [],
  );

  const handleConfirmClick = useCallback(() => {
    confirmDialog(
      `총 1,000건 중 ${commaizeNumber(rowSelectionModel.length)}건에 대해 다운로드가 가능합니다.`,
      {
        message: '다운로드를 진행하시겠습니까?',
        onConfirm: () => {},
      },
    );
  }, [rowSelectionModel, confirmDialog]);

  const handleRequestSubmit = () => {
    requestCertification(
      {
        consentSeq,
        certificateType: '',
        targets: rowSelectionModel as number[],
      },
      {
        onSuccess: () => {},
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const { columns } = useCreateGridColumns(CERTIFICATION_DETAIL_COLUMNS, {
    onNameClick: (unionRegisterSeq: number) => {
      console.log(unionRegisterSeq);
    },
    onDownloadClick: () => {},
  });

  const meetCertIssuableStatusCodeGroup = useMemo(
    () => getGroupCode('MEET_CERT_ISSUABLE_STATUS'),
    [getGroupCode],
  );

  return (
    <Stack gap={2}>
      <Typography level="title-lg" marginBottom="15px">
        동의서 제목
      </Typography>

      <Descriptions legacy={false} columns={4}>
        <DescriptionsItem label="신청가능">0건</DescriptionsItem>
        <DescriptionsItem label="신청중">0건</DescriptionsItem>
        <DescriptionsItem label="신청불가">0건</DescriptionsItem>
        <DescriptionsItem label="다운로드">0건</DescriptionsItem>
      </Descriptions>

      <IndexTemplate
        SearchComponent={
          <Search
            values={searchState}
            onSubmit={setSearchState}
            onReset={initState}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    placeholder="이름 검색"
                    label="이름"
                    name="meetName"
                    fullWidth
                  />
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={200}>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>문서보관 상태</FormLabel>
                  <Search.SelectField>
                    <Select
                      sx={{ width: '100%' }}
                      name="certificateIssuanceStatus"
                      value={certificateIssuanceStatus}
                      onChange={(_, value) =>
                        handleOnSelect(value as unknown as string)
                      }
                    >
                      <Option value="ALL">전체</Option>

                      {meetCertIssuableStatusCodeGroup?.items.map((item) => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Search.SelectField>
                </FormControl>
              </Grid>
            </Grid>
          </Search>
        }
        TopPanelComponent={
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체 <Typography color="primary">{0}</Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록 <Typography color="primary">{0}</Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택 <Typography color="primary">{0}</Typography>
            </Typography>
          </Stack>
        }
        IndexComponent={
          <Stack height={442}>
            <DataGrid
              rows={[]}
              columns={columns}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={handleRowSelectedModelChange}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Stack>
        }
        BottomPanelComponent={
          <Stack direction="row" justifyContent="space-between">
            <Button variant="outlined" color="neutral">
              이전
            </Button>

            <Stack direction="row" gap={1}>
              <Button variant="outlined" onClick={handleConfirmClick}>
                증명서 다운로드
              </Button>
              <Button>발급 신청</Button>
            </Stack>
          </Stack>
        }
      />

      <RequestModal
        requestor=""
        certificationType=""
        requestCount={rowSelectionModel.length}
        availableCount={rowSelectionModel.length}
        cost={0}
        open={isRequestModalOpen}
        onClose={setIsRequestModalOpen}
        onSubmit={handleRequestSubmit}
      />
    </Stack>
  );
};

export default CertificationTab;
