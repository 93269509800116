import { Box, Chip, Stack, Typography } from '@wooriga/design-system';

import { MeetVoteForm, MeetVoteType } from 'apis/types/meet';

type CandidateBase = {
  no: number;
  name: string;
  description: string;
};
type AgendaBase = {
  name: string;
  description: string;
  voteForm: MeetVoteForm;
  voteType: MeetVoteType;
  order: number;
  selectCount: number;
  candidates: CandidateBase[];
};
type AgendaProps = {
  agendaData: AgendaBase;
};

const Agenda = ({ agendaData }: AgendaProps) => {
  const {
    voteForm,

    order,
    name,
    selectCount,
    description,
    candidates,
  } = agendaData;
  const candidatesLength = candidates.length || 0;

  const chipTypeRender = (
    voteForm: string,
    selectCount: number,
    candidatesLength: number,
  ) => {
    if (voteForm === 'AGAINST') {
      return (
        <Box display="flex" flexDirection="row" alignItems="baseline">
          <Chip
            sx={{
              marginRight: 1,
            }}
            variant="solid"
            color="success"
            size="sm"
          >
            찬반투표
          </Chip>
          <Chip variant="soft" color="neutral" size="sm">
            후보 {candidatesLength}건
          </Chip>
        </Box>
      );
    }
    if (voteForm === 'SELECT') {
      return (
        <Box display="flex" flexDirection="row" alignItems="baseline">
          <Chip
            sx={{
              marginRight: 1,
            }}
            variant="solid"
            color="blue"
            size="sm"
          >
            선택투표
          </Chip>
          <Chip variant="soft" color="blue" size="sm">
            후보 {candidatesLength} 명 중 {selectCount} 선택
          </Chip>
        </Box>
      );
    }
  };
  return (
    <>
      <Stack
        mt={2}
        padding={2}
        gap={1.5}
        borderRadius={8}
        bgcolor="background.surface"
        sx={(theme) => theme.variants.outlined.neutral}
      >
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="row" alignItems="baseline">
            <Typography
              mr={1.5}
              fontSize="md"
              fontWeight="lg"
              lineHeight="md"
              textColor="neutral.500"
            >
              제 {order}호 안건
            </Typography>
            <Typography
              mr={1.25}
              fontSize="md"
              fontWeight="lg"
              lineHeight="md"
              textColor="common.black"
            >
              {name}
            </Typography>
          </Box>
          {chipTypeRender(voteForm, selectCount, candidatesLength)}
        </Box>
        {description ? (
          <Box>
            <Typography>{description}</Typography>
          </Box>
        ) : (
          ''
        )}
      </Stack>
    </>
  );
};

export default Agenda;
