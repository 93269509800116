import { GridColDef } from '@mui/x-data-grid-premium';
import { Button, Link } from '@wooriga/design-system';

import { UnionRegisterAgentInfo, UnionRegisterInfo } from 'apis/types/union';
import CommonCode from 'components/CommonCode';
import { formatBirth, formatTelephone } from 'utils/format';
import { formatCompanyRegistrationNumber } from 'utils/format/company';

export const COUNSEL_CREATE_GRID_COLUMNS = (
  onClickName: (unionRegisterSeq: number) => void,
) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row.unionRegisterNo,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'positionDescription',
      headerName: '직책',
    },
    {
      field: 'agent',
      headerName: '대리인',
      width: 160,
      minWidth: 160,
      valueGetter: (params: UnionRegisterAgentInfo) =>
        [params?.name?.name, params?.name?.nameClass].join(''),
    },
    {
      field: 'ownerType',
      headerName: '소유 구분',
      renderCell: (params) => (
        <CommonCode
          groupCode="UNION_REGISTER_USER_DIVIDE"
          code={params.value}
        />
      ),
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) => row,
      renderCell: (params) => {
        const { name, unionRegisterSeq } = params.value;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(unionRegisterSeq)}
          >
            {[name?.name, name?.nameClass].join(' ')}
          </Link>
        );
      },
    },
    {
      field: 'birth',
      headerName: '생년월일',
      width: 130,
      minWidth: 130,
      // valueGetter: (_, row) => row.birth ?? row.companyNo,
      valueGetter: (_, row) => {
        if (row.birth) {
          return formatBirth(row.birth);
        } else {
          return formatCompanyRegistrationNumber(row?.companyNo);
        }
      },
    },
    {
      field: 'mainPhone',
      headerName: '연락처',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        row.mainPhone && formatTelephone(row.mainPhone?.phoneNo),
    },
    {
      field: 'realAddress',
      headerName: '실제 거주지',
      width: 230,
      minWidth: 230,
      renderCell: ({ value }) =>
        value && `${value.address || ''} ${value.detailAddress || ''}`,
    },
    {
      field: 'tendency',
      headerName: '우호도',
      width: 110,
      renderCell: (params) => (
        <CommonCode groupCode="TENDENCY_TYPE" code={params.value} />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: 100,
      valueGetter: (_, row) => row.unionRegisterSeq,
      renderCell: (params) => (
        <Button variant="soft" component={Link} href={`${params.value}`}>
          상담 등록
        </Button>
      ),
    },
  ] as GridColDef<UnionRegisterInfo>[];
