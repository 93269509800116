import { Grid, GridProps, Stack } from '@wooriga/design-system';
import { useCallback, useMemo } from 'react';

import ColorMapItem from 'components/ColorMap/ColorMapItem';
import ColorMapLabel from 'components/ColorMap/ColorMapLabel';

export interface ColorMapValue {
  id: string;
  title: string;
  value: number;
  label?: string;
}

export interface ColorMapProps {
  data: ColorMapValue[];
  colors: string[];
  orientation?: 'vertical' | 'horizontal';
  divide?: number;
  cellWidth?: number;
  cellHeight?: number;
  disabledColorLabel?: boolean;
  valueFormatter?: (value: number) => string;
  labelFormatter?: (label: string) => string;
}

const ColorMap = (props: ColorMapProps) => {
  const {
    data: defaultData,
    colors,
    valueFormatter,
    orientation = 'horizontal',
    divide = 3,
  } = props;

  const modulo = 100 % colors.length;
  const matchContinuousColor = useCallback(
    (scale: number) => {
      const scaledPercent = scale;
      const moduloPercent = modulo * 10;
      let index =
        Math.floor((scaledPercent - moduloPercent) / (colors.length * 2)) +
        (modulo - 1);

      if (scaledPercent <= moduloPercent) {
        index = Math.floor(scaledPercent / (colors.length * 2 + 1));
      }

      return colors[index];
    },
    [colors, modulo],
  );

  const directions = useMemo(
    () => ({
      container:
        orientation === 'horizontal'
          ? 'column'
          : ('row' as GridProps['direction']),
      item:
        orientation === 'horizontal'
          ? 'row'
          : ('column' as GridProps['direction']),
    }),
    [orientation],
  );

  const data = useMemo(
    () =>
      defaultData.map((item) => ({
        ...item,
        color: matchContinuousColor(item.value),
      })),
    [defaultData, matchContinuousColor],
  );

  return (
    <Stack gap={7.75}>
      <ColorMapLabel colors={colors} />

      <Grid
        container
        direction={directions.container}
        spacing={1}
        sx={{ overflowX: 'auto', flexGrow: 1 }}
      >
        {Array.from({ length: Math.ceil(data.length / divide) }).map(
          (_, index) => (
            <Grid key={`color_map_column_${index}`}>
              <Stack direction={directions.item} gap={1}>
                {data
                  .slice(index * divide, (index + 1) * divide)
                  .map(({ id, title, value, label, color }) => (
                    <ColorMapItem
                      key={`color_map_item_${id}`}
                      color={color}
                      title={title}
                      value={valueFormatter?.(value) ?? String(value)}
                      label={label}
                    />
                  ))}
              </Stack>
            </Grid>
          ),
        )}
      </Grid>
    </Stack>
  );
};

export default ColorMap;
