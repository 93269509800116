import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Chip, ChipProps } from '@wooriga/design-system';
import { useMemo } from 'react';

import CommonCode, { CommonCodeProps } from 'components/CommonCode';

export interface ElectronicConsentProgressStatusChipProps
  extends Pick<CommonCodeProps, 'code'> {
  size?: ChipProps['size'];
  disabledIcon?: boolean;
}

const ElectronicConsentProgressStatusChip = ({
  code,
  size,
  disabledIcon,
}: ElectronicConsentProgressStatusChipProps) => {
  const getVariant = (): {
    variant?: ChipProps['variant'];
    color?: ChipProps['color'];
  } => {
    switch (code) {
      case 'BEFORE':
        return {
          color: 'warning',
        };
      case 'IN_PROGRESS':
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: 'blue',
        };
      case 'COMPLETED':
        return {
          color: 'neutral',
        };

      default:
        return {};
    }
  };

  const Icon = useMemo(() => {
    switch (code) {
      case 'BEFORE':
        return HourglassEmptyIcon;
      case 'IN_PROGRESS':
        return RotateRightIcon;
      case 'COMPLETED':
        return ModeStandbyIcon;
      default:
        return () => null;
    }
  }, [code]);

  return (
    <Chip
      {...getVariant()}
      size={size ?? 'md'}
      startDecorator={!disabledIcon ? <Icon /> : null}
    >
      <CommonCode groupCode="ELECTRONIC_CONSENT_PROGRESS_STATUS" code={code} />
    </Chip>
  );
};

export default ElectronicConsentProgressStatusChip;
