import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import { LocalAddress } from 'apis/types/common';
import { MeetResponse } from 'apis/types/meet';
import { MeetsAgendasQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { ApiError, ApiResponseData } from 'types/api';

export type MeetsQuery = UseQueryResult<
  ApiResponseData<MeetResponse[]>,
  AxiosError
>;

export interface MeetsParams {
  meetName: string;
  moverName: string;
  meetStatus: string;
  openType: string;
  meetMethod: string;
  meetType: string;
}

export type LiveMeetsQuery = UseQueryResult<ApiResponseData, AxiosError>;

// _temp 나중에 제거 필요
export const usePremiumServiceQuery = (meetSeq: number): LiveMeetsQuery =>
  useQuery({
    queryKey: [`/v2/general-meetings/${meetSeq}/premium-services`],
  });
// 안건 목록 조회
export const useAgendaQuery = (meetSeq: number): MeetsAgendasQuery =>
  useQuery({
    queryKey: [`/v2/general-meetings/${meetSeq}/agendas`],
  });

export interface Setting {
  onlineGeneralMeetingSeq: number;
  accessUrl: string;
  generalMeetingAt: string;
}
export type SettingQuery = UseQueryResult<ApiResponseData<Setting>, AxiosError>;

export const useSettingsQuery = (meetSeq: number): SettingQuery =>
  useQuery({
    queryKey: [`/v2/general-meetings/${meetSeq}/online-general-meetings`],
  });

export interface MetaData {
  status: string;
  stageArn: string;
  streamingStatus: string;
  token: string;
  expiredTokenTime: string;
  id: string;
  password: string;
}

export type MetaDataQuery = UseQueryResult<
  ApiResponseData<MetaData>,
  AxiosError
>;
// metadata 조회
export const useMetadataQuery = (
  onlineGeneralMeetingSeq: number,
): MetaDataQuery =>
  useQuery({
    queryKey: [
      `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/meta-datas`,
    ],
  });

export interface MeetsGeneralData {
  onlineGeneralMeetingSeq: number;
  startAt: string;
  totalParticipantCount: number;
  cumulativeParticipantCount: number;
  currentParticipantCount: number;
  playUrl: string;
  endAt: string;
  streamingStatus: string;
}
export type MeetsGeneralDataQuery = UseQueryResult<
  ApiResponseData<MeetsGeneralData>,
  AxiosError
>;

// 온라인 총회 정보 조회
export const useGeneralOnlineMeetsQuery = (
  onlineGeneralMeetingSeq: number,
): MeetsGeneralDataQuery =>
  useQuery({
    queryKey: [`/v2/online-general-meetings/${onlineGeneralMeetingSeq}`],
  });

// 임시로 추가 된 쿼리 나중에 필요 없어서 삭제 될 예정
export const usePrivateKeyQuery = (meetSeq: number): LiveMeetsQuery =>
  useQuery({
    queryKey: [`/v2/general-meetings/${meetSeq}/seqs`],
  });

export interface PremiumServiceBody {
  mbSeq: number;
  premiumServiceType: string;
}

export type PremiumServiceMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PremiumServiceBody
>;
export type PremiumServiceCancelMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  {
    premiumServiceType: string;
  }
>;

export const usePremiumServiceMutation = (
  meetSeq: number,
): PremiumServiceMutation =>
  useMutation({
    mutationFn: async ({ mbSeq, premiumServiceType }) => {
      console.log('premiumServiceType2', premiumServiceType);
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/v2/general-meetings/${meetSeq}/premium-services`,
        { premiumServiceType: premiumServiceType },
        {
          headers: {
            mbSeq: mbSeq,
          },
        },
      );

      return data;
    },
  });

export const usePremiumServiceCancelMutation = (
  meetSeq: number,
): PremiumServiceCancelMutation =>
  useMutation({
    mutationFn: async ({ premiumServiceType }) => {
      console.log('premiumServiceType2', premiumServiceType);
      const { data } = await axiosInstance.delete<ApiResponseData>(
        `/v2/general-meetings/${meetSeq}/premium-services`,
        {
          data: { premiumServiceType }, // data 키로 본문 데이터 전달
        },
      );

      return data;
    },
  });

export const useGenerateGeneralMeetMutation = (meetSeq: number) =>
  useMutation({
    mutationFn: async () => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/v2/general-meetings/${meetSeq}/online-general-meetings`,
      );

      return data;
    },
  });

export interface MetadataBody {
  onlineGeneralMeetingSeq: number;
}

export type MetadataMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  MetadataBody
>;

export const useCreateMetadataMutation = (): MetadataMutation =>
  useMutation({
    mutationFn: async ({ onlineGeneralMeetingSeq }) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/meta-datas`,
      );

      return data;
    },
  });

export type NoticeMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  noticeBody
>;
export interface noticeBody {
  onlineGeneralMeetingSeq: number;
  message: string;
}

export interface Notice {
  message: string;
}

export type NoticeQuery = UseQueryResult<ApiResponseData<Notice[]>, AxiosError>;
// metadata 조회
export const useNoticeQuery = (onlineGeneralMeetingSeq: number): NoticeQuery =>
  useQuery({
    queryKey: [
      `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/notices`,
    ],
  });

export type OpinionListQuery = UseQueryResult<
  ApiResponseData<Opinion[]>,
  AxiosError
>;
export type OpinionQuery = UseQueryResult<ApiResponseData<Opinion>, AxiosError>;
// opinion list
export const useOpinionsQuery = (
  onlineGeneralMeetingSeq: number,
): OpinionListQuery =>
  useQuery({
    queryKey: [
      `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/opinions`,
    ],
    refetchInterval: 10000,
  });

export interface Viewer {
  name: string;
  phone: number;
  gender: string;
  birth: string;
  viewerSeq: number;
}
export interface Opinion {
  comment: string;
  commentAt: string;
  status: string;
  opinionSeq: number;
  viewer: Viewer;
}
export type OpinionMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  OpinionBody
>;
export interface OpinionBody {
  onlineGeneralMeetingSeq: number;
  opinionSeq: number;
  answer: string;
}

// export const useOpinionDetailQuery
export const useOpinionDetailQuery = (
  opinionSeq: number,
  onlineGeneralMeetingSeq: number,
): OpinionQuery =>
  useQuery({
    queryKey: [
      `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/opinions/${opinionSeq}`,
    ],
  });
// 의견 답변 등록
export const useCreateCommentMutation = (): OpinionMutation =>
  useMutation({
    mutationFn: async ({ opinionSeq, onlineGeneralMeetingSeq, answer }) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/opinions/${opinionSeq}`,
        {
          answer,
        },
      );

      return data;
    },
  });
//참석자
export interface Participant {
  mpSeq: number;
  unionRegisterSeq: number;
  unionRegisterNo: string;
  managementNo: number;
  name: {
    name: string;
    nameClass: string;
  };
  localAddresses: LocalAddress[];
  agent: {
    name: {
      name: string;
      nameClass: string;
    };
  };
  voteCategory: string;
  attendanceCategory: string;
  attendanceStatus: string;
  attendanceAt: string;
}
export type ParticipantListQuery = UseQueryResult<
  ApiResponseData<Participant[]>,
  AxiosError
>;

export const useAttendeeQuery = (
  onlineGeneralMeetingSeq: number,
): ParticipantListQuery =>
  useQuery({
    queryKey: [
      `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/participants`,
    ],
    refetchInterval: 10000,
  });

export interface Speech {
  speechSeq: number;
  viewerName: string;
  registeredAt: string;
  speechStatusType: string;
  speechCategory: string;
}

export type SpeechListQuery = UseQueryResult<
  ApiResponseData<Speech[]>,
  AxiosError
>;

export const useSpeechQuery = (
  onlineGeneralMeetingSeq: number,
): SpeechListQuery =>
  useQuery({
    queryKey: [
      `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/speeches`,
    ],
    refetchInterval: 10000,
  });

// 공지 등록
export const useCreateNoticeMutation = (): NoticeMutation =>
  useMutation({
    mutationFn: async ({ onlineGeneralMeetingSeq, message }) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/notices`,
        {
          message,
        },
      );

      return data;
    },
  });

export interface StatusBody {
  onlineGeneralMeetingSeq: number;
  streamingStatusType: string;
}

export type StatusMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  StatusBody
>;

//온라인 총회 상태 변경
export const useChangeOnlineGeneralMeetStatusMutation = (): StatusMutation =>
  useMutation({
    mutationFn: async ({ onlineGeneralMeetingSeq, streamingStatusType }) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/status`,
        {
          streamingStatusType,
        },
      );

      return data;
    },
  });

export type SpeechMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  {
    onlineGeneralMeetingSeq: number;
    speechSeq: number;
    status: string;
  }
>;

// 발언요청
export const useSpeechMutation = (): SpeechMutation =>
  useMutation({
    mutationFn: async ({ onlineGeneralMeetingSeq, speechSeq, status }) => {
      const { data } = await axiosInstance.patch<ApiResponseData>(
        `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/speeches/${speechSeq}/status`,
        {
          status,
        },
      );

      return data;
    },
  });

export interface ViewerDetail extends Viewer {
  localAddresses: LocalAddress[];
}

export type ViwerInfoQuery = UseQueryResult<
  ApiResponseData<ViewerDetail>,
  AxiosError
>;
//viewerInfo
export const useViewerInfoQuery = (
  onlineGeneralMeetingSeq: number,
  viewerSeq: number,
): ViwerInfoQuery =>
  useQuery({
    queryKey: [
      `/v2/online-general-meetings/${onlineGeneralMeetingSeq}/viewers/${viewerSeq}`,
    ],
  });
