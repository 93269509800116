import { GridColDef, Select, Option } from '@wooriga/design-system';

import { MeetStatus } from 'apis/types/meet';
import MeetVoteResultChip from 'components/Chips/MeetVoteResultChip';
import { MeetsVoteResultTableRow } from 'lim/generalMeetingHistoryDetail/fixtures/meetsVoteAgainstResult';
import { GridColumnsHandlers } from 'types/grid';

const noneRowSpanValueGetter = () => null;

// 집계중
export const MEETS_VOTE_SELECT_RESULT_TABLE_COLUMNS = ({
  onSelect,
  meetStatus,
}: GridColumnsHandlers<{
  onSelect: (row: MeetsVoteResultTableRow, value: boolean | null) => void;
  meetStatus: MeetStatus;
}>): GridColDef<MeetsVoteResultTableRow>[] => [
  {
    field: 'no',
    headerName: '기호',
    width: 50,
    minWidth: 50,
  },
  {
    field: 'name',
    headerName: '보기',
    width: 180,
    minWidth: 180,
    flex: 1,
    rowSpanValueGetter: (value, row) => `${row.no}-${value}`,
    // valueGetter: (value, row) => (row.method === '총합' ? value : ''),
  },
  {
    field: 'method',
    headerName: '투표 종류',
    rowSpanValueGetter: noneRowSpanValueGetter,
    valueGetter: (value) =>
      value === 'total'
        ? '총합'
        : value === 'electronicResult'
          ? '전자투표'
          : value === 'onsiteResult'
            ? '현장투표'
            : '서면제출',
  }, // name + description
  {
    field: 'yesCount',
    headerName: '선택',
    width: 80,
    minWidth: 80,
    rowSpanValueGetter: noneRowSpanValueGetter,
  },
  {
    field: 'voteRate',
    headerName: '득표율',
    width: 80,
    minWidth: 80,
    rowSpanValueGetter: noneRowSpanValueGetter,
    valueFormatter: (value, row) =>
      row.method === 'total' ? `${(value * 100).toFixed(2)}%` : '',
  },
  {
    field: 'isPassed',
    headerName: '의결결과',
    display: 'flex',
    rowSpanValueGetter: noneRowSpanValueGetter,
    renderCell: ({ value, row }) => {
      if (row.method !== 'total') return <></>;

      if (meetStatus === 'COMPLETE')
        return <MeetVoteResultChip variant="solid" value={value} />;

      return (
        <Select
          size="sm"
          multiple={false}
          value={value === undefined ? null : value}
          onChange={(_, value) => onSelect(row, value)}
          renderValue={(option) =>
            option && option.value !== null ? (
              <MeetVoteResultChip value={option.value} />
            ) : (
              '선택'
            )
          }
        >
          <Option value={null}>선택</Option>
          <Option value={true}>가결</Option>
          <Option value={false}>부결</Option>
        </Select>
      );
    },
  },
];
