import {
  Button,
  Callout,
  Stack,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useState } from 'react';

import { useCreateNoticeMutation } from 'apis/meet/live/api';
import useFeedback from 'hooks/useFeedback';

export interface NoticePanelProps {
  meetSeq?: number;
  onlineGeneralMeetingSeq: number;
  noticeMessage: string;
}

const NoticePanel = ({
  onlineGeneralMeetingSeq,
  noticeMessage,
}: NoticePanelProps) => {
  const [inputNotice, setInputNotice] = useState('');
  const { snackbar } = useFeedback();

  const { mutate: noticeMutate } = useCreateNoticeMutation();

  const handleChangeValues = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputNotice(event.target.value);
  };

  const handleClickNotice = () => {
    console.log('currentNotice', inputNotice);
    if (!inputNotice) return;

    noticeMutate(
      {
        onlineGeneralMeetingSeq: onlineGeneralMeetingSeq,
        message: inputNotice,
      },
      {
        onSuccess: () => {
          snackbar('공지가 등록되었습니다.', { color: 'success' });
          setInputNotice('');
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  return (
    <>
      <Stack gap={3} marginTop={2}>
        <Stack gap={1}>
          <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
            현재 등록된 공지
          </Typography>
          <Callout variant="soft" size="lg" color="neutral">
            {noticeMessage}
          </Callout>
        </Stack>

        <Stack gap={1}>
          <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
            공지 등록
          </Typography>
          <Stack flexDirection="row" gap={1}>
            <Textarea
              variant="outlined"
              color="neutral"
              size="md"
              minRows={4}
              value={inputNotice}
              validateOptions={{ maxLength: 150 }}
              endDecorator={
                <Stack width="100%" direction="row">
                  <Stack ml="auto" mr={1}>
                    <Typography
                      fontSize="md"
                      fontWeight="md"
                      lineHeight="md"
                      textColor="primary.500"
                    >
                      {inputNotice.length || 0}/150
                    </Typography>
                  </Stack>
                </Stack>
              }
              onChange={handleChangeValues}
              placeholder="등록하실 공지를 입력해 주세요"
              sx={{
                flex: 1,
              }}
            />

            <Button
              size="lg"
              variant="solid"
              color="primary"
              disabled={inputNotice.length === 0 ? true : false}
              onClick={handleClickNotice}
            >
              등록
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
export default NoticePanel;
