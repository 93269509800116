import { formatDate, formatDateTime } from '@wooriga/common-utils';
import { Button, Chip, GridColDef, Link } from '@wooriga/design-system';

import {
  ElectronicConsentCertificate,
  ElectronicConsentDetailCertification,
  ElectronicConsentRecipient,
} from 'apis/types/consent';
import { UnionRegisterInfo } from 'apis/types/union';
import ElectronicConsentSubmitStatusChip from 'components/Chips/ElectronicConsentSubmitStatusChip';
import { GridColumnsHandlers } from 'types/grid';

export const ELECTRONIC_CONSENT_TARGET_COLUMNS = ({
  onClickName,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (unionRegisterSeq: number) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row?.unionRegisterNo || '',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row?.shareType)?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      headerAlign: 'center',
      type: 'boolean',
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        row?.name
          ? [row?.name?.name, row?.name?.nameClass].join('')
          : '이름없음',
      renderCell: (params) => {
        const { value, row } = params;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(row?.unionRegisterSeq)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', row?.ownerType)?.name || '',
    },
    {
      field: 'areaDong',
      headerName: '법정동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, dong }) => ({
          key: `area-dong-${localAddressSeq}`,
          value: dong,
        })),
    },
    {
      field: 'jibun',
      headerName: '지번',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, jibun }) => ({
          key: `jibun-${localAddressSeq}`,
          value: jibun,
        })),
    },
    {
      field: 'building',
      headerName: '건물명',
      flex: 1,
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, building }) => ({
          key: `building-${localAddressSeq}`,
          value: building,
        })),
    },
    {
      field: 'apartmentDong',
      headerName: '동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, apartmentDong }) => ({
          key: `apartmentDong-${localAddressSeq}`,
          value: apartmentDong,
        })),
    },
    {
      field: 'apartmentHosu',
      headerName: '호수',
      multiline: true,
      valueGetter: (_, row) =>
        row?.localAddresses?.map(({ localAddressSeq, apartmentHosu }) => ({
          key: `apartmentHosu-${localAddressSeq}`,
          value: apartmentHosu,
        })),
    },
  ] as GridColDef<UnionRegisterInfo>[];

export const ELECTRONIC_CONSENT_RECIPIENTS_COLUMNS = ({
  onClickName,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (recipient: ElectronicConsentRecipient) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row?.registers.unionRegisterNo || '',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row?.registers.shareType)
          ?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      headerAlign: 'center',
      type: 'boolean',
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        row?.registers.name
          ? [row?.registers.name?.name, row?.registers.name?.nameClass].join('')
          : '이름없음',
      renderCell: (params) => {
        const { value, row } = params;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(row)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', row?.registers.ownerType)
          ?.name || '',
    },
    {
      field: 'areaDong',
      headerName: '법정동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(({ localAddressSeq, dong }) => ({
          key: `area-dong-${localAddressSeq}`,
          value: dong,
        })),
    },
    {
      field: 'jibun',
      headerName: '지번',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(({ localAddressSeq, jibun }) => ({
          key: `jibun-${localAddressSeq}`,
          value: jibun,
        })),
    },
    {
      field: 'building',
      headerName: '건물명',
      flex: 1,
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(({ localAddressSeq, building }) => ({
          key: `building-${localAddressSeq}`,
          value: building,
        })),
    },
    {
      field: 'apartmentDong',
      headerName: '동',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(
          ({ localAddressSeq, apartmentDong }) => ({
            key: `apartmentDong-${localAddressSeq}`,
            value: apartmentDong && `${apartmentDong}동`,
          }),
        ),
    },
    {
      field: 'apartmentHosu',
      headerName: '호수',
      multiline: true,
      valueGetter: (_, row) =>
        row?.registers.localAddresses?.map(
          ({ localAddressSeq, apartmentHosu }) => ({
            key: `apartmentHosu-${localAddressSeq}`,
            value: apartmentHosu && `${apartmentHosu}호`,
          }),
        ),
    },
    {
      field: 'landTotalArea',
      headerName: '토지면적',
      valueGetter: (_, row) =>
        row.registers.localAddresses[0]?.landOwnership?.totalArea ?? '',
      valueFormatter: (value: string) => value && `${value}㎡`,
    },
    {
      field: 'buildingUsage',
      headerName: '건축물용도',
      valueGetter: (_, row) =>
        getCommonCode(
          'BUILDING_USAGE',
          row.registers.localAddresses[0]?.buildingOwnership?.buildingUsage ??
            '',
        )?.name || '',
    },
    {
      field: 'buildingTotalArea',
      headerName: '건축물면적',
      valueGetter: (_, row) =>
        row.registers.localAddresses[0]?.buildingOwnership?.totalArea ?? '',
      valueFormatter: (value: string) => value && `${value}㎡`,
    },
    {
      field: 'status',
      headerName: '제출상태',
      renderCell: (params) => {
        return (
          <ElectronicConsentSubmitStatusChip
            size="sm"
            code={params.value}
            label={
              getCommonCode(
                'ELECTRONIC_CONSENT_RECIPIENT_SUBMIT_STATUS',
                params.value,
              )?.name || ''
            }
          />
        );
      },
    },
    {
      field: 'submittedAt',
      headerName: '제출일시',
      valueGetter: (value) => (value && formatDate(value)) || '',
    },
  ] as GridColDef<ElectronicConsentRecipient>[];

export const ELECTRONIC_CONSENT_CERTIFICATE_COLUMNS = ({
  onClickName,
  onClickExtension,
  getCommonCode,
}: GridColumnsHandlers<{
  onClickName: (meetSeq: number) => void;
  onClickExtension: (meetSeq: number) => void;
}>) =>
  [
    {
      field: 'no',
      headerName: 'No',
      width: 80,
      minWidth: 80,
      valueGetter: (_, row) => row.electronicConsent?.no ?? '',
    },
    {
      field: 'name',
      headerName: '전자동의서 제목',
      width: 200,
      minWidth: 200,
      flex: 1,
      valueGetter: (_, row) => row.electronicConsent?.name || '',
      renderCell: (params) => {
        const consentSeq = params.row.electronicConsent.consentSeq;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(consentSeq)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'status',
      headerName: '동의서 상태',
      valueGetter: (_, row) =>
        row.electronicConsent.status &&
        getCommonCode('MEET_METHOD', row.electronicConsent.status)?.name,
    },
    {
      field: 'documentStorageEndAt',
      headerName: '문서보관 종료일',
      width: 110,
      minWidth: 110,
      valueGetter: (_, row) =>
        row.documentStorageEndAt && formatDate(row.documentStorageEndAt),
    },
    {
      field: 'documentStorageStatus',
      headerName: '문서보관 상태',
      valueGetter: (value) =>
        value && getCommonCode('MEET_METHOD', value)?.name,
    },
    {
      field: 'documentStorageExtensionStatus',
      headerName: '문서보관 연장',
      align: 'center',
      valueGetter: (value) =>
        getCommonCode('DOCUMENT_STORAGE_STATUS', value)?.name,
      renderCell: (params) => {
        if (params.value) return <Chip>{params.value}</Chip>;

        return (
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            onClick={() =>
              onClickExtension(params.row.electronicConsent.consentSeq)
            }
          >
            신청
          </Button>
        );
      },
    },
  ] as GridColDef<ElectronicConsentCertificate>[];

export const CERTIFICATION_DETAIL_COLUMNS = ({
  onNameClick,
  onDownloadClick,
  getCommonCode,
}: GridColumnsHandlers<{
  onNameClick: (unionRegisterSeq: number) => void;
  onDownloadClick: (...fileSeqs: number[]) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row?.register.unionRegisterNo || '',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      width: 100,
      minWidth: 100,
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_SHARE_TYPE', row?.register.shareType)
          ?.name || '',
    },
    {
      field: 'isRepresentative',
      headerName: '대표자',
      headerAlign: 'center',
      type: 'boolean',
      valueGetter: (_, row) => row.register.isRepresentative,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      renderCell: (params) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onNameClick(params.row.register.unionRegisterSeq)}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: 'ownerType',
      headerName: '개인/법인',
      valueGetter: (_, row) =>
        getCommonCode('UNION_REGISTER_USER_DIVIDE', row?.register.ownerType)
          ?.name || '',
    },
    {
      field: 'submitStatus',
      headerName: '제출여부',
      valueGetter: (value) => getCommonCode('VOTING_STATUS_TYPE', value)?.name,
    },
    {
      field: 'submittedAt',
      headerName: '제출일시',
      width: 200,
      minWidth: 200,
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'downloadedAt',
      headerName: '최근 다운로드 일시',
      width: 200,
      minWidth: 200,
      valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'issuanceStatus',
      headerName: '발급상태',
      valueGetter: (value) =>
        getCommonCode('MEET_CERT_ISSUABLE_STATUS', value)?.name,
      renderCell: (params) => {
        const { value, row } = params;
        const { issuanceStatus, certificateFileSeq } = row;

        if (issuanceStatus === 'COMPLETE' && certificateFileSeq)
          return (
            <Button
              variant="outlined"
              color="neutral"
              size="sm"
              onClick={() => onDownloadClick(certificateFileSeq)}
            >
              다운로드
            </Button>
          );

        return value && <Chip>{value}</Chip>;
      },
    },
  ] as GridColDef<ElectronicConsentDetailCertification>[];
