import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';

import certificationPage from 'pages/main/consent-management/certifications';
import historyPage from 'pages/main/consent-management/history';
import { CustomRouteObject } from 'types/route';

const consentManagementPage: CustomRouteObject = {
  handle: {
    getTitle: () => '전자 동의서 관리',
    getIcon: () => <PieChartOutlinedIcon />,
    getMenuCode: () => 'M12',
  },
  children: [historyPage, certificationPage],
};

export default consentManagementPage;
