import HowToVoteIcon from '@mui/icons-material/HowToVote';
import InfoIcon from '@mui/icons-material/Info';
import { gridClasses, isAutogeneratedRow } from '@mui/x-data-grid-premium';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  useTheme,
  Callout,
  DataGrid,
  Stack,
  Pagination,
  Box,
  Sheet,
  Grid,
  PieCenterLabel,
  PieChart,
  Typography,
  Chip,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useReadMeetAttendSituation } from 'apis/meet/attend-situation/api';
import {
  MeetAttendSituationTableRow,
  ONLINE_MEET_ATTEND_SITUATION_TABLE_COLUMNS,
  ONSITE_MEET_ATTEND_SITUATION_TABLE_COLUMNS,
  TOTAL_MEET_ATTEND_SITUATION_TABLE_COLUMNS,
} from 'apis/meet/attend-situation/fixtures/attendSituation';
import { useReadMeetParticipants } from 'apis/meet/participants/api';
import { MEET_PARTICIPANTS_TABLE_COLUMNS } from 'apis/meet/participants/fixtures/participants';
import { MeetMethods, MeetParticipantInfo, MeetStatus } from 'apis/types/meet';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import JointBookStatusContent from 'components/pages/meet-management/history/detail/RealTimeAttendance/JointBookStatusContent';
import MeetStatusContent from 'components/pages/meet-management/history/detail/RealTimeAttendance/MeetStatusContent';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import { PaginationState } from 'hooks/useQueryControls/types';
import {
  MeetJointBookResultQuery,
  MeetsDetailQuery,
  MeetsRealtimeStatusCountQuery,
} from 'lim/generalMeetingHistoryDetail/apis';

export interface RealTimeAttendanceProps {
  meetMethod: MeetMethods;
  meetStatus: MeetStatus;
  meetsRealtimeStatusCountQuery?: MeetsRealtimeStatusCountQuery;
  meetsDetailQuery: MeetsDetailQuery;
  meetsJointBookQuery?: MeetJointBookResultQuery;
  meetSeq: number;
}

const RealTimeAttendance = ({
  meetStatus,
  meetSeq,
}: RealTimeAttendanceProps) => {
  const theme = useTheme();
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const [paginationModel, setPaginationModel] = useState<PaginationState>({
    pageSize: 10,
    page: 0,
  });

  const readMeetAttendSituation = useReadMeetAttendSituation({
    generalMeetingSeq: meetSeq,
  });

  const readMeetParticipants = useReadMeetParticipants({
    generalMeetingSeq: meetSeq,
  });

  const {
    onSiteAttendSituationInfo = [],
    onlineAttendSituationInfo = [],
    totalAttendSituationInfo = [],
    totalOnsiteValue = 0,
    totalOnlineValue = 0,
    writtenVoteValue = 0,
    electronicVoteValue = 0,
    nonAttendanceValue = 0,
  } = useMemo(() => {
    if (!readMeetAttendSituation?.data || !readMeetAttendSituation?.data?.data)
      return {};

    const {
      totalParticipantCount,
      writtenVoteOnSite,
      electronicVoteOnSite,
      writtenVoteOnline,
      electronicVoteOnline,
      onlineAttendance,
      onsiteAttendance,
      writtenVote,
      electronicVote,
      nonAttendance,
    } = readMeetAttendSituation.data.data;

    const totalOnsiteValue =
      writtenVoteOnSite.count +
      electronicVoteOnSite.count +
      onsiteAttendance.count;
    let totalOnsitePercetage =
      (totalOnsiteValue / totalParticipantCount) * 100 || 0;
    if (totalOnsitePercetage === Infinity) totalOnsitePercetage = 0;

    const onSiteAttendSituationInfo: MeetAttendSituationTableRow[] = [
      {
        id: 'writtenVoteOnSite',
        label: 'ⓛ 서면 투표 후 직접 출석',
        helpTooltip: {
          title: '서면 투표 후 직접 출석',
          description: '서면결의서를 제출 후 총회 현장에 출석한 선거인 수',
        },
        count: writtenVoteOnSite.count,
      },
      {
        id: 'electronicVoteOnSite',
        label: '② 전자 투표 후 직접 출석',
        helpTooltip: {
          title: '전자 투표 후 직접 출석',
          description: '사전 전자 투표 후 총회 현장에 출석한 선거인 수',
        },
        count: electronicVoteOnSite.count,
      },
      {
        id: 'onsiteAttendance',
        label: '③ 현장 직접 출석',
        helpTooltip: {
          title: '현장 직접 출석',
          description: '총회 현장에 출석한 선거인 수',
        },
        count: onsiteAttendance.count,
      },
      {
        id: 'totalOnsite',
        label: '④ 총 현장 출석(①+②+③)',
        count: totalOnsiteValue,
        percentage: totalOnsitePercetage,
      },
    ];

    const totalOnlineValue =
      writtenVoteOnline.count +
      electronicVoteOnline.count +
      onlineAttendance.count;
    let totalOnlinePercentage =
      (totalOnlineValue / totalParticipantCount) * 100;
    if (totalOnlinePercentage === Infinity) totalOnlinePercentage = 0;

    const onlineAttendSituationInfo: MeetAttendSituationTableRow[] = [
      {
        id: 'writtenVoteOnline',
        label: '⑤ 서면 투표 후 참석',
        helpTooltip: {
          title: '서면 투표 후 참석',
          description: '서면결의서를 제출 후 온라인 총회에 참석한 선거인 수',
        },
        count: writtenVoteOnline.count,
      },
      {
        id: 'electronicVoteOnline',
        label: '⑥ 전자 투표 후 참석',
        helpTooltip: {
          title: '전자 투표 후 참석',
          description: '서면결의서를 제출 후 온라인 총회에 참석한 선거인 수',
        },
        count: electronicVoteOnline.count,
      },
      {
        id: 'onlineAttendance',
        label: '⑦ 온라인 총회 참석',
        helpTooltip: {
          title: '온라인 총회 참석',
          description: '온라인 총회로 참석한 선거인 수(직접 참석으로 인정)',
        },
        count: onlineAttendance.count,
      },
      {
        id: 'totalOnline',
        label: '⑧ 총 온라인 참석(⑤+⑥+⑦)',
        count: totalOnlineValue,
        percentage: totalOnlinePercentage,
      },
    ];

    const totalAttendanceValue =
      totalOnsiteValue +
      totalOnlineValue +
      writtenVote.count +
      electronicVote.count;
    let totalAttendancePercentage =
      (totalAttendanceValue / totalParticipantCount) * 100 || 0;

    if (totalAttendancePercentage === Infinity) totalAttendancePercentage = 0;
    const nonAttendanceValue = nonAttendance.count;
    const nonAttendancePercentage = nonAttendance.percentage;

    const totalAttendSituationInfo: MeetAttendSituationTableRow[] = [
      {
        id: 'totalOnsite',
        label: '④ 총 현장 출석',
        count: totalOnsiteValue,
      },
      {
        id: 'totalOnline',
        label: '⑧ 총 온라인 참석',
        count: totalOnlineValue,
      },
      {
        id: 'totalDirectAttendance',
        label: '⑨ 직접 출석(④+⑧)',
        count: totalOnsiteValue + totalOnlineValue,
        percentage: totalOnsitePercetage + totalOnlinePercentage,
      },
      {
        id: 'writtenVote',
        label: '⑩ 서면 제출',
        helpTooltip: {
          title: '서면 제출',
          description:
            '서면결의서를 제출 하였으나 총회 현장에 출석하지 않은 선거인 수',
        },
        count: writtenVote.count,
      },
      {
        id: 'electronicVote',
        label: '⑪ 전자 투표',
        helpTooltip: {
          title: '전자 투표',
          description:
            '사전 전자 투표를 하였으나 총회 현장에 출석하지 않은 선거인 수',
        },
        count: electronicVote.count,
      },
      {
        id: 'totalAttendance',
        label: '총 출석(⑨+⑩+⑪)',
        count: totalAttendanceValue,
        percentage: totalAttendancePercentage,
      },
      {
        id: 'nonAttendance',
        label: '미출석',
        count: nonAttendanceValue,
        percentage: nonAttendancePercentage,
      },
    ];

    return {
      onSiteAttendSituationInfo,
      onlineAttendSituationInfo,
      totalAttendSituationInfo,
      totalOnsiteValue,
      totalOnlineValue,
      writtenVoteValue: writtenVote.count,
      electronicVoteValue: electronicVote.count,
      nonAttendanceValue,
    };
  }, [readMeetAttendSituation.data]);

  const series = useMemo(
    () => [
      {
        innerRadius: 96,
        outerRadius: 120,
        data: [
          {
            id: 'totalOnsite',
            label: `현장 출석 ${commaizeNumber(totalOnsiteValue)}`,
            value: totalOnsiteValue,
          },
          {
            id: 'totalOnline',
            label: `온라인 출석 ${commaizeNumber(totalOnlineValue)}`,
            value: totalOnlineValue,
          },
          {
            id: 'vote',
            label: `사전투표(전자+서면) ${commaizeNumber(writtenVoteValue + electronicVoteValue)}`,
            value: writtenVoteValue + electronicVoteValue,
          },
          {
            id: 'nonAttendance',
            label: `미출석 ${commaizeNumber(nonAttendanceValue)}`,
            value: nonAttendanceValue,
          },
        ],
      },
    ],
    [
      electronicVoteValue,
      nonAttendanceValue,
      totalOnlineValue,
      totalOnsiteValue,
      writtenVoteValue,
    ],
  );

  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState<boolean>(false);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  const handleClickName = useCallback((row: MeetParticipantInfo) => {
    setSelectUnionRegisterSeq(row.unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const { columns: onsiteMeetAttendSituationColumns } = useCreateGridColumns(
    ONSITE_MEET_ATTEND_SITUATION_TABLE_COLUMNS,
  );

  const { columns: onlineMeetAttendSituationColumns } = useCreateGridColumns(
    ONLINE_MEET_ATTEND_SITUATION_TABLE_COLUMNS,
  );

  const { columns: totalMeetAttendSituationColumns } = useCreateGridColumns(
    TOTAL_MEET_ATTEND_SITUATION_TABLE_COLUMNS,
  );

  const { columns: meetParticipantsColumns } = useCreateGridColumns(
    MEET_PARTICIPANTS_TABLE_COLUMNS,
    { onClickName: handleClickName },
  );
  const { meetParticipantsRows, count } = useMemo(() => {
    const meetParticipantsRows = readMeetParticipants.data?.data || [];
    const totalElements =
      readMeetParticipants.data?.pagination?.totalElements || 0;
    const totalDataCount =
      readMeetParticipants.data?.pagination?.totalDataCount || 0;
    const count = Math.ceil(totalElements / paginationModel.pageSize);

    return {
      meetParticipantsRows,
      totalDataCount,
      totalElements,
      count,
    };
  }, [
    paginationModel.pageSize,
    readMeetParticipants.data?.data,
    readMeetParticipants.data?.pagination?.totalDataCount,
    readMeetParticipants.data?.pagination?.totalElements,
  ]);

  return (
    <>
      <Stack gap={3}>
        <Callout variant="outlined" startDecorator={<InfoIcon />}>
          <MeetStatusContent meetStatus={meetStatus} />
          <JointBookStatusContent meetStatus={meetStatus} meetSeq={meetSeq} />
        </Callout>

        {meetStatus === 'BEFORE' ? (
          <Sheet
            variant="outlined"
            sx={{
              width: '100%',
              height: 320,
              borderRadius: 'md',
              p: 5,
              alignContent: 'center',
              textAlign: 'center',
            }}
          >
            <Typography
              fontSize="md"
              fontWeight="md"
              lineHeight="md"
              textColor="neutral.500"
            >
              현재 표시할 데이터가 없습니다.
            </Typography>
          </Sheet>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid xs={12} maxWidth={450} minHeight={680}>
                <Sheet
                  variant="outlined"
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 'md',
                    p: 5,
                  }}
                >
                  <Box>
                    <Chip
                      variant="soft"
                      color="neutral"
                      startDecorator={<HowToVoteIcon />}
                      sx={{ mb: 2 }}
                    >
                      총회 출석률
                    </Chip>

                    <Typography fontSize="xl3" fontWeight="lg" lineHeight="xs">
                      {commaizeNumber(
                        readMeetAttendSituation.data?.data.totalAttendance
                          .count,
                      )}
                      /
                      {commaizeNumber(
                        readMeetAttendSituation.data?.data
                          .totalParticipantCount,
                      )}
                    </Typography>

                    <Typography
                      fontSize="md"
                      fontWeight="md"
                      lineHeight="md"
                      textColor="neutral.500"
                    >
                      (총 출석 수 / 총 선거인 수)
                    </Typography>
                  </Box>

                  <Box mt={-13.5}>
                    <PieChart
                      series={series}
                      title="총회 참석률"
                      margin={{ left: 0, right: 0 }}
                      width={370}
                      height={600}
                      colors={[
                        theme.palette.blue[500],
                        theme.palette.primary[500],
                        theme.palette.warning[400],
                        theme.palette.neutral[200],
                      ]}
                      loading={readMeetAttendSituation.isLoading}
                      slotProps={{
                        legend: {
                          position: {
                            horizontal: 'middle',
                            vertical: 'bottom',
                          },
                        },
                      }}
                    >
                      {!readMeetAttendSituation.isLoading && (
                        <PieCenterLabel
                          fontSize="xl3"
                          fontWeight="xl"
                          lineHeight="xs"
                        >
                          {
                            readMeetAttendSituation.data?.data?.totalAttendance
                              .percentage
                          }
                          %
                        </PieCenterLabel>
                      )}
                    </PieChart>
                  </Box>
                </Sheet>
              </Grid>

              <Grid container spacing={3}>
                <Grid xs={12} width={450}>
                  <Sheet
                    variant="outlined"
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 'md',
                      p: 3,
                    }}
                  >
                    <Stack gap={3}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        현장 출석 및 온라인 참석 유형
                      </Typography>

                      <DataGrid
                        disableRowSelectionOnClick
                        loading={readMeetAttendSituation.isPending}
                        columns={onsiteMeetAttendSituationColumns}
                        rows={onSiteAttendSituationInfo}
                        columnHeaderHeight={56}
                        rowHeight={56}
                        sx={{
                          [`.${gridClasses.columnHeader}`]: {
                            fontSize: '1.125rem',
                          },
                          [`.${gridClasses.cell}`]: {
                            fontSize: '1.125rem',
                          },
                          [`.${gridClasses.cell}.total`]: {
                            backgroundColor: theme.palette.primary[100],
                            color: theme.palette.primary[500],
                          },
                        }}
                        getCellClassName={(params) => {
                          if (
                            params.id !== 'totalOnsite' ||
                            isAutogeneratedRow(params.row)
                          ) {
                            return '';
                          }
                          return 'total';
                        }}
                      />

                      <DataGrid
                        disableRowSelectionOnClick
                        loading={readMeetAttendSituation.isPending}
                        columns={onlineMeetAttendSituationColumns}
                        rows={onlineAttendSituationInfo}
                        columnHeaderHeight={56}
                        rowHeight={56}
                        sx={{
                          [`.${gridClasses.columnHeader}`]: {
                            fontSize: '1.125rem',
                          },
                          [`.${gridClasses.cell}`]: {
                            fontSize: '1.125rem',
                          },
                          [`.${gridClasses.cell}.total`]: {
                            backgroundColor: theme.palette.primary[100],
                            color: theme.palette.primary[500],
                          },
                        }}
                        getCellClassName={(params) => {
                          if (
                            params.id !== 'totalOnline' ||
                            isAutogeneratedRow(params.row)
                          ) {
                            return '';
                          }
                          return 'total';
                        }}
                      />
                    </Stack>
                  </Sheet>
                </Grid>
                <Grid xs={12} width={450}>
                  <Sheet
                    variant="outlined"
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 'md',
                      p: 3,
                    }}
                  >
                    <Stack gap={3}>
                      <Typography
                        fontSize="md"
                        fontWeight="lg"
                        lineHeight="md"
                        textColor="neutral.500"
                      >
                        총 출석 유형
                      </Typography>

                      <DataGrid
                        disableRowSelectionOnClick
                        loading={readMeetAttendSituation.isPending}
                        columns={totalMeetAttendSituationColumns}
                        rows={totalAttendSituationInfo}
                        rowHeight={56}
                        columnHeaderHeight={56}
                        sx={{
                          [`.${gridClasses.columnHeader}`]: {
                            fontSize: '1.125rem',
                          },
                          [`.${gridClasses.cell}`]: {
                            fontSize: '1.125rem',
                          },
                          [`.${gridClasses.cell}.total`]: {
                            backgroundColor: theme.palette.primary[100],
                            color: theme.palette.primary[500],
                          },
                        }}
                        getCellClassName={(params) => {
                          if (
                            (params.id !== 'totalAttendance' &&
                              params.id !== 'totalDirectAttendance') ||
                            isAutogeneratedRow(params.row)
                          ) {
                            return '';
                          }
                          return 'total';
                        }}
                      />
                    </Stack>
                  </Sheet>
                </Grid>
              </Grid>
            </Grid>

            <Box>
              <DataGrid
                loading={readMeetParticipants.isPending}
                columns={meetParticipantsColumns}
                rows={meetParticipantsRows}
                getRowId={(row) => row.meetParticipantSeq}
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            </Box>

            <Stack alignItems="center">
              <Pagination
                orientation="horizontal"
                count={count}
                page={paginationModel.page + 1}
                onChange={(_, page) =>
                  page &&
                  setPaginationModel({
                    page: page - 1,
                    pageSize: paginationModel.pageSize,
                  })
                }
              />
            </Stack>
          </>
        )}
      </Stack>

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}
    </>
  );
};

export default RealTimeAttendance;
