import {
  // DefinedUseQueryResult,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
// import { generateInitRealData } from 'lim/_api';
import { NameInfo, PostAddress, RealAddress } from 'apis/types/common';
import {
  OnsiteVoteStatusResponse,
  MeetParticipantResponse,
  PromotionUserResponse,
  MeetParticipantAgentInfo,
} from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

// import { participantDetailData } from './json';

export interface ElectronicVoteStatus {
  sendStatus: string;
  voteStatus: string;
  receivedAt: string;
  openedAt: string;
  votedAt: string;
  resendCount: number;
}

export interface ParticipantDetail {
  meetParticipantSeq: number;
  unionRegisterSeq: number;
  unionRegisterNo: string;
  name: NameInfo;
  birth: string;
  phoneNo: string;
  gender: string;
  realAddress: RealAddress;
  tendency: string;
  position: string;
  shareType: string;
  agent: MeetParticipantAgentInfo;
  electronicVoteStatus: ElectronicVoteStatus;
  onsiteVoteStatus: OnsiteVoteStatusResponse;
  promotionUser: PromotionUserResponse;
}

export type MeetsParticipantsDetailQuery = UseQueryResult<
  ApiResponseData<MeetParticipantResponse>,
  AxiosError
>;

export const useMeetsParticipantsDetailQuery = (
  meetSeq: number,
  meetParticipantSeq: number,
): MeetsParticipantsDetailQuery =>
  useQuery({
    queryKey: [`/v1/meets/${meetSeq}/participants/${meetParticipantSeq}`],
    // initialData: generateInitRealData(participantDetailData),
  });

export interface ParticipantDetailEditBody {
  name: string;
  nameClass: string;
  birth?: string;
  companyNo?: string;
  ownerType?: string;
  gender?: string;
  phoneNo: string;
  agent: MeetParticipantAgentInfo;
  postAddress: PostAddress;
  realAddress: RealAddress;
}

export type MeetsParticipantsDetailEditMutation = UseMutationResult<
  ApiResponseData<ParticipantDetail>,
  ApiError,
  ParticipantDetailEditBody
>;

export const useMeetsParticipantsDetailEditMutation = (
  meetSeq: number,
  meetParticipantSeq: number,
): MeetsParticipantsDetailEditMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.put<
        ApiResponseData<ParticipantDetail>
      >(`/v1/meets/${meetSeq}/participants/${meetParticipantSeq}`, formData);

      return data;
    },
  });

export interface ParticipantDetailDeleteMutationBody {
  meetPassword: string;
  deleteReason: string;
}

export type MeetsParticipantsDetailDeleteMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  ParticipantDetailDeleteMutationBody
>;

export const useMeetsParticipantsDetailDeleteMutation = (
  meetSeq: number,
  meetParticipantSeq: number,
): MeetsParticipantsDetailDeleteMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.delete<ApiResponseData>(
        `/v1/meets/${meetSeq}/participants/${meetParticipantSeq}`,
        { data: formData },
      );

      return data;
    },
  });
