import { createRevealSlice, RevealSlice } from '@wooriga/common-reveal';
import {
  AlertDialog,
  AlertDialogProps,
  Modal,
  Snackbar,
  SnackbarProps,
} from '@wooriga/design-system';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { ChargeModalSlice, createChargeModalSlice } from 'stores/charge';
import { createFeedbackSlice, FeedbackSlice } from 'stores/feedback';
import { createPageCacheSlice, PageCacheSlice } from 'stores/page';

type RevealComponentProps = {
  snackbar: SnackbarProps;
  alert: AlertDialogProps;
  confirm: AlertDialogProps;
};

export type GlobalSlice = PageCacheSlice &
  FeedbackSlice &
  ChargeModalSlice &
  RevealSlice<RevealComponentProps>;

export const useBoundStore = create<GlobalSlice>()(
  persist(
    (...a) => ({
      ...createPageCacheSlice(...a),
      ...createFeedbackSlice(...a),
      ...createChargeModalSlice(...a),
      ...createRevealSlice<RevealComponentProps>({
        snackbar: ({
          onExit,
          variant = 'soft',
          anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
          ...other
        }) => (
          <Snackbar
            variant={variant}
            anchorOrigin={anchorOrigin}
            onUnmount={onExit}
            {...other}
          />
        ),
        alert: ({ message = '알림', open, onSubmit, onExit, ...other }) => (
          <Modal open={open} onClose={onExit}>
            <AlertDialog
              message={message}
              onSubmit={(result) => {
                onSubmit?.(result);
                onExit?.();
              }}
              {...other}
            />
          </Modal>
        ),
        confirm: ({ message = '확인', open, onSubmit, onExit, ...other }) => (
          <Modal open={open} onClose={onExit}>
            <AlertDialog
              message={message}
              type="OKCancel"
              onSubmit={(result) => {
                onSubmit?.(result);
                onExit?.();
              }}
              {...other}
            />
          </Modal>
        ),
      })(...a),
    }),
    {
      name: 'wooriga-page',
      partialize: (state) => ({ pages: state.pageCaches }),
    },
  ),
);
