import JoyLinearProgress from '@mui/joy/LinearProgress';
import type { LinearProgressProps as JoyLinearProgressProps } from '@mui/joy/LinearProgress';
import { ForwardedRef, forwardRef } from 'react';

export type LinearProgressProps = JoyLinearProgressProps;

const LinearProgress = (
  props: LinearProgressProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return <JoyLinearProgress {...props} ref={ref} />;
};

export default forwardRef<HTMLDivElement, LinearProgressProps>(LinearProgress);
