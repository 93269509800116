import type {
  BaseSingleInputFieldProps,
  DateTimeValidationError,
  FieldSection,
  UseDateTimeFieldProps,
} from '@mui/x-date-pickers-pro';
import {
  useClearableField,
  unstable_useDateTimeField as useDateTimeField,
} from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef } from 'react';

import JoyField, {
  JoyFieldProps,
} from 'design-system/components/pickers/JoyField';

export interface DateTimeFieldProps
  extends UseDateTimeFieldProps<Date, false>,
    BaseSingleInputFieldProps<
      Date | null,
      Date,
      FieldSection,
      false,
      DateTimeValidationError
    > {
  variant?: JoyFieldProps['variant'];
  color?: JoyFieldProps['color'];
  size?: JoyFieldProps['size'];
  error?: JoyFieldProps['error'];
  helperText?: JoyFieldProps['helperText'];
  fullWidth?: JoyFieldProps['fullWidth'];
  required?: JoyFieldProps['required'];
}

const DateTimeField = (
  props: DateTimeFieldProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { slots, slotProps, ...textFieldProps } = props;

  const fieldResponse = useDateTimeField<Date, false, typeof textFieldProps>({
    ...textFieldProps,
    enableAccessibleFieldDOMStructure: false,
  });

  /* If you don't need a clear button, you can skip the use of this hook */
  const processedFieldProps = useClearableField({
    ...fieldResponse,
    slots,
    slotProps,
  });

  return <JoyField ref={ref} {...processedFieldProps} />;
};

export default forwardRef<HTMLDivElement, DateTimeFieldProps>(DateTimeField);
