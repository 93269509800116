import AddIcon from '@mui/icons-material/Add';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Chip,
  ChipProps,
  CircularProgress,
  Grid,
  Input,
  LinearProgressProps,
  Sheet,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useReadElectronicConsents } from 'apis/electronic-consents/apis';
import { ElectronicConsent, ProgressStatus } from 'apis/types/consent';
import ElectronicConsentCard from 'components/pages/consent-management/history/ElectronicConsentCard';
import useDebounce from 'hooks/useDebounce';
import detail from 'pages/main/consent-management/history/detail/index';
import open from 'pages/main/consent-management/open';
import { CustomRouteObject } from 'types/route';

const BOARD_LIST = [
  {
    code: 'BEFORE',
    label: '진행전',
    color: 'warning',
    iconColor: '#9A5B13',
    Icon: HourglassEmptyIcon,
  },
  {
    code: 'IN_PROGRESS',
    label: '진행중',
    color: 'blue',
    iconColor: '#0B6BCB',
    Icon: RotateRightIcon,
  },
  {
    code: 'COMPLETED',
    label: '마감',
    color: 'neutral',
    iconColor: 'neutral',
    Icon: ModeStandbyIcon,
  },
];

const HistoryPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const unionSeq = Number(params.unionSeq);

  const [search, setSearch] = useState<string>('');

  const debouncedSearchValue = useDebounce(search);
  const {
    data: consentsResponse,
    isLoading,
    isError,
    error,
  } = useReadElectronicConsents({
    searchUnionSeq: unionSeq,
    searchTitle: debouncedSearchValue,
  });

  const boards = useMemo(() => {
    const boards = new Map<ProgressStatus, ElectronicConsent[]>();

    const consents = consentsResponse?.data ?? [];

    consents.forEach((consent) => {
      const { progressStatus } = consent;

      if (boards.has(progressStatus)) {
        boards.set(progressStatus, [
          ...(boards.get(progressStatus) ?? []),
          consent,
        ]);
        return;
      }

      boards.set(progressStatus, [consent]);
    });

    return boards;
  }, [consentsResponse]);

  const handleMoveToCreate = () => {
    navigate('open', { relative: 'route' });
  };

  if (isError) throw error;

  return (
    <Stack height="100%" gap={2.25}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <Input
            placeholder="동의서 제목 검색"
            sx={{ minWidth: 340 }}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            startDecorator={<SearchIcon />}
          />
        </Stack>

        <Button startDecorator={<AddIcon />} onClick={handleMoveToCreate}>
          전자 동의서 등록하기
        </Button>
      </Stack>

      {isLoading && (
        <Stack height="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {!isLoading && (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {BOARD_LIST.map(({ code, label, color, iconColor, Icon }) => (
            <Grid key={`board_${code}`} xs={4}>
              <Sheet
                color="neutral"
                sx={{ height: '100%', borderRadius: 'md' }}
              >
                <Stack height="100%" p={2} gap={2.5}>
                  <Stack direction="row" alignItems="center" gap={1.5}>
                    <Typography
                      fontSize="lg"
                      fontWeight="xl"
                      lineHeight="md"
                      startDecorator={
                        <Icon sx={{ fontSize: 20, color: iconColor }} />
                      }
                    >
                      {label}
                    </Typography>
                    <Chip
                      variant="outlined"
                      color={color as ChipProps['color']}
                      size="sm"
                    >
                      {boards.get(code as ProgressStatus)?.length ?? 0}
                    </Chip>
                  </Stack>

                  <Stack flex={1} gap={3}>
                    {!boards.get(code as ProgressStatus)?.length && (
                      <Typography
                        m="auto"
                        color="neutral"
                        fontSize="md"
                        fontWeight="md"
                        lineHeight="md"
                        alignSelf="center"
                      >
                        등록된 전자 동의서가 없습니다
                      </Typography>
                    )}

                    {boards
                      .get(code as ProgressStatus)
                      ?.map(
                        ({
                          consentSeq,
                          title,
                          startedAt,
                          endedAt,
                          recipientCount,
                        }) => (
                          <ElectronicConsentCard
                            key={`electronic_consent_${consentSeq}`}
                            seq={consentSeq}
                            title={title}
                            startedAt={startedAt}
                            endedAt={endedAt}
                            rate={Math.floor(
                              (recipientCount.submitted /
                                recipientCount.total) *
                                100,
                            )}
                            onClick={(seq) => navigate(`${seq}`)}
                            linearProgressProps={{
                              color: color as LinearProgressProps['color'],
                            }}
                          />
                        ),
                      )}
                  </Stack>
                </Stack>
              </Sheet>
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <HistoryPage />,
  handle: {
    getTitle: () => '전자 동의서 내역',
  },
};

const electronicConsentHistory = {
  path: '/unions/:unionSeq/consent-management',
  children: [route, detail, open],
  handle: {
    getTitle: () => '전자 동의서 내역',
    getMenuCode: () => 'M1201',
  },
};

export default electronicConsentHistory;
