import {
  Button,
  DataGrid,
  Link,
  Pagination,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFileDownloadMutation } from 'apis/common/apis';
import {
  useElectronicDocumentDetailQuery,
  useElectronicDocumentRecipientsListQuery,
} from 'apis/electronic-posts/apis';
import { ELECTRONIC_POSTS_SEND_LISTS_GRID_COLUMNS } from 'apis/electronic-posts/fixtures/send-lists';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const DetailPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);
  const electronicDocumentSeq = Number(params.postSeq);

  const [fileSeq, setFileSeq] = useState(-1);
  const [fileName, setFileName] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { snackbar } = useFeedback();

  const {
    data: electronicPostsRecipientsData,
    isLoading,
    isError,
    error,
  } = useElectronicDocumentRecipientsListQuery({
    unionSeq: unionSeq,
    electronicDocumentSeq: electronicDocumentSeq,
  });
  const { data: electronicPostsDetailData } = useElectronicDocumentDetailQuery({
    unionSeq: unionSeq,
    electronicDocumentSeq: electronicDocumentSeq,
  });

  const { mutate: fileDownload, isPending } = useFileDownloadMutation();

  const handleClickName = useCallback((unionRegisterSeq: number) => {
    setSelectUnionRegisterSeq(unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const { columns } = useCreateGridColumns({
    columns: ELECTRONIC_POSTS_SEND_LISTS_GRID_COLUMNS,
    handlers: {
      onClickName: handleClickName,
    },
  });

  const downloadHandler = useCallback(() => {
    fileDownload(
      { fileSeq },
      {
        onSuccess: (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;

          link.download = fileName;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(url);
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  }, [fileSeq]);

  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState<boolean>(false);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  if (isError) {
    throw error;
  }

  const totalElements =
    electronicPostsRecipientsData?.pagination?.totalElements || 0;

  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);
  useEffect(() => {
    if (!electronicPostsDetailData?.data) {
      return;
    }

    setFileSeq(electronicPostsDetailData?.data?.attachFile?.fileSeq);
    setFileName(electronicPostsDetailData?.data?.attachFile?.originName);
  }, [electronicPostsDetailData?.data]);

  return (
    <>
      <Stack gap={2}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Stack>
            <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
              {electronicPostsDetailData?.data?.title || '제목'}
            </Typography>
          </Stack>
          <Stack>
            <Button loading={isPending} onClick={downloadHandler}>
              전자등기 문서 다운로드
            </Button>
          </Stack>
        </Stack>

        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(
                  electronicPostsRecipientsData?.pagination?.totalDataCount ||
                    0,
                )}
                {/* {commaizeNumber(0)} */}
              </Typography>
            </Typography>

            {/* <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                
                {commaizeNumber(0)}
              </Typography>
            </Typography> */}
          </Stack>
        </Stack>
        <Stack gap={2}>
          <Stack height={442}>
            <DataGrid
              rows={electronicPostsRecipientsData?.data || []}
              columns={columns}
              getRowId={(row) => row.electronicDocumentRecipientSeq}
              loading={isLoading}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            ></DataGrid>
          </Stack>
          <Stack alignItems="center">
            <Pagination
              color="neutral"
              variant="plain"
              size="md"
              orientation="horizontal"
              showFirstButton={false}
              showLastButton={false}
              hidePrevButton={false}
              hideNextButton={false}
              count={pageTotalCount}
              onChange={(_, page) => {
                page &&
                  setPaginationModel({
                    ...paginationModel,
                    page: page - 1,
                  });
              }}
            />
          </Stack>
          <Stack flexDirection="row" justifyContent="space-between">
            <Button
              size="lg"
              variant="outlined"
              color="neutral"
              component={Link}
              href=".."
            >
              목록
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}
    </>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <DetailPage />,
  handle: {
    getTitle: () => '등기 발송 상태 조회',
  },
};

const detailPage = {
  path: '/unions/:unionSeq/electronic-posts/detail/:postSeq',
  children: [route],
  handle: {
    getTitle: () => '등기 조회',
  },
};

export default detailPage;
