import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import {
  ElectronicConsent,
  ElectronicConsentRecipient,
  ElectronicConsentStatisticArea,
  ElectronicConsentStatisticDong,
  ElectronicConsentTemplate,
  ProgressStatus,
} from 'apis/types/consent';
import { UnionRegisterInfo } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

// 일반 동의서 파트
export interface ConsentsRegisterSearchParams {
  unionSeq: number;
  unionRegisterNo: string;
  name: string;
  phoneNo: string;
  localAddress: string;
  shareType: string;
  searchFrom: string;
  searchTo: string;
}
export interface ConsentRegisterParams {
  unionSeq: number;
}
export interface ConsentRegisterBody {
  unionRegisterSeqs: number[];
  responseAt?: string;
  consentStatus: string;
}

export interface ConsentsParams {
  unionSeq: number;
  unionRegisterNo?: string;
  name?: string;
  phoneNo?: string;
  localAddress?: string;
  shareType?: string;
  searchFrom?: string;
  searchTo?: string;
}

export type ConsentsRegisterReturnData = UnionRegisterInfo[];
export const useConsentsQuery = (
  params: ConsentsParams,
): UseQueryResult<ApiResponseData<ConsentsRegisterReturnData>, ApiError> => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/consents/registers`, restParams],
  });
};

export type RegisterConsentRequest = ConsentRegisterParams &
  ConsentRegisterBody;

export const useRegisterConsentMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  RegisterConsentRequest
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/consents`,
        body,
      );

      return data;
    },
  });

// 전자 동의서 파트

export interface ElectronicConsentTemplatesParams {
  searchUnionSeq: number;
}

export type ElectronicConsentTemplatesReturnData = ElectronicConsentTemplate[];

export const useReadElectronicConsentTemplates = (
  params: ElectronicConsentTemplatesParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentTemplatesReturnData, true>,
  ApiError
> => {
  const { searchUnionSeq } = params;

  return useQuery({
    queryKey: [`/_mock/electronic-consent-templates`, { searchUnionSeq }],
  });
};

export interface ElectronicConsentsParams {
  unionSeq: number;
  search: string;
}

export type ElectronicConsentsReturnData = ElectronicConsent[];

export const useReadElectronicConsents = (
  params: ElectronicConsentsParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentsReturnData, true>,
  ApiError
> => {
  const { unionSeq, search } = params;

  return useQuery({
    queryKey: [`/_mock/electronic-consents`, { unionSeq, search }],
  });
};

export interface ElectronicConsentParams {
  consentSeq: number;
}

export type ElectronicConsentReturnData = ElectronicConsent;

export const useReadElectronicConsent = (
  params: ElectronicConsentParams,
): UseQueryResult<ApiResponseData<ElectronicConsentReturnData>, ApiError> => {
  const { consentSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/_mock/electronic-consents/${consentSeq}`, restParams],
  });
};

export interface ElectronicConsentRecipientsParams {
  consentSeq: number;
  name?: string;
  shareType?: string;
}

export type ElectronicConsentRecipientsReturnData =
  ElectronicConsentRecipient[];

export const useReadElectronicConsentRecipients = (
  params: ElectronicConsentRecipientsParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentsReturnData, true>,
  ApiError
> => {
  const { consentSeq, name, shareType } = params;

  return useQuery({
    queryKey: [
      `/_mock/electronic-consents/${consentSeq}/recipients`,
      { name, shareType },
    ],
  });
};

export interface ElectronicConsentStatisticsAreaParams {
  consentSeq: number;
  isEnabled?: boolean;
}

export type ElectronicConsentStatisticsAreaReturnData =
  ElectronicConsentStatisticArea;

export const useReadElectronicConsentStatisticsArea = (
  params: ElectronicConsentStatisticsAreaParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentStatisticsAreaReturnData>,
  ApiError
> => {
  const { consentSeq, isEnabled } = params;

  return useQuery({
    queryKey: [`/_mock/electronic-consents/${consentSeq}/statistics/area`],
    enabled: isEnabled,
  });
};

export interface ElectronicConsentStatisticsDongParams {
  consentSeq: number;
  isEnabled?: boolean;
}

export type ElectronicConsentStatisticsDongReturnData =
  ElectronicConsentStatisticDong[];

export const useReadElectronicConsentStatisticsDong = (
  params: ElectronicConsentStatisticsDongParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentStatisticsDongReturnData>,
  ApiError
> => {
  const { consentSeq, isEnabled } = params;

  return useQuery({
    queryKey: [`/_mock/electronic-consents/${consentSeq}/statistics/dong`],
    enabled: isEnabled,
  });
};

export interface ElectronicConsentCreateBody {
  templateSeq: number;
  unionSeq: number;
  unionRegisterSeqs: number[];
  title: string;
  content: string;
  isSignatureRequired: boolean;
  isActiveCondition: boolean;
  consentCondition: number;
  startedAt: string;
  endedAt: string;
  consentUrl: string;
}

export const useElectronicConsentCreateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentCreateBody
> =>
  useMutation({
    mutationFn: async (body) => {
      const { data } = await axiosInstance.post(
        `/_mock/electronic-consents`,
        body,
      );

      return data;
    },
  });

export interface ElectronicConsentUpdateParams {
  consentSeq: number;
}

export const useElectronicConsentUpdateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentUpdateParams &
    Omit<ElectronicConsentCreateBody, 'unionRegisterSeqs' | 'consentUrl'>
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq, ...body } = params;

      const { data } = await axiosInstance.put(
        `/_mock/electronic-consents/${consentSeq}`,
        body,
      );

      return data;
    },
  });

export interface ElectronicConsentProgressStatusParams {
  consentSeq: number;
}

export interface ElectronicConsentProgressStatusBody {
  progressStatus: ProgressStatus;
}

export const useElectronicConsentProgressStatusMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentProgressStatusParams & ElectronicConsentProgressStatusBody
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq, ...body } = params;

      const { data } = await axiosInstance.patch(
        `/_mock/electronic-consents/${consentSeq}/progress-status`,
        body,
      );

      return data;
    },
  });

export interface ElectronicConsentRecipientsUpdateParams {
  consentSeq: number;
}

export interface ElectronicConsentRecipientsUpdateBody {
  unionSeq: number;
  unionRegisterSeqs: number[];
}

export const useElectronicConsentRecipientsUpdateMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentRecipientsUpdateParams &
      ElectronicConsentRecipientsUpdateBody
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { consentSeq, ...body } = params;

        const { data } = await axiosInstance.post(
          `/_mock/electronic-consents/${consentSeq}/recipients`,
          body,
        );

        return data;
      },
    });

export interface ElectronicConsentRecipientWithdrawalParams {
  consentSeq: number;
  recipientSeq: number;
}

export const useElectronicConsentRecipientWithdrawalMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentRecipientWithdrawalParams
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { consentSeq, recipientSeq } = params;

        const { data } = await axiosInstance.post(
          `/_mock/electronic-consents/${consentSeq}/recipients/${recipientSeq}/withdraw`,
        );

        return data;
      },
    });

export interface ElectronicConsentDownloadParams {
  consentSeq: number;
}

export interface ElectronicConsentDownloadBody {
  unionRegisterSeqs: number[];
}

export const useElectronicConsentDownloadMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentDownloadParams & ElectronicConsentDownloadBody
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq } = params;

      const { data } = await axiosInstance.post(
        `/_mock/electronic-consents/${consentSeq}/download`,
      );

      return data;
    },
  });

export interface ElectronicConsentRecipientDeleteParams {
  consentSeq: number;
  recipientSeq: number;
}

export const useElectronicConsentRecipientDeleteMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentRecipientDeleteParams
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { consentSeq, recipientSeq } = params;

        const { data } = await axiosInstance.delete(
          `/_mock/electronic-consents/${consentSeq}/recipients/${recipientSeq}`,
        );

        return data;
      },
    });

export interface ElectronicConsentSendParams {
  consentSeq: number;
}

export const useElectronicConsentSendMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentSendParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq } = params;

      const { data } = await axiosInstance.post(
        `/_mock/electronic-consents/${consentSeq}/send`,
      );

      return data;
    },
  });
