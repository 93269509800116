import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, IconButton, Sheet, Stack } from '@wooriga/design-system';
import { PropsWithChildren, useCallback, useState } from 'react';
import {
  FieldValues,
  FormProvider,
  useForm,
  type DefaultValues,
  type UseFormProps,
} from 'react-hook-form';

import Field from 'components/Search/Field';
import SelectField from 'components/Search/SelectField';

export interface SearchProps<TFieldValues extends FieldValues>
  extends PropsWithChildren<UseFormProps<TFieldValues>> {
  collapsible?: boolean;
  defaultValues?: DefaultValues<TFieldValues>;
  values?: TFieldValues;
  onSubmit?: (data: TFieldValues) => void;
  onReset?: () => void;
}

const Search = <T extends FieldValues>(props: SearchProps<T>) => {
  const {
    collapsible,
    defaultValues,
    values,
    onSubmit,
    onReset,
    children,
    ...other
  } = props;

  const [open, setOpen] = useState<boolean>(true);

  const methods = useForm<T>({
    defaultValues,
    values,
    ...other,
  });

  const enhancedSubmit = methods.handleSubmit((data) => {
    onSubmit && onSubmit(data);
  });

  const handleReset = useCallback(() => {
    methods.reset(defaultValues);
    onReset && onReset();
  }, [methods, onReset, defaultValues]);

  const handleOpenToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <FormProvider {...methods}>
      <Sheet
        variant="outlined"
        component="form"
        onSubmit={enhancedSubmit}
        onReset={handleReset}
        sx={{ p: 2, borderRadius: 'md' }}
      >
        <Stack position="relative">
          {collapsible && (
            <Box ml="auto">
              <IconButton
                variant="outlined"
                onClick={handleOpenToggle}
                sx={{
                  position: open ? 'absolute' : 'relative',
                  top: 0,
                  right: 0,
                }}
              >
                {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
              </IconButton>
            </Box>
          )}

          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: open ? '1fr' : '0fr',
              transitionProperty: 'grid-template-rows, opacity',
              transitionDuration: '0.25s',
              opacity: open ? '100%' : '0%',
            }}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              columnGap={2}
              overflow="hidden"
            >
              <Box flex={1}>{children}</Box>

              <Stack
                flexDirection="row"
                alignItems="flex-end"
                alignContent="flex-end"
                flexWrap="wrap"
                gap={1}
                mt={5}
              >
                <Button
                  type="reset"
                  variant="soft"
                  size="md"
                  color="neutral"
                  startDecorator={<RefreshIcon />}
                >
                  초기화
                </Button>
                <Button type="submit" size="md" startDecorator={<SearchIcon />}>
                  검색
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Sheet>
    </FormProvider>
  );
};

Search.Field = Field;
Search.SelectField = SelectField;

export default Search;
