import { commaizeNumber } from '@wooriga/common-utils';
import { Grid } from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import {
  useReadElectronicConsent,
  useReadElectronicConsentStatisticsArea,
  useReadElectronicConsentStatisticsDong,
} from 'apis/electronic-consents/apis';
import ConsentInfoModal from 'components/pages/consent-management/history/detail/modals/ConsentInfoModal';
import ConsentPreviewModal from 'components/pages/consent-management/history/detail/modals/ConsentPreviewModal';
import ConsentAreaChartPanel from 'components/pages/consent-management/history/panels/ConsentAreaChartPanel';
import ConsentDongChartPanel from 'components/pages/consent-management/history/panels/ConsentDongChartPanel';
import ConsentInfoPanel from 'components/pages/consent-management/history/panels/ConsentInfoPanel';
import ConsentTargetChartPanel from 'components/pages/consent-management/history/panels/ConsentTargetChartPanel';
import useFeedback from 'hooks/useFeedback';

export interface DetailMenuTabProps {
  unionSeq: number;
  consentSeq: number;
}

const DetailMenuTab = ({ unionSeq, consentSeq }: DetailMenuTabProps) => {
  const { snackbar } = useFeedback();

  const { data: consentResponse, isLoading: isConsentLoading } =
    useReadElectronicConsent({ consentSeq });

  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);

  const [isAreaPanelOpen, setIsAreaPanelOpen] = useState<boolean>(false);
  const [isDongPanelOpen, setIsDongPanelOpen] = useState<boolean>(false);

  const { data: statisticsArea } = useReadElectronicConsentStatisticsArea({
    consentSeq,
    isEnabled: isAreaPanelOpen,
  });
  const { data: statisticsDong } = useReadElectronicConsentStatisticsDong({
    consentSeq,
    isEnabled: isDongPanelOpen,
  });

  const handleCopyElectronicConsentUrl = useCallback(async () => {
    if (!consentResponse?.data.consentUrl) {
      snackbar('복사할 전자 동의서 URL이 없습니다.', { color: 'danger' });
      return;
    }

    await navigator.clipboard.writeText(consentResponse?.data.consentUrl);

    snackbar('전자 동의서 URL이 복사되었습니다.');
  }, [consentResponse?.data, snackbar]);

  const { consent, submitted, total } = useMemo(
    () => ({
      consent: consentResponse?.data,
      submitted: consentResponse?.data.recipientCount.submitted ?? 0,
      total: consentResponse?.data.recipientCount.total ?? 0,
    }),
    [consentResponse?.data],
  );

  const area = useMemo(() => {
    const total = Math.floor(statisticsArea?.data.totalArea ?? 0);
    const consented = Math.floor(statisticsArea?.data.consentedArea ?? 0);
    const percentage = (consented / total) * 100;

    return {
      title: `${percentage}%`,
      label: `(${commaizeNumber(consented)}㎡ / ${commaizeNumber(total)}㎡)`,
      percentage,
    };
  }, [statisticsArea]);

  const dong = useMemo(
    () =>
      statisticsDong?.data.map(({ dong, totalCount, consentedCount }) => ({
        id: `${dong}`,
        title: `${dong}동`,
        value: (consentedCount / totalCount) * 100,
        label: `${consentedCount}/${totalCount}명`,
      })) ?? [],
    [statisticsDong],
  );

  return (
    <Grid container spacing={3}>
      <Grid xs={12} lg={6}>
        <ConsentInfoPanel
          data={{ consent }}
          loading={isConsentLoading}
          onClickInfo={() => setIsInfoModalOpen(true)}
          onClickPreview={() => setIsPreviewModalOpen(true)}
          onClickClipboard={handleCopyElectronicConsentUrl}
        />
      </Grid>

      <Grid xs={12} lg={6}>
        <ConsentTargetChartPanel
          data={{ total, submitted }}
          loading={isConsentLoading}
        />
      </Grid>

      <Grid xs={12} lg={6}>
        <ConsentAreaChartPanel
          data={area}
          open={isAreaPanelOpen}
          onChangeOpen={setIsAreaPanelOpen}
        />
      </Grid>

      <Grid xs={12} lg={6}>
        <ConsentDongChartPanel
          data={dong}
          open={isDongPanelOpen}
          onChangeOpen={setIsDongPanelOpen}
        />
      </Grid>

      {isInfoModalOpen && (
        <ConsentInfoModal
          unionSeq={unionSeq}
          consentSeq={consentSeq}
          open={isInfoModalOpen}
          onClose={setIsInfoModalOpen}
        />
      )}

      {isPreviewModalOpen && consent?.template.fileUrl && (
        <ConsentPreviewModal
          url={consent.template.fileUrl}
          open={isPreviewModalOpen}
          onClose={setIsPreviewModalOpen}
        />
      )}
    </Grid>
  );
};

export default DetailMenuTab;
