import { useRevealAdapter } from '@wooriga/common-reveal';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from 'stores';

export const useReveal = () => {
  const revealStore = useBoundStore(
    useShallow(({ defaultElements, mount, unmount, reveals, clear }) => ({
      reveals,
      clear,
      defaultElements,
      mount,
      unmount,
    })),
  );

  return useRevealAdapter(() => revealStore);
};
