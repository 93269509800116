import { Chip, ChipProps } from '@wooriga/design-system';

import { MeetVoteForm } from 'apis/types/meet';

export interface MeetVoteFormChipProps {
  code: MeetVoteForm;
}

const MeetVoteFormChip = ({ code }: MeetVoteFormChipProps) => {
  const getVariant = (): {
    variant?: ChipProps['variant'];
    color?: ChipProps['color'];
    children?: ChipProps['children'];
  } => {
    switch (code) {
      case 'AGAINST':
        return {
          variant: 'outlined',
          color: 'blue',
          children: '찬반투표',
        };
      case 'SELECT':
        return {
          variant: 'outlined',
          color: 'primary',
          children: '선택투표',
        };

      default:
        return {};
    }
  };

  return <Chip {...getVariant()} size="sm" />;
};

export default MeetVoteFormChip;
