import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  Box,
  Chip,
  PieCenterLabel,
  PieChart,
  Sheet,
  Typography,
  useTheme,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import { percentage } from 'components/PieChartWrapper/PercentLabel';
import { VoteAggregateResponse } from 'lim/generalMeetingHistoryDetail/apis';

interface Props {
  data?: VoteAggregateResponse;
}

const DuplicateChart = ({ data }: Props) => {
  const theme = useTheme();

  const { series, colors } = useMemo(() => {
    if (!data) return { series: [], colors: [] };
    const {
      notVotedCount,
      electronicVoteCount,
      writtenVoteCount,
      onsiteVoteCount,
      duplicateVoteCount,
    } = data;

    const colors = duplicateVoteCount
      ? [
          theme.palette.blue[400],
          theme.palette.success[400],
          theme.palette.primary[300],
          theme.palette.warning[400],
          theme.palette.neutral[200],
        ]
      : [
          theme.palette.blue[400],
          theme.palette.success[400],
          theme.palette.primary[300],
          theme.palette.neutral[200],
        ];

    const series = [
      {
        innerRadius: 96,
        outerRadius: 120,
        data: [
          {
            id: 'electronicVoteCount',
            label: `전자투표 ${commaizeNumber(electronicVoteCount)}`,
            value: electronicVoteCount,
          },
          {
            id: 'writtenVoteCount',
            label: `서면제출 ${commaizeNumber(writtenVoteCount)}`,
            value: writtenVoteCount,
          },
          {
            id: 'onsiteVoteCount',
            label: `현장투표 ${commaizeNumber(onsiteVoteCount)}`,
            value: onsiteVoteCount,
          },
          ...(duplicateVoteCount
            ? [
                {
                  id: 'duplicateVoteCount',
                  label: `중복투표 ${commaizeNumber(duplicateVoteCount)}`,
                  value: duplicateVoteCount,
                },
              ]
            : []),
          {
            id: 'notVotedCount',
            label: `미투표 ${commaizeNumber(notVotedCount)}`,
            value: notVotedCount,
          },
        ],
      },
    ];

    return { series, colors };
  }, [
    data,
    theme.palette.blue,
    theme.palette.neutral,
    theme.palette.primary,
    theme.palette.success,
    theme.palette.warning,
  ]);

  return (
    <Sheet
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        borderRadius: 'md',
        p: 5,
      }}
    >
      {!data ? (
        <Box
          flex={1}
          alignContent="center"
          justifyContent="center"
          height={240}
        >
          <Typography
            fontSize="md"
            fontWeight="md"
            lineHeight="md"
            textColor="neutral.500"
            textAlign="center"
          >
            표시할 데이터가 없습니다.
          </Typography>
        </Box>
      ) : (
        <>
          <Box>
            <Chip
              variant="soft"
              color="neutral"
              startDecorator={<HowToVoteIcon />}
              sx={{ mb: 2 }}
            >
              최종 투표결과
            </Chip>

            <Typography fontSize="xl3" fontWeight="lg" lineHeight="xs">
              {commaizeNumber(data.totalVotedCount)}/
              {commaizeNumber(data.totalParticipantCount)}
            </Typography>

            <Typography
              fontSize="md"
              fontWeight="md"
              lineHeight="md"
              textColor="neutral.500"
            >
              (총 투표 수 / 총 선거인 수)
            </Typography>
          </Box>

          <Box>
            <PieChart
              series={series}
              title="총회 참석률"
              margin={{ left: 0 }}
              width={400}
              height={250}
              colors={colors}
              slotProps={{
                legend: {
                  //   padding: 20,
                },
              }}
            >
              <PieCenterLabel fontSize="xl3" fontWeight="xl" lineHeight="xs">
                {percentage(data?.totalVotedCount, data?.totalParticipantCount)}
                %
              </PieCenterLabel>
            </PieChart>
          </Box>
        </>
      )}
    </Sheet>
  );
};

export default DuplicateChart;
