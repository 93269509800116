import { gridClasses, useGridApiRef } from '@mui/x-data-grid-premium';
import {
  Box,
  Chip,
  DataGrid,
  Select,
  Option,
  Sheet,
  Stack,
  Typography,
  useTheme,
} from '@wooriga/design-system';
import * as _ from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { MeetMethods, MeetStatus } from 'apis/types/meet';
import MeetVoteFormChip from 'components/Chips/MeetVoteFormChip';
import { MeetMethodResultKeys } from 'components/pages/meet-management/history/detail/utils';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import { VoteResult } from 'lim/generalMeetingHistoryDetail/apis';
import { MeetsAgendasTableColumnsProps } from 'lim/generalMeetingHistoryDetail/fixtures';
import {
  MEETS_VOTE_AGAINST_RESULT_TABLE_COLUMNS,
  MeetsVoteResultTableRow,
} from 'lim/generalMeetingHistoryDetail/fixtures/meetsVoteAgainstResult';
import { MEETS_VOTE_SELECT_RESULT_TABLE_COLUMNS } from 'lim/generalMeetingHistoryDetail/fixtures/meetsVoteSelectResult';

export type DataGridValueKeys = Pick<
  MeetsAgendasTableColumnsProps,
  'yesCount' | 'noCount' | 'abstentionCount'
>;

type MeetResultKeys = MeetMethodResultKeys | 'total';

interface AgendaResultGridProps {
  name: string;
  control: Control;
  meetMethod: MeetMethods;
  meetStatus: MeetStatus;
  data: VoteResult;
}

const AgendaResultGrid = ({
  name,
  control,
  meetMethod,
  meetStatus,
  data,
}: AgendaResultGridProps) => {
  const theme = useTheme();
  const apiRef = useGridApiRef();

  const [order, setOrder] = useState<'DEFAULT' | 'VOTE_RATE' | null>('DEFAULT');

  const defaultValue = useMemo(
    () => ({
      agendaSeq: data.agendaSeq,
      order: data.order,
      voteType: data.voteType,
      voteForm: data.voteForm,
      selectCount: data.selectCount,
      candidates: data.candidates.map(({ no, isPassed }) => ({
        candidateNo: no,
        isPassed: isPassed === undefined ? null : isPassed,
      })),
    }),
    [
      data.agendaSeq,
      data.candidates,
      data.order,
      data.selectCount,
      data.voteForm,
      data.voteType,
    ],
  );

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const rows = useMemo(() => {
    const meetMethods: MeetResultKeys[] =
      meetMethod === 'ELECTRONIC'
        ? ['total', 'electronicResult']
        : meetMethod === 'ONSITE'
          ? ['total', 'onsiteResult', 'writtenSubmissionResult']
          : [
              'total',
              'electronicResult',
              'onsiteResult',
              'writtenSubmissionResult',
            ];

    const rows = data.candidates.flatMap((candidate) =>
      meetMethods.map((meetMethod) => {
        const target =
          meetMethod === 'total'
            ? [
                candidate.electronicResult,
                candidate.onsiteResult,
                candidate.writtenSubmissionResult,
              ]
            : meetMethod === 'electronicResult'
              ? [candidate.electronicResult]
              : meetMethod === 'onsiteResult'
                ? [candidate.onsiteResult]
                : [candidate.writtenSubmissionResult];

        const counts = target.reduce(
          (acc, obj) =>
            _.mergeWith(acc, obj, (a, b) => {
              if (_.isNumber(a) && _.isNumber(b)) {
                return a + b;
              }

              return a || b;
            }),
          {
            yesCount: 0,
            noCount: 0,
            abstentionCount: 0,
            totalCount: 0,
          },
        );

        const voteRate = counts.yesCount / counts.totalCount || 0;
        const isPassed = value.candidates.find(
          ({ candidateNo }: { candidateNo: number }) =>
            candidate.no === candidateNo,
        )?.isPassed;
        return {
          id: `${data.agendaSeq}-${candidate.no}-${meetMethod}`,
          agendaSeq: data.agendaSeq,
          no: candidate.no,
          name: candidate.name,
          method: meetMethod,
          voteRate,
          isPassed,
          ...counts,
        };
      }),
    );

    return rows;
  }, [data.agendaSeq, data.candidates, meetMethod, value.candidates]);

  const handleChange = useCallback(
    (
      row: MeetsVoteResultTableRow,
      newValue: MeetsVoteResultTableRow['isPassed'],
    ) => {
      const newRows = {
        ...value,
        candidates: value.candidates.map(
          (candidate: {
            candidateNo: string;
            isPassed: MeetsVoteResultTableRow['isPassed'];
          }) =>
            candidate.candidateNo === row.no
              ? { candidateNo: candidate.candidateNo, isPassed: newValue }
              : candidate,
        ),
      };

      onChange(newRows);
    },
    [onChange, value],
  );

  const { columns: againstColumns } = useCreateGridColumns(
    MEETS_VOTE_AGAINST_RESULT_TABLE_COLUMNS,
    {
      onSelect: handleChange,
      meetStatus,
    },
  );

  const { columns: selectColumns } = useCreateGridColumns(
    MEETS_VOTE_SELECT_RESULT_TABLE_COLUMNS,
    {
      onSelect: handleChange,
      meetStatus,
    },
  );

  const sortRows = useMemo(() => {
    if (order === 'DEFAULT') return rows;

    const grouped = _.groupBy(rows, 'no');

    const sortedGroups = _.orderBy(
      Object.values(grouped),
      (group) => {
        const totalItem = group.find((item) => item.method === 'total');
        return totalItem ? totalItem.voteRate : 0;
      },
      ['desc'],
    );

    return _.flatMap(sortedGroups);
  }, [order, rows]);

  const passedCandidateNos: number[] = useMemo(
    () =>
      value.candidates
        .filter(({ isPassed }: { isPassed: boolean }) => isPassed)
        .map(({ candidateNo }: { candidateNo: number }) => candidateNo),
    [value.candidates],
  );

  return (
    <Sheet variant="outlined" sx={{ borderRadius: 'md', p: 2.25 }}>
      <Stack flexDirection="row" gap={1.25} mb={2.25}>
        <Stack flex={1} flexDirection="row" gap={1.25}>
          <Typography
            fontSize="md"
            fontWeight="lg"
            lineHeight="md"
            textColor="neutral.500"
            whiteSpace="nowrap"
            flex={0}
          >{`제 ${data.order}호`}</Typography>

          <Typography
            fontSize="md"
            fontWeight="lg"
            lineHeight="md"
            whiteSpace="wrap"
            sx={{ wordBreak: 'break-all' }}
          >
            {data.name}
          </Typography>

          <Box flex={0}>
            <Stack flexDirection="row" gap={1.25}>
              <MeetVoteFormChip code={data.voteForm} />
              {data.voteType === 'MULTI' && (
                <Chip
                  variant="soft"
                  color="neutral"
                  size="sm"
                >{`${data.selectCount}건 선택`}</Chip>
              )}
            </Stack>
          </Box>
        </Stack>

        <Box flex={0}>
          <Stack flexDirection="row" alignItems="center">
            <Typography whiteSpace="nowrap" mr={1.25}>
              정렬:
            </Typography>
            <Select<'DEFAULT' | 'VOTE_RATE', false>
              size="sm"
              value={order}
              onChange={(_, value) => setOrder(value)}
            >
              <Option value="DEFAULT">기호</Option>
              <Option value="VOTE_RATE">득표율</Option>
            </Select>
          </Stack>
        </Box>
      </Stack>

      <Box>
        <DataGrid
          apiRef={apiRef}
          disableRowSelectionOnClick
          showCellVerticalBorder
          columns={data.voteForm === 'AGAINST' ? againstColumns : selectColumns}
          rows={sortRows}
          getRowClassName={({ row }) =>
            passedCandidateNos.includes(row.no) ? 'passed' : ''
          }
          unstable_rowSpanning
          disableColumnMenu
          disableColumnReorder
          disableColumnSorting
          sx={{
            [`.${gridClasses.row}.passed`]: {
              backgroundColor: theme.palette.blue[50],
            },
          }}
        />
      </Box>
    </Sheet>
  );
};

export default AgendaResultGrid;
