/* eslint-disable @typescript-eslint/ban-ts-comment */

import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  Link,
  FormControl,
  FormLabel,
  Select,
  Option,
  useGridUtils,
  Grid,
  DateRangePicker,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { MeetStatus } from 'apis/types/meet';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import { useMeetsPromotionUserDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import {
  MeetsPromotionUsersParams,
  PromotionUserBody,
  PromotionUserUpdateBody,
  useMeetsPromotionUserUpdateMutation,
  useMeetsPromotionUsersAccountSendMutation,
  useMeetsPromotionUsersMutation,
  useMeetsPromotionUsersQuery,
} from 'lim/meetPromotion/apis';
import { PROMOTION_DETAIL_HEAD_TABLE_COLUMNS } from 'lim/meetPromotion/fixtures';
import { commaizeNumber } from 'utils/format';

import PromotionHeadEditModal from '../modals/PromotionHeadEditModal';
import PromotionRegisterModal from '../modals/PromotionRegisterModal';

interface PromotionHeadTabProps {
  meetStatus: MeetStatus;
  meetSeq: number;
}

const defaultParams: MeetsPromotionUsersParams = {
  name: '',
  id: '',
  phoneNo: '',
  userStatus: '',
  searchFrom: '',
  searchTo: '',
};

const PromotionHeadTab = ({ meetStatus, meetSeq }: PromotionHeadTabProps) => {
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: `promotion-head-list`,
    activeSaveSnapshot: true,
  });
  const { getGroupCode } = useCommonCodes();
  const { snackbar, confirmDialog } = useFeedback();

  const userStatusGroup = useMemo(
    () => [
      { code: '', name: '전체', sortOrder: 0 },
      ...(getGroupCode('PROMOTION_USER_STATUS')?.items || []),
    ],
    [getGroupCode],
  );

  const [params, setParams] = useState(defaultParams);
  const [userStatus, setUserStatus] = useState('');

  const [selectedID, setSelectedID] = useState(0);
  const [selectedCellIDs, setSelectedCellIDs] = useState<GridRowSelectionModel>(
    [],
  );

  const [showEditModal, setShowEditModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const { data, isLoading, isError, error, refetch } =
    useMeetsPromotionUsersQuery(meetSeq, 'HEAD', params);

  const promotionUserDetailQuery = useMeetsPromotionUserDetailQuery(
    meetSeq,
    selectedID,
  );
  const { mutate } = useMeetsPromotionUserUpdateMutation(meetSeq, selectedID);
  const { mutate: sendMutate } =
    useMeetsPromotionUsersAccountSendMutation(meetSeq);
  const { mutate: registerMutate } = useMeetsPromotionUsersMutation(meetSeq);

  const handleOnSelect = (value: string) => {
    setUserStatus(value);
  };

  const handleOnSearch = (params: MeetsPromotionUsersParams) => {
    let newValue = {} as MeetsPromotionUsersParams;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    setParams({
      ...newValue,
      userStatus,
    });
  };

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const {
          promotionUserSeq,
          id,
          name,
          phoneNo,
          memo,
          userStatus,
          createdAt,
          position,
          assignedParticipantCount,
        } = row;
        return {
          id: promotionUserSeq,
          accountID: id,
          userStatus,
          name,

          phoneNo,
          memo,
          position,
          createdAt,
          assignedParticipantCount,
        };
      }) || []
    );
  }, [data?.data]);

  const handleOnNameClick = (id: string | number) => {
    setShowEditModal(true);
    setSelectedID(Number(id));
  };

  const handleOnSubmit = (formData: PromotionUserUpdateBody) => {
    mutate(formData, {
      onSuccess: () => {
        refetch();
        snackbar('정보 수정이 완료되었습니다.', { color: 'success' });

        promotionUserDetailQuery.refetch();
        setShowEditModal(false);
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, { color: 'danger' });
      },
    });
  };

  const handleOnSendButtonClick = () => {
    if (!selectedCellIDs.length) {
      snackbar('선택된 대상이 없습니다.', { color: 'danger' });
      return;
    }

    confirmDialog('홍보 팀장 계정 발송', {
      onConfirm: () => {
        sendMutate(
          { promotionUserSeqs: selectedCellIDs as number[] },
          {
            onSuccess: () => {
              snackbar('계정이 발송되었습니다.', { color: 'success' });
              setSelectedCellIDs([]);
            },
            onError: (e) => {
              snackbar(e.response?.data.message ?? e.message, {
                color: 'danger',
              });
            },
          },
        );
      },
    });
  };

  const handleRegister = (formData: PromotionUserBody) => {
    registerMutate(formData, {
      onSuccess: () => {
        refetch();

        snackbar('등록이 완료되었습니다.', { color: 'success' });
        setShowRegisterModal(false);
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, { color: 'danger' });
      },
    });
  };

  const { columns } = useCreateGridColumns({
    columns: PROMOTION_DETAIL_HEAD_TABLE_COLUMNS,
    handlers: {
      onNameClick: handleOnNameClick,
    },
  });

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <Search
          onSubmit={handleOnSearch}
          defaultValues={defaultParams}
          onReset={() => {
            setParams(defaultParams);
            setUserStatus('');
          }}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="이름을 입력하세요."
                  label="이름"
                  name="name"
                  fullWidth
                  slotProps={{
                    input: { maxLength: 30 },
                  }}
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="아이디을 입력하세요."
                  label="아이디"
                  name="id"
                  fullWidth
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="연락처를 입력하세요."
                  label="연락처"
                  name="phoneNo"
                  fullWidth
                  validateOptions={{ maxLength: 11, regex: /^[0-9\s]*$/ }}
                />
              </Search.Field>
            </Grid>

            <Grid container xs={12} gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>계정상태</FormLabel>
                    <Select
                      sx={{ width: '100%' }}
                      placeholder="계정상태를 선택하세요."
                      name="userStatus"
                      value={userStatus}
                      onChange={(_, value) =>
                        handleOnSelect(value as unknown as string)
                      }
                    >
                      {/* <Option value="">전체</Option> */}
                      {userStatusGroup.map((item) => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Search.Field>
              </Grid>

              <Grid xs={12} maxWidth={416}>
                <Search.Field>
                  <DateRangePicker
                    label="기간조회"
                    name="search"
                    sx={{ field: { maxWidth: 200 } }}
                  />
                </Search.Field>
              </Grid>
            </Grid>
          </Grid>
        </Search>
      </Stack>

      <Stack flexDirection="row" gap={1}>
        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          전체{' '}
          <Typography color="primary">
            {commaizeNumber(data?.pagination?.totalDataCount || 0)}
          </Typography>
        </Typography>

        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          조회 목록{' '}
          <Typography color="primary">
            {commaizeNumber(data?.pagination?.totalElements || 0)}
          </Typography>
        </Typography>
      </Stack>

      <Stack height={442}>
        <DataGrid
          apiRef={datagridApiRef}
          rows={rows}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
          checkboxSelection
          onRowSelectionModelChange={setSelectedCellIDs}
          isRowSelectable={(params) =>
            params.row?.userStatus === 'USE' ? true : false
          }
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <Button component={Link} href=".." variant="outlined" color="neutral">
            목록
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              exportExcel();
            }}
          >
            내역 다운
          </Button>
        </Stack>

        <Stack direction="row" gap={1}>
          {meetStatus === 'PROCESS' && (
            <>
              <Button
                variant="outlined"
                color="neutral"
                onClick={handleOnSendButtonClick}
              >
                계정 발송
              </Button>
              <Button
                color="primary"
                onClick={() => setShowRegisterModal(true)}
              >
                홍보 팀장 등록
              </Button>
            </>
          )}
        </Stack>
      </Stack>

      {showEditModal && (
        <PromotionHeadEditModal
          promotionUserDetailQuery={promotionUserDetailQuery}
          open={showEditModal}
          onClose={setShowEditModal}
          onSubmit={handleOnSubmit}
        />
      )}

      {showRegisterModal && (
        <PromotionRegisterModal
          open={showRegisterModal}
          onClose={setShowRegisterModal}
          onSubmit={handleRegister}
        />
      )}
    </Stack>
  );
};

export default PromotionHeadTab;
