import { Fragment } from 'react';
import { createPortal } from 'react-dom';

import { RevealPortalProps } from 'common-reveal/types';

const RevealPortal = <T,>({ store }: RevealPortalProps<T>) => {
  const { reveals } = store();

  return createPortal(
    [...reveals].map(([id, element]) => (
      <Fragment key={`reveal_${id}`}>{element}</Fragment>
    )),
    document.querySelector('#root')!,
  );
};

export default RevealPortal;
