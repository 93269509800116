import HelpIcon from '@mui/icons-material/Help';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Stack, Tooltip, Typography } from '@wooriga/design-system';

import { MeetAttendSituationAttendance } from 'apis/types/meet';

export interface MeetAttendSituationTableRow
  extends MeetAttendSituationAttendance {
  id: string;
  label: string;
  helpTooltip?: { title: string; description: string };
}

export const ONSITE_MEET_ATTEND_SITUATION_TABLE_COLUMNS =
  (): GridColDef<MeetAttendSituationTableRow>[] => [
    {
      field: 'type',
      headerName: '현장 출석 유형',
      width: 252,
      minWidth: 252,
      valueGetter: (_, row) => row.label,
      renderCell: ({ value, row }) =>
        row.helpTooltip ? (
          <Tooltip
            variant="outlined"
            arrow
            placement="right"
            title={
              <Stack gap={1} p={2}>
                <Typography fontSize="sm" fontWeight="lg" lineHeight="sm">
                  {row.helpTooltip.title}
                </Typography>
                <Typography fontSize="sm" fontWeight="md" lineHeight="sm">
                  {row.helpTooltip.description}
                </Typography>
              </Stack>
            }
          >
            <Typography
              fontSize="inherit"
              fontWeight="inherit"
              lineHeight="inherit"
              endDecorator={
                <HelpIcon
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                    fontSize: '1.25rem',
                  })}
                />
              }
            >
              {value}
            </Typography>
          </Tooltip>
        ) : (
          value
        ),
    },
    {
      field: 'count',
      headerName: '선거인 수',
      type: 'number',
      flex: 1,
      valueFormatter: (value, row) =>
        row.percentage !== undefined
          ? `${value} 명 (${row.percentage.toFixed(2)}%)`
          : `${value} 명`,
    },
  ];

export const ONLINE_MEET_ATTEND_SITUATION_TABLE_COLUMNS =
  (): GridColDef<MeetAttendSituationTableRow>[] => [
    {
      field: 'type',
      headerName: '온라인 참석 유형',
      width: 252,
      minWidth: 252,
      valueGetter: (_, row) => row.label,
      renderCell: ({ value, row }) =>
        row.helpTooltip ? (
          <Tooltip
            variant="outlined"
            arrow
            placement="right"
            title={
              <Stack gap={1} p={2}>
                <Typography fontSize="sm" fontWeight="lg" lineHeight="sm">
                  {row.helpTooltip.title}
                </Typography>
                <Typography fontSize="sm" fontWeight="md" lineHeight="sm">
                  {row.helpTooltip.description}
                </Typography>
              </Stack>
            }
          >
            <Typography
              fontSize="inherit"
              fontWeight="inherit"
              lineHeight="inherit"
              endDecorator={
                <HelpIcon
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                    fontSize: '1.25rem',
                  })}
                />
              }
            >
              {value}
            </Typography>
          </Tooltip>
        ) : (
          value
        ),
    },
    {
      field: 'count',
      headerName: '선거인 수',
      type: 'number',
      flex: 1,
      valueFormatter: (value, row) =>
        row.percentage !== undefined
          ? `${value} 명 (${row.percentage.toFixed(2)}%)`
          : `${value} 명`,
    },
  ];

export const TOTAL_MEET_ATTEND_SITUATION_TABLE_COLUMNS =
  (): GridColDef<MeetAttendSituationTableRow>[] => [
    {
      field: 'type',
      headerName: '총회 출석 유형',
      width: 252,
      minWidth: 252,
      valueGetter: (_, row) => row.label,
      renderCell: ({ value, row }) =>
        row.helpTooltip ? (
          <Tooltip
            variant="outlined"
            arrow
            placement="right"
            title={
              <Stack gap={1} p={2}>
                <Typography fontSize="sm" fontWeight="lg" lineHeight="sm">
                  {row.helpTooltip.title}
                </Typography>
                <Typography fontSize="sm" fontWeight="md" lineHeight="sm">
                  {row.helpTooltip.description}
                </Typography>
              </Stack>
            }
          >
            <Typography
              fontSize="inherit"
              fontWeight="inherit"
              lineHeight="inherit"
              endDecorator={
                <HelpIcon
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                    fontSize: '1.25rem',
                  })}
                />
              }
            >
              {value}
            </Typography>
          </Tooltip>
        ) : (
          value
        ),
    },
    {
      field: 'count',
      headerName: '선거인 수',
      type: 'number',
      flex: 1,
      valueFormatter: (value, row) =>
        row.percentage !== undefined
          ? `${value} 명 (${row.percentage.toFixed(2)}%)`
          : `${value} 명`,
    },
  ];
