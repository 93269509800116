import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { UnionServiceInfo } from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

export interface UnionServiceInfoParams {
  unionSeq: number;
}

export type UnionServiceInfoReturnData = UnionServiceInfo;

export const unionServiceInfoQuery = ({
  unionSeq,
}: UnionServiceInfoParams) => ({
  queryKey: [`/v1/unions/${unionSeq}/service-info`],
});

export const useUnionServiceInfoQuery = (
  params: UnionServiceInfoParams,
): UseQueryResult<ApiResponseData<UnionServiceInfoReturnData>, ApiError> =>
  useQuery(unionServiceInfoQuery(params));
