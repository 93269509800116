import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Pagination,
  FormControl,
  FormLabel,
  Select,
  Option,
  Grid,
  Box,
  useGridUtils,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  UnionMembersParams,
  useApprovalMutation,
  useUnionMembersQuery,
} from 'apis/union/member/apis';
import { MEMBER_TABLE_COLUMNS } from 'apis/union/member/fixtures';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import useQueryControls from 'hooks/useQueryControls';
import memberDetailPage from 'pages/main/community/members/detail';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const defaultSearchParams = {
  unionRegisterName: '',
  unionRegisterNo: '',
  phoneNo: '',
  nickname: '',
  level: 'ALL',
};

const MembersPage = () => {
  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const { snackbar, confirmDialog } = useFeedback();
  const { getGroupCode } = useCommonCodes();
  const codeGroup = useMemo(() => getGroupCode('USER_LEVEL'), [getGroupCode]);
  const [unionRegisterSeq, setUnionRegisterSeq] = useState(NaN);

  const { datagridApiRef } = useGridUtils({
    key: 'members',
  });

  const {
    searchState,
    setSearchState,
    paginationState,
    setPaginationState,
    initState,
  } = useQueryControls({
    defaultSearchParams,
    initSearchParams: defaultSearchParams,
  });

  const searchParams = useMemo(() => {
    const { level, ...other } = searchState;
    const params = { ...other, level: level === 'ALL' ? '' : level };
    return params;
  }, [searchState]);

  const {
    data: unionMembersReturnData,
    refetch,
    isLoading,
    isError,
    error,
  } = useUnionMembersQuery(unionSeq, searchParams);
  const { mutate } = useApprovalMutation(unionSeq, unionRegisterSeq);

  const handleSearchSubmit = useCallback(
    (data: UnionMembersParams) => setSearchState(data),
    [setSearchState],
  );

  const handleSearchReset = useCallback(() => {
    initState();
  }, [initState]);

  const handleApprove = useCallback(
    (unionRegisterSeq: number, isApproval: boolean) => {
      setUnionRegisterSeq(unionRegisterSeq);

      confirmDialog(isApproval ? '승인 하시겠습니까?' : '거부 하시겠습니까?', {
        onConfirm: () => {
          mutate(
            { isApproval },
            {
              onSuccess: () => {
                snackbar(
                  isApproval
                    ? '승인이 완료되었습니다.'
                    : '승인이 거부되었습니다.',
                  { color: 'success' },
                );
                refetch();
              },
              onError: (e) => {
                snackbar(e.response?.data.message ?? e.message, {
                  color: 'danger',
                });
              },
            },
          );
        },
      });
    },
    [confirmDialog, mutate, refetch, snackbar],
  );

  const columnsHandles = useMemo(
    () => ({ onApprove: handleApprove }),
    [handleApprove],
  );

  const { columns } = useCreateGridColumns({
    columns: MEMBER_TABLE_COLUMNS,
    handlers: columnsHandles,
  });

  const rows = useMemo(
    () => unionMembersReturnData?.data || [],
    [unionMembersReturnData?.data],
  );

  const totalElements = unionMembersReturnData?.pagination?.totalElements || 0;
  const totalDataCount =
    unionMembersReturnData?.pagination?.totalDataCount || 0;
  const count = Math.ceil(totalElements / 10);

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <Search
          values={searchState}
          onSubmit={handleSearchSubmit}
          onReset={handleSearchReset}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  fullWidth
                  placeholder="조합원 이름 입력"
                  label="조합원 이름"
                  name="unionRegisterName"
                  slotProps={{
                    input: { maxLength: 30 },
                  }}
                />
              </Search.Field>
            </Grid>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="닉네임을 입력"
                  label="닉네임"
                  name="nickname"
                  fullWidth
                />
              </Search.Field>
            </Grid>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  label="연락처"
                  name="phoneNo"
                  placeholder="숫자만 입력"
                  validateOptions={{
                    maxLength: 11,
                    regex: /^[0-9]*$/,
                  }}
                  fullWidth
                />
              </Search.Field>
            </Grid>
            <Grid xs={12} maxWidth={200}>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>가입자 등급</FormLabel>
                <Search.SelectField>
                  <Select
                    sx={{ width: '100%' }}
                    placeholder="가입자 등급을 선택"
                    name="level"
                  >
                    <Option value="ALL">전체</Option>
                    {codeGroup?.items.map((item) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Search.SelectField>
              </FormControl>
            </Grid>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  label="연번"
                  name="unionRegisterNo"
                  placeholder='숫자 또는 "-"입력'
                  validateOptions={{
                    maxLength: 11,
                    regex: /^(?!.*--)[0-9-]*$/,
                  }}
                  fullWidth
                />
              </Search.Field>
            </Grid>
          </Grid>
        </Search>
      </Stack>
      <Stack flexDirection="row" gap={1}>
        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          전체{' '}
          <Typography color="primary">
            {commaizeNumber(totalDataCount)}
          </Typography>
        </Typography>

        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          조회 목록{' '}
          <Typography color="primary">
            {commaizeNumber(totalElements)}
          </Typography>
        </Typography>
      </Stack>
      <Stack gap={2}>
        <Box>
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.unionMemberSeq}
            loading={isLoading}
            disableRowSelectionOnClick
            pagination
            paginationModel={paginationState}
            onPaginationModelChange={setPaginationState}
          />
        </Box>

        <Stack alignItems="center">
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton
            showLastButton
            hidePrevButton={false}
            hideNextButton={false}
            count={count}
            page={paginationState.page + 1}
            onChange={(_, page) => {
              page &&
                setPaginationState({
                  page: page - 1,
                  pageSize: paginationState.pageSize,
                });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const membersPage: CustomRouteObject = {
  path: '/unions/:unionSeq/community/members',
  children: [
    { path: '', index: true, element: <MembersPage /> },
    memberDetailPage,
  ],
  handle: {
    getTitle: () => '가입자 관리',
    getHelpTootip: () => ({
      title: '가입자 관리 도움말',
      contents: (
        <>
          <b>우리가 웹</b>에서 <b>조합 홈페이지</b>에 <b>가입</b>한{' '}
          <b>가입자 목록</b>을 볼 수 있습니다.
          <br />
          <b>조합원 명부</b>와 <b>매칭</b>되는 회원인 경우 <b>연번</b>와
          <b>소재지</b>가 표시됩니다.
          <br />
          <b>가입자</b>의 <b>정보</b>를 <b>확인</b>하시려면 <b>닉네임</b>을
          <b>눌러 상세 페이지</b>로 <b>진입</b>해 주세요.
        </>
      ),
    }),
    getMenuCode: () => 'M0101',
  },
};

export default membersPage;
