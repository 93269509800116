import { GridColDef } from '@wooriga/design-system';
import { useMemo } from 'react';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import createGridColumns, {
  CreateGridColumnsProps,
} from 'hooks/useCreateGridColumns_legacy/createGridColumns_legacy';
import { GridColumnsHandlers } from 'types/grid';

export interface UseCreateGridColumnsProps<H>
  extends Omit<CreateGridColumnsProps<H>, 'handlers'> {
  handlers?: H;
}

const useCreateGridColumns = <H>(props: UseCreateGridColumnsProps<H>) => {
  const { handlers: handlersProp, columns } = props;

  const { getCode } = useCommonCodes();

  const handlers = useMemo(
    () =>
      ({
        ...handlersProp,
        getCommonCode: getCode,
      }) as GridColumnsHandlers<H>,
    [getCode, handlersProp],
  );

  return useMemo(
    () =>
      createGridColumns({
        handlers,
        columns,
      }),
    [columns, handlers],
  ) as { handlers: GridColumnsHandlers<H>; columns: GridColDef[] };
};

export default useCreateGridColumns;
