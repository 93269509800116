import InfoIcon from '@mui/icons-material/Info';
import styled from '@mui/joy/styles/styled';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Stack,
  Typography,
  DataGrid,
  Callout,
  Link,
  Button,
  Select,
  Option,
  useGridUtils,
  Checkbox,
} from '@wooriga/design-system';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UNION_REGISTER_TABLE_COLUMNS } from 'apis/union/register/fixtures/meet';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import MockMeetInfoModal from 'components/pages/meet-management/open/modals/MockMeetInfoModal';
import { isMissed } from 'components/pages/meet-management/open/utils';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import {
  OpenType,
  useUnionRegistersQuery,
} from 'lim/generalMeetingHistoryDetail/apis';
import { commaizeNumber } from 'utils/format';

interface MeetTypeTabProps {
  unionSeq: number;
  openType: OpenType;
}

const MeetTypeTab = ({ unionSeq, openType }: MeetTypeTabProps) => {
  const navigate = useNavigate();
  const { pageContext } = useLayoutContext();

  const { unionBasename } = pageContext || {};

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);
  const [showMockMeetInfo, setShowMockMeetInfo] = useState(false);

  const [clickedUnionId, setClickedUnionId] = useState(0);
  const [status, setStatus] = useState('all');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  // 모의 총회 관련 상태
  const [isMock, setIsMock] = useState(false);

  const { data, isLoading, isError, error } = useUnionRegistersQuery(unionSeq);

  const { snackbar, alertDialog } = useFeedback();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'meet-open-tab',
    activeSaveSnapshot: false,
  });

  const handleNameClick = useCallback((id: string | number) => {
    setShowUnionInfoModal(true);
    setClickedUnionId(Number(id));
  }, []);

  const handleOnSelect = (value: string) => {
    setStatus(value);
  };

  const validateMockMeet = (selectedIds: number[]) => {
    if (selectedIds.length > 10) {
      alertDialog(
        '모의 투표를 진행하시는 경우 선거인은 최대 10명까지 선택할 수 있습니다.',
        {
          color: 'danger',
        },
      );

      const newValue = selectedIds.slice(0, 10);
      setSelectedIds(newValue);

      return false;
    }
    return true;
  };

  const handleRowSelect = (ids: GridRowSelectionModel) => {
    const selectedIds = ids as number[];

    if (isMock && !validateMockMeet(selectedIds)) {
      return;
    }

    setSelectedIds(selectedIds);
  };

  const handleOnSubmit = () => {
    if (!selectedIds.length) {
      return snackbar('선거인을 선택하세요', {
        color: 'danger',
      });
    }

    if (isMock) {
      return setShowMockMeetInfo(true);
    }

    navigate('agenda', {
      state: {
        ids: selectedIds,
        openType,
        isMock,
      },
    });
  };

  const handleOnMockSubmit = (count: number) => {
    navigate('agenda', {
      state: {
        ids: selectedIds,
        openType,
        isMock,
        mockCount: count - selectedIds.length,
      },
    });
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;

    setIsMock(checked);
    return validateMockMeet(selectedIds);
  };

  const rows = useMemo(() => {
    return (
      data?.data
        ?.filter((data) => {
          switch (openType) {
            case 'GENERAL':
              return true;
            case 'DELEGATE':
              return data.positionDescription === '대의원';

            case 'BOARD':
              return data.positionDescription === '이사';
          }
        })
        .filter((data) => {
          if (status === 'all') return true;
          if (status === 'SHARE') return data.shareType === status;

          if (status === 'isMissed') {
            return isMissed(data);
          }
          return true;
        }) || []
    );
  }, [data?.data, openType, status]);

  const columnsHanlders = useMemo(
    () => ({
      onLinkClick: handleNameClick,
    }),
    [handleNameClick],
  );

  const { columns } = useCreateGridColumns({
    handlers: columnsHanlders,
    columns: UNION_REGISTER_TABLE_COLUMNS,
  });

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack marginBottom={3}>
        <Callout
          color="warning"
          variant="outlined"
          startDecorator={<InfoIcon />}
        >
          <Typography>
            빨간색으로 표시 항목은 누락된 정보가 있는 조합원입니다. 다시 한번
            확인해 주세요.
          </Typography>
          <Typography color="danger">
            이름, 성별, 연락처, 생년월일 정보가 누락 또는 오입력된 경우
            전자투표의 수신 또는 열람이 불가합니다.{' '}
            <Link href={`${unionBasename}/union-management/registers`}>
              조합원 명부 관리
            </Link>{' '}
            의 조합원정보에서 수정해 주세요.
          </Typography>
          <Typography>
            총회 개설 시 전자투표는 대리인에게 발송되며, 법인의 경우 대리인을
            선임하지 않으면 본인인증이 불가하여 전자투표 진행이 어려울 수
            있습니다.
          </Typography>
          <br />
          <Typography>
            총회 개설 전{' '}
            <Link href={`${unionBasename}/union-management/union-infos`}>
              조합 정보 관리
            </Link>{' '}
            에서 조합 사무실 정보를 등록해 주세요.
          </Typography>
          <Typography>
            조합 사무실 정보의{' '}
            <Typography component="span" color="danger">
              사무실 연락처
            </Typography>
            는 알림 문자 내{' '}
            <Typography component="span" color="danger">
              문의 전화번호
            </Typography>
            로 사용됩니다.
          </Typography>
          <Typography>
            <Typography component="span" color="danger">
              &quot;모의 투표 진행&quot;
            </Typography>
            을 체크시 가상 선거인을 대상으로 하는 모의 투표를 진행해 볼 수
            있습니다.
          </Typography>
        </Callout>
      </Stack>

      <Stack>
        <Checkbox
          sx={{ width: 'fit-content' }}
          label="모의 투표 진행"
          defaultChecked={false}
          onChange={handleCheckbox}
        />
        <Typography color="danger">
          * 모의 투표를 진행할 경우 선거인은 10명으로 제한됩니다.
        </Typography>
      </Stack>

      <Stack gap={1.75}>
        <Typography level="title-lg">선거인 명부 선택</Typography>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="end"
          gap={1}
        >
          <Stack direction="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(data?.pagination?.totalDataCount || 0)}
              </Typography>
            </Typography>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택 선거인{' '}
              <Typography color="primary">
                {commaizeNumber(selectedIds.length)}
              </Typography>
            </Typography>
          </Stack>

          <Select
            defaultValue="all"
            onChange={(_, value) => handleOnSelect(value as string)}
            startDecorator={<Typography level="body-md">상태 :</Typography>}
          >
            <Option value="all">전체</Option>
            <Option value="isMissed">정보누락</Option>
            <Option value="SHARE">공유자</Option>
          </Select>
        </Stack>
      </Stack>

      <Stack height={442} gap={2}>
        <StyledDataGrid
          apiRef={datagridApiRef}
          rows={rows}
          columns={columns}
          loading={isLoading}
          checkboxSelection
          disableRowSelectionOnClick
          getRowId={(row) => row.unionRegisterSeq}
          getRowClassName={(params) =>
            isMissed(params.row) ? `theme--isMissed` : ''
          }
          onRowSelectionModelChange={handleRowSelect}
          rowSelectionModel={selectedIds}
        />
      </Stack>

      <Stack flexDirection="row" alignSelf="end" gap={1}>
        <Button
          color="neutral"
          variant="outlined"
          onClick={() => exportExcel()}
        >
          내려받기
        </Button>
        <Button onClick={handleOnSubmit}>
          선거인 명부 확정 및 총회 정보 입력
        </Button>
      </Stack>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: clickedUnionId }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
      <MockMeetInfoModal
        open={showMockMeetInfo}
        unionCount={selectedIds.length}
        onClose={setShowMockMeetInfo}
        onConfirm={handleOnMockSubmit}
      />
    </Stack>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .theme--isMissed': {
    backgroundColor: theme.palette.danger[100],
    '&:hover': {
      backgroundColor: theme.palette.danger[200],
    },
  },
}));

export default MeetTypeTab;
