import { Link } from '@wooriga/design-system';

import { TypeGuardCol } from 'lim/_fixtures/type';
import { formatDateTime } from 'utils/format';

export interface AddressGroupTable {
  id: number;
  name: string;
  unionRegisterCount: number;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
}

export const ADDRESS_GROUP_TABLE_COLUMNS: TypeGuardCol<AddressGroupTable>[] = [
  {
    field: 'name',
    headerName: '그룹명',
    flex: 1,
    renderCell: (params) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          href={`${params.id}`}
        >
          {params.value}
        </Link>
      );
    },
  },
  {
    field: 'unionRegisterCount',
    headerName: '명수',
    valueFormatter: (value) => (value !== undefined ? `${value}명` : ''),
  },
  {
    field: 'createdAt',
    headerName: '생성일시',
    width: 180,
    minWidth: 180,
    valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    field: 'modifiedAt',
    headerName: '수정일시',
    width: 180,
    minWidth: 180,
    valueFormatter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
  },
];
