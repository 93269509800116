import InfoIcon from '@mui/icons-material/Info';
import { Callout, useTheme } from '@wooriga/design-system';

import { MeetMethods, MeetStatus } from 'apis/types/meet';

interface ResultCalloutProps {
  hasRows: boolean;
  duplicateVoteCount?: number;
  meetStatus: MeetStatus;
  meetMethod: MeetMethods;
}

const ResultCallout = ({
  hasRows,
  duplicateVoteCount = 0,
  meetMethod,
  meetStatus,
}: ResultCalloutProps) => {
  const theme = useTheme();
  const isElectronic = meetMethod === 'ELECTRONIC';
  const isOnsite = meetMethod === 'ONSITE';
  const isOverall = meetMethod === 'ONSITE_ELECTRONIC';

  if (!hasRows && isOverall)
    return (
      <Callout variant="outlined" startDecorator={<InfoIcon />}>
        전자 투표 진행 중입니다.
        <br />
        전자투표 마감 후, 최종 투표 결과를 확인하실 수 있습니다.
      </Callout>
    );

  if (meetStatus === 'COMPLETE')
    return (
      <Callout variant="outlined" startDecorator={<InfoIcon />}>
        종료된 총회 입니다.
      </Callout>
    );

  if (isOverall) {
    switch (meetStatus) {
      case 'BEFORE':
        return;
      case 'CANCEL':
        return;
      case 'PROCESS':
        return (
          <Callout variant="outlined" startDecorator={<InfoIcon />}>
            총회가 진행 중입니다. <br />
            모든 투표 결과 집계가 완료되고, 중복투표자가 없는 경우 <br />
            아래 “개표 결과 입력하기” 버튼을 눌러 집계 결과를 입력해 주세요.
            {duplicateVoteCount > 0 ? (
              <>
                <br />
                <br />
                <span>
                  <span style={{ color: `${theme.palette.danger[500]}` }}>
                    중복투표 {duplicateVoteCount}건
                  </span>
                  이 확인되었습니다. 총회 운영시스템에서 중복투표 처리 후 화면을
                  새로고침 해주세요 [
                  <a
                    style={{ color: `${theme.palette.blue[500]}` }}
                    rel="noreferrer"
                    target="_blank"
                    href="https://vms.wooriga.kr"
                  >
                    바로가기
                  </a>
                  ]
                </span>
              </>
            ) : null}
          </Callout>
        );
      case 'COUNTING':
        return (
          <Callout variant="outlined" startDecorator={<InfoIcon />}>
            개표 결과 입력이 완료되지 않았습니다. <br />
            개표 현황은 자동집계된 전자투표의 결과만 보여집니다. <br />
            아래 “개표 결과 재입력” 버튼을 눌러 다시 개표 결과 입력을
            진행해주세요.
          </Callout>
        );
    }
  }

  if (isElectronic) {
    switch (meetStatus) {
      case 'BEFORE':
        return;
      case 'CANCEL':
        return;
      case 'PROCESS':
        return (
          <Callout variant="outlined" startDecorator={<InfoIcon />}>
            전자 투표 진행 중입니다. <br />
            전자투표 마감 후, 최종 투표 결과를 확인하실 수 있습니다.
          </Callout>
        );
      case 'COUNTING':
        return (
          <Callout variant="outlined" startDecorator={<InfoIcon />}>
            전자투표가 완료되었습니다. <br />
            투표 결과를 확인 후, 의결 결과를 선택해주세요. <br />
            안건이 통과되었으면 “가결”, 안건이 통과되지 못하였으면 “부결”로
            선택해주세요.
          </Callout>
        );
    }
  }
  if (isOnsite) {
    switch (meetStatus) {
      case 'BEFORE':
        return;
      case 'CANCEL':
        return;
      case 'PROCESS':
        return (
          <Callout variant="outlined" startDecorator={<InfoIcon />}>
            총회가 진행 중입니다. <br />
            서면 제출 집계, 현장 투표 결과 집계가 완료되면, <br />
            아래 “개표 결과 입력하기” 버튼을 눌러 집계 결과를 입력해 주세요.
            <br />
          </Callout>
        );
      case 'COUNTING':
        return (
          <Callout variant="outlined" startDecorator={<InfoIcon />}>
            개표 결과 입력이 완료되지 않았습니다. <br />
            개표 현황은 자동집계된 전자투표의 결과만 보여집니다. <br />
            아래 “개표 결과 재입력” 버튼을 눌러 다시 개표 결과 입력을
            진행해주세요.
          </Callout>
        );
    }
  }

  return (
    <Callout variant="outlined" startDecorator={<InfoIcon />}>
      전자투표가 완료되었습니다. <br />
      투표 결과를 확인 후, 의결 결과를 선택해주세요. <br />
      안건이 통과되었으면 “가결”, 안건이 통과되지 못하였으면 “부결”로
      선택해주세요.
    </Callout>
  );
};

export default ResultCallout;
