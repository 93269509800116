import { Box, DataGrid, Stack } from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { useSpeechMutation, useSpeechQuery } from 'apis/meet/live/api';
import { ONLINE_GENERAL_MEET_SPEECH_COLUMNS } from 'apis/meet/live/fixtures';
import ViewerModal from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/components/ViewerModal';
import useCreateGridColumns from 'hooks/useCreateGridColumns';

export interface SpeechPanelProps {
  meetSeq?: number;
  onlineGeneralMeetingSeq: number;
}
const SpeechPanel = ({ onlineGeneralMeetingSeq }: SpeechPanelProps) => {
  const [viewerSeq, setViewerSeq] = useState(0);
  const [open, setOpen] = useState(false);
  const { data, isLoading, refetch } = useSpeechQuery(onlineGeneralMeetingSeq);
  const { mutate: speechMutate } = useSpeechMutation();

  const handleLinkClick = (seq: string | number) => {
    if (!seq) return;
    setViewerSeq(Number(seq));
    setOpen(true);
  };
  const handleClickSpeech = (speechSeq: number, type: string) => {
    speechMutate(
      { onlineGeneralMeetingSeq, speechSeq, status: type },
      {
        onSuccess: () => {
          refetch();
        },
        onError: () => {},
      },
    );
  };

  const rows = useMemo(() => {
    const rawData = data?.data || [];

    const hasSpeaking = rawData.some(
      (item) =>
        item.speechStatusType === 'SPEAKING' ||
        item.speechStatusType === 'PENDING',
    );

    const updatedData = rawData.map((item) => ({
      ...item,
      isAble: !hasSpeaking,
    }));

    return updatedData;
  }, [data?.data]);

  const { columns } = useCreateGridColumns(ONLINE_GENERAL_MEET_SPEECH_COLUMNS, {
    onNameClick: handleLinkClick,
    onClickSpeech: handleClickSpeech,
  });

  return (
    <>
      <Stack>
        <Box
          sx={{
            height: 442,
          }}
        >
          <DataGrid
            rows={rows}
            loading={isLoading}
            getRowId={(row) => {
              return row.speechSeq;
            }}
            columns={columns}
          />
        </Box>
      </Stack>
      {open && (
        <ViewerModal
          open={open}
          viewerSeq={viewerSeq}
          onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default SpeechPanel;
