import {
  Card,
  CardContent,
  chartColors,
  Stack,
  Switch,
  Typography,
} from '@wooriga/design-system';

import ColorMap, { ColorMapValue } from 'components/ColorMap/ColorMap';

export interface ConsentDongChartPanelProps {
  open: boolean;
  data: ColorMapValue[];
  onChangeOpen: (open: boolean) => void;
}

const ConsentDongChartPanel = ({
  open,
  data,
  onChangeOpen,
}: ConsentDongChartPanelProps) => {
  const heightOptions = open ? { height: '100%' } : null;

  return (
    <Card sx={{ px: 5, py: 3, ...heightOptions }}>
      <CardContent sx={{ gap: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            fontSize="md"
            fontWeight="lg"
            lineHeight="md"
            textColor="neutral.500"
          >
            각 동별 기준 동의율
          </Typography>
          <Switch
            checked={open}
            onChange={(event) => onChangeOpen(event.target.checked)}
          />
        </Stack>

        {open && (
          <ColorMap
            divide={5}
            data={data}
            colors={chartColors.blueGreenHeats}
            valueFormatter={(value) => `${value}%`}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ConsentDongChartPanel;
