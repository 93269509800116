export const percentage = (partialValue: number, totalValue: number) => {
  return Math.round((100 * partialValue) / totalValue);
};

const PercentLabel = (props: {
  size?: 'lg' | 'md' | 'sm';
  dy?: string | undefined;
  text: string;
}) => {
  const { dy: defaultDy, size, text } = props;

  const fontSize = size === 'lg' ? 24 : size === 'sm' ? 12 : 20;
  const dy = defaultDy ?? '-1rem';

  return (
    <tspan fontSize={fontSize} dy={dy}>
      {text}
    </tspan>
  );
};

export default PercentLabel;
