import { GridColDef } from '@mui/x-data-grid-premium';
import { Chip, Link } from '@wooriga/design-system';

import { MeetParticipantResponse } from 'apis/types/meet';
import { MessageRegister } from 'apis/types/message';
import { GridColumnsHandlers } from 'types/grid';
import { formatTelephone } from 'utils/format';

export interface MessageRegisterColumnProps {
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}

export const MESSAGE_REGISTER_COLUMNS = ({
  onClickUnionMember,
}: GridColumnsHandlers<MessageRegisterColumnProps>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 160,
      minWidth: 160,
      valueGetter: (value: MessageRegister['name']) =>
        `${value?.name ?? ''}${value?.nameClass ?? ''}`,
      renderCell: ({ value, row }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickUnionMember(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'positionDescription',
      headerName: '직책',
      width: 100,
    },
    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 160,
      minWidth: 160,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'phoneMemo',
      headerName: '비고',
      flex: 1,
    },
  ] as GridColDef<MessageRegister>[];

export const MESSAGE_ELECTOR_COLUMNS = ({
  isElectronic,
  onClickUnionMember,
  getCommonCode,
}: GridColumnsHandlers<{
  isElectronic: boolean;
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        `${row.name?.name ?? ''}${row.name?.nameClass ?? ''}`,
      renderCell: ({ value, row }) => (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickUnionMember(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'positionDescription',
      headerName: '직책',
    },
    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 160,
      minWidth: 160,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'status',
      headerName: '제출현황',
      valueGetter: (_, row) =>
        getCommonCode(
          isElectronic ? 'MEET_PARTICIPANT_VOTE_STATUS' : 'WRITTEN_SUBMIT_TYPE',
          isElectronic
            ? row.electronicVoteStatus.progressStatus
            : row.onsiteVoteStatus.writtenSubmissionType,
        )?.name,
    },
  ] as GridColDef<MeetParticipantResponse & { status: string }>[];

export const MESSAGE_AGREEMENT_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        row?.name
          ? [row?.name?.name, row?.name?.nameClass].join('')
          : '이름없음',
    },
    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 160,
      minWidth: 160,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'consentStatus',
      headerName: '동의현황',
      valueGetter: (value) =>
        getCommonCode('UNION_AGREEMENT_RESPONSE_TYPE', value)?.name,
    },
    // 확인 필요
    {
      field: 'phoneMemo',
      headerName: '메모',
      width: 200,
    },
  ] as GridColDef<MessageRegister & { status: string }>[];

export const MESSAGE_ONLINE_MEET_COLUMNS = ({
  onClickUnionMember,
}: GridColumnsHandlers<{
  isElectronic: boolean;
  onClickUnionMember: (unionMemberSeq: number | undefined) => void;
}>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) => {
        return `${row.name?.name ?? ''}${row.name?.nameClass ?? ''}`;
        // 조합원 이름이라는 칼럼명에 맞게 주석 처리하였으나, 대리인의 이름이 나와야한다는 요구사항이 있을 경우 아래 주석 해제
        // if (row.agent) {
        //   return `${row.agent?.name?.name ?? ''}${row.agent?.name?.nameClass ?? ''}`;
        // }
        // if (!row.agent) {
        //   return `${row.name?.name ?? ''}${row.name?.nameClass ?? ''}`;
        // }
      },
      renderCell: ({ value, row }) => {
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickUnionMember(row.unionRegisterSeq)}
          >
            {value}
          </Link>
        );
      },
    },

    {
      field: 'phoneNo',
      headerName: '연락처',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) => {
        if (row.agent) {
          return formatTelephone(row?.agent?.phoneNo);
        }
        if (!row.agent) return formatTelephone(row.phoneNo);
      },
    },
    {
      field: 'attendanceCategory',
      headerName: '참석유형',
      valueGetter: (_, row) => row.attendanceCategory,
      renderCell: (params) => {
        return params.row.attendanceCategory === 'ONLINE' ? (
          <Chip color="success" variant="outlined" size="lg">
            온라인
          </Chip>
        ) : params.row.attendanceCategory === 'NOT_ATTEND' ? (
          <Chip color="neutral" variant="outlined" size="lg">
            미참석
          </Chip>
        ) : params.row.attendanceCategory === 'ONSITE' ? (
          <Chip color="blue" variant="outlined" size="lg">
            현장
          </Chip>
        ) : (
          ''
        );
      },
    },
  ] as GridColDef<
    MeetParticipantResponse & { status: string; attendanceCategory: string }
  >[];
