import { formatBirth, formatTelephone } from '@wooriga/common-utils';
import { GridColDef, Link } from '@wooriga/design-system';

// import { UnionRegisterAgentInfo } from 'apis/types/union';
import { UnionRegisterReturnData } from 'apis/union/register/apis';
import { OnLinkClick } from 'lim/_fixtures/type';
import { GridColumnsHandlers } from 'types/grid';
import { formatCompanyRegistrationNumber } from 'utils/format/company';

export const UNION_REGISTER_TABLE_COLUMNS = ({
  onLinkClick,
  getCommonCode,
}: GridColumnsHandlers<{ onLinkClick: OnLinkClick }>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'shareType',
      headerName: '소유구분',
      groupable: true,
      groupingValueGetter: (value) =>
        (value && getCommonCode('UNION_REGISTER_SHARE_DIVIDE', value)?.name) ||
        '',
      valueGetter: (value) =>
        getCommonCode('UNION_REGISTER_SHARE_DIVIDE', value)?.name || '',
    },

    {
      field: 'isRepresentative',
      headerName: '대표자',
      type: 'boolean',
      groupingValueGetter: (value) => (value === true ? true : false),
      valueGetter: (value) => (value === true ? true : false),
      groupable: true,
    },

    {
      field: 'hasVotingRight',
      headerName: '의결권',
      type: 'boolean',
      groupingValueGetter: (value) => (value === true ? true : false),
      valueGetter: (value) => (value === true ? true : false),
      groupable: true,
    },

    {
      field: 'positionDescription',
      headerName: '직책',
    },
    {
      field: 'name',
      headerName: '이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        row?.unionRegisterSeq &&
        (row?.name
          ? [row?.name?.name, row?.name?.nameClass].join('')
          : '이름없음'),
      renderCell: (params) => {
        const { value } = params;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onLinkClick(params.id)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'agent',
      headerName: '대리인',
      //   valueGetter: (params: UnionRegisterAgentInfo, row) =>
      //     row?.agent?.unionRegisterAgentSeq &&
      //     [params?.name?.name, params?.name?.nameClass].join(''),
      type: 'boolean',
    },
    {
      field: 'birth',
      headerName: '생년월일',
      width: 130,
      minWidth: 130,
      valueGetter: (_, row) =>
        formatBirth(row?.birth) ??
        (formatCompanyRegistrationNumber(row?.companyNo) || ''),
    },
    {
      field: 'gender',
      headerName: '성별',
      width: 50,
      minWidth: 50,
      valueGetter: (value: string) =>
        value === 'M' ? '남자' : value === 'F' ? '여자' : '',
    },
    {
      field: 'mainPhone',
      headerName: '조합원 연락처',
      width: 130,
      minWidth: 130,
      valueGetter: (_, row) =>
        (row?.mainPhone?.phoneNo && formatTelephone(row?.mainPhone?.phoneNo)) ||
        '',
    },
    // { field: 'localAddresses', headerName: '소재지', flex: 1 },
  ] as GridColDef<UnionRegisterReturnData>[];
