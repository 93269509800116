import { GridColDef } from '@mui/x-data-grid-premium';
import { Link } from '@wooriga/design-system';

import { Post } from 'apis/types/post';
import { GridColumnsHandlers } from 'types/grid';
import { commaizeNumber, formatDateTime } from 'utils/format';

export const POST_RECORD_COLUMNS = ({ getCommonCode }: GridColumnsHandlers) =>
  [
    // {
    //   field: 'no',
    //   headerName: 'No',
    //   width: 78,
    //   valueGetter: (_, row, __, apiRef) =>
    //     apiRef.current.getAllRowIds().indexOf(row?.postSeq) + 1 || '',
    // },
    {
      field: 'postType',
      headerName: '발송방식',
      valueGetter: (value) => getCommonCode('POST_SEND_DIVIDE', value)?.name,
    },
    {
      field: 'colorType',
      headerName: '출력색상',
      valueGetter: (value) => getCommonCode('POST_COLOR_DIVIDE', value)?.name,
    },
    {
      field: 'flexType',
      headerName: '출력형태',
      valueGetter: (value) => getCommonCode('POST_FLEX_DIVIDE', value)?.name,
    },
    {
      field: 'sendQuantity',
      headerName: '발송매수',
      valueGetter: (value) => (value > 0 ? `${commaizeNumber(value)}장` : ''),
    },
    {
      field: 'requestCount',
      headerName: '수신자 수',
      valueGetter: (value) => `${commaizeNumber(value)}명`,
      renderCell: ({ row, value }) =>
        value !== undefined && (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            href={`${row.postSeq}`}
          >
            {value}
          </Link>
        ),
    },
    {
      field: 'requestedAt',
      headerName: '신청일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'sentAt',
      headerName: '발송일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'deliveredAt',
      headerName: '배달완료일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'postStatus',
      headerName: '제작발송상태',
      valueGetter: (value) => getCommonCode('POST_STATUS', value)?.name,
    },
  ] as GridColDef<Post>[];
