import {
  gridClasses,
  GridValidRowModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { DataGrid, GridColDef, useTheme } from '@wooriga/design-system';

import { MeetsAgendasTableColumnsProps } from 'lim/generalMeetingHistoryDetail/fixtures';

export type DataGridValueKeys = Pick<
  MeetsAgendasTableColumnsProps,
  'yesCount' | 'noCount' | 'abstentionCount'
>;

type GroupingModelKeys = keyof MeetsAgendasTableColumnsProps;

export type DataGridValues = Record<string, DataGridValueKeys>;

interface ResultGridProps<RowType, ColumnType extends GridValidRowModel> {
  rows: RowType[];
  columns: GridColDef<ColumnType>[];
  defaultGroupingExpansionDepth?: number;
  groupingModels?: GroupingModelKeys[];
}

const ResultGrid = <RowType, ColumnType extends GridValidRowModel>({
  rows,
  columns,
  defaultGroupingExpansionDepth = -1,
  groupingModels,
}: ResultGridProps<RowType, ColumnType>) => {
  const theme = useTheme();

  const defaultModels = ['agendaSeq'];
  const model = groupingModels
    ? [...defaultModels, ...groupingModels]
    : defaultModels;

  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model,
      },
    },
  });
  theme;

  return (
    <DataGrid
      apiRef={apiRef}
      disableRowSelectionOnClick
      initialState={initialState}
      defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
      disableColumnMenu
      disableColumnSorting
      groupingColDef={{
        hideDescendantCount: true,
      }}
      sx={{
        [`.${gridClasses.cell}.group`]: {
          // backgroundColor: theme.palette.background.surface,
          backgroundColor: '#fff',
        },
        [`.${gridClasses.cell}`]: {
          backgroundColor: theme.palette.neutral[100],
        },
      }}
      getCellClassName={(params) => {
        if (params.rowNode.type !== 'group') return '';

        return 'group';
      }}
      columns={columns}
      rows={rows}
    />
  );
};

export default ResultGrid;
