import { QueryClient, useQuery, UseQueryResult } from '@tanstack/react-query';

import { MeetParticipantInfo } from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

export interface ReadMeetParticipantsRequest {
  // Path
  generalMeetingSeq: number;
}

export interface ReadMeetParticipantsResponse
  extends ApiResponseData<MeetParticipantInfo[]> {}

const readMeetParticipantsOption = ({
  generalMeetingSeq,
}: ReadMeetParticipantsRequest) => ({
  queryKey: [`/v2/general-meetings/${generalMeetingSeq}/participants`],
});

export const readMeetParticipants = async (
  queryClient: QueryClient,
  params: ReadMeetParticipantsRequest,
) =>
  await queryClient.fetchQuery<ReadMeetParticipantsResponse, ApiError>(
    readMeetParticipantsOption(params),
  );

export const useReadMeetParticipants = (
  params: ReadMeetParticipantsRequest,
): UseQueryResult<ReadMeetParticipantsResponse, ApiError> =>
  useQuery(readMeetParticipantsOption(params));
