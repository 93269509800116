const primary = ['#117268', '#29978D', '#69BDB5', '#B1E1DD', '#DAF3F0'];

const success = ['#136C13', '#1F7A1F', '#51BC51', '#A1E8A1', '#C7F7C7'];

const blue = ['#185EA5', '#0B6BCB', '#4393E4', '#97C3F0', '#C7DFF7'];

// heatmap colors

const blueGreenHeats = [
  '#F2F3F3',
  '#E3FBE3',
  '#C7F7C7',
  '#A1E8A1',
  '#C7DFF7',
  '#97C3F0',
  '#4393E4',
];

export default { primary, success, blue, blueGreenHeats };
