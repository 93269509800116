import { ReactElement } from 'react';
import { StateCreator, useStore } from 'zustand';

import { createRevealStore } from 'common-reveal/core/vanilla';
import { CreateReveals, DefaultElements } from 'common-reveal/types';

export const createReveal = <TDefaultElements>(
  defaultElements?: DefaultElements<TDefaultElements>,
) => {
  const api = createRevealStore<ReactElement, TDefaultElements>(
    defaultElements || ({} as DefaultElements<TDefaultElements>),
  );

  const store = (
    selector?: (
      state: CreateReveals<ReactElement, TDefaultElements>,
    ) => CreateReveals<ReactElement, TDefaultElements>,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  ) => useStore(api, selector || ((state) => state));

  Object.assign(store, api);

  return store;
};

export const createRevealSlice =
  <TDefaultElements>(
    defaultElements?: DefaultElements<TDefaultElements>,
  ): StateCreator<CreateReveals<ReactElement, TDefaultElements>> =>
  (set) => ({
    defaultElements:
      defaultElements || ({} as DefaultElements<TDefaultElements>),
    reveals: new Map(),

    mount: (id, element) =>
      set((state) => {
        const clonedReveals = new Map(state.reveals);
        clonedReveals.set(id, element);
        return { reveals: clonedReveals };
      }),

    unmount: (id) =>
      set((state) => {
        const clonedReveals = new Map(state.reveals);
        clonedReveals.delete(id);
        return { reveals: clonedReveals };
      }),

    clear: () => set(() => ({ reveals: new Map() })),
  });
