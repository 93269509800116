import styled from '@mui/joy/styles/styled';

import type { DescriptionsOwnerState } from 'design-system/components/dataDisplay/descriptions/Descriptions';
import type { DescriptionsItemProps } from 'design-system/components/dataDisplay/descriptions/DescriptionsItem';

export type DescriptionsRowProps = {
  ownerState: DescriptionsOwnerState;
  row: DescriptionsItemProps[];
};

const Row = ({ ownerState, row }: DescriptionsRowProps) => {
  return (
    <Item ownerState={ownerState}>
      {row.map(({ label, span = 1, children }, index) => [
        <ItemLabel
          key={`item-label-${index}`}
          colSpan={1}
          ownerState={ownerState}
        >
          {label}
        </ItemLabel>,
        <ItemContent
          key={`item-content-${index}`}
          colSpan={span === 1 ? 1 : span - 1}
          ownerState={ownerState}
        >
          {children}
        </ItemContent>,
      ])}
    </Item>
  );
};

const Item = styled('tr', {
  name: 'JoyDescriptionsItem-legacy',
  slot: 'root',
})<{ ownerState: DescriptionsOwnerState }>`
  vertical-align: top;
`;

const ItemLabel = styled('th', {
  name: 'JoyDescriptionsItem-legacy',
  slot: 'label',
})<{ ownerState: DescriptionsOwnerState }>(({ theme, ownerState }) => ({
  textAlign: 'left',
  width: '7.5rem',
  maxWidth: 'max-content',
  minWidth: 'fit-content',
  wordBreak: 'keep-all',
  overflowWrap: 'break-word',

  ...(ownerState.size === 'sm' && {
    fontSize: theme.fontSize.xs,
    fontWeight: theme.fontWeight.md,
    lineHeight: theme.lineHeight.xl,
  }),

  ...(ownerState.size === 'md' && {
    fontSize: theme.fontSize.sm,
    fontWeight: theme.fontWeight.lg,
    lineHeight: theme.lineHeight.xl,
  }),

  ...(ownerState.size === 'lg' && {
    fontSize: theme.fontSize.md,
    fontWeight: theme.fontWeight.lg,
    lineHeight: theme.lineHeight.md,
  }),
}));

const ItemContent = styled('td', {
  name: 'JoyDescriptionsItem-legacy',
  slot: 'content',
})<{ ownerState: DescriptionsOwnerState }>(({ theme, ownerState }) => ({
  fontWeight: theme.fontWeight.md,

  ...(ownerState.size === 'sm' && {
    fontSize: theme.fontSize.xs,
    lineHeight: theme.lineHeight.xl,
  }),

  ...(ownerState.size === 'md' && {
    fontSize: theme.fontSize.sm,
    lineHeight: theme.lineHeight.sm,
  }),

  ...(ownerState.size === 'lg' && {
    fontSize: theme.fontSize.md,
    lineHeight: theme.lineHeight.md,
  }),

  ...(ownerState.variant !== 'solid' && {
    color: theme.palette.text.secondary,
  }),
}));

export default Row;
