import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { formatDateTime } from '@wooriga/common-utils';
import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  Link,
  FormControl,
  FormLabel,
  Select,
  Option,
  Grid,
} from '@wooriga/design-system';
import { saveAs } from 'file-saver';
import * as _ from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import {
  CertificatesParticipantsParams,
  CertificationType,
  useCertificatesCostCalculateMutation,
  useCertificatesMutation,
  useCertificatesParticipantsQuery,
  useCertificatesSummaryQuery,
} from 'apis/meet/certificate/api';
import { useFileDownloadCertificates } from 'apis/meet/certificate/download/api';
import { CERTIFICATION_DETAIL_TABLE_COLUMNS } from 'apis/meet/certificate/fixtures';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import MeetsInfoDescription from 'components/pages/common/MeetsInfoDescription';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import RequestModal from 'components/pages/meet-management/certifications/modals/RequestModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import { useMeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { commaizeNumber } from 'utils/format';

interface CertificationTabProps {
  unionSeq: number;
  meetSeq: number;
  type: CertificationType;
}

const defaultParams: CertificatesParticipantsParams = {
  unionRegisterNo: '',
  name: '',
  certificateIssuanceStatus: '',
  searchFrom: '',
  searchTo: '',
};

const CertificationTab = ({
  unionSeq,
  meetSeq,
  type,
}: CertificationTabProps) => {
  const { alertDialog, confirmDialog, snackbar } = useFeedback();
  const { pageContext } = useLayoutContext();
  const { getGroupCode, getCode } = useCommonCodes();
  const meetCertIssuableStatusCodeGroup = useMemo(
    () => getGroupCode('MEET_CERT_ISSUABLE_STATUS'),
    [getGroupCode],
  );

  const [params, setParams] = useState(defaultParams);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [availableRows, setAvailableRows] = useState<GridRowSelectionModel>([]);
  const [downloadableRows, setDownloadableRows] = useState<number[]>([]);
  const [certificateIssuanceStatus, setCertificateIssuanceStatus] =
    useState('');

  const meetsDetailQuery = useMeetsDetailQuery(meetSeq);

  const { data, isLoading, isError, error, refetch } =
    useCertificatesParticipantsQuery(meetSeq, type, params);

  const { data: summaryData, refetch: summaryRefetch } =
    useCertificatesSummaryQuery(meetSeq);
  const { mutate: costMutate } = useCertificatesCostCalculateMutation(meetSeq);
  const { mutate } = useCertificatesMutation(meetSeq);
  const fileDownloadCertificates = useFileDownloadCertificates();

  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const [selectedUnionID, setSelectedUnionID] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const handleDownloadClick = useCallback(
    (...fileSeqs: number[]) => {
      fileDownloadCertificates.mutateAsync(
        {
          meetSeq,
          certificateType: type,
          fileSeqs,
        },
        {
          onSuccess: (response) => {
            const currentDate = formatDateTime(
              new Date(),
              'yyyy-MM-dd_HH:mm:ss',
            );
            const blob = new Blob([response]);
            saveAs(
              blob,
              `${getCode('MEET_CERT_TYPE', type)?.name}_${currentDate}.zip`,
            );
          },
          onError: (error) => {
            snackbar(error?.response?.data.message ?? error.message, {
              color: 'danger',
            });
          },
        },
      );
    },
    [fileDownloadCertificates, getCode, meetSeq, snackbar, type],
  );

  const handlers = useMemo(
    () => ({
      onNameClick: (unionRegisterSeq: number) => {
        setSelectedUnionID(unionRegisterSeq);
        setShowUnionInfoModal(true);
      },
      onDownloadClick: handleDownloadClick,
    }),
    [handleDownloadClick],
  );

  const { columns } = useCreateGridColumns({
    columns: CERTIFICATION_DETAIL_TABLE_COLUMNS,
    handlers,
  });

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const { certificateIssuanceStatus, certificateFileSeq } = row;
        const {
          meetParticipantSeq,
          unionRegisterNo,
          agent,
          name,
          electronicVoteStatus,
          unionRegisterSeq,
        } = row.meetParticipant;
        const { receivedAt, openedAt, votedAt } = electronicVoteStatus;
        return {
          id: meetParticipantSeq,
          unionRegisterSeq: unionRegisterSeq,
          unionRegisterNo,
          agent: agent ? 'O' : '',
          name: name?.name,
          receivedAt,
          openedAt,
          votedAt,
          electronicVoteStatus: electronicVoteStatus.voteStatus,
          certificateIssuanceStatus,
          certificateFileSeq,
        };
      }) || []
    );
  }, [data?.data]);

  const handleOnSelect = (value: string) => {
    setCertificateIssuanceStatus(value);
  };

  const handleOnSearch = (params: CertificatesParticipantsParams) => {
    let newValue = {} as CertificatesParticipantsParams;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    setParams({
      ...newValue,
      certificateIssuanceStatus,
    });
  };

  const handleRowSelectedModelChange = useCallback(
    (
      rowSelectionModel: GridRowSelectionModel,
      // details: GridCallbackDetails,
    ) => {
      const selectionRows = _.filter(rows, (row) =>
        _.some(rowSelectionModel, (selectionRow) => row.id === selectionRow),
      );

      setSelectedRows(rowSelectionModel);
      setAvailableRows(
        _.filter(
          selectionRows,
          (selectionRow) => selectionRow.certificateIssuanceStatus === 'ABLE',
        ).map((selectionRow) => selectionRow.id),
      );
      setDownloadableRows(
        _.filter(
          selectionRows,
          (selectionRow) =>
            selectionRow.certificateFileSeq !== undefined &&
            selectionRow.certificateIssuanceStatus === 'COMPLETE',
        ).map((selectionRow) => selectionRow.certificateFileSeq) as number[],
      );
    },
    [rows],
  );

  const handleClick = useCallback(
    (action: 'request' | 'download') => {
      const isRequestAction = action === 'request';
      const targetRows = isRequestAction ? availableRows : downloadableRows;

      if (!targetRows.length)
        return alertDialog(
          `요청하신 증명서를 ${isRequestAction ? '발급 신청' : '다운로드'}할 수 없습니다.\n${isRequestAction ? '발급 신청이' : '다운로드가'} 가능한 선거인을 선택해 주세요.`,
        );

      if (isRequestAction) {
        setShowRequestModal(true);
        return costMutate(
          {
            certificateType: type,
            meetParticipantSeqs: availableRows as number[],
          },
          {
            onSuccess: (data) => {
              setTotalCost(data.data.totalCost);
            },
            // 비용 계산 실패시
            onError: (e) => {
              snackbar(e.response?.data.message ?? e.message, {
                color: 'danger',
              });
            },
          },
        );
      }

      confirmDialog(
        `총 ${selectedRows.length}건 중 ${downloadableRows.length}건에 대해 다운로드가 가능합니다.\n진행하시겠습니까?`,
        {
          onConfirm: () => {
            handleDownloadClick(...(downloadableRows as number[]));
          },
        },
      );
    },
    [
      alertDialog,
      availableRows,
      confirmDialog,
      costMutate,
      downloadableRows,
      handleDownloadClick,
      selectedRows.length,
      snackbar,
      type,
    ],
  );

  const handleOnSubmit = () => {
    const formData = {
      certificateType: type,
      meetParticipantSeqs: selectedRows as number[],
    };
    mutate(formData, {
      onSuccess: () => {
        snackbar('발급 신청이 완료되었습니다.', { color: 'success' });
        handleRowSelectedModelChange([]);
        summaryRefetch();
        refetch();
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, { color: 'danger' });
      },
    });
  };

  const certificateType =
    type === 'DIST_CERT'
      ? 'distributionCertificate'
      : type === 'VOTE_CERT'
        ? 'voteCertificate'
        : 'electronicCertificate';

  const getJson = () => {
    if (!summaryData?.data)
      return generateDescriptionsJSON(
        ['신청 가능', '신청중', '발급 완료', '다운로드'],
        [],
      );

    const { ableCount, requestCount, completeCount, downloadCount } =
      summaryData.data[certificateType];

    return generateDescriptionsJSON(
      ['신청 가능', '신청중', '발급 완료', '다운로드'],
      [ableCount, requestCount, completeCount, downloadCount],
    );
  };

  if (isError) throw error;

  const certificationType =
    type === 'DIST_CERT'
      ? '유통 증명서'
      : type === 'VOTE_CERT'
        ? '투표내역 증명서'
        : '전자문서 증명서';

  return (
    <Stack gap={3}>
      <MeetsInfoDescription meetsDetailQuery={meetsDetailQuery} />

      <Stack gap={1.75}>
        <Typography level="title-lg">발급 현황</Typography>
        <DescriptionsRenderer json={getJson()} columns={2} />
      </Stack>

      <Search
        defaultValues={defaultParams}
        onSubmit={handleOnSearch}
        onReset={() => {
          setParams(defaultParams);
          setCertificateIssuanceStatus('');
        }}
      >
        <Grid container gap={2}>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                label="연번"
                name="unionRegisterNo"
                placeholder='숫자 또는 "-"입력'
                validateOptions={{
                  maxLength: 11,
                  regex: /^(?!.*--)[0-9-]*$/,
                }}
                fullWidth
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <TextField
                placeholder="이름을 입력하세요."
                label="이름"
                name="name"
                fullWidth
                slotProps={{
                  input: { maxLength: 30 },
                }}
              />
            </Search.Field>
          </Grid>
          <Grid xs={12} maxWidth={200}>
            <Search.Field>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel>발급 상태</FormLabel>
                <Select
                  sx={{ width: '100%' }}
                  placeholder="발급 대상을 선택하세요."
                  name="certificateIssuanceStatus"
                  value={certificateIssuanceStatus}
                  onChange={(_, value) =>
                    handleOnSelect(value as unknown as string)
                  }
                >
                  <Option value="">전체</Option>

                  {meetCertIssuableStatusCodeGroup?.items.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </Search.Field>
          </Grid>

          {/* <Search.Field>
            <DateRangePicker
              label="기간조회"
              name="search"
              sx={{ field: { maxWidth: 200 } }}
            />
          </Search.Field> */}
        </Grid>
      </Search>

      <Stack gap={2}>
        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(data?.pagination?.totalDataCount || 0)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(data?.pagination?.totalElements || 0)}
            </Typography>
          </Typography>

          {selectedRows.length > 0 && (
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택{' '}
              <Typography color="primary">
                {commaizeNumber(selectedRows.length)}
              </Typography>
            </Typography>
          )}
        </Stack>

        <Stack height={442}>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            checkboxSelection
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={handleRowSelectedModelChange}
          />
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Button component={Link} href=".." variant="outlined" color="neutral">
          목록
        </Button>

        <Stack direction="row" gap={1}>
          <Button onClick={() => handleClick('request')}>발급 신청</Button>
          <Button variant="outlined" onClick={() => handleClick('download')}>
            증명서 다운로드
          </Button>
        </Stack>
      </Stack>

      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectedUnionID }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}

      <RequestModal
        requestor={pageContext?.memberUnionInfo?.name || ''}
        certificationType={certificationType}
        requestCount={selectedRows.length}
        availableCount={availableRows.length}
        cost={totalCost}
        open={showRequestModal}
        onClose={setShowRequestModal}
        onSubmit={handleOnSubmit}
      />
    </Stack>
  );
};

export default CertificationTab;
