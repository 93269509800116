import { GridColDef } from '@mui/x-data-grid-premium';
import { Link } from '@wooriga/design-system';

import { Message } from 'apis/types/message';
import { GridColumnsHandlers } from 'types/grid';
import { commaizeNumber, formatDateTime, formatTelephone } from 'utils/format';

export const MESSAGES_COLUMNS = ({ getCommonCode }: GridColumnsHandlers) =>
  [
    // {
    //   field: 'no',
    //   headerName: 'No',
    //   valueGetter: (_, row, __, apiRef) =>
    //     apiRef.current.getAllRowIds().indexOf(row?.smsId) + 1 || '',
    // },
    {
      field: 'smsType',
      headerName: '문자유형',
      width: 160,
      valueGetter: (value) => getCommonCode('UNION_SMS_DIVIDE', value)?.name,
      renderCell: ({ row, value, rowNode }) => {
        if (rowNode.type === 'group') {
          return value;
        }

        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            href={`${row.smsId}`}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'sendType',
      headerName: '발송방식',
      valueGetter: (value) => getCommonCode('SMS_SEND_TYPE', value)?.name,
    },
    {
      field: 'outgoingPhoneNo',
      headerName: '발신번호',
      width: 130,
      valueGetter: (value) => formatTelephone(value),
    },
    {
      field: 'requestedAt',
      headerName: '신청일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) =>
        value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'sentAt',
      headerName: '발송일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) =>
        value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'completedAt',
      headerName: '완료일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) =>
        value && formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'recipientCount',
      headerName: '수신자 수',
      valueGetter: (value) =>
        value !== undefined ? `${commaizeNumber(value)}명` : '',
    },
    {
      field: 'successCount',
      headerName: '성공',
      valueGetter: (value) =>
        value !== undefined ? `${commaizeNumber(value)}명` : '',
    },
    {
      field: 'failCount',
      headerName: '실패',
      valueGetter: (value) =>
        value !== undefined ? `${commaizeNumber(value)}명` : '',
    },
    {
      field: 'sendStatus',
      headerName: '발송상태',
      valueGetter: (value) => getCommonCode('UNION_SMS_STATUS', value)?.name,
    },
  ] as GridColDef<Message>[];
