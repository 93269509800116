import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import { FileInfo } from 'apis/types/common';
import { ApiResponseData, ApiError } from 'types/api';

export interface Document {
  title: string;
  attach: {
    fileSeq: number;
    originName?: string;
  };
}

export type Documents = Document[];

export interface ElectronicDocumentPostParams {
  unionSeq: number;
  unionRegisterSeqs: number[];
  sendMethod: string;
  signatureExpireIn: number;
  isScheduled: boolean;
  scheduledAt?: string;
  documents: Document[];
}

export interface ElectronicDocumentListQueryParams {
  unionSeq: number;
}
export interface ElectronicDocumentRecipientsListQueryParams {
  unionSeq: number;
  electronicDocumentSeq: number;
}

type UseElectronicDocumentListQueryResult = UseQueryResult<
  ApiResponseData,
  ApiError
>;

export type ElectronicDocumentDetailReturnData = {
  title: string;
  documentStatus: string;
  attachFile: FileInfo;
};

type UseElectronicDocumentDetailQueryResult = UseQueryResult<
  ApiResponseData<ElectronicDocumentDetailReturnData>,
  ApiError
>;
// 전자문서 목록 조회
export const useElectronicDocumentListQuery = (
  params: ElectronicDocumentListQueryParams,
): UseElectronicDocumentListQueryResult => {
  const { unionSeq } = params;
  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/electronic-documents`],
  });
};
//전자문서 상세 조회
export const useElectronicDocumentDetailQuery = (
  params: ElectronicDocumentRecipientsListQueryParams,
): UseElectronicDocumentDetailQueryResult => {
  const { unionSeq, electronicDocumentSeq } = params;
  return useQuery({
    queryKey: [
      `/v1/unions/${unionSeq}/electronic-documents/${electronicDocumentSeq}`,
    ],
  });
};

//전자문서 수신인 상세 조회
export const useElectronicDocumentRecipientsListQuery = (
  params: ElectronicDocumentRecipientsListQueryParams,
): UseElectronicDocumentListQueryResult => {
  const { unionSeq, electronicDocumentSeq } = params;
  return useQuery({
    queryKey: [
      `/v1/unions/${unionSeq}/electronic-documents/${electronicDocumentSeq}/recipients`,
    ],
  });
};

// 문서 등록
export const useElectronicDocumentsMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError<ApiError>,
  ElectronicDocumentPostParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, ...other } = params;

      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/electronic-documents`,
        other,
      );

      return data;
    },
  });

export interface ElectronicDocumentSend {
  unionSeq: number;
  electronicDocumentSeq: number;
}

// 발송
export const useElectronicDocumentsSendMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError<ApiError>,
  ElectronicDocumentSend
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, electronicDocumentSeq } = params;

      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/electronic-documents/${electronicDocumentSeq}/send`,
      );

      return data;
    },
  });

export interface ElectronicDocumentsBalanceReturnData {
  totalCost?: number;
}
//비용 조회
export const useReadElectronicDocumentsBalanceMutation = (): UseMutationResult<
  ApiResponseData<ElectronicDocumentsBalanceReturnData>,
  AxiosError<ApiError>,
  ElectronicDocumentSend
> =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, electronicDocumentSeq } = params;

      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/electronic-documents/${electronicDocumentSeq}/cost/calculate`,
      );

      return data;
    },
  });
