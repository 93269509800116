import { format, FormatDateOptions } from 'date-fns';
import { ko } from 'date-fns/locale/ko';
import * as _ from 'lodash-es';

export const formatDateFns = <T extends Date>(
  date: string | number | undefined | null | Date | T,
  formatStr = "yyyy-MM-dd'T'HH:mm:ss",
  options?: Omit<FormatDateOptions, 'locale'>,
) => formatDateTime(date, formatStr, options);

export const formatDateTime = <T extends Date>(
  date: string | number | undefined | null | Date | T,
  formatStr = 'yyyy-MM-dd HH:mm:ss',
  options?: Omit<FormatDateOptions, 'locale'>,
) => {
  if (
    date === undefined ||
    date === null ||
    (typeof date === 'string' && date.trim() === '')
  ) {
    return date || '';
  }

  return format(date, formatStr, { ...options, locale: ko });
};

export const formatDate = <T extends Date>(
  date: string | number | undefined | null | Date | T,
  formatStr = 'yyyy-MM-dd',
  options?: Omit<FormatDateOptions, 'locale'>,
) => formatDateTime(date, formatStr, options);

export const getLaterDate = <T extends Date>(
  value1: string | number | undefined | null | T,
  value2: string | number | undefined | null | T,
) => {
  const validateValue1 = _.isNull(value1) || _.isUndefined(value1);
  const validateValue2 = _.isNull(value2) || _.isUndefined(value2);

  if (validateValue1 && validateValue2) {
    return undefined;
  }

  if (validateValue1) {
    return value2;
  }

  if (validateValue2) {
    return value1;
  }

  const date1 = new Date(value1);
  const date2 = new Date(value2);

  return date1 > date2 ? value1 : value2;
};

export const getEarlierDate = <T extends Date>(
  value1: string | number | undefined | null | T,
  value2: string | number | undefined | null | T,
) => {
  const validateValue1 = _.isNull(value1) || _.isUndefined(value1);
  const validateValue2 = _.isNull(value2) || _.isUndefined(value2);

  if (validateValue1 && validateValue2) {
    return undefined;
  }

  if (validateValue1) {
    return value2;
  }

  if (validateValue2) {
    return value1;
  }

  const date1 = new Date(value1);
  const date2 = new Date(value2);

  return date1 < date2 ? value1 : value2;
};
