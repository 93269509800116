import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Callout,
  FormLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import {
  useChangeOnlineGeneralMeetStatusMutation,
  useCreateMetadataMutation,
  useMetadataQuery,
} from 'apis/meet/live/api';
import useFeedback from 'hooks/useFeedback';

export interface ConfigPanelProps {
  meetSeq?: number;
  onlineGeneralMeetingSeq: number;
}

const ConfigPanel = ({ onlineGeneralMeetingSeq }: ConfigPanelProps) => {
  const { snackbar } = useFeedback();

  const { data: metadataReturnData, refetch: refetchMetadata } =
    useMetadataQuery(onlineGeneralMeetingSeq);

  const metadata = useMemo(() => {
    if (!metadataReturnData?.data) {
      return {
        metadaStatus: '',
        metadaStreamingStatus: '',
        metadataStageArn: '',
        metadataToken: '',
        id: '',
        password: '',
      };
    }

    return {
      metadaStatus: metadataReturnData.data.status || '',
      metadaStreamingStatus: metadataReturnData.data.streamingStatus || '',
      metadataStageArn: metadataReturnData.data.stageArn || '',
      metadataToken: metadataReturnData.data.token || '',
      id: metadataReturnData.data.id || '',
      password: metadataReturnData.data.password || '',
    };
  }, [metadataReturnData?.data]);

  const {
    metadaStatus,
    metadaStreamingStatus,
    metadataStageArn,
    metadataToken,
    id,
    password,
  } = metadata;

  const { mutate: createMetadataMutate, isPending } =
    useCreateMetadataMutation();

  const { mutate: changeStatusMutate } =
    useChangeOnlineGeneralMeetStatusMutation();

  const copyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      snackbar('텍스트가 클립보드에 복사되었습니다.'); // 복사 성공 메시지
    } catch (error) {
      console.error('클립보드 복사 실패:', error);
      snackbar('복사에 실패했습니다. 다시 시도해주세요.', { color: 'danger' }); // 실패 메시지
    }
  };

  const handleClickChangeStatus = (type: string) => {
    if (!onlineGeneralMeetingSeq) return;

    const typeText =
      type === 'BROADCASTING'
        ? '송출 시작'
        : type === 'STOPPED'
          ? '송출 정지'
          : '';
    changeStatusMutate(
      {
        onlineGeneralMeetingSeq: onlineGeneralMeetingSeq,
        streamingStatusType: type,
      },
      {
        onSuccess: () => {
          snackbar(`${typeText}`, { color: 'success' });
          refetchMetadata();
        },
        onError: (error) => {
          console.log('error', error);
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleClickMetadata = () => {
    console.log('onlineGeneralMeetingSeq', onlineGeneralMeetingSeq);
    if (!onlineGeneralMeetingSeq) return;
    createMetadataMutate(
      {
        onlineGeneralMeetingSeq: onlineGeneralMeetingSeq,
      },
      {
        onSuccess: (res) => {
          console.log(res);
          snackbar('메타데이터가 생성 되었습니다.', { color: 'success' });
          refetchMetadata();
        },
        onError: (error) => {
          console.log('error', error);
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };
  return (
    <>
      <Stack gap={3} marginTop={2}>
        <Callout
          variant="outlined"
          color="primary"
          size="md"
          startDecorator={<InfoIcon />}
        >
          <Stack gap={0.75}>
            <Typography
              textColor="primary.700"
              fontSize="sm"
              fontWeight="md"
              lineHeight="sm"
            >
              온라인 총회 영상 송출을 대기중입니다.
            </Typography>
            <Stack>
              <Typography
                textColor="primary.700"
                fontSize="sm"
                fontWeight="md"
                lineHeight="sm"
              >
                1. 송출정보 생성하기 버튼을 클릭하여 영상URL과 Key를 생성
                하세요.
              </Typography>
              <Typography
                ml={2}
                textColor="primary.700"
                fontSize="sm"
                fontWeight="md"
                lineHeight="sm"
              >
                ※ 생성 후 오른쪽 복사 버튼을 클릭하면 클립보드에 복사 됩니다.
              </Typography>
            </Stack>
            <Stack>
              <Typography
                textColor="primary.700"
                fontSize="sm"
                fontWeight="md"
                lineHeight="sm"
              >
                2. 생성된 영상 URL, Key를 방송 프로그램에 등록(입력 또는
                붙여넣기)하세요.
              </Typography>
              <Typography
                ml={2}
                textColor="primary.700"
                fontSize="sm"
                fontWeight="md"
                lineHeight="sm"
              >
                ※ 음성 발언용 Key는 생성 후 14일간 유효합니다.
              </Typography>
            </Stack>
            <Typography
              textColor="primary.700"
              fontSize="sm"
              fontWeight="md"
              lineHeight="sm"
            >
              3. 방송프로그램을 시작하세요.
            </Typography>

            <Typography
              textColor="primary.700"
              fontSize="sm"
              fontWeight="md"
              lineHeight="sm"
            >
              4. 정상적으로 화면이 표시되는 것을 확인하세요.
            </Typography>

            <Typography
              textColor="primary.700"
              fontSize="sm"
              fontWeight="md"
              lineHeight="sm"
            >
              5. 온라인 총회 시작 버튼을 클릭하면 송출이 시작됩니다
            </Typography>
          </Stack>
        </Callout>
        {/* 생성 전 */}
        {metadaStatus === 'CREATED' ? (
          <Box justifyContent="center" display="flex">
            <Button onClick={handleClickMetadata} loading={isPending}>
              송출정보 생성하기
            </Button>
          </Box>
        ) : (
          <>
            <Stack gap={2}>
              <Stack gap={1} flexDirection="row" display="flex">
                <Stack flex={1}>
                  <FormLabel>온라인 총회 영상 URL</FormLabel>
                  <Stack display="flex" flexDirection="row" gap={1}>
                    <TextField
                      value={metadataStageArn}
                      readOnly
                      size="md"
                      fullWidth
                      endDecorator={
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={() => {
                            copyClipBoard(metadataStageArn || '');
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      }
                    />
                  </Stack>
                </Stack>
                <Stack flex={1}>
                  <FormLabel>음성 발언용 Key</FormLabel>
                  <Stack display="flex" flexDirection="row" gap={1}>
                    <TextField
                      value={metadataToken}
                      readOnly
                      size="md"
                      fullWidth
                      endDecorator={
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={() => {
                            copyClipBoard(metadataToken || '');
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Stack gap={1} flexDirection="row" display="flex">
                <Stack flex={1}>
                  <FormLabel>음성 발언 관리자용 아이디</FormLabel>
                  <Stack display="flex" flexDirection="row" gap={1}>
                    <TextField
                      value={id}
                      readOnly
                      size="md"
                      fullWidth
                      endDecorator={
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={() => {
                            copyClipBoard(id || '');
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      }
                    />
                  </Stack>
                </Stack>
                <Stack flex={1}>
                  <FormLabel>음성 발언 관리자용 비밀번호</FormLabel>
                  <Stack display="flex" flexDirection="row" gap={1}>
                    <TextField
                      value={password}
                      readOnly
                      size="md"
                      fullWidth
                      endDecorator={
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={() => {
                            copyClipBoard(password || '');
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {metadaStreamingStatus === 'BROADCASTING' ? (
              <Box justifyContent="right" display="flex">
                <Button
                  size="lg"
                  variant="solid"
                  color="danger"
                  onClick={() => {
                    handleClickChangeStatus('STOPPED');
                  }}
                >
                  온라인 총회 종료
                </Button>
              </Box>
            ) : (
              <Box justifyContent="right" display="flex">
                <Button
                  size="lg"
                  variant="solid"
                  color="primary"
                  onClick={() => {
                    handleClickChangeStatus('BROADCASTING');
                  }}
                >
                  온라인 총회 시작
                </Button>
              </Box>
            )}
          </>
        )}

        {/* 생성 이후 */}
      </Stack>
    </>
  );
};

export default ConfigPanel;
