// /unions/{unionSeq}/registers/{unionRegisterSeq}/registration-changes

import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import {
  RegistrationChangeHistoryInfo,
  RegistrationChangesInfo,
} from 'apis/types/union';
import { ApiError, ApiResponseData } from 'types/api';

export interface RegistrationChangesQueryParams {
  // Path
  unionSeq: number;
  unionRegisterSeq: number;
}

export type RegistrationChangesQueryReturnData = RegistrationChangesInfo;

export const useRegistrationChangesQuery = ({
  unionSeq,
  unionRegisterSeq,
}: RegistrationChangesQueryParams): UseQueryResult<
  ApiResponseData<RegistrationChangesQueryReturnData>,
  ApiError
> =>
  useQuery({
    queryKey: [
      `/v1/unions/${unionSeq}/registers/${unionRegisterSeq}/registration-changes`,
    ],
  });

export interface RegistrationChangesConfirmMutationParams {
  // Path
  unionSeq: number;
  unionRegisterSeq: number;
}

export const useRegistrationChangesConfirmMutation = (): UseMutationResult<
  ApiResponseData,
  AxiosError,
  RegistrationChangesConfirmMutationParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, unionRegisterSeq } = request;

      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/registers/${unionRegisterSeq}/registration-changes/confirm`,
      );

      return data;
    },
  });

export interface RegistrationChangeHistoriesQueryParams {
  // Path
  unionSeq: number;

  // Body
}

export type RegistrationChangeHistoriesQueryReturnData =
  RegistrationChangeHistoryInfo[];

export const useRegistrationChangeHistoriesQuery = ({
  unionSeq,
  ...body
}: RegistrationChangeHistoriesQueryParams): UseQueryResult<
  ApiResponseData<RegistrationChangeHistoriesQueryReturnData>,
  ApiError
> =>
  useQuery({
    queryKey: [`/v1/unions/${unionSeq}/registration-change-histories`, body],
  });
