import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Pagination,
  Grid,
  Button,
  Link,
} from '@wooriga/design-system';
import { ChangeEvent, useMemo, useState } from 'react';

import { ArchiveParams, useArchiveListQuery } from 'apis/archives/apis';
import { ARCHIVES_TABLE_COLUMNS } from 'apis/archives/fixture';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useLayoutContext from 'hooks/useLayoutContext';
import archvicesDetailPage from 'pages/main/union-management/archives/detail';
import archvicesRegisterPage from 'pages/main/union-management/archives/register';
import archvicesUpdatePage from 'pages/main/union-management/archives/update';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const DEFAULT_SEARCH_PARAMS = {
  title: '',
};

const ArchvicesPage = () => {
  const { pageContext } = useLayoutContext();
  const { memberUnionInfo } = pageContext || {};
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [searchParams, setSearchParams] = useState<ArchiveParams>({
    unionSeq: memberUnionInfo?.unionSeq || 0,
    ...paginationModel,
    ...DEFAULT_SEARCH_PARAMS,
  });
  const { data, isPending, isError, isLoading, error } =
    useArchiveListQuery(searchParams);
  const handleChangePagination = (
    _: ChangeEvent<unknown>,
    page: number | null,
  ) => {
    if (!page) {
      return;
    }

    setPaginationModel({ ...paginationModel, page: page - 1 });
  };

  const handleSearchParams = (values: Partial<ArchiveParams> | undefined) => {
    if (!values) {
      return;
    }
    setSearchParams((prevSearchParams) => ({ ...prevSearchParams, ...values }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };
  const rows = useMemo(() => data?.data || [], [data]);
  const { columns } = useCreateGridColumns({ columns: ARCHIVES_TABLE_COLUMNS });

  if (isError) throw error;

  if (isLoading || isPending || !data.pagination) return <></>;

  const totalDataCount = data?.pagination.totalDataCount || 0;
  const totalElements = data?.pagination.totalElements || 0;

  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationModel.pageSize);

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <Search
          defaultValues={DEFAULT_SEARCH_PARAMS}
          onSubmit={handleSearchParams}
          onReset={handleSearchReset}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={360}>
              <Search.Field>
                <TextField
                  label="제목"
                  name="title"
                  placeholder="제목 입력"
                  fullWidth
                />
              </Search.Field>
            </Grid>
          </Grid>
        </Search>
      </Stack>

      <Stack gap={2}>
        <Stack flexDirection="row" gap={1}>
          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            전체{' '}
            <Typography color="primary">
              {commaizeNumber(totalDataCount)}
            </Typography>
          </Typography>

          <Typography fontSize="md" fontWeight="lg" lineHeight="md">
            조회 목록{' '}
            <Typography color="primary">
              {commaizeNumber(totalElements)}
            </Typography>
          </Typography>
        </Stack>
        <Stack height={442}>
          <DataGrid
            columns={columns}
            rows={rows || []}
            getRowId={(row) => row.archiveSeq}
            loading={isPending}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Stack>

        <Stack alignItems="center">
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton={false}
            showLastButton={false}
            hidePrevButton={false}
            hideNextButton={false}
            count={pageTotalCount}
            onChange={handleChangePagination}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button component={Link} href="register" color="primary">
            등록
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const archvicesPage: CustomRouteObject = {
  path: '/unions/:unionSeq/union-management/archives',
  children: [
    { path: '', index: true, element: <ArchvicesPage /> },
    archvicesDetailPage,
    archvicesRegisterPage,
    archvicesUpdatePage,
  ],
  handle: {
    getTitle: () => '자료실 관리',
    getMenuCode: () => 'M0410',
  },
};

export default archvicesPage;
