import { useTheme } from '@mui/joy';
import {
  Stack,
  Typography,
  TabList,
  Tabs,
  Tab,
  TabPanel,
} from '@wooriga/design-system';
import { useParams } from 'react-router-dom';

import DetailMenuTab from 'components/pages/consent-management/history/tabs/DetailMenuTab';
import TargetManagementTab from 'components/pages/consent-management/history/tabs/RecipientManagementTab';
import { CustomRouteObject } from 'types/route';

const DetailPage = () => {
  const params = useParams();

  const unionSeq = Number(params.unionSeq);
  const consentSeq = Number(params.consentSeq);

  const theme = useTheme();

  return (
    <Stack gap={2}>
      <Typography level="title-lg" marginBottom="15px">
        전자 동의서 상세
      </Typography>

      <Tabs
        defaultValue={0}
        selectedTabColor="primary"
        selectedTabVariant="plain"
        tabIndicatorInset
        sx={{
          marginBottom: '30px',
          background: theme.palette.background.body,
        }}
      >
        <TabList disableUnderline>
          <Tab value={0}>동의서 현황</Tab>
          <Tab value={1}>대상자 관리</Tab>
        </TabList>

        <TabPanel value={0}>
          <DetailMenuTab unionSeq={unionSeq} consentSeq={consentSeq} />
        </TabPanel>
        <TabPanel value={1}>
          <TargetManagementTab unionSeq={unionSeq} consentSeq={consentSeq} />
        </TabPanel>
      </Tabs>
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <DetailPage />,
  handle: {
    getTitle: () => '전자 동의서 상세',
  },
};

const electronicConsentDetail = {
  path: ':consentSeq',
  children: [route],
  handle: {
    getTitle: () => '전자 동의서 상세',
  },
};

export default electronicConsentDetail;
