import {
  Box,
  Button,
  Chip,
  GridColDef,
  Input,
  Link,
  Stack,
  Typography,
} from '@wooriga/design-system';

import {
  MeetVoteForm,
  MeetVoteType,
  PromotionUserResponse,
} from 'apis/types/meet';
import MeetVoteFormChip from 'components/Chips/MeetVoteFormChip';
import CommonCode from 'components/CommonCode';
import { OnInputChange, OnLinkClick } from 'lim/_fixtures/type';
import { Participant, ShareType } from 'lim/generalMeetingHistoryDetail/apis';
import { GridColumnsHandlers } from 'types/grid';
import {
  formatBirth,
  formatDate,
  formatDateTime,
  formatTelephone,
} from 'utils/format';

export type ElectronicVoteManagementColumns = Omit<
  Participant,
  'electronicVoteStatus'
> &
  Participant['electronicVoteStatus'] & {
    promotionUserSeq: PromotionUserResponse['promotionUserSeq'];
  };

export const ELECTRONIC_VOTE_MANAGEMENT_COLUMNS = (
  onNameClick: OnLinkClick,
  onCountClick: OnLinkClick,
  onPromotionUserClick: (id: number) => void,
): GridColDef<ElectronicVoteManagementColumns>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
  },
  {
    field: 'name',
    headerName: '이름',
    width: 160,
    minWidth: 160,
    renderCell: ({ row, value }) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onNameClick(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      );
    },
  },
  {
    field: 'birth',
    headerName: '생년월일',
    width: 130,
    minWidth: 130,
    valueGetter: (_, value) => formatBirth(value.birth),
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 160,
    minWidth: 160,
    valueGetter: (_, value) => formatTelephone(value.phoneNo),
  },
  {
    field: 'tendency',
    headerName: '우호도',
    renderCell: ({ value }) => (
      <CommonCode groupCode="TENDENCY_TYPE" code={value} />
    ),
  },
  {
    field: 'progressStatus',
    headerName: '진행상태',
    renderCell: ({ value }) => (
      <CommonCode groupCode="MEET_PARTICIPANT_VOTE_STATUS" code={value} />
    ),
  },
  {
    field: 'voteStatus',
    headerName: '전자투표 상태',
    renderCell: ({ value }) => (
      <CommonCode groupCode="VOTING_STATUS_TYPE" code={value} />
    ),
  },
  {
    field: 'receivedAt',
    headerName: '수신일시',
    width: 180,
    minWidth: 180,
    valueGetter: (_, value) =>
      formatDateTime(value.receivedAt, 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    field: 'openedAt',
    headerName: '열람일시',
    width: 180,
    minWidth: 180,
    valueGetter: (_, value) =>
      formatDateTime(value.openedAt, 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    field: 'votedAt',
    headerName: '투표일시',
    width: 180,
    minWidth: 180,
    valueGetter: (_, value) =>
      formatDateTime(value.votedAt, 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    field: 'resendCount',
    headerName: '발송',
    width: 150,
    renderCell: ({ id, value }) => {
      return (
        <Button variant="plain" onClick={() => onCountClick(id)}>
          {value}
        </Button>
      );
    },
  },
  {
    field: 'promotionUser',
    headerName: '담당자',
    width: 150,
    renderCell: ({ row, value }) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onPromotionUserClick(row.promotionUserSeq)}
        >
          {value}
        </Link>
      );
    },
  },
];

export type OnsiteVoteManagementColumns = Omit<
  Participant,
  'onsiteVoteStatus'
> &
  Participant['onsiteVoteStatus'] & {
    promotionUserSeq: PromotionUserResponse['promotionUserSeq'];
  };

export const ONSITE_VOTE_MANAGEMENT_COLUMNS = (
  onNameClick: OnLinkClick,
  onPromotionUserClick: (id: number) => void,
): GridColDef<OnsiteVoteManagementColumns>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
  },
  {
    field: 'name',
    headerName: '이름',
    width: 160,
    minWidth: 160,
    renderCell: ({ value, row }) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onNameClick(row.unionRegisterSeq)}
        >
          {value}
        </Link>
      );
    },
  },
  {
    field: 'birth',
    headerName: '생년월일',
    width: 130,
    minWidth: 130,
    valueGetter: (_, value) => formatBirth(value.birth),
  },
  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 160,
    minWidth: 160,
    valueGetter: (_, value) => formatTelephone(value.phoneNo),
  },
  {
    field: 'tendency',
    headerName: '우호도',

    renderCell: ({ value }) => (
      <CommonCode groupCode="TENDENCY_TYPE" code={value} />
    ),
  },
  {
    field: 'writtenSubmissionType',
    headerName: '제출현황',

    renderCell: ({ value }) => (
      <CommonCode groupCode="WRITTEN_SUBMIT_TYPE" code={value} />
    ),
  },
  {
    field: 'writtenSubmissionStatus',
    headerName: '서면투표 상태',
    renderCell: ({ value }) => (
      <CommonCode groupCode="VOTING_WRITTEN_TYPE" code={value} />
    ),
  },
  {
    field: 'writtenSubmissionAt',
    headerName: '서면 제출일',
    valueGetter: (_, value) => formatDate(value.writtenSubmissionAt),
  },
  {
    field: 'writtenWithdrawalAt',
    headerName: '철회일',
    valueGetter: (_, value) => formatDate(value.writtenWithdrawalAt),
  },

  {
    field: 'attendExpectedType',
    headerName: '총회참석예정',

    renderCell: ({ value }) => (
      <CommonCode groupCode="ATTEND_EXPECTED_TYPE" code={value} />
    ),
  },
  {
    field: 'promotionUser',
    headerName: '담당자',
    width: 160,
    minWidth: 160,
    renderCell: ({ value, row }) => {
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onPromotionUserClick(row.promotionUserSeq)}
        >
          {value}
        </Link>
      );
    },
  },
];

interface SendingHistoryColumnProps {
  progressStatus: string;
  sentAt: string;
  receivedAt: string;
  openedAt: string;
  votedAt: string;
}

export const SENDING_HISTORY_COLUMNS: GridColDef<SendingHistoryColumnProps>[] =
  [
    {
      field: 'progressStatus',
      headerName: '진행상태',
      renderCell: ({ value }) => (
        <CommonCode groupCode="MEET_PARTICIPANT_VOTE_STATUS" code={value} />
      ),
    },
    {
      field: 'sentAt',
      headerName: '발송일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'receivedAt',
      headerName: '수신일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'openedAt',
      headerName: '열람일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'votedAt',
      headerName: '투표일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
  ];

export type VoterManagementTableColumns = {
  id: number;
  unionRegisterNo: string;
  unionRegisterSeq?: number;
  position: string;
  agent: boolean;
  shareType: ShareType;
  name: string;
  gender: string;
  birth: string;
  phoneNo: string;
  progressStatus: string;
  realAddress: string;
  postAddress: string;
  isDisabled: boolean;
};

export const VOTER_MANAGEMENT_TABLE_COLUMNS: GridColDef<
  VoterManagementTableColumns & {
    edit: '';
  }
>[] = [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  }, //
  {
    field: 'shareType',
    headerName: '소유구분',
    renderCell: ({ value }) => (
      <CommonCode groupCode="UNION_REGISTER_SHARE_TYPE" code={value} />
    ),
  },
  {
    field: 'position',
    headerName: '직책',
    renderCell: ({ value }) => (
      <CommonCode groupCode="UNION_REGISTER_POSITION" code={value} />
    ),
  },
  { field: 'name', headerName: '이름', width: 160, minWidth: 160 }, // name?.name
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
  },
  {
    field: 'birth',
    headerName: '생년월일',
    width: 130,
    minWidth: 130,
    valueGetter: (value) => formatBirth(value),
  }, //
  {
    field: 'gender',
    headerName: '성별',
    valueGetter: (value: string) =>
      value === 'M' ? '남자' : value === 'F' ? '여자' : '',
  }, //

  {
    field: 'phoneNo',
    headerName: '연락처',
    width: 160,
    minWidth: 160,
    valueGetter: (value) => formatTelephone(value),
  }, //
  {
    field: 'realAddress',
    headerName: '실제 거주지',
    width: 230,
    minWidth: 230,
  },
  {
    field: 'postAddress',
    headerName: '우편물 수령지',
    width: 230,
    minWidth: 230,
  },
  {
    field: 'progressStatus',
    headerName: '진행상태',
    renderCell: ({ value }) => (
      <CommonCode groupCode="MEET_PARTICIPANT_VOTE_STATUS" code={value} />
    ),
  }, // sendStatus

  {
    field: 'edit',
    headerName: '수정',
    sortable: false,
    renderCell: ({ row, id }) => {
      row.progressStatus === '';
      return (
        <Button disabled={row.isDisabled} component={Link} href={`${id}`}>
          수정
        </Button>
      );
    },
  },
];

export type MeetsAgendasTableColumnsProps = {
  order: string;
  name: string;
  description: string;
  voteForm: MeetVoteForm;
  voteType: MeetVoteType;
  candidate: string;
  selectCount: number;
  no: number;
  yesCount: number;
  noCount: number;
  abstentionCount: number;
  totalCount: number;
};

export const MEETS_AGENDAS_ElECTRONIC_TABLE_COLUMNS: GridColDef<MeetsAgendasTableColumnsProps>[] =
  [
    {
      field: 'agendaSeq',
      headerName: '의안',
      width: 105,
      minWidth: 105,
      renderCell: ({ id, rowNode, api }) => {
        if (rowNode.type !== 'group') return null;

        const groupAgendaSeq = (id as string).split('/')?.[1];
        const rowId = api.getAllRowIds().find((rowId) => {
          const [agendaSeq] = (rowId as string).split('-');
          return Number(agendaSeq) === Number(groupAgendaSeq);
        });
        const row = rowId && api.getRow(rowId);
        return `제 ${row.order} 호`;
      },
    },
    {
      field: 'voteForm',
      headerName: '안건 유형',
      width: 120,
      minWidth: 120,
      renderCell: ({ id, rowNode, api }) => {
        if (rowNode.type !== 'group') return null;

        const groupAgendaSeq = (id as string).split('/')?.[1];
        const rowId = api.getAllRowIds().find((rowId) => {
          const [agendaSeq] = (rowId as string).split('-');
          return Number(agendaSeq) === Number(groupAgendaSeq);
        });
        const row = rowId && api.getRow(rowId);

        return <MeetVoteFormChip code={row?.voteForm} />;
      },
    },
    {
      field: 'candidateNo',
      headerName: '기호',
      valueFormatter: (value) => `${value}번`,
      renderCell: ({ rowNode, formattedValue }) => {
        if (rowNode.type === 'group') return null;

        return formattedValue;
      },
    },
    {
      field: 'candidateName',
      headerName: '안건/후보명',
      flex: 1,
      renderCell: ({ id, value, rowNode, api }) => {
        if (rowNode.type !== 'group') return value;

        const groupAgendaSeq = (id as string).split('/')?.[1];
        const rowId = api.getAllRowIds().find((rowId) => {
          const [agendaSeq] = (rowId as string).split('-');
          return Number(agendaSeq) === Number(groupAgendaSeq);
        });
        const row = rowId && api.getRow(rowId);

        return (
          <Stack flexDirection="row" overflow="hidden" gap={1}>
            <Typography
              fontSize="inherit"
              fontWeight="inherit"
              lineHeight="inherit"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {row.name}
            </Typography>
            <Box>
              {row.voteType === 'MULTI' && (
                <Chip
                  variant="soft"
                  color="neutral"
                  size="sm"
                >{`${row.selectCount}건 선택`}</Chip>
              )}
            </Box>
          </Stack>
        );
      },
    },
    {
      field: 'yesCount',
      headerName: '찬성/선택',
    },
    {
      field: 'noCount',
      headerName: '반대',
      valueGetter: (value, row) => (row.voteForm === 'SELECT' ? '' : value),
    },
    {
      field: 'abstentionCount',
      headerName: '기권',
      valueGetter: (value, row) => (row.voteForm === 'SELECT' ? '' : value),
    },
    {
      field: 'totalCount',
      headerName: '합계',
      valueGetter: (value, row) => (row.voteForm === 'SELECT' ? '' : value),
    },
  ];

export const MEETS_AGENDAS_TABLE_COLUMNS = <
  Count,
  Values extends Record<string, Record<string, number>>,
>({
  count,
  values,
  onInputChange,
}: GridColumnsHandlers<{
  count: Count;
  values: Values;
  onInputChange: OnInputChange;
}>): GridColDef<MeetsAgendasTableColumnsProps>[] => [
  {
    field: 'agendaSeq',
    headerName: '의안',
    width: 105,
    minWidth: 105,
    renderCell: ({ id, rowNode, api }) => {
      if (rowNode.type !== 'group') return null;

      const groupAgendaSeq = (id as string).split('/')?.[1];
      const rowId = api.getAllRowIds().find((rowId) => {
        const [agendaSeq] = (rowId as string).split('-');
        return Number(agendaSeq) === Number(groupAgendaSeq);
      });
      const row = rowId && api.getRow(rowId);
      return `제 ${row.order} 호`;
    },
  },
  {
    field: 'voteForm',
    headerName: '안건 유형',
    width: 120,
    minWidth: 120,
    renderCell: ({ id, rowNode, api }) => {
      if (rowNode.type !== 'group') return null;

      const groupAgendaSeq = (id as string).split('/')?.[1];
      const rowId = api.getAllRowIds().find((rowId) => {
        const [agendaSeq] = (rowId as string).split('-');
        return Number(agendaSeq) === Number(groupAgendaSeq);
      });
      const row = rowId && api.getRow(rowId);

      return <MeetVoteFormChip code={row?.voteForm} />;
    },
  },
  {
    field: 'candidateNo',
    headerName: '기호',
    valueFormatter: (value) => `${value}번`,
    renderCell: ({ rowNode, formattedValue }) => {
      if (rowNode.type === 'group') return null;

      return formattedValue;
    },
  },
  {
    field: 'candidateName',
    headerName: '안건/후보명',
    flex: 1,
    renderCell: ({ id, value, rowNode, api }) => {
      if (rowNode.type !== 'group') return value;

      const groupAgendaSeq = (id as string).split('/')?.[1];
      const rowId = api.getAllRowIds().find((rowId) => {
        const [agendaSeq] = (rowId as string).split('-');
        return Number(agendaSeq) === Number(groupAgendaSeq);
      });
      const row = rowId && api.getRow(rowId);

      return (
        <Stack flexDirection="row" overflow="hidden" gap={1}>
          <Typography
            fontSize="inherit"
            fontWeight="inherit"
            lineHeight="inherit"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {row.name}
          </Typography>
          <Box>
            {row.voteType === 'MULTI' && (
              <Chip
                variant="soft"
                color="neutral"
                size="sm"
              >{`${row.selectCount}건 선택`}</Chip>
            )}
          </Box>
        </Stack>
      );
    },
  },
  {
    field: 'yesCount',
    headerName: '찬성/선택',
    display: 'flex',
    renderCell: ({ rowNode, id, field }) => {
      if (rowNode.type !== 'group') {
        const value = Number(values[id]?.[field]);

        return (
          <Input
            size="sm"
            validateOptions={{
              regex: /^[0-9]*$/,
            }}
            name={field}
            defaultValue={0}
            value={value}
            onChange={(e) => onInputChange(id, e)}
          />
        );
      }
    },
  },
  {
    field: 'noCount',
    headerName: '반대',
    display: 'flex',
    renderCell: ({ row, rowNode, field, id }) => {
      if (row.voteForm === 'SELECT') return <></>;

      if (rowNode.type !== 'group') {
        const value = Number(values[id]?.[field]);

        return (
          <Input
            size="sm"
            validateOptions={{
              regex: /^[0-9]*$/,
            }}
            name={field}
            defaultValue={0}
            value={value}
            onChange={(e) => onInputChange(id, e)}
          />
        );
      }
    },
  },
  {
    field: 'abstentionCount',
    headerName: '기권',
    display: 'flex',
    renderCell: ({ row, rowNode, field, id }) => {
      if (row.voteForm === 'SELECT') return <></>;

      if (rowNode.type !== 'group') {
        const value = Number(values[id]?.[field]);

        return (
          <Input
            size="sm"
            validateOptions={{
              regex: /^[0-9]*$/,
            }}
            name={field}
            defaultValue={0}
            value={value}
            onChange={(e) => onInputChange(id, e)}
          />
        );
      }
    },
  },
  {
    field: 'totalCount',
    headerName: '합계',
    valueGetter: (_, row) => (row.voteForm === 'SELECT' ? '' : count),
    renderCell: ({ rowNode }) => {
      if (rowNode.type === 'group') return <></>;
    },
  },
];

export type MeetsParticipantHistoriesTableColumnProps = {
  unionRegisterNo: string;
  position: string;
  agent: string;
  shareType: string;
  name: string;
  gender: string;
  birth: string;
  modifiedAt: string;
  modifiedBy: string;
  actionType: string;
};

export const PARTICIPANT_HISTORIES_TABLE_COLUMNS = (
  onLinkClick: OnLinkClick,
): GridColDef<
  MeetsParticipantHistoriesTableColumnProps & { detail: string }
>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  { field: 'position', headerName: '직책' },
  {
    field: 'agent',
    headerName: '대리인',
    type: 'boolean',
    valueGetter: (value) => value === 'O',
  },
  {
    field: 'shareType',
    headerName: '소유구분',
    renderCell: ({ value }) => (
      <CommonCode groupCode="UNION_REGISTER_SHARE_TYPE" code={value} />
    ),
  },
  { field: 'name', headerName: '이름', width: 160, minWidth: 160 },
  {
    field: 'gender',
    headerName: '성별',
    width: 50,
    valueGetter: (value: string) =>
      value === 'M' ? '남자' : value === 'F' ? '여자' : '',
  },
  {
    field: 'birth',
    headerName: '생년월일',
    width: 130,
    minWidth: 130,
    valueGetter: (value) => formatBirth(value),
  },
  {
    field: 'modifiedAt',
    headerName: '변동일시',
    width: 180,
    minWidth: 180,
    valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
  },
  { field: 'modifiedBy', headerName: '작업자' },
  {
    field: 'actionType',
    headerName: '기능',
    renderCell: ({ value }) => (
      <CommonCode groupCode="MEET_PARTICIPANT_ACTION_TYPE" code={value} />
    ),
  },
  {
    field: 'detail',
    headerName: '내역',
    sortable: false,
    width: 160,
    minWidth: 160,
    renderCell: ({ id }) => {
      return (
        <Button
          size="md"
          variant="outlined"
          color="primary"
          onClick={() => onLinkClick(id)}
        >
          상세보기
        </Button>
      );
    },
  },
];
