import {
  Box,
  Button,
  Option,
  //DatePicker,
  FormControl,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Textarea,
  Typography,
  Grid,
  FileDragDrop,
  FileList,
  Snackbar,
  SnackbarClose,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useArchiveRegisterMutation } from 'apis/archives/apis';
import { useFileUploadMutation } from 'apis/common/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import useLayoutContext from 'hooks/useLayoutContext';
import { ArchiveUpdate } from 'kwon/archives/types';
import { CustomRouteObject } from 'types/route';

export interface Files {
  fileSeq: number;
  originName: string;
}

const ArchvicesRegisterPage = () => {
  const navigate = useNavigate();
  const { pageContext } = useLayoutContext();
  const memberUnionInfo = pageContext?.memberUnionInfo;

  const [toastInfo, setToastInfo] = useState({
    success: true,
    show: false,
    content: '',
  });

  const { getGroupCode } = useCommonCodes();

  const codeGroup = useMemo(
    () => getGroupCode('ARCHIVE_CATEGORY'),
    [getGroupCode],
  );

  const [currentFileList, setCurrentFileList] = useState<Files[]>([]);

  const { mutate: registerArchive } = useArchiveRegisterMutation();
  const { mutateAsync: uploadFiles } = useFileUploadMutation();

  const methods = useForm<ArchiveUpdate>({
    defaultValues: {
      title: '',
      content: '',
      category: '',
      isPublic: true,
      fileSeqs: [],
    },
  });

  const handleSelectFiles = (upcomingFiles: FileList | null) => {
    if (!upcomingFiles) {
      return;
    }

    const fileList = new DataTransfer();
    fileList.items.add(upcomingFiles[0]);

    uploadFiles(
      {
        divisionCode: 'UNION_BOARD',
        files: fileList.files,
      },
      {
        onSuccess: (response) => {
          if (response.data) {
            response.data[0].fileSeq &&
              setCurrentFileList([...currentFileList, response.data[0]]);
          }
        },
        onError: (error) => {
          console.log('error', error);
        },
      },
    );
  };

  const handleSubmit = methods.handleSubmit((formData) => {
    formData.fileSeqs = currentFileList.map((item) => item.fileSeq);

    if (!formData.isPublic) {
      formData.isPublic = false;
    }

    registerArchive(
      {
        unionSeq: memberUnionInfo?.unionSeq || 0,
        ...formData,
      },
      {
        onSuccess: () => {
          navigate('..');
        },
        onError: () => {
          setToastInfo({
            success: false,
            show: true,
            content: '등록 실패했습니다.',
          });
        },
      },
    );
  });

  return (
    <FormProvider {...methods}>
      <Stack gap={2} component="form" onSubmit={handleSubmit}>
        <Stack gap={1.75}>
          <Typography level="title-lg">자료실 등록</Typography>
          <Typography level="title-lg">자료 정보</Typography>

          <Stack gap={2}>
            <Grid container xs={12} gap={2}>
              <Grid xs={3}>
                {/* <FormControl>
                  <FormLabel>구분</FormLabel>
                  <Select {...methods.register('category')}>
                    {codeGroup.data?.items.map((item) => {
                      return (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <FormControl>
                  <FormLabel>구분</FormLabel>
                  <Select
                    {...methods.register('category', {
                      required: true,
                    })}
                    onChange={(_, value) => {
                      value ?? methods.setValue('category', value || '');
                    }}
                  >
                    {codeGroup?.items.map((item) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={2}>
                <Box minWidth={200}>
                  <FormControl>
                    <FormLabel>공개여부</FormLabel>
                    <RadioGroup
                      orientation="horizontal"
                      size="sm"
                      key="isPublic"
                      //   value={'isPublic'}
                      {...methods.register('isPublic')}
                    >
                      <Radio
                        value={true}
                        size="md"
                        color="neutral"
                        variant="outlined"
                        label="공개"
                        checked={methods.watch('isPublic')}
                        // checked
                      />
                      <Radio
                        value={false}
                        size="md"
                        color="neutral"
                        variant="outlined"
                        label="비공개"
                        checked={!methods.watch('isPublic')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Stack>
              <TextField
                label="제목"
                fullWidth
                placeholder="제목 입력"
                {...methods.register('title', {
                  required: true,
                })}
              />
            </Stack>
            <Stack>
              <Typography>내용</Typography>
              <Textarea
                placeholder="내용 입력"
                sx={{
                  height: '422px',
                }}
                {...methods.register('content', {
                  required: true,
                })}
              />
            </Stack>
            <Stack>
              <Typography>첨부파일</Typography>
              <Box>
                <FileDragDrop
                  variant="outlined"
                  color="neutral"
                  orientation="horizontal"
                  accept="image/png, image/jpg, image/jpeg, application/pdf,application/vnd.ms-excel,                  
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.ms-powerpoint,
                  application/vnd.openxmlformats-officedocument.presentationml.presentation,
                  application/msword,
                  video/mp4,
                  audio/mpeg,
                  audio/wav,
                  application/x-hwp
                  "
                  fullWidth
                  onSelectFiles={handleSelectFiles}
                />
              </Box>
            </Stack>
            <Stack>
              <Typography>
                첨부파일 목록{' '}
                <Typography color="primary">
                  {currentFileList?.length}
                </Typography>
              </Typography>
              <Box>
                <FileList<Files>
                  variant="outlined"
                  color="neutral"
                  value={currentFileList}
                  labelGetter={(value) => value.originName}
                  fullWidth
                  onDelete={(file) => {
                    if (file) {
                      setCurrentFileList(
                        currentFileList.filter(
                          (item) => item.fileSeq !== file.fileSeq,
                        ),
                      );
                    }
                  }}
                />
              </Box>
            </Stack>
          </Stack>
          <Stack direction="row" alignSelf="end" gap={1}>
            <Button
              component={Link}
              variant="outlined"
              color="neutral"
              size="md"
              href=".."
            >
              목록
            </Button>

            <Button size="md" color="primary" type="submit">
              등록
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Snackbar
        color="danger"
        open={toastInfo.show}
        endDecorator={<SnackbarClose />}
        onClose={() =>
          setToastInfo({
            ...toastInfo,
            show: false,
          })
        }
      >
        {toastInfo.content}
      </Snackbar>
    </FormProvider>
  );
};

const archvicesRegisterPage: CustomRouteObject = {
  path: 'register',
  element: <ArchvicesRegisterPage />,
  handle: {
    getTitle: () => '자료실 등록',
  },
};
export default archvicesRegisterPage;
