import {
  // DefinedUseQueryResult,
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { NameInfo, RealAddress } from 'apis/types/common';
import {
  ElectronicVoteStatusResponse,
  MeetParticipantAgentResponse,
  OnsiteVoteStatusResponse,
  PromotionUserResponse,
} from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

export interface OnsiteVote {
  onsiteVoteSeq: number;
  meetAt: string;
  submissionStartAt: string;
  submissionEndAt: string;
  jibunAddress: string;
  roadAddress: string;
  detailAddress: string;
  attendanceRate: number;
  contactNo: string;
}

export interface ElectronicVote {
  electronicVoteSeq: number;
  senderName: string;
  contactNo: string;
  description: string;
  documentNo: string;
  voteStartAt: string;
  voteEndAt: string;
}

export interface Meet {
  no: number;
  meetSeq: number;
  name: string;
  openType: string;
  meetType: string;
  meetMethod: string;
  meetStatus: string;
  meetAt: string;
  agendaCount: number;
  participantCount: number;
  moverName: string;
  createdAt: string;
  onsiteVote: OnsiteVote;
  electronicVote: ElectronicVote;
}

export interface CertificateMeetData {
  meet: Meet;
  documentStorageEndAt: string;
  documentStorageStatus: string;
  documentStorageExtensionStatus: string;
}

export interface CertificateExtensionBody {
  phoneNo: string;
}

export type CertificateExtensionMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  CertificateExtensionBody
>;

export const useCertificateExtensionMutation = (
  meetSeq: number,
): CertificateExtensionMutation =>
  useMutation({
    mutationFn: async (formData) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/v1/meets/${meetSeq}/certificate-extensions`,
        formData,
      );
      return data;
    },
  });

export interface MeetParticipant {
  meetParticipantSeq: number;
  unionRegisterSeq: number;
  unionRegisterNo: string;
  name: NameInfo;
  birth: string;
  phoneNo: string;
  gender: string;
  realAddress: RealAddress;
  tendency: string;
  position: string;
  shareType: string;
  agent: MeetParticipantAgentResponse;
  electronicVoteStatus: ElectronicVoteStatusResponse;
  onsiteVoteStatus: OnsiteVoteStatusResponse;
  promotionUser: PromotionUserResponse;
}

export interface CertificateParticipant {
  meetParticipant: MeetParticipant;
  certificateIssuanceStatus: string;
  certificateFileSeq?: number;
}

export type CertificationType = 'DIST_CERT' | 'VOTE_CERT' | 'ELEC_CERT';

export type CertificatesParticipantsQuery = UseQueryResult<
  ApiResponseData<CertificateParticipant[]>,
  ApiError
>;

export interface CertificatesParticipantsParams {
  unionRegisterNo: string;
  name: string;
  certificateIssuanceStatus: string;
  searchFrom: string;
  searchTo: string;
}

export const useCertificatesParticipantsQuery = (
  meetSeq: number,
  certificationType: CertificationType,
  params: CertificatesParticipantsParams,
): CertificatesParticipantsQuery =>
  useQuery({
    queryKey: [
      `/v1/meets/${meetSeq}/certificates/participants?certificateType=${certificationType}`,
      params,
    ],
    placeholderData: keepPreviousData,
    // initialData: generateInitRealData(certificatesParticipantsData),
  });

export interface CertificateSummary {
  ableCount: number;
  requestCount: number;
  completeCount: number;
  downloadCount: number;
}

export interface CertificatesSummary {
  distributionCertificate: CertificateSummary;
  voteCertificate: CertificateSummary;
  electronicCertificate: CertificateSummary;
}

export type CertificatesSummaryQuery = UseQueryResult<
  ApiResponseData<CertificatesSummary>,
  ApiError
>;

export const useCertificatesSummaryQuery = (
  meetSeq: number,
): CertificatesSummaryQuery =>
  useQuery({
    queryKey: [`/v1/meets/${meetSeq}/certificates/summary`],
    // initialData: generateInitRealData(certificatesSummaryData),
  });

export interface CertificatesCostCalculate {
  totalCost: number;
}

export type CertificatesCostCalculateMutation = UseMutationResult<
  ApiResponseData<CertificatesCostCalculate>,
  ApiError,
  PostCertificatesBody
>;

export const useCertificatesCostCalculateMutation = (
  meetSeq: number,
): CertificatesCostCalculateMutation =>
  useMutation({
    mutationFn: async (body) => {
      const { data } = await axiosInstance.post<
        ApiResponseData<CertificatesCostCalculate>
      >(`/v1/meets/${meetSeq}/certificates/cost/calculate`, body);

      return data;
    },
  });

export interface PostCertificatesBody {
  certificateType: CertificationType;
  meetParticipantSeqs: number[];
}

export type PostCertificatesMutation = UseMutationResult<
  ApiResponseData,
  ApiError,
  PostCertificatesBody
>;

export const useCertificatesMutation = (
  meetSeq: number,
): PostCertificatesMutation =>
  useMutation({
    mutationFn: async (body: PostCertificatesBody) => {
      const { data } = await axiosInstance.post<ApiResponseData>(
        `/v1/meets/${meetSeq}/certificates`,
        body,
      );

      return data;
    },
  });
