import { GridColDef } from '@wooriga/design-system';

import { GridColumnsHandlers } from 'types/grid';

// export interface CreateGridColumnsProps<H> {
//   handlers: GridColumnsHandlers<H>;
//   columns: (handlers: GridColumnsHandlers<H>) => GridColDef[];
// }

export type CreateGridColumns<H> = (
  handlers: GridColumnsHandlers<H>,
) => GridColDef[];

export type CreateGridHandlers<H> = GridColumnsHandlers<H>;

const createGridColumns = <H>(
  columns: CreateGridColumns<H>,
  handlers: CreateGridHandlers<H>,
) => {
  return { columns: columns(handlers), handlers };
};

export default createGridColumns;
