import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  RouteObject as DefaultRouteObject,
  RouterProvider,
  RouterProviderProps,
  createBrowserRouter,
} from 'react-router-dom';

import { RouteObject } from 'common-providers/providers/ReactRouterProvider/types';

const recursivelyExtendRoutes = (
  routes: RouteObject[],
  queryClient: QueryClient,
): DefaultRouteObject[] => {
  return routes.map(({ loader, action, lazy, children, ...other }) => {
    const extendedRoute = {
      ...other,
      loader: loader?.(queryClient),
      action: action?.(queryClient),
      lazy: lazy
        ? async () => {
            const { loader, action, ...other } = (await lazy?.()) || {};
            return {
              loader: (
                loader as unknown as (
                  queryClient: QueryClient,
                ) => DefaultRouteObject['loader'] | undefined
              )?.(queryClient),
              action: (
                action as unknown as (
                  queryClient: QueryClient,
                ) => DefaultRouteObject['action'] | undefined
              )?.(queryClient),
              ...other,
            };
          }
        : undefined,
    } as DefaultRouteObject;

    if (children) {
      extendedRoute.children = recursivelyExtendRoutes(children, queryClient);
    }

    return extendedRoute;
  });
};

export interface ReactRouterProviderProps<T extends RouteObject>
  extends Omit<RouterProviderProps, 'router'> {
  routes: T[];
}

const ReactRouterProvider = <T extends RouteObject>({
  routes,
  ...other
}: ReactRouterProviderProps<T>) => {
  const queryClient = useQueryClient();

  const extendedRoutes = useMemo(
    () => recursivelyExtendRoutes(routes, queryClient),
    [routes, queryClient],
  );
  const router = createBrowserRouter(extendedRoutes, {
    basename: '/',
  });

  return <RouterProvider router={router} {...other} />;
};

export default ReactRouterProvider;
