import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { MeetMethods, MeetStatus } from 'apis/types/meet';
import MeetPasswordConfirmModal from 'components/modals/MeetPasswordConfirmModal';
import { DataGridValues } from 'components/pages/meet-management/history/detail/common/ResultGrid';
import DuplicateChart from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/DuplicateChart';
import ElectronicResultModal from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ElectronicResultModal';
import ResultContent from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ResultContent';
import ResultCallout from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ResultContent/ResultCallout';
import { combineResults } from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/utils';
import VoteResultEnterModal from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/VoteResultEnterModal';
import { MeetMethodProps } from 'components/pages/meet-management/history/detail/type';
import { useReveal } from 'hooks/useReveal';
import {
  CompleteMeetBody,
  ElectronicVoteSendStatusCountQuery,
  MeetCandidateInfo,
  MeetsAgendasQuery,
  MeetsDetailQuery,
  MeetsOnsiteVoteCount,
  MeetsPasswordVerifyMutateFunction,
  MeetsRealtimeStatusCountQuery,
  MeetsVoteResultQuery,
  MeetsVoteResultSummaryQuery,
  useElectronicVoteResultQuery,
  useVoteAggregatesQuery,
  VoteResultBody,
} from 'lim/generalMeetingHistoryDetail/apis';

import VoterEditModal from './VoterEditModal';

const schema = yup.object().shape({
  results: yup.array().of(
    yup.object().shape({
      agendaSeq: yup.number().required(),
      order: yup.number().required(),
      voteType: yup.string().oneOf(['MONO', 'MULTI']).required(),
      voteForm: yup.string().oneOf(['AGAINST', 'SELECT']).required(),
      selectCount: yup.number().required(),
      candidates: yup
        .array()
        .of(
          yup.object().shape({
            candidateNo: yup.number().required(),
            isPassed: yup.boolean().nullable(),
          }),
        )
        .required()
        .when('voteForm', {
          is: (value: string) => value === 'AGAINST',
          then: (schema) =>
            schema.test('is-passed', function (value) {
              const { order } = this.parent;
              return (
                value.filter(({ isPassed }) => isPassed === null).length ===
                  0 ||
                this.createError({
                  path: this.path,
                  message: `제 ${order}호 안건 결과 미입력`,
                })
              );
            }),
          otherwise: (schema) =>
            schema.when('voteType', {
              is: (value: string) => value === 'MONO',
              then: (schema) =>
                schema.test('is-passed', function (value) {
                  const { order } = this.parent;
                  return (
                    value.filter(({ isPassed }) => isPassed).length === 1 ||
                    this.createError({
                      path: this.path,
                      message: `제 ${order}호 안건 결과 이상`,
                    })
                  );
                }),
              otherwise: (schema) =>
                schema.test('is-passed', function (value) {
                  const { order, selectCount } = this.parent;

                  return (
                    value.filter(({ isPassed }) => isPassed).length ===
                      selectCount ||
                    this.createError({
                      path: this.path,
                      message: `제 ${order}호 안건 결과 이상`,
                    })
                  );
                }),
            }),
        }),
    }),
  ),
});

interface Onsite {
  meetsRealtimeStatusCountQuery: MeetsRealtimeStatusCountQuery;
  meetsVoteResultSummaryQuery: MeetsVoteResultSummaryQuery;
  meetsAgendasQuery: MeetsAgendasQuery;
  meetsOnsiteVoteCount: MeetsOnsiteVoteCount;

  onEnterResult?: (formData: VoteResultBody) => void;
  onMeetReset?: () => void;
  passwordVerifyMutate?: MeetsPasswordVerifyMutateFunction;
}

interface Electronic {
  electronicVoteSendStatusCountQuery: ElectronicVoteSendStatusCountQuery;
}

export type TallyResultProps<MeetMethod extends MeetMethods> = MeetMethodProps<
  MeetMethod,
  Electronic,
  Onsite
> & {
  meetMethod: MeetMethod;
  // ------------------------ 공통 ------------------------
  meetsVoteResultQuery: MeetsVoteResultQuery;
  meetsDetailQuery: MeetsDetailQuery;
  onMeetComplete: (formData: CompleteMeetBody) => void;
};

const TallyResultTab = <MeetMethod extends MeetMethods>({
  meetMethod,
  // ------------------------ 공통 ------------------------
  meetsAgendasQuery,
  meetsVoteResultSummaryQuery,
  meetsVoteResultQuery,
  meetsDetailQuery,
  // ------------------------ 전자 ------------------------
  // electronicVoteSendStatusCountQuery,
  // ------------------------ 서면 ------------------------
  // meetsRealtimeStatusCountQuery,
  meetsOnsiteVoteCount,

  // event
  onMeetComplete,
  onMeetReset,
  onEnterResult,
}: TallyResultProps<MeetMethod>) => {
  const isOnsite =
    meetMethod === 'ONSITE' && meetsVoteResultSummaryQuery && meetsAgendasQuery;
  const isOverall =
    meetMethod === 'ONSITE_ELECTRONIC' &&
    meetsVoteResultSummaryQuery &&
    meetsAgendasQuery;

  const params = useParams();

  const { data } = meetsVoteResultQuery;
  const { openReveal } = useReveal();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const meetId = Number(params.meetSeq);

  const { data: electonicVoteResult } = useElectronicVoteResultQuery(meetId);
  const { data: voteAggregateData } = useVoteAggregatesQuery(meetId);

  const summaryData = meetsVoteResultSummaryQuery?.data;
  const meetStatus = meetsDetailQuery.data?.data.meetStatus || 'BEFORE';

  const [showOnsiteResultEnterModal, setShowOnsiteResultEnterModal] =
    useState(false);
  const [
    showWrittenSubmitResultEnterModal,
    setShowWrittenSubmitResultEnterModal,
  ] = useState(false);
  const [showElectronicResultModal, setShowElectronicResultModal] =
    useState(false);
  const [showVoterEditModal, setShowVoterEditModal] = useState(false);

  const [writtenSubmitDataGridValue, setWrittenSubmitDataGridValue] =
    useState<DataGridValues>({});

  const isMock = useMemo(
    () => meetsDetailQuery.data?.data.isMock || false,
    [meetsDetailQuery.data],
  );

  const totalCount = useMemo(
    () => meetsDetailQuery.data?.data.participantCount || 0,
    [meetsDetailQuery.data],
  );

  const result = useMemo(() => data?.data || [], [data?.data]);

  const handleShowMeetCompleteModal = (meetStatus: MeetStatus) => {
    if (isMock && meetStatus === 'COMPLETE') {
      return handleSwitchRealMeet();
    }

    const shouldEnterResult =
      meetStatus === 'PROCESS' && (isOnsite || isOverall);
    if (shouldEnterResult) {
      return openReveal(({ open, onExit }) => (
        <MeetPasswordConfirmModal
          meetSeq={meetId}
          title="개표 결과 입력 주의 사항"
          detail={
            <>
              다음으로 넘어가면 총회 진행 상태가 ‘집계중’으로 변경됩니다.
              <br />
              ’집계중’ 상태에서는 총회관리시스템에 읽기 권한만 주어집니다.
              <br />
              개표 결과를 입력하기 전에 수정이 필요하면 취소 버튼을 눌러,
              <br />
              총회관리시스템에서 추가 작업을 완료한 후 개표 결과를 입력해
              주세요.
              <br />
              <br />
              개표 결과 입력 후에는 다시 재입력을 통해 ‘진행중’ 상태로 돌아갈 수
              있습니다.
              <br />
              <br />
              개표 결과는 총회 종료 전까지 수정할 수 있으며, 입력이 완료되면
              안건의 의결 결과를 선택하여 총회를 종료할 수 있습니다.
            </>
          }
          open={open}
          onClose={onExit}
          onSuccess={() => {
            if (isOverall) {
              return setShowElectronicResultModal(true);
            }
            setShowWrittenSubmitResultEnterModal(true);
          }}
        />
      ));
    }

    handleSubmit(
      (data) => {
        const approvalList: MeetCandidateInfo[] = [];
        const rejectionList: MeetCandidateInfo[] = [];

        data.results?.forEach(({ agendaSeq, candidates }) => {
          candidates?.forEach(({ candidateNo, isPassed }) => {
            const listInfo = {
              agendaSeq,
              candidateNo,
            };
            isPassed
              ? approvalList.push(listInfo)
              : rejectionList.push(listInfo);
          });
        });

        openReveal(({ open, onExit }) => (
          <MeetPasswordConfirmModal
            meetSeq={meetId}
            title="총회 종료 주의사항"
            detail={
              <>
                총회가 종료되면 더 이상 수정이 불가합니다.
                <br />
                <br />
                총회 종료 후에는 총회관리시스템과 조합운영관리시스템(OMS)에서
                해당 총회에 대한 모든 정보의 입력 및 수정이 불가능해집니다.
                시점이 확인된 모든 전자투표 기록과 개표 결과는
                공인전자문서센터에 안전하게 보관되며, 법적 효력을 위해 보관된
                문서는 수정할 수 없습니다.
                <br />
                <br />
                수정이 필요하시면 재입력 버튼을 눌러 총회관리시스템에서 추가
                작업을 완료한 후 다시 개표 결과를 입력해 주세요.
              </>
            }
            open={open}
            onClose={onExit}
            onSuccess={() => onMeetComplete({ approvalList, rejectionList })}
          />
        ));
      },
      (errors) => {
        if (errors.results) {
          const message = errors.results.find?.(
            (result) => result && result.candidates?.message,
          )?.candidates?.message;

          message &&
            openReveal('alert', {
              detail: `의결 결과가 정상적으로 입력되지 않았습니다.\n${message}`,
            });
        }
      },
    )();
  };

  const handleSwitchRealMeet = () => {
    setShowVoterEditModal(true);
  };

  const handleElectronicResultSubmit = () => {
    // 무조건 서면 집계 입력 모달
    setShowWrittenSubmitResultEnterModal(true);
  };

  const handleWrittenSubmitResultEnterSubmit = (
    dataGridValue: DataGridValues,
  ) => {
    // 다음 단게 무조건 현장 투표 집계 입력 모달
    setShowOnsiteResultEnterModal(true);
    setWrittenSubmitDataGridValue(dataGridValue);
  };

  const handleOnsiteResultEnterSubmit = (dataGridValue: DataGridValues) => {
    if (summaryData?.data) {
      const decision = {
        onsiteCount: summaryData.data.onsiteVoteCount,
        writtenSubmissionCount: summaryData.data.writtenSubmissionCount,
      };

      const combinedResult = combineResults(
        writtenSubmitDataGridValue,
        dataGridValue,
      );

      const agendas = combinedResult.map((result) => {
        const find = data?.data?.find(
          (data) => data.agendaSeq === result.agendaSeq,
        );
        return {
          ...result,
          order: find?.order || 1,
        };
      });

      const formData = {
        decision,
        agendas,
      };

      openReveal('confirm', {
        message: '개표 결과 입력을 완료하시겠습니까?',
        detail: '입력한 내용이 집계되어 반영됩니다.\n진행하시겠습니까?',
        onSubmit: (result) => {
          if (result === 'Cancel') return;

          setShowOnsiteResultEnterModal(false);
          setShowWrittenSubmitResultEnterModal(false);
          onEnterResult?.(formData);
        },
      });
    }
  };

  const handleStatusReset = () => {
    openReveal(({ open, onExit }) => (
      <MeetPasswordConfirmModal
        meetSeq={meetId}
        title="개표 결과 재입력 주의사항"
        detail={
          <>
            확인을 선택하면 총회 진행상태가 진행중으로 변경됩니다.
            <br />
            <br />
            단, 이미 생성된 총회 참석자 연명부는 삭제되며, 이후 추가 작업을
            완료한 후 개표 결과 입력과 연명부 다운로드를 진행해 주세요.
            <br />
            <br />
            집계된 개표 결과는 초기화되며, 개표 결과를 다시 입력하실 수
            있습니다.
          </>
        }
        open={open}
        onClose={onExit}
        onSuccess={() => {
          onMeetReset?.();
        }}
      />
    ));
  };

  return (
    <Stack gap={3}>
      <ResultCallout
        duplicateVoteCount={voteAggregateData?.data.duplicateVoteCount}
        hasRows={!!electonicVoteResult?.data.length}
        meetMethod={meetMethod}
        meetStatus={meetStatus}
      />

      <DuplicateChart
        data={meetStatus !== 'BEFORE' ? voteAggregateData?.data : undefined}
      />

      <ResultContent
        control={control}
        isMock={isMock}
        meetMethod={meetMethod}
        meetStatus={meetStatus}
        meetsDetailQuery={meetsDetailQuery}
        result={result}
        onSubmit={handleShowMeetCompleteModal}
        onReset={handleStatusReset}
      />

      {isOverall && (
        <ElectronicResultModal
          voterCount={totalCount}
          registeredCount={summaryData?.data.electronicVoteCount || 0}
          open={showElectronicResultModal}
          onClose={setShowElectronicResultModal}
          onSubmit={handleElectronicResultSubmit}
        />
      )}
      {(isOnsite || isOverall) && (
        <>
          <VoteResultEnterModal
            type="writtenSubmissionResult"
            meetsAgendasQuery={meetsAgendasQuery}
            voterCount={totalCount}
            registeredCount={
              meetsOnsiteVoteCount?.data?.data.writtenSubmission || 0
            }
            open={showWrittenSubmitResultEnterModal}
            onClose={setShowWrittenSubmitResultEnterModal}
            onSubmit={handleWrittenSubmitResultEnterSubmit}
          />

          <VoteResultEnterModal
            type="onsiteResult"
            meetsAgendasQuery={meetsAgendasQuery}
            voterCount={totalCount}
            registeredCount={meetsOnsiteVoteCount?.data?.data.onsiteVote || 0}
            open={showOnsiteResultEnterModal}
            onClose={setShowOnsiteResultEnterModal}
            onSubmit={handleOnsiteResultEnterSubmit}
          />
        </>
      )}
      <VoterEditModal
        open={showVoterEditModal}
        onClose={setShowVoterEditModal}
      />
    </Stack>
  );
};

export default TallyResultTab;
