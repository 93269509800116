import { MeetMethods, MeetStatus } from 'apis/types/meet';

export type MeetMethodResultKeys =
  | 'onsiteResult'
  | 'electronicResult'
  | 'writtenSubmissionResult';

export const generateMeetMethodKey = (key: MeetMethods | string) => {
  switch (key) {
    case 'ONSITE':
      return 'onsiteVoteStatus';
    case 'ELECTRONIC':
      return 'electronicVoteStatus';
    default:
      return 'onsiteVoteStatus';
  }
};

export const generateMeetStatus = (meetStatus: MeetStatus) => {
  switch (meetStatus) {
    case 'BEFORE':
      return '진행전';
    case 'PROCESS':
      return '진행중';
    case 'COUNTING':
      return '집계중';
    case 'COMPLETE':
      return '종료';
    default:
      return '취소';
  }
};

export const generateMeetMethodName = (
  meetMethodResultKey: MeetMethodResultKeys,
) => {
  switch (meetMethodResultKey) {
    case 'onsiteResult':
      return '서면 제출';
    case 'electronicResult':
      return '전자투표';
    case 'writtenSubmissionResult':
      return '현장 투표';

    default:
      return '';
  }
};

export function calculateTimeMinuteGap(meetTime?: string): string {
  if (!meetTime) return '';

  const meetDate = new Date(meetTime);
  const now = new Date();
  const diffMs = now.getTime() - meetDate.getTime(); // 밀리초 단위 차이

  if (diffMs < 0) return ''; // 미래 시간인 경우 빈 문자열 반환

  const diffSeconds = Math.floor(diffMs / 1000); // 초 단위 차이
  const diffMinutes = Math.floor(diffSeconds / 60); // 분 단위 차이
  const diffHours = Math.floor(diffMinutes / 60); // 시간 단위 차이

  const remainingMinutes = diffMinutes % 60; // 남은 분
  const remainingSeconds = diffSeconds % 60; // 남은 초

  // 항상 두 자리로 표시
  const hours = diffHours.toString().padStart(2, '0');
  const minutes = remainingMinutes.toString().padStart(2, '0');
  const seconds = remainingSeconds.toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

export function calculateTimeDifference(
  timeOne: string,
  timeTwo: string,
): string {
  if (!timeOne || !timeTwo) return '';

  const dateOne = new Date(timeOne);
  const dateTwo = new Date(timeTwo);

  const diffMs = Math.abs(dateTwo.getTime() - dateOne.getTime()); // 밀리초 단위 절대값 차이

  const diffSeconds = Math.floor(diffMs / 1000); // 초 단위 차이
  const diffMinutes = Math.floor(diffSeconds / 60); // 분 단위 차이
  const diffHours = Math.floor(diffMinutes / 60); // 시간 단위 차이

  const remainingMinutes = diffMinutes % 60; // 남은 분
  const remainingSeconds = diffSeconds % 60; // 남은 초

  // 항상 두 자리로 표시
  const hours = diffHours.toString().padStart(2, '0');
  const minutes = remainingMinutes.toString().padStart(2, '0');
  const seconds = remainingSeconds.toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}
