import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import {
  Caller,
  Message,
  MessageBalance,
  MessageReceiver,
  MessageRegister,
  MessageSent,
} from 'apis/types/message';
import { ApiError, ApiResponseData } from 'types/api';

/**
 * GET
 */

// 문자 발송 대상자(조합원) 목록 조회
export type MessageRegistersReturnData = MessageRegister[];
export interface MessageRegistersParams {
  unionSeq: number;
  unionRegisterGroupSeq?: number;
  consentStatus: string;
}
type UseMessageRegistersQueryResult = UseQueryResult<
  ApiResponseData<MessageRegistersReturnData>,
  ApiError
>;

export const useMessageRegistersQuery = (
  params: MessageRegistersParams,
): UseMessageRegistersQueryResult => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/sms/recipients`, restParams],
  });
};

// 문자 내역 목록 조회
export type MessagesRequest = Message[];
export interface MessagesParams {
  unionSeq: number;
  outgoingPhoneNo: string;
  requestedAtFrom: string;
  requestedAtTo: string;
  sentAtFrom: string;
  sentAtTo: string;
  completedAtFrom: string;
  completedAtTo: string;
}
type UseMessagesQueryResult = UseQueryResult<
  ApiResponseData<MessagesRequest, true>,
  ApiError
>;

export const useMessagesQuery = (
  params: MessagesParams,
): UseMessagesQueryResult => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/sms`, restParams],
  });
};

// 문자 내역 상세 조회
export type MessageRequest = Message;
export interface MessageParams {
  unionSeq: number;
  smsId: string;
}
type UseMessageQueryResult = UseQueryResult<
  ApiResponseData<MessageRequest>,
  ApiError
>;

export const useMessageQuery = (
  params: MessageParams,
): UseMessageQueryResult => {
  const { unionSeq, smsId } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/sms/${smsId}`],
  });
};

// 문자 수취인 목록 조회
export type MessageReceiversReturnData = MessageReceiver[];
export interface MessageReceiversParams {
  unionSeq: number;
  smsId: string;
  phoneNo: string;
}
type UseMessageReceiversQueryResult = UseQueryResult<
  ApiResponseData<MessageReceiversReturnData>,
  ApiError
>;

export const useMessageReceiversQuery = (
  params: MessageReceiversParams,
): UseMessageReceiversQueryResult => {
  const { unionSeq, smsId, ...restParams } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/sms/${smsId}/recipients`, restParams],
  });
};

// 보낸 문자함 목록 조회
export type MessageSentsRequest = MessageSent[];
export interface MessageSentsParams {
  unionSeq: number;
  page: number;
  size?: number;
  keyword: string;
  searchFrom: string;
  searchTo: string;
}
type UseMessageSentsQueryResult = UseQueryResult<
  ApiResponseData<MessageSentsRequest>,
  ApiError
>;

export const useMessageSentsQuery = (
  params: MessageSentsParams,
): UseMessageSentsQueryResult => {
  const { unionSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/sent-sms`, restParams],
  });
};

// 보낸 문자함 상세 조회
export type MessageSentRequest = MessageSent;
export interface MessageSentParams {
  unionSeq: number;
  smsId: string;
}

type UseMessageSentQueryResult = UseQueryResult<
  ApiResponseData<MessageSentRequest, true>,
  ApiError
>;

export const useMessageSentQuery = (
  params: MessageSentParams,
): UseMessageSentQueryResult => {
  const { unionSeq, smsId } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/sent-sms/${smsId}`],
  });
};

type UseMessageSentMutationResult = UseMutationResult<
  ApiResponseData<MessageSentRequest>,
  ApiError,
  MessageSentParams
>;

export const useMessageSentMutation = (): UseMessageSentMutationResult =>
  useMutation({
    mutationFn: async (params) => {
      const { unionSeq, smsId } = params;

      const { data } = await axiosInstance.get(
        `/v1/unions/${unionSeq}/sent-sms/${smsId}`,
      );

      return data;
    },
  });

// 발신번호 목록 조회
export type CallersRequest = Caller[];
export interface CallersParams {
  unionSeq: number;
}
type UseCallersQueryResult = UseQueryResult<
  ApiResponseData<CallersRequest>,
  ApiError
>;

export const useCallersQuery = (
  params: CallersParams,
): UseCallersQueryResult => {
  const { unionSeq } = params;

  return useQuery({
    queryKey: [`/v1/unions/${unionSeq}/sms-callers`],
  });
};

/**
 * POST
 */

// 문자 발송
export type CreateMessageRequest = CreateMessageParams & CreateMessageBody;
export interface CreateMessageParams {
  unionSeq: number;
}
export interface CreateMessageBody {
  title: string;
  message: string;
  outgoingPhoneNo: string;
  sendType: string;
  scheduledAt: string;
  recipients: {
    unionRegisterSeq: number;
    name: string;
    phoneNo: string;
  }[];
  files: File[];
}
type UseCreateMessageMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreateMessageRequest
>;

export const useCreateMessageMutation = (): UseCreateMessageMutationResult =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, recipients, files, ...restFormData } = request;
      const formData = new FormData();

      Object.entries(restFormData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      files.forEach((file) => {
        formData.append(
          'files',
          new Blob([file], { type: 'application/json' }),
        );
      });

      formData.append(
        'recipients',
        new Blob([JSON.stringify(recipients)], {
          type: 'application/json',
        }),
      );

      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/sms`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          transformRequest: [() => formData],
        },
      );

      return data;
    },
  });

// 문자 발송 취소
export type CreateMessageCancelRequest = CreateMessageCancelParams &
  CreateMessageCancelBody;
export interface CreateMessageCancelParams {
  unionSeq: number;
}
export interface CreateMessageCancelBody {
  smsIds: string[];
}
type UseCreateMessageCancelMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreateMessageCancelRequest
>;

export const useCreateMessageCancelMutation =
  (): UseCreateMessageCancelMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, ...body } = request;
        const { data } = await axiosInstance.post(
          `/v1/unions/${unionSeq}/sms/cancel`,
          body,
        );

        return data;
      },
    });

// 문자 발송 비용 조회
export type CreateMessageBalanceReturnData = MessageBalance;
export type CreateMessageBalanceRequest = CreateMessageBalanceParams &
  CreateMessageBody;
export interface CreateMessageBalanceParams {
  unionSeq: number;
}
type UseCreateMessageBalanceMutationResult = UseMutationResult<
  ApiResponseData<CreateMessageBalanceReturnData>,
  ApiError,
  CreateMessageBalanceRequest
>;

export const useCreateMessageBalanceMutation =
  (): UseCreateMessageBalanceMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, files, recipients, ...restFormData } = request;
        const formData = new FormData();

        Object.entries(restFormData).forEach(([key, value]) => {
          formData.append(key, value);
        });

        files.forEach((file) => {
          formData.append(
            'files',
            new Blob([file], { type: 'application/json' }),
          );
        });

        formData.append(
          'recipients',
          new Blob([JSON.stringify(recipients)], {
            type: 'application/json',
          }),
        );

        const { data } = await axiosInstance.post(
          `/v1/unions/${unionSeq}/sms/cost/calculate`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            transformRequest: [() => formData],
          },
        );

        return data;
      },
    });

// 발신번호 등록
export type CreateCallerRequest = CreateCallerParams & CreateCallerBody;
export interface CreateCallerParams {
  unionSeq: number;
}
export interface CreateCallerBody {
  outgoingPhoneNo: string;
  name: string;
  phoneNo: string;
  communicationFileSeq: number;
  attorneyFileSeq: number;
  businessRegistrationFileSeq: number;
  employmentCertificateFileSeq: number;
  identityFileSeq: number;
}
type UseCreateCallerMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  CreateCallerRequest
>;

export const useCreateCallerMutation = (): UseCreateCallerMutationResult =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/sms-callers`,
        body,
      );

      return data;
    },
  });

/**
 * DELETE
 */

// 보낸 문자함 항목 삭제
export type DeleteMessageSentRequest = DeleteMessageSentParams;
export interface DeleteMessageSentParams {
  unionSeq: number;
  smsId: string;
}
type UseDeleteMessageSentMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  DeleteMessageSentRequest
>;

export const useDeleteMessageSentMutation =
  (): UseDeleteMessageSentMutationResult =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq, smsId } = request;
        const { data } = await axiosInstance.delete(
          `/v1/unions/${unionSeq}/sent-sms/${smsId}`,
        );

        return data;
      },
    });

// 발신번호 삭제
export type DeleteCallerRequest = DeleteCallerParams & DeleteCallerBody;
export interface DeleteCallerParams {
  unionSeq: number;
}
export interface DeleteCallerBody {
  smsCallerSeqs: number[];
}
type UseDeleteCallerMutationResult = UseMutationResult<
  ApiResponseData,
  ApiError,
  DeleteCallerRequest
>;

export const useDeleteCallerMutation = (): UseDeleteCallerMutationResult =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq, ...body } = request;
      const { data } = await axiosInstance.delete(
        `/v1/unions/${unionSeq}/sms-callers`,
        { data: body },
      );

      return data;
    },
  });
