import { useForwardRef } from '@wooriga/common-hooks';
import { Box, Stack } from '@wooriga/design-system';
import { ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';

export interface DefaultPlayerProps {
  onReady?: (player: Player) => void;
  options?: {
    autoplay?: boolean;
    controls?: boolean;
    responsive?: boolean;
    fluid?: boolean;
    sources?: { src: string; type: string }[];
  };
}

const DefaultPlayer = (
  props: DefaultPlayerProps,
  ref: ForwardedRef<Player>,
) => {
  const videoRef = useRef<HTMLDivElement>(null); // 비디오 엘리먼트 레퍼런스
  const playerRef = useForwardRef<Player>(ref); // 플레이어 레퍼런스
  const { options, onReady } = props;

  // window 객체에서 IVSPlayer, registerIVSTech, registerIVSQualityPlugin 가져오기
  const { IVSPlayer, registerIVSTech, registerIVSQualityPlugin, videojs } =
    window;

  // useEffect 훅: IVSPlayer 및 Video.js 초기화
  useEffect(() => {
    // IVSPlayer, registerIVSTech, videojs 체크
    if (!IVSPlayer) {
      console.log('IVS 플레이어 또는 비디오.js가 로드되지 않았습니다.');
      return;
    }

    if (!IVSPlayer || !registerIVSTech || !videojs) {
      console.log('IVS 플레이어 또는 비디오.js가 로드되지 않았습니다.');
      return;
    }

    // IVSPlayer가 지원되지 않는 경우
    const { isPlayerSupported } = IVSPlayer;
    if (!isPlayerSupported) {
      console.log('IVS 플레이어가 지원되지 않습니다.');
      return;
    }

    // IVSPlayer 및 품질 플러그인 등록
    registerIVSTech(videojs);
    registerIVSQualityPlugin(videojs);

    // 비디오 엘리먼트가 없는 경우 초기화 중단
    if (!videoRef.current) return;

    // 플레이어가 없을 경우 초기화
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered'); // 큰 재생 버튼 추가
      videoElement.id = 'amazon-ivs-videojs'; // ID 설정

      // // 초기화전에 dom 에 추가
      // videoRef.current?.appendChild(videoElement);

      // 비디오 옵션 설정
      const videoOptions = {
        ...options,
        techOrder: ['AmazonIVS'], // Amazon IVS를 첫 번째 기술로 설정
      };

      // 비디오.js 플레이어 초기화
      const player = (playerRef.current = videojs(
        videoElement,
        videoOptions,
        () => {
          onReady?.(player); // 플레이어가 준비되면 콜백 실행

          // IVS 플레이어와 메타데이터 이벤트 리스너 설정
          const ivsPlayer = player.getIVSPlayer();
          const PlayerEventType = player.getIVSEvents().PlayerEventType;

          // TEXT_METADATA_CUE 이벤트 감지 및 로깅
          ivsPlayer.addEventListener(
            PlayerEventType.TEXT_METADATA_CUE,
            (cue) => {
              const metadataText = cue.text;
              const position = ivsPlayer.getPosition().toFixed(2);
              console.log(
                `메타데이터 감지: "${metadataText}", 재생 시작 후 ${position}s에 감지되었습니다.`,
              );
            },
          );
        },
      ));

      // 비디오 엘리먼트를 DOM에 추가
      videoRef.current?.appendChild(videoElement);
    } else {
      // 이미 플레이어가 있을 경우 옵션 업데이트
      const player = playerRef.current;

      options?.sources && player.src(options.sources);
      options?.autoplay && player.autoplay(options.autoplay);
    }
  }, [
    IVSPlayer,
    registerIVSTech,
    registerIVSQualityPlugin,
    videojs,
    options,
    onReady,
    playerRef,
  ]);

  // 컴포넌트가 언마운트될 때 플레이어 정리
  // useEffect(() => {
  //   return () => {};
  // }, [playerRef]);
  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;

      if (options?.sources) {
        player.src(options.sources);
      }

      if (options?.autoplay !== undefined) {
        player.autoplay(options.autoplay);
      }

      if (options?.controls !== undefined) {
        player.controls(options.controls);
      }
    }
  }, [options]);

  return (
    <Stack display="flex" flexDirection="row">
      <Box width={534}>
        <Stack
          sx={{
            border: '1px solid #000',
            padding: '2px',
          }}
        >
          <div data-vjs-player>
            <div ref={videoRef} />
            {/* 비디오를 렌더링할 div */}
          </div>
        </Stack>
      </Box>
    </Stack>
  );
};

export default forwardRef<Player, DefaultPlayerProps>(DefaultPlayer);
