import { format, FormatDateOptions } from 'date-fns';
import { ko } from 'date-fns/locale/ko';

const DAY_TO_SECOND = 1000 * 60 * 60 * 24;
const MONTH_TO_SECOND = 1000 * 60 * 60 * 24 * 30;
const YEAR_TO_SECOND = 1000 * 60 * 60 * 24 * 30 * 12;

export const formatDateFns = <T extends Date>(
  date: string | number | undefined | null | Date | T,
  formatStr = "yyyy-MM-dd'T'HH:mm:ss",
  options?: Omit<FormatDateOptions, 'locale'>,
) => formatDateTime(date, formatStr, options);

export const formatDateTime = <T extends Date>(
  date: string | number | undefined | null | Date | T,
  formatStr = 'yyyy-MM-dd HH:mm:ss',
  options?: Omit<FormatDateOptions, 'locale'>,
) => {
  if (
    date === undefined ||
    date === null ||
    (typeof date === 'string' && date.trim() === '')
  ) {
    return date || '';
  }

  return format(date, formatStr, { ...options, locale: ko });
};

export const formatDate = <T extends Date>(
  date: string | number | undefined | null | Date | T,
  formatStr = 'yyyy-MM-dd',
  options?: Omit<FormatDateOptions, 'locale'>,
) => formatDateTime(date, formatStr, options);

// 활용성 높이는 개선이 필요
export const formatDateDistanceHangul = <T extends Date>(
  startDate: string | T,
  endDate: string | T,
) => {
  if (!startDate || !endDate) {
    return '';
  }

  const result = [];

  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  const distance = end - start;

  const leftYears = Math.floor(distance / YEAR_TO_SECOND);
  const leftMonths = Math.floor((distance % YEAR_TO_SECOND) / MONTH_TO_SECOND);
  const leftDays = Math.floor((distance % YEAR_TO_SECOND) / DAY_TO_SECOND);

  if (leftYears > 0) {
    result.push(`${leftYears}년`);
  }

  if (leftMonths > 0) {
    result.push(`${leftMonths}개월`);
  }

  if (leftDays >= 0 && leftDays < 30 && leftMonths === 0 && leftYears === 0) {
    result.push(`1개월 미만`);
  }

  if (leftDays < 0) {
    result.push('');
  }

  return result.join(' ').trim();
};
