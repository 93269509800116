import { Stack } from '@wooriga/design-system';
import { useMemo } from 'react';

import { useAgendaQuery } from 'apis/meet/live/api';
import Agenda from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/components/Agenda';

export interface ConfigPanelProps {
  meetSeq: number;
}

// const test = [1, 2, 3, 4, 5];
const AgendaPanel = ({ meetSeq }: ConfigPanelProps) => {
  const { data: agendaQuery } = useAgendaQuery(meetSeq);
  const agendaList = useMemo(
    () => agendaQuery?.data || [],
    [agendaQuery?.data],
  );
  return (
    <>
      <Stack>
        {agendaList.map((agendaData, index) => {
          return <Agenda agendaData={agendaData} key={index} />;
        })}
      </Stack>
    </>
  );
};

export default AgendaPanel;
