import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import {
  Button,
  DataGrid,
  DateRangePicker,
  Grid,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  TextField,
  Typography,
  useGridUtils,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  MessagesParams,
  useCreateMessageCancelMutation,
  useMessagesQuery,
} from 'apis/message/apis';
import { MESSAGE_TABS } from 'apis/message/constants';
import { MESSAGES_COLUMNS } from 'apis/message/fixtures';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';
import messageRecordDetailPage from 'pages/main/union-management/messages/records/[smsId]';
import { CustomRouteObject } from 'types/route';
import { commaizeNumber } from 'utils/format';

const DEFAULT_SEARCH_VALUES = {
  outgoingPhoneNo: '',
  requestedAtFromTo: '',
  sentAtFromTo: '',
  completedAtFromTo: '',
};

const DEFAULT_SEARCH_PARAMS = {
  outgoingPhoneNo: '',
  requestedAtFrom: '',
  requestedAtTo: '',
  sentAtFrom: '',
  sentAtTo: '',
  completedAtFrom: '',
  completedAtTo: '',
};

interface SearchParamsValues {
  outgoingPhoneNo: string;
  requestedAtFromTo: string;
  sentAtFromTo: string;
  completedAtFromTo: string;
}

const MessageRecordPage = () => {
  const params = useParams();
  const { pageContext } = useLayoutContext();

  const { unionSeq } = params;
  const { unionBasename } = pageContext || {};

  const [searchParams, setSearchParams] = useState<MessagesParams>({
    unionSeq: Number(unionSeq),
    ...DEFAULT_SEARCH_PARAMS,
  });

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const {
    data: records,
    isPending,
    isError,
    error,
    refetch: refetchRecords,
  } = useMessagesQuery(searchParams);

  const { mutate: cancelMessages } = useCreateMessageCancelMutation();

  const { snackbar, confirmDialog } = useFeedback();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'message-records',
    activeSaveSnapshot: false,
  });

  const { selectedCount, isRowSelected } = useMemo(
    () => ({
      selectedCount: rowSelectionModel.length,
      isRowSelected: !!(rowSelectionModel.length > 0),
    }),
    [rowSelectionModel],
  );

  const handleSearchParams = (values: SearchParamsValues) => {
    const {
      requestedAtFromTo: [requestedAtFrom, requestedAtTo],
      sentAtFromTo: [sentAtFrom, sentAtTo],
      completedAtFromTo: [completedAtFrom, completedAtTo],
      ...otherValues
    } = values;

    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...otherValues,
      requestedAtFrom,
      requestedAtTo,
      sentAtFrom,
      sentAtTo,
      completedAtFrom,
      completedAtTo,
    }));
  };

  const handleSearchReset = () => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleSubmitCancellation = () => {
    confirmDialog(
      `선택하신 항목 총 ${selectedCount}개 문자 예약 전송을 취소하시겠습니까?`,
      {
        message: '문자 예약 전송을 취소하시겠습니까?',
        onConfirm: () => {
          cancelMessages(
            {
              unionSeq: Number(unionSeq),
              smsIds: rowSelectionModel as string[],
            },
            {
              onSuccess: () => {
                refetchRecords();

                snackbar('문자 예약 전송 취소가 완료되었습니다.', {
                  color: 'success',
                });
              },
              onError: (error) => {
                snackbar(error.response?.data.message ?? error.message, {
                  color: 'danger',
                });
              },
            },
          );
        },
      },
    );
  };

  const rows = useMemo(() => records?.data || [], [records]);

  const { columns } = useCreateGridColumns(MESSAGES_COLUMNS);

  if (isError) {
    throw error;
  }

  return (
    <Tabs
      color="neutral"
      selectedTabVariant="plain"
      selectedTabColor="primary"
      tabIndicatorInset
      defaultValue={1}
      value={1}
    >
      <TabList disableUnderline>
        {MESSAGE_TABS.map(({ label, path }, index) => (
          <Tab
            key={`tab_${index}`}
            component={Link}
            value={index}
            href={`${unionBasename}${path}`}
          >
            {label}
          </Tab>
        ))}
      </TabList>

      <TabPanel value={1}>
        <Stack gap={2}>
          <Stack gap={1.75}>
            <Search
              defaultValues={DEFAULT_SEARCH_VALUES}
              onSubmit={handleSearchParams}
              onReset={handleSearchReset}
            >
              <Grid container gap={2}>
                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="신청일시"
                      name="requestedAtFromTo"
                      sx={{ field: { maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={4}>
                  <Grid maxWidth={200}>
                    <Search.Field>
                      <TextField
                        label="발신번호"
                        name="outgoingPhoneNo"
                        placeholder="발신번호 입력"
                        fullWidth
                        validateOptions={{
                          maxLength: 11,
                          regex: /^[0-9]*$/,
                        }}
                      />
                    </Search.Field>
                  </Grid>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="발송일시"
                      name="sentAtFromTo"
                      sx={{ field: { maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>

                <Grid xs={12} maxWidth={416}>
                  <Search.Field>
                    <DateRangePicker
                      label="완료일시"
                      name="completedAtFromTo"
                      sx={{ field: { maxWidth: 200 } }}
                    />
                  </Search.Field>
                </Grid>
              </Grid>
            </Search>
          </Stack>

          <Stack gap={2}>
            <Stack flexDirection="row" gap={1}>
              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                전체{' '}
                <Typography color="primary">
                  {commaizeNumber(records?.pagination?.totalDataCount || 0)}
                </Typography>
              </Typography>

              <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                조회 목록{' '}
                <Typography color="primary">
                  {commaizeNumber(records?.pagination?.totalElements || 0)}
                </Typography>
              </Typography>
            </Stack>

            <Stack height={442}>
              <DataGrid
                apiRef={datagridApiRef}
                rows={rows}
                columns={columns}
                loading={isPending}
                rowSelectionModel={rowSelectionModel}
                getRowId={(row) => row.smsId}
                onRowSelectionModelChange={setRowSelectionModel}
                checkboxSelection
                disableRowSelectionOnClick
                isRowSelectable={({ row }) =>
                  row.sendType === 'SCHEDULE' && row.sendStatus === 'WAIT'
                }
              />
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => exportExcel()}
              >
                내역 다운
              </Button>
              <Button
                disabled={!isRowSelected}
                onClick={handleSubmitCancellation}
              >
                예약 발송 취소
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </TabPanel>
    </Tabs>
  );
};

const messageRecordPage: CustomRouteObject = {
  path: 'records',
  children: [
    {
      index: true,
      element: <MessageRecordPage />,
    },
    messageRecordDetailPage,
  ],
  handle: {
    getTitle: () => '내역 관리',
  },
};

export default messageRecordPage;
