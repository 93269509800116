/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  useGridUtils,
  Grid,
  DateRangePicker,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import {
  PromotionUserExecutionHistoriesParams,
  usePromotionUserExecutionHistoriesQuery,
} from 'lim/meetPromotion/apis';
import { PROMOTION_EXECUTION_TABLE_COLUMNS } from 'lim/meetPromotion/fixtures';
import { commaizeNumber } from 'utils/format';

interface PromotionExecutionTabProps {
  meetSeq: number;
}

const defaultParams: PromotionUserExecutionHistoriesParams = {
  name: '',
  keyword: '',
  searchFrom: '',
  searchTo: '',
};

const PromotionExecutionTab = ({ meetSeq }: PromotionExecutionTabProps) => {
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: `promotion-execution-list`,
    activeSaveSnapshot: true,
  });
  const { columns } = useCreateGridColumns({
    columns: PROMOTION_EXECUTION_TABLE_COLUMNS,
  });

  const [params, setParams] = useState(defaultParams);

  const { data, isLoading, isError, error } =
    usePromotionUserExecutionHistoriesQuery(meetSeq, params);

  const handleOnSearch = (params: PromotionUserExecutionHistoriesParams) => {
    let newValue = {} as PromotionUserExecutionHistoriesParams;

    Object.entries(params).forEach(([key, value]) => {
      if (key === 'search' && value?.length) {
        return (newValue = {
          ...newValue,
          [`${key}From`]: value[0],
          [`${key}To`]: value[1],
        });
      }

      newValue = {
        ...newValue,
        [key]: value,
      };
    });

    setParams(newValue);
  };

  const rows = useMemo(() => {
    return (
      data?.data?.map((row) => {
        const {
          promotionUserConnectionHistorySeq,
          promotionUser: { id, name, position },
          actionedAt,
          menu,
          message,
        } = row;

        return {
          id: promotionUserConnectionHistorySeq,
          position,
          name,
          userID: id,
          actionedAt,
          menu,
          message,
        };
      }) || []
    );
  }, [data?.data]);

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack gap={1.75}>
        <Search
          onSubmit={handleOnSearch}
          defaultValues={defaultParams}
          onReset={() => {
            setParams(defaultParams);
          }}
        >
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="이름을 입력하세요."
                  label="이름"
                  name="name"
                  fullWidth
                  slotProps={{
                    input: { maxLength: 30 },
                  }}
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="키워드을 입력하세요."
                  label="키워드"
                  name="keyword"
                  fullWidth
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={416}>
              <Search.Field>
                <DateRangePicker
                  label="기간조회"
                  name="search"
                  sx={{ field: { maxWidth: 200 } }}
                />
              </Search.Field>
            </Grid>
          </Grid>
        </Search>
      </Stack>

      <Stack flexDirection="row" gap={1}>
        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          전체{' '}
          <Typography color="primary">
            {commaizeNumber(data?.pagination?.totalDataCount || 0)}
          </Typography>
        </Typography>

        <Typography fontSize="md" fontWeight="lg" lineHeight="md">
          조회 목록{' '}
          <Typography color="primary">
            {commaizeNumber(data?.pagination?.totalElements || 0)}
          </Typography>
        </Typography>
      </Stack>

      <Stack height={442}>
        <DataGrid
          apiRef={datagridApiRef}
          rows={rows}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
        />
      </Stack>

      <Stack alignSelf="end">
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            exportExcel();
          }}
        >
          내역 다운
        </Button>
      </Stack>
    </Stack>
  );
};

export default PromotionExecutionTab;
