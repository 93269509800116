import { useCallback, useEffect, useRef } from 'react';

import RevealWrapper from 'common-reveal/components/RevealWrapper';
import { createReveal } from 'common-reveal/core/react';
import {
  ExtractProps,
  RevealComponentHandler,
  RevealElement,
  RevealElementProps,
} from 'common-reveal/types';

const useRevealAdapter = <T,>(
  useCustomStore: ReturnType<typeof createReveal<T>>,
) => {
  const { defaultElements, mount, unmount } = useCustomStore();

  const revealIds = useRef<string[]>([]);
  const revealRef = useRef<RevealComponentHandler | null>(null);

  const isComponent = useCallback(
    (
      typeOrElement: RevealElement | keyof typeof defaultElements,
    ): typeOrElement is RevealElement => typeof typeOrElement !== 'string',
    [],
  );

  const isDefaultReveal = useCallback(
    (
      typeOrElement: RevealElement | keyof typeof defaultElements,
    ): typeOrElement is keyof typeof defaultElements =>
      typeof typeOrElement === 'string' && typeOrElement in defaultElements,
    [defaultElements],
  );

  const openReveal = useCallback(
    <T extends RevealElement | keyof typeof defaultElements>(
      typeOrElement: T,
      options?: T extends keyof typeof defaultElements
        ? Omit<
            ExtractProps<(typeof defaultElements)[T]>,
            'open' | 'onClose' | 'onExit'
          >
        : never,
    ) => {
      const id = Date.now().toString();
      revealIds.current = [...revealIds.current, id];

      if (isDefaultReveal(typeOrElement)) {
        const type = typeOrElement;

        if (!defaultElements && !defaultElements?.[typeOrElement]) {
          throw new Error('해당된 Reveal을 createReveal에서 찾을 수 없습니다.');
        }

        mount(
          id,
          <RevealWrapper
            ref={revealRef}
            element={defaultElements[type] as RevealElement<RevealElementProps>}
            elementProps={options}
            onExit={() => {
              unmount(id);
              revealIds.current = revealIds.current.filter(
                (revealId) => revealId !== id,
              );
            }}
          />,
        );

        return;
      }

      if (isComponent(typeOrElement)) {
        const element = typeOrElement;

        mount(
          id,
          <RevealWrapper
            ref={revealRef}
            element={element}
            onExit={() => {
              unmount(id);
              revealIds.current = revealIds.current.filter(
                (revealId) => revealId !== id,
              );
            }}
          />,
        );
      }
    },
    [mount, unmount, defaultElements, isComponent, isDefaultReveal],
  );

  const closeReveal = useCallback(() => {
    revealRef.current?.close();
  }, []);

  useEffect(() => {
    return () => {
      revealIds.current.forEach((id) => {
        unmount(id);
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { openReveal, closeReveal };
};

export default useRevealAdapter;
