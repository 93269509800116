import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  Button,
  DataGrid,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import {
  useElectronicConsentDownloadMutation,
  useElectronicConsentSendMutation,
  useReadElectronicConsent,
  useReadElectronicConsentRecipients,
} from 'apis/electronic-consents/apis';
import { ELECTRONIC_CONSENT_RECIPIENTS_COLUMNS } from 'apis/electronic-consents/fixture';
import { ElectronicConsentRecipient } from 'apis/types/consent';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import ConsentRecipientAddModal from 'components/pages/consent-management/history/detail/modals/ConsentRecipientAddModal';
import ConsentRecipientInfoModal from 'components/pages/consent-management/history/detail/modals/ConsentRecipientInfoModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';
import IndexTemplate from 'templates/IndexTemplate';

const DEFAULT_SEARCH_PARAMS = {
  searchRecipientName: '',
  searchShareType: '',
};

export interface RecipientManagementTabProps {
  unionSeq: number;
  consentSeq: number;
}

const RecipientManagementTab = ({
  unionSeq,
  consentSeq,
}: RecipientManagementTabProps) => {
  const { getGroupCode } = useCommonCodes();
  const { snackbar } = useFeedback();

  const [searchParams, setSearchParams] = useState<
    typeof DEFAULT_SEARCH_PARAMS
  >(DEFAULT_SEARCH_PARAMS);
  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState<boolean>(false);
  const [openConsentRecipientAddModal, setOpenConsentRecipientAddModal] =
    useState<boolean>(false);
  // const [openMessageBalanceModal, setOpenMessageBalanceModal] =
  //   useState<boolean>(false);

  const [selectedValues, setSelectedValues] = useState<
    Pick<typeof DEFAULT_SEARCH_PARAMS, 'searchShareType'>
  >({
    searchShareType: '',
  });

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [selectedRecipient, setSelectedRecipient] = useState<
    ElectronicConsentRecipient | undefined
  >(undefined);

  const {
    data: consent,
    isError: isConsentError,
    error: consentError,
    isLoading: isConsentLoading,
  } = useReadElectronicConsent({ consentSeq });
  const {
    data: recipients,
    isError: isRecipientsError,
    error: recipientsError,
    isLoading: isRecipientsLoading,
  } = useReadElectronicConsentRecipients({
    consentSeq,
    ...searchParams,
  });

  const { mutate: sendMessage } = useElectronicConsentSendMutation();
  const { mutate: downloadConsent, isPending: isDownloading } =
    useElectronicConsentDownloadMutation();

  const handleSearchParams = (
    values: Partial<typeof DEFAULT_SEARCH_PARAMS>,
  ) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...values,
      ...selectedValues,
    }));
  };

  const handleSearchReset = () => {
    setSelectedValues({ searchShareType: '' });
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleRowSelectedModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      setRowSelectionModel(rowSelectionModel);
    },
    [],
  );

  const handleClickSendMessage = useCallback(() => {
    sendMessage(
      { consentSeq },
      {
        onSuccess: () => {
          snackbar('전자 동의서가 발송되었습니다.', {
            color: 'success',
          });
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  }, [consentSeq, sendMessage, snackbar]);

  const handleClickDownloadConsent = useCallback(() => {
    downloadConsent(
      {
        consentSeq,
        unionRegisterSeqs: rowSelectionModel as number[],
      },
      {
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  }, [consentSeq, rowSelectionModel, downloadConsent, snackbar]);

  const handleClickName = useCallback(
    (recipient: ElectronicConsentRecipient) => {
      setSelectedRecipient(recipient);
      setOpenUnionRegisterDetailModal(true);
    },
    [],
  );

  const { rows, unionRegisterSeqs } = useMemo(
    () => ({
      rows: recipients?.data || [],
      unionRegisterSeqs: recipients?.data.map(
        ({ registers }) => registers.unionRegisterSeq,
      ),
    }),
    [recipients],
  );
  const { columns } = useCreateGridColumns(
    ELECTRONIC_CONSENT_RECIPIENTS_COLUMNS,
    {
      onClickName: handleClickName,
    },
  );

  const { totalElements, totalDataCount } = useMemo(
    () => recipients?.pagination ?? { totalElements: 0, totalDataCount: 0 },
    [recipients?.pagination],
  );

  const shareTypeCodeGroup = useMemo(
    () => getGroupCode('UNION_REGISTER_SHARE_TYPE'),
    [getGroupCode],
  );

  const isLoading = isConsentLoading || isRecipientsLoading;
  const isError = isConsentError || isRecipientsError;
  const error = isConsentError ? consentError : recipientsError;

  if (isError) throw error;

  return (
    <>
      <IndexTemplate
        SearchComponent={
          <Search
            defaultValues={DEFAULT_SEARCH_PARAMS}
            onSubmit={handleSearchParams}
            onReset={handleSearchReset}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    label="이름"
                    name="searchRecipientName"
                    placeholder="이름 검색"
                    validateOptions={{
                      maxLength: 30,
                    }}
                    fullWidth
                  />
                </Search.Field>
              </Grid>

              <Search.Field>
                <Grid xs={12} maxWidth={200}>
                  <FormControl sx={{ width: '100%' }}>
                    <FormLabel>소유구분</FormLabel>

                    <Select
                      name="searchShareType"
                      value={selectedValues.searchShareType}
                      onChange={(_, value) =>
                        setSelectedValues({
                          searchShareType: value as unknown as string,
                        })
                      }
                    >
                      <Option value="">전체</Option>

                      {shareTypeCodeGroup?.items.map(({ code, name }) => (
                        <Option key={`share_type_${code}`} value={code}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Search.Field>
            </Grid>
          </Search>
        }
        TopPanelComponent={
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              선택{' '}
              <Typography color="primary">
                {commaizeNumber(rowSelectionModel.length)}
              </Typography>
            </Typography>
          </Stack>
        }
        IndexComponent={
          <Stack height={442}>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={isLoading}
              rowSelectionModel={rowSelectionModel}
              getRowId={(row) => row.registers.unionRegisterSeq}
              columnBufferPx={Infinity}
              getRowHeight={(params) =>
                (params?.model?.registers.localAddresses?.length || 1) * 40 ||
                40
              }
              onRowSelectionModelChange={handleRowSelectedModelChange}
              checkboxSelection
              disableRowSelectionOnClick
              isRowSelectable={({ row }) =>
                row.status === 'SUBMITTED' || row.status === 'AUTO_SUBMITTED'
              }
            />
          </Stack>
        }
        BottomPanelComponent={
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button
              variant="outlined"
              disabled={consent?.data.progressStatus === 'COMPLETED'}
              onClick={() => setOpenConsentRecipientAddModal(true)}
            >
              대상자 추가
            </Button>

            <Button
              variant="outlined"
              disabled={consent?.data.progressStatus === 'COMPLETED'}
              onClick={handleClickSendMessage}
            >
              일괄 발송
            </Button>

            <Button
              loading={isDownloading}
              onClick={handleClickDownloadConsent}
              disabled={rowSelectionModel.length === 0}
            >
              동의서 다운로드
            </Button>
          </Stack>
        }
      />

      {selectedRecipient && openUnionRegisterDetailModal && (
        <ConsentRecipientInfoModal
          params={{
            unionSeq,
            consentSeq,
            recipient: selectedRecipient,
            consentStatus: consent?.data.progressStatus ?? '',
          }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}

      {openConsentRecipientAddModal && (
        <ConsentRecipientAddModal
          unionSeq={unionSeq}
          consentSeq={consentSeq}
          recipientSeqs={unionRegisterSeqs}
          open={openConsentRecipientAddModal}
          onClose={setOpenConsentRecipientAddModal}
        />
      )}

      {/* {openMessageBalanceModal && (
        <MessageBalanceModal
          unionSeq={Number(unionSeq)}
          data={{} as CreateMessageBody}
          open={openMessageBalanceModal}
          isMutationLoading={false}
          onClose={setOpenMessageBalanceModal}
          onSubmit={handleSendConsentMessages}
        />
      )} */}
    </>
  );
};

export default RecipientManagementTab;
