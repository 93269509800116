/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  TextField,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import Search from 'components/Search';
import {
  MeetsParticipantsParams,
  VoterMeetsParticipantsParams,
} from 'lim/generalMeetingHistoryDetail/apis';

const defaultParams: VoterMeetsParticipantsParams = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
};

const SearchFilter = ({
  onSearchSubmit,
}: {
  onSearchSubmit: (params: VoterMeetsParticipantsParams) => void;
}) => {
  const { getGroupCode } = useCommonCodes();

  const statusGroup = useMemo(
    () => getGroupCode('MEET_PARTICIPANT_VOTE_STATUS'),
    [getGroupCode],
  );
  // const [searchParams, setSearchParams] = useState(defaultParams);

  const [selectParams, setSelectParams] = useState<
    Pick<MeetsParticipantsParams<'ELECTRONIC'>, 'electronicVoteProgressStatus'>
  >({
    electronicVoteProgressStatus: '',
  });

  const handleOnSearch = (params: MeetsParticipantsParams<'ELECTRONIC'>) => {
    onSearchSubmit({
      ...params,
      ...selectParams,
    });
  };

  const handleOnReset = () => {
    onSearchSubmit(defaultParams);
    setSelectParams({
      electronicVoteProgressStatus: '',
    });
  };

  const handleOnSelect = (
    key: 'electronicVoteProgressStatus',
    value: string,
  ) => {
    setSelectParams({
      ...selectParams,
      [key]: value,
    });
  };

  return (
    <Search
      defaultValues={defaultParams}
      onSubmit={handleOnSearch}
      onReset={handleOnReset}
    >
      <Grid container gap={2}>
        <Grid xs={12}>
          <Grid container gap={2}>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  label="연번"
                  name="unionRegisterNo"
                  placeholder='숫자 또는 "-"입력'
                  validateOptions={{
                    maxLength: 11,
                    regex: /^(?!.*--)[0-9-]*$/,
                  }}
                  fullWidth
                />
              </Search.Field>
            </Grid>
            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="연락처를 입력하세요"
                  label="연락처"
                  name="phoneNo"
                  fullWidth
                  validateOptions={{
                    maxLength: 11,
                    regex: /^[0-9]*$/,
                  }}
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <TextField
                  placeholder="이름을 입력하세요"
                  label="이름"
                  name="name"
                  fullWidth
                  slotProps={{
                    input: { maxLength: 10 },
                  }}
                />
              </Search.Field>
            </Grid>

            <Grid xs={12} maxWidth={200}>
              <Search.Field>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>상태</FormLabel>
                  <Select
                    fullWidth
                    name="electronicVoteProgressStatus"
                    value={selectParams.electronicVoteProgressStatus}
                    onChange={(_, value) =>
                      handleOnSelect(
                        'electronicVoteProgressStatus',
                        value as unknown as string,
                      )
                    }
                  >
                    <Option value="">전체</Option>

                    {statusGroup?.items.map((item) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </Search.Field>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Search>
  );
};

export default SearchFilter;
