import { QueryClient, useQuery, UseQueryResult } from '@tanstack/react-query';

import { MeetAttendSituationInfo } from 'apis/types/meet';
import { ApiError, ApiResponseData } from 'types/api';

export interface ReadMeetAttendSituationRequest {
  // Path
  generalMeetingSeq: number;
}

export interface ReadMeetAttendSituationResponse
  extends ApiResponseData<MeetAttendSituationInfo> {}

const readMeetAttendSituationOption = ({
  generalMeetingSeq,
}: ReadMeetAttendSituationRequest) => ({
  queryKey: [`/v2/general-meetings/${generalMeetingSeq}/attend-situation`],
});

export const readMeetAttendSituation = async (
  queryClient: QueryClient,
  params: ReadMeetAttendSituationRequest,
) =>
  await queryClient.fetchQuery<ReadMeetAttendSituationResponse, ApiError>(
    readMeetAttendSituationOption(params),
  );

export const useReadMeetAttendSituation = (
  params: ReadMeetAttendSituationRequest,
): UseQueryResult<ReadMeetAttendSituationResponse, ApiError> =>
  useQuery(readMeetAttendSituationOption(params));
