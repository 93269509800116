import RefreshIcon from '@mui/icons-material/Refresh';
import { formatDateTime } from '@wooriga/common-utils';
import {
  Box,
  Button,
  DataGrid,
  Stack,
  useGridUtils,
} from '@wooriga/design-system';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAttendeeQuery } from 'apis/meet/live/api';
import { ONLINE_GENERAL_MEET_ATTENDEE_COLUMNS } from 'apis/meet/live/fixtures';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import MessageOnlineModal from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/components/MessageOnlineModal';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useLayoutContext from 'hooks/useLayoutContext';

export interface AttendeePanelProps {
  meetSeq: number;
  onlineGeneralMeetingSeq: number;
  accessUrl: string;
  meetAt: string;
}

const AttendeePanel = ({
  meetSeq,
  onlineGeneralMeetingSeq,
  accessUrl,
  meetAt,
}: AttendeePanelProps) => {
  const { pageContext } = useLayoutContext();
  const unionName = pageContext?.memberUnionInfo?.name;
  const { unionSeq } = useParams();
  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'meet-attendee',
    activeSaveSnapshot: false,
  });

  const [selectedUnionID, setSelectedUnionID] = useState(0);
  const [showUnionInfoModal, setShowUnionInfoModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const { data, isLoading, refetch } = useAttendeeQuery(
    onlineGeneralMeetingSeq,
  );
  const rows = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  const handleLinkClick = (id: string | number) => {
    setShowUnionInfoModal(true);
    setSelectedUnionID(Number(id));
  };

  const { columns } = useCreateGridColumns(
    ONLINE_GENERAL_MEET_ATTENDEE_COLUMNS,
    {
      onNameClick: handleLinkClick,
    },
  );
  return (
    <>
      <Stack gap={3} marginTop={2}>
        <Stack gap={2}>
          <Box
            sx={{
              height: 442,
            }}
          >
            <DataGrid
              rows={rows}
              loading={isLoading}
              getRowId={(row) => {
                return row.unionRegisterSeq;
              }}
              apiRef={datagridApiRef}
              columns={columns}
              // key={(row?.union)}
            />
          </Box>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box>
              <Button
                size="lg"
                variant="outlined"
                color="neutral"
                onClick={() => exportExcel()}
              >
                참석자 목록 내려받기
              </Button>
            </Box>
            <Box display="flex" gap={1}>
              <Button
                size="lg"
                variant="outlined"
                color="neutral"
                onClick={() => {
                  refetch();
                }}
                startDecorator={<RefreshIcon />}
              >
                새로고침
              </Button>
              <Button
                size="lg"
                variant="solid"
                color="primary"
                onClick={() => {
                  setShowMessageModal(true);
                }}
              >
                온라인 총회 링크 발송
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {showMessageModal && (
        <MessageOnlineModal
          meetSeq={meetSeq}
          meetMethod="ELECTRONIC"
          defaultValues={{
            title: '',
            message: `안녕하세요. ${unionName} 조합원 여러분
총회가 ${formatDateTime(meetAt, 'yyyy-MM-dd HH:mm')}에 현장 및 온라인으로 개최됩니다. 온라인 총회에 참석하시면, 언제 어디서든 편리하게 의견을 나누실수 있으며 현장참석과 동일하게 인정됩니다.

아래 링크로 온라인으로 총회에 참석하실 수 있습니다. 많은 관심과 참여 부탁드립니다!

링크:${accessUrl}
            
감사합니다.
${unionName} 드림
            `,

            files: [],
          }}
          open={showMessageModal}
          onClose={setShowMessageModal}
        />
      )}
      {showUnionInfoModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq, unionRegisterSeq: selectedUnionID }}
          open={showUnionInfoModal}
          onClose={setShowUnionInfoModal}
        />
      )}
    </>
  );
};
export default AttendeePanel;
