import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import axiosInstance from 'apis/axiosInstance';
import { ApiError, ApiResponseData } from 'types/api';

export interface UnionRegistrationTraceQueryParams {
  // Path
  unionSeq: number;
}

export interface UnionRegistrationTraceQueryReturnData {
  registrationTraceStatus: string; // UNION_REGISTRATION_TRACE_STATUS
}

export const useUnionRegistrationTraceQuery = ({
  unionSeq,
}: UnionRegistrationTraceQueryParams): UseQueryResult<
  ApiResponseData<UnionRegistrationTraceQueryReturnData>,
  ApiError
> => useQuery({ queryKey: [`/v1/unions/${unionSeq}/registration-trace`] });

// /unions/{unionSeq}/registration-trace/activate
// /unions/{unionSeq}/registration-trace/deactivate

export interface UnionRegistrationTraceActivateMutationParams {
  // Path
  unionSeq: number;
}

export interface UnionRegistrationTraceActivateMutationReturnData {}

export const useUnionRegistrationTraceActivateMutation = (): UseMutationResult<
  ApiResponseData<UnionRegistrationTraceActivateMutationReturnData>,
  ApiError,
  UnionRegistrationTraceActivateMutationParams
> =>
  useMutation({
    mutationFn: async (request) => {
      const { unionSeq } = request;
      const { data } = await axiosInstance.post(
        `/v1/unions/${unionSeq}/registration-trace/activate`,
      );

      return data;
    },
  });

export interface UnionRegistrationTraceDeactivateMutationParams {
  // Path
  unionSeq: number;
}

export interface UnionRegistrationTraceDeactivateMutationReturnData {}

export const useUnionRegistrationTraceDeactivateMutation =
  (): UseMutationResult<
    ApiResponseData<UnionRegistrationTraceDeactivateMutationReturnData>,
    ApiError,
    UnionRegistrationTraceDeactivateMutationParams
  > =>
    useMutation({
      mutationFn: async (request) => {
        const { unionSeq } = request;
        const { data } = await axiosInstance.post(
          `/v1/unions/${unionSeq}/registration-trace/deactivate`,
        );

        return data;
      },
    });
