import { useTheme, Stack, Typography } from '@wooriga/design-system';
import { useMemo } from 'react';

import { MeetMethods } from 'apis/types/meet';
import PieChartWrapper from 'components/PieChartWrapper';
import { percentage } from 'components/PieChartWrapper/PercentLabel';
import {
  MeetsAttendExpectedCountQuery,
  MeetsWrittenSubmissionCountQuery,
} from 'lim/generalMeetingHistoryDetail/apis';

interface Onsite {
  meetsWrittenSubmissionCountQuery?: MeetsWrittenSubmissionCountQuery;
  meetsAttendExpectedCountQuery?: MeetsAttendExpectedCountQuery;
}

type OnsitePieChartProps = Onsite & {
  totalCount: number;

  meetMethod: MeetMethods;
};

const OnsitePieChart = ({
  meetMethod,
  totalCount,

  meetsWrittenSubmissionCountQuery,
  meetsAttendExpectedCountQuery,
}: OnsitePieChartProps) => {
  const theme = useTheme();

  const onsiteSubmitRateSeries = useMemo(() => {
    const submissionCountData = meetsWrittenSubmissionCountQuery?.data;
    if (!submissionCountData) return [{ data: [] }];
    const { direct, registered, manager } = submissionCountData.data;

    const submitted = direct + registered + manager;
    const unSubmitted = totalCount - submitted;

    if (!submitted) return [{ data: [] }];

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'submitted',
            color: theme.palette.success[400],
            label: `제출 ${submitted}`,
            value: submitted,
          },
          {
            id: 'unSubmitted',
            color: theme.palette.primary[100],
            value: unSubmitted,
          },
        ],
      },
    ];
  }, [meetsWrittenSubmissionCountQuery?.data]);

  const onsiteSubmitMethodSeries = useMemo(() => {
    const submissionCountData = meetsWrittenSubmissionCountQuery?.data;

    if (!submissionCountData) return [{ data: [] }];
    const { direct, registered, manager } = submissionCountData.data;

    const total = direct + registered + manager;
    if (!total) return [{ data: [] }];

    return [
      {
        data: [
          {
            id: 'direct',
            label: `직접 ${direct}`,
            color: theme.palette.success[400],
            value: direct,
          },
          {
            id: 'registered',
            label: `등기 ${registered}`,
            color: theme.palette.success[300],
            value: registered,
          },
          {
            id: 'manager',
            label: `담당자 ${manager}`,
            color: theme.palette.success[200],
            value: manager,
          },
        ],
      },
    ];
  }, [meetsWrittenSubmissionCountQuery?.data]);

  const onsiteExpectedSeries = useMemo(() => {
    const attendExpectedCountQuery = meetsAttendExpectedCountQuery?.data;
    if (!attendExpectedCountQuery) return [{ data: [] }];
    const { mySelf, agent } = attendExpectedCountQuery.data;
    const total = mySelf + agent;
    const unexpected = totalCount - total;

    if (!total) return [{ data: [] }];

    return [
      {
        innerRadius: 60,
        outerRadius: 75,
        data: [
          {
            id: 'mySelf',
            label: `본인 ${mySelf}`,
            color: theme.palette.primary[400],
            value: mySelf,
          },
          {
            id: 'agent',
            label: `대리인 ${agent}`,
            color: theme.palette.primary[300],
            value: agent,
          },
          {
            id: 'unExpected',
            color: theme.palette.primary[100],
            value: unexpected,
          },
        ],
      },
    ];
  }, [meetsAttendExpectedCountQuery?.data]);

  const onsiteSubmitRateLabel = useMemo(() => {
    const submissionCountData = meetsWrittenSubmissionCountQuery?.data;
    if (!submissionCountData) return { percentage: '', rate: '' };
    const { direct, registered, manager } = submissionCountData.data;

    const submitted = direct + registered + manager;

    return {
      percentage: `${percentage(submitted, totalCount)}%`,
      rate: `${submitted}명 / ${totalCount}명`,
    };
  }, [meetsWrittenSubmissionCountQuery?.data]);

  const onsiteExpectedLabel = useMemo(() => {
    const attendExpectedCountQuery = meetsAttendExpectedCountQuery?.data;
    if (!attendExpectedCountQuery) return { percentage: '', rate: '' };
    const { mySelf, agent } = attendExpectedCountQuery.data;

    return {
      percentage: `${percentage(mySelf + agent, totalCount)}%`,
      rate: `${mySelf + agent}명 / ${totalCount}명`,
    };
  }, [meetsAttendExpectedCountQuery?.data]);

  if (!meetMethod.includes('ONSITE')) return <></>;

  return (
    <Stack>
      <Typography level="title-md">서면 제출 현황</Typography>

      <Stack flexWrap="wrap" flexDirection="row" gap={2}>
        <PieChartWrapper series={onsiteSubmitRateSeries} title="서면 제출률">
          <PieChartWrapper.PercentLabel
            label={onsiteSubmitRateLabel.percentage}
          />
          <PieChartWrapper.RateLabel label={onsiteSubmitRateLabel.rate} />
        </PieChartWrapper>

        <PieChartWrapper
          series={onsiteSubmitMethodSeries}
          title="서면 제출 방법"
        />

        <PieChartWrapper series={onsiteExpectedSeries} title="참석 예정률">
          <PieChartWrapper.PercentLabel
            label={onsiteExpectedLabel.percentage}
          />
          <PieChartWrapper.RateLabel label={onsiteExpectedLabel.rate} />
        </PieChartWrapper>
      </Stack>
    </Stack>
  );
};

export default OnsitePieChart;
