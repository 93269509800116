import styled from '@mui/styled-engine';
import { useMemo } from 'react';

export interface WoorigaLogoChartProps {
  value: number; // 0 ~ 100% 기준
  reverse?: boolean;
}

const WoorigaLogoChart = ({ value, reverse }: WoorigaLogoChartProps) => {
  const topFill = reverse ? '#E5E8E8' : '#29978D';
  const bottomFill = reverse ? '#29978D' : '#E5E8E8';

  const height = useMemo(() => {
    const valued = value || 0;

    if (valued < 0 || valued > 100) {
      return reverse ? '0%' : '100%';
    }

    return reverse ? `${valued}%` : `${100 - valued}%`;
  }, [value, reverse]);

  return (
    <svg
      width="245"
      height="232"
      viewBox="0 0 245 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip-fill">
          <StyledRect height={height} />
        </clipPath>
      </defs>

      <path
        d="M228.288 0H168.707C163.98 0 159.387 1.42495 155.462 4.09674L9.14222 103.665C3.43391 107.539 0 114.041 0 120.943V232H55.0317V180.123C55.0317 164.938 67.3403 152.648 82.5476 152.648C97.7549 152.648 110.063 164.938 110.063 180.123V232H220.082V149.264C220.082 147.483 220.35 145.701 220.93 144.009L244.699 19.9048C246.662 9.61843 238.768 0.0890595 228.288 0.0890595V0ZM191.229 66.1712C183.112 72.2273 172.231 71.3812 167.013 64.3455C161.75 57.3098 164.114 46.7117 172.231 40.6557C180.347 34.5996 191.229 35.4457 196.446 42.4814C201.709 49.5171 199.345 60.1152 191.229 66.1712Z"
        fill={topFill}
      />

      <path
        d="M228.288 0H168.707C163.98 0 159.387 1.42495 155.462 4.09674L9.14222 103.665C3.43391 107.539 0 114.041 0 120.943V232H55.0317V180.123C55.0317 164.938 67.3403 152.648 82.5476 152.648C97.7549 152.648 110.063 164.938 110.063 180.123V232H220.082V149.264C220.082 147.483 220.35 145.701 220.93 144.009L244.699 19.9048C246.662 9.61843 238.768 0.0890595 228.288 0.0890595V0ZM191.229 66.1712C183.112 72.2273 172.231 71.3812 167.013 64.3455C161.75 57.3098 164.114 46.7117 172.231 40.6557C180.347 34.5996 191.229 35.4457 196.446 42.4814C201.709 49.5171 199.345 60.1152 191.229 66.1712Z"
        fill={bottomFill}
        clipPath="url(#clip-fill)"
      />
    </svg>
  );
};

const StyledRect = styled('rect')((props) => ({
  x: 0,
  y: 0,
  width: '100%',
  height: props.height,
}));

export default WoorigaLogoChart;
