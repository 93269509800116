import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  Box,
  Button,
  Chip,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  Stack,
  Typography,
  // Typography,
} from '@wooriga/design-system';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import ResultGrid from 'components/pages/meet-management/history/detail/common/ResultGrid';
import useFeedback from 'hooks/useFeedback';
import { useElectronicVoteResultQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { MEETS_AGENDAS_ElECTRONIC_TABLE_COLUMNS } from 'lim/generalMeetingHistoryDetail/fixtures';

interface ElectronicResultModalProps {
  voterCount: number;
  registeredCount: number;
  open: boolean;

  onClose: (open: false) => void;
  onSubmit: () => void;
}

const ElectronicResultModal = ({
  voterCount,
  registeredCount,
  open,

  onClose,
  onSubmit,
}: ElectronicResultModalProps) => {
  const { meetSeq } = useParams();
  const { confirmDialog } = useFeedback();
  const meetId = Number(meetSeq);

  const { data } = useElectronicVoteResultQuery(meetId);

  const handleClose = () => {
    confirmDialog('개표 결과 입력을 취소하시겠습니까?', {
      message: '입력한 내용은 저장되지 않습니다.',
      onConfirm: () => {
        onClose(false);
      },
    });
  };

  const rows = useMemo(() => {
    return (
      data?.data?.flatMap((voteResult) => {
        const {
          agendaSeq,
          order,
          name,
          voteForm,
          voteType,
          selectCount,
          description,
          candidates,
        } = voteResult;

        return candidates?.map((candidate) => {
          const { isPassed, electronicResult } = candidate;
          const { yesCount, noCount, totalCount, abstentionCount } =
            electronicResult;

          return {
            id: `${agendaSeq}-${candidate.no}-electronicResult`,
            agendaSeq,
            order,
            voteForm,
            voteType,
            selectCount,
            name,
            description,
            method: '전자투표',
            candidateNo: candidate.no,
            candidateName: candidate.name,
            yesCount,
            noCount,
            totalCount,
            abstentionCount,
            voteRate: selectCount ? yesCount / selectCount : 0,
            isPassed,
          };
        });
      }) || []
    );
  }, [data?.data]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalOverflow>
        <ModalDialog maxWidth="lg" sx={{ width: '100%' }}>
          <Stack flexDirection="row" justifyContent="space-between">
            <DialogTitle>전자투표 결과</DialogTitle>

            <Chip
              variant="soft"
              color="primary"
              size="lg"
              endDecorator={<NavigateNextIcon />}
            >
              다음 단계 : 서면제출 개표 결과 입력
            </Chip>
          </Stack>

          <DialogContent>
            <Stack gap={3}>
              <Descriptions legacy={false}>
                <DescriptionsItem label="전자투표 수">
                  {registeredCount}
                </DescriptionsItem>
              </Descriptions>

              <Box>
                <Typography
                  fontSize="md"
                  fontWeight="lg"
                  lineHeight="md"
                  mb={1}
                >
                  총 선거인{' '}
                  <Typography color="primary">
                    {commaizeNumber(voterCount || 0)}
                  </Typography>
                </Typography>

                <Box height={480}>
                  <ResultGrid
                    columns={MEETS_AGENDAS_ElECTRONIC_TABLE_COLUMNS}
                    rows={rows}
                  />
                </Box>
              </Box>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button type="submit" onClick={() => onSubmit()}>
              다음
            </Button>
            <Button variant="outlined" color="neutral" onClick={handleClose}>
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default ElectronicResultModal;
