import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from 'apis/axiosInstance';
import {
  ElectronicConsent,
  ElectronicConsentCertificate,
  ElectronicConsentRecipient,
  ElectronicConsentStatisticArea,
  ElectronicConsentStatisticDong,
  ElectronicConsentTemplate,
  ProgressStatus,
} from 'apis/types/consent';
import { ApiError, ApiResponseData } from 'types/api';

export interface ElectronicConsentTemplatesParams {
  searchUnionSeq?: number;
  searchTemplateName?: string;
}

export type ElectronicConsentTemplatesReturnData = ElectronicConsentTemplate[];

export const useReadElectronicConsentTemplates = (
  params: ElectronicConsentTemplatesParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentTemplatesReturnData, true>,
  ApiError
> => {
  const { searchUnionSeq } = params;

  return useQuery({
    queryKey: [`/v2/electronic-consent-templates`, { searchUnionSeq }],
  });
};

export interface ElectronicConsentsParams {
  searchUnionSeq: number;
  searchTitle: string;
}

export interface ElectronicConsentTemplateReturnData {
  // 최소한의 필요로 하는 Property만 Type 추가
  pages: { id: string; content: { backgroundImage: { src: string } } }[];
}

export const useReadElectronicConsentTemplate = (
  templateUrl: string,
): UseQueryResult<
  AxiosResponse<ElectronicConsentTemplateReturnData>,
  AxiosError
> => {
  const templatePath = templateUrl.split('/').slice(3).join('/');

  return useQuery({
    queryKey: ['/v2/electronic-consent-template', templatePath],
    queryFn: async () => await axios.get(templateUrl),
    enabled: !!templateUrl,
  });
};

export type ElectronicConsentsReturnData = ElectronicConsent[];

export const useReadElectronicConsents = (
  params: ElectronicConsentsParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentsReturnData, true>,
  ApiError
> => {
  const { searchUnionSeq, searchTitle } = params;

  return useQuery({
    queryKey: [`/v2/electronic-consents`, { searchUnionSeq, searchTitle }],
  });
};

export interface ElectronicConsentParams {
  consentSeq: number;
}

export type ElectronicConsentReturnData = ElectronicConsent;

export const useReadElectronicConsent = (
  params: ElectronicConsentParams,
): UseQueryResult<ApiResponseData<ElectronicConsentReturnData>, ApiError> => {
  const { consentSeq, ...restParams } = params;

  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}`, restParams],
  });
};

export interface ElectronicConsentRecipientsParams {
  consentSeq: number;
  searchRecipientName?: string;
  searchShareType?: string;
}

export type ElectronicConsentRecipientsReturnData =
  ElectronicConsentRecipient[];

export const useReadElectronicConsentRecipients = (
  params: ElectronicConsentRecipientsParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentRecipientsReturnData, true>,
  ApiError
> => {
  const { consentSeq, searchRecipientName, searchShareType } = params;

  return useQuery({
    queryKey: [
      `/v2/electronic-consents/${consentSeq}/recipients`,
      { searchRecipientName, searchShareType },
    ],
  });
};

export interface ElectronicConsentStatisticsAreaParams {
  consentSeq: number;
  isEnabled?: boolean;
}

export type ElectronicConsentStatisticsAreaReturnData =
  ElectronicConsentStatisticArea;

export const useReadElectronicConsentStatisticsArea = (
  params: ElectronicConsentStatisticsAreaParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentStatisticsAreaReturnData>,
  ApiError
> => {
  const { consentSeq, isEnabled } = params;

  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}/statistics/area`],
    enabled: isEnabled,
  });
};

export interface ElectronicConsentStatisticsDongParams {
  consentSeq: number;
  isEnabled?: boolean;
}

export type ElectronicConsentStatisticsDongReturnData =
  ElectronicConsentStatisticDong[];

export const useReadElectronicConsentStatisticsDong = (
  params: ElectronicConsentStatisticsDongParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentStatisticsDongReturnData>,
  ApiError
> => {
  const { consentSeq, isEnabled } = params;

  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}/statistics/dong`],
    enabled: isEnabled,
  });
};

export interface ElectronicConsentCertificatesParams {
  name: string;
}

export type ElectronicConsentCertificatesReturnData =
  ElectronicConsentCertificate[];

export const useReadElectronicConsentCertificates = (
  params: ElectronicConsentCertificatesParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentCertificatesReturnData>,
  ApiError
> => {
  const { name } = params;

  return useQuery({
    queryKey: [`/v2/certificate-electronic-consents`, { name }],
  });
};

export interface ElectronicConsentCertificateSummaryParams {
  consentSeq: number;
}

export type ElectronicConsentCertificateSummaryReturnData =
  ElectronicConsentStatisticDong[];

export const useReadElectronicConsentCertificateSummary = (
  params: ElectronicConsentCertificateSummaryParams,
): UseQueryResult<
  ApiResponseData<ElectronicConsentCertificateSummaryReturnData>,
  ApiError
> => {
  const { consentSeq } = params;

  return useQuery({
    queryKey: [`/v2/electronic-consents/${consentSeq}/certificates/summary`],
  });
};

export interface ElectronicConsentCreateBody {
  templateSeq: number;
  unionSeq: number;
  unionRegisterSeqs: number[];
  title: string;
  content: string;
  isSignatureRequired: boolean;
  isActiveCondition: boolean;
  consentCondition: number;
  startedAt: string;
  endedAt: string | null;
  linkCode: string;
}

export const useElectronicConsentCreateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentCreateBody
> =>
  useMutation({
    mutationFn: async (body) => {
      const { data } = await axiosInstance.post(
        `/v2/electronic-consents`,
        body,
      );

      return data;
    },
  });

export interface ElectronicConsentUpdateParams {
  consentSeq: number;
}

export const useElectronicConsentUpdateMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentUpdateParams &
    Omit<ElectronicConsentCreateBody, 'unionRegisterSeqs' | 'linkCode'>
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq, ...body } = params;

      const { data } = await axiosInstance.put(
        `/v2/electronic-consents/${consentSeq}`,
        body,
      );

      return data;
    },
  });

export interface ElectronicConsentProgressStatusParams {
  consentSeq: number;
}

export interface ElectronicConsentProgressStatusBody {
  progressStatus: ProgressStatus;
}

export const useElectronicConsentProgressStatusMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentProgressStatusParams & ElectronicConsentProgressStatusBody
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq, ...body } = params;

      const { data } = await axiosInstance.post(
        `/v2/electronic-consents/${consentSeq}/progress-status`,
        body,
      );

      return data;
    },
  });

export interface ElectronicConsentRecipientsUpdateParams {
  consentSeq: number;
}

export interface ElectronicConsentRecipientsUpdateBody {
  unionSeq: number;
  unionRegisterSeqs: number[];
}

export const useElectronicConsentRecipientsUpdateMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentRecipientsUpdateParams &
      ElectronicConsentRecipientsUpdateBody
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { consentSeq, ...body } = params;

        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/recipients`,
          body,
        );

        return data;
      },
    });

export interface ElectronicConsentRecipientWithdrawalParams {
  consentSeq: number;
  recipientSeq: number;
}

export const useElectronicConsentRecipientWithdrawalMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentRecipientWithdrawalParams
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { consentSeq, recipientSeq } = params;

        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/recipients/${recipientSeq}/withdraw`,
        );

        return data;
      },
    });

export interface ElectronicConsentDownloadParams {
  consentSeq: number;
}

export interface ElectronicConsentDownloadBody {
  unionRegisterSeqs: number[];
}

export const useElectronicConsentDownloadMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentDownloadParams & ElectronicConsentDownloadBody
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq } = params;

      const { data } = await axiosInstance.post(
        `/v2/electronic-consents/${consentSeq}/download`,
      );

      return data;
    },
  });

export interface ElectronicConsentRecipientDeleteParams {
  consentSeq: number;
  recipientSeq: number;
}

export const useElectronicConsentRecipientDeleteMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentRecipientDeleteParams
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { consentSeq, recipientSeq } = params;

        const { data } = await axiosInstance.delete(
          `/v2/electronic-consents/${consentSeq}/recipients/${recipientSeq}`,
        );

        return data;
      },
    });

export interface ElectronicConsentSendParams {
  consentSeq: number;
}

export const useElectronicConsentSendMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentSendParams
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq } = params;

      const { data } = await axiosInstance.post(
        `/v2/electronic-consents/${consentSeq}/send`,
      );

      return data;
    },
  });

export interface ElectronicConsentCertificateExtensionParams {
  consentSeq: number;
}

export interface ElectronicConsentCertificateExtensionBody {
  phoneNo: string;
}

export const useElectronicConsentCertificateExtensionMutation =
  (): UseMutationResult<
    ApiResponseData,
    ApiError,
    ElectronicConsentCertificateExtensionParams &
      ElectronicConsentCertificateExtensionBody
  > =>
    useMutation({
      mutationFn: async (params) => {
        const { consentSeq, ...body } = params;

        const { data } = await axiosInstance.post(
          `/v2/electronic-consents/${consentSeq}/certificate-extensions`,
          body,
        );

        return data;
      },
    });

export interface ElectronicConsentCertificateParams {
  consentSeq: number;
}

export interface ElectronicConsentCertificateBody {
  certificateType: string;
  targets: number[];
}

export const useElectronicConsentCertificatesMutation = (): UseMutationResult<
  ApiResponseData,
  ApiError,
  ElectronicConsentCertificateParams & ElectronicConsentCertificateBody
> =>
  useMutation({
    mutationFn: async (params) => {
      const { consentSeq, ...body } = params;

      const { data } = await axiosInstance.post(
        `/v2/electronic-consents/${consentSeq}/certificates`,
        body,
      );

      return data;
    },
  });
