import JoyRadioGroup from '@mui/joy/RadioGroup';
import type { RadioGroupProps as JoyRadioGroupProps } from '@mui/joy/RadioGroup';
import { VariantProp } from '@mui/joy/styles/types';
import { ForwardedRef, forwardRef, useMemo } from 'react';

import FormControl, {
  FormControlProps,
} from 'design-system/components/inputs/FormControl';
import FormHelperText, {
  FormHelperTextProps,
} from 'design-system/components/inputs/FormHelperText';
import FormLabel, {
  FormLabelProps,
} from 'design-system/components/inputs/FormLabel';
import { RadioProps } from 'design-system/components/inputs/radio/Radio';
import RadioGroupContext from 'design-system/components/inputs/radio/RadioGroupContext';

export type RadioGroupProps = Omit<FormControlProps, 'onChange'> &
  JoyRadioGroupProps & {
    variant?: VariantProp;
    label?: FormLabelProps['children'];
    helperText?: FormHelperTextProps['children'];
    formLabelProps?: FormLabelProps;
    radioProps?: RadioProps;
    formHelperTextProps?: FormHelperTextProps;
  };

const RadioGroup = (
  props: RadioGroupProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    label,
    formLabelProps,
    helperText,
    formHelperTextProps,
    children,
    disabled,
    id,
    color,
    size = 'md',
    variant,
    error,
    required,
    ...other
  } = props;

  const contextValue = useMemo(
    () => ({
      label,
      formLabelProps,
      formHelperTextProps,
      helperText,
      children,
      disabled,
      color,
      size,
      variant,
      error,
      ...other,
    }),
    [
      label,
      formLabelProps,
      formHelperTextProps,
      helperText,
      children,
      disabled,
      color,
      size,
      variant,
      error,
      other,
    ],
  );

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <FormControl
        disabled={disabled}
        id={id}
        error={error}
        color={color}
        required={required}
      >
        {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}

        <JoyRadioGroup ref={ref} size={size} {...other}>
          {children}
        </JoyRadioGroup>

        {helperText && (
          <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </RadioGroupContext.Provider>
  );
};

export default forwardRef<HTMLDivElement, RadioGroupProps>(RadioGroup);
