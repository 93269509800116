import { Chip, ChipProps } from '@wooriga/design-system';

import CommonCode, { CommonCodeProps } from 'components/CommonCode';

const MeetStatusChip = ({ code }: Pick<CommonCodeProps, 'code'>) => {
  const getVariant = (): {
    variant?: ChipProps['variant'];
    color?: ChipProps['color'];
  } => {
    switch (code) {
      case 'BEFORE':
        return {
          variant: 'outlined' as ChipProps['variant'],
        };
      case 'PROCESS':
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          color: 'blue',
        };
      case 'COUNTING':
        return {
          color: 'success',
        };
      case 'COMPLETE':
        return {};

      default:
        return {};
    }
  };

  return (
    <Chip {...getVariant()} size="sm">
      <CommonCode groupCode="MEET_STATUS" code={code} />
    </Chip>
  );
};

export default MeetStatusChip;
