import styled from '@mui/joy/styles/styled';
import { VariantProp } from '@mui/joy/styles/types/variants';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

import FormControl from 'design-system/components/inputs/FormControl';
import FormHelperText from 'design-system/components/inputs/FormHelperText';
import FormLabel from 'design-system/components/inputs/FormLabel';
import Input, { InputProps } from 'design-system/components/inputs/Input';

export type TextFieldSlot = 'root' | 'label' | 'input' | 'helpText';

export type TextFieldProps = InputProps & {
  variant?: VariantProp;
  label?: ReactNode;
  helperText?: ReactNode;
};

export type TextFieldOwnerState = TextFieldProps;

const TextField = (
  props: TextFieldProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    disabled,
    id,
    label,
    helperText,
    fullWidth,
    error,
    color,
    required,
    ...other
  } = props;

  return (
    <TextFieldRoot
      disabled={disabled}
      id={id}
      ref={ref}
      fullWidth={fullWidth}
      error={error}
      color={color}
      required={required}
    >
      <TextFieldLabel htmlFor={id}>{label}</TextFieldLabel>
      <TextFieldInput disabled={disabled} fullWidth={fullWidth} {...other} />
      {helperText && <TextFieldHelpText>{helperText}</TextFieldHelpText>}
    </TextFieldRoot>
  );
};

const TextFieldRoot = styled(FormControl, {
  name: 'JoyTextField',
  slot: 'root',
  shouldForwardProp: (propName) => propName !== 'fullWidth',
})<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => fullWidth && { width: '100%' }}
`;

const TextFieldLabel = styled(FormLabel, {
  name: 'JoyTextField',
  slot: 'label',
})``;

const TextFieldInput = styled(Input, {
  name: 'JoyTextField',
  slot: 'input',
})``;

const TextFieldHelpText = styled(FormHelperText, {
  name: 'JoyTextField',
  slot: 'helpText',
})``;

export default forwardRef<HTMLDivElement, TextFieldProps>(TextField);
