import type { LinkProps as JoyLinkProps } from '@mui/joy/Link';
import { ForwardedRef, forwardRef } from 'react';
import {
  Link as RouteDomLink,
  LinkProps as RouteDomLinkProps,
  useInRouterContext,
  useLocation,
} from 'react-router-dom';

export interface LinkBehaviorProps extends Omit<JoyLinkProps, 'href'> {
  href?: RouteDomLinkProps['to'] & JoyLinkProps['href'];
  state?: RouteDomLinkProps['state'];
}

const LinkWithRouterLocation = forwardRef<HTMLAnchorElement, LinkBehaviorProps>(
  (props, ref) => {
    const { href, state, ...other } = props;

    const location = useLocation();

    return (
      <RouteDomLink
        ref={ref}
        to={href as RouteDomLinkProps['to']}
        state={{ from: location, ...state }}
        {...other}
      />
    );
  },
);

LinkWithRouterLocation.displayName = 'LinkWithRouterLocation';

const LinkBehavior = (
  props: LinkBehaviorProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) => {
  const isInRouter = useInRouterContext();

  const { href, ...other } = props;

  if (isInRouter) {
    return <LinkWithRouterLocation ref={ref} href={href} {...other} />;
  }

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} ref={ref} />;
};

export default forwardRef<HTMLAnchorElement, LinkBehaviorProps>(LinkBehavior);
