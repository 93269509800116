import { Box, Stack, Typography } from '@wooriga/design-system';
import { memo } from 'react';

export interface ColorMapLabelProps {
  colors: string[];
  leftLabel?: string;
  rightLabel?: string;
}

const ColorMapLabel = (props: ColorMapLabelProps) => {
  const { colors, leftLabel = '최저', rightLabel = '최고' } = props;

  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <Typography
        textColor="neutral.500"
        fontSize="sm"
        fontWeight="md"
        lineHeight="sm"
      >
        {leftLabel}
      </Typography>

      <Stack flexDirection="row">
        {colors.map((color) => (
          <Box
            key={`heatmap_label_color_${color}`}
            width={14}
            height={14}
            bgcolor={color}
          />
        ))}
      </Stack>

      <Typography
        textColor="neutral.500"
        fontSize="sm"
        fontWeight="md"
        lineHeight="sm"
      >
        {rightLabel}
      </Typography>
    </Stack>
  );
};

ColorMapLabel.displayName = 'ColorMapLabel';

export default memo(ColorMapLabel);
