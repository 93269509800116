import { Chip, ChipProps } from '@wooriga/design-system';

export interface MeetVoteResultChipProps {
  variant?: ChipProps['variant'];
  value: boolean | null;
}

const MeetVoteResultChip = ({
  variant = 'soft',
  value,
}: MeetVoteResultChipProps) => {
  const getVariant = (): {
    color?: ChipProps['color'];
    children?: ChipProps['children'];
  } => {
    switch (value) {
      case true:
        return {
          color: 'blue',
          children: '가결',
        };
      case false:
        return {
          color: 'danger',
          children: '부결',
        };

      default:
        return {};
    }
  };

  return <Chip {...getVariant()} variant={variant} size="sm" />;
};

export default MeetVoteResultChip;
