import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Callout,
  Stack,
  Typography,
} from '@wooriga/design-system';

import { usePremiumServiceMutation } from 'apis/meet/live/api';
import useFeedback from 'hooks/useFeedback';
import useLayoutContext from 'hooks/useLayoutContext';

export interface PendingStepProps {
  meetSeq: number;
  refetch?: () => void;
}

const PendingStep = ({ meetSeq, refetch }: PendingStepProps) => {
  const { pageContext } = useLayoutContext();
  const memberSeq = pageContext?.memberInfo?.memberSeq;
  const { snackbar, confirmDialog } = useFeedback();
  const { mutate: premiumServiceMutate } = usePremiumServiceMutation(meetSeq);
  const handleClickLiveMeet = () => {
    if (!memberSeq) return;
    confirmDialog(
      '온라인 총회를 신청하시면 승인 후 개설 시 입력하신 연락처로 연락을 드립니다.',
      {
        message: '온라인 총회를 신청하시겠습니까?',
        size: 'md',
        onConfirm: () => {
          premiumServiceMutate(
            {
              mbSeq: memberSeq,
              premiumServiceType: 'ONLINE_GENERAL_MEETING',
            },
            {
              onSuccess: () => {
                snackbar('온라인 총회 신청이 완료되었습니다.', {
                  color: 'success',
                });
                refetch && refetch();
              },
              onError: (error) => {
                snackbar(error.response?.data.message ?? error.message, {
                  color: 'danger',
                });
              },
            },
          );
        },
      },
    );
  };
  return (
    <>
      <Stack gap={3}>
        <Callout
          variant="outlined"
          color="warning"
          size="md"
          startDecorator={<InfoIcon />}
        >
          <Typography>온라인 총회에 대한 설명</Typography>
          <Typography>
            1. 선거인의 휴대폰으로 발송된 링크를 통해 온라인으로 참석 하실 수
            있으며 현장 참석과 동일하게 인정됩니다.
          </Typography>
          <Typography>
            2. 언제 어디서든 총회에 참석하시어 의견 등 소통 가능합니다.
          </Typography>
          <Typography>
            3. 온라인 총회는 본인 명의의 휴대폰 인증을 진행하므로
            연락처(휴대폰번호)정보가 필요합니다.
          </Typography>
          <Typography>
            4. 온라인 총회를 신청하시면 승인 후 개설 시 입력하신 연락처로 연락을
            드립니다.
          </Typography>
        </Callout>
        <Box justifyContent="center" display="flex">
          <Button onClick={handleClickLiveMeet}>온라인 총회 신청</Button>
        </Box>
      </Stack>
    </>
  );
};

export default PendingStep;
