export const MIME_TYPES: { [key: string]: string } = {
  'application/pdf': 'PDF',
  'image/png': 'PNG',
  'image/jpeg': 'JPEG',
  'image/jpg': 'JPG',
  'image/gif': 'GIF',
  'text/plain': 'TXT',
  'application/msword': 'DOC',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'DOCX',
  'application/vnd.ms-excel': 'XLS',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
  'application/vnd.ms-powerpoint': 'PPT',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PPTX',
  'application/zip': 'ZIP',
  'application/x-rar-compressed': 'RAR',
  'video/mp4': 'MP4',
  'audio/mpeg': 'MP3',
  'audio/wav': 'WAV',
  'audio/ogg': 'OGG',
  'video/ogg': 'OGV',
  'video/webm': 'WEBM',
  'application/x-hwp': 'HWP',
  'application/json': 'JSON',
  // 필요에 따라 더 많은 MIME 타입 추가 가능
};
