import { styled } from '@mui/joy';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { useQueryClient } from '@tanstack/react-query';
import { commaizeNumber } from '@wooriga/common-utils';
import {
  Button,
  DataGrid,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';

import { useElectronicConsentRecipientsUpdateMutation } from 'apis/electronic-consents/apis';
import { ELECTRONIC_CONSENT_TARGET_COLUMNS } from 'apis/electronic-consents/fixture';
import { UnionRegisterInfo } from 'apis/types/union';
import { useUnionRegistersQuery } from 'apis/union/register/apis';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import UnionRegisterInfoDetailModal from 'components/pages/common/UnionRegisterInfoDetailModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns';
import useFeedback from 'hooks/useFeedback';

const DEFAULT_SEARCH_PARAMS = {
  name: '',
  shareType: '',
  ownerType: '',
};

export interface ConsentRecipientAddModalProps {
  unionSeq: number;
  consentSeq: number;
  recipientSeqs: number[] | undefined;
  open: boolean;
  onClose: (show: false) => void;
}

const ConsentRecipientAddModal = ({
  unionSeq,
  consentSeq,
  recipientSeqs,
  open,
  onClose,
}: ConsentRecipientAddModalProps) => {
  const { getGroupCode } = useCommonCodes();
  const { snackbar } = useFeedback();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useState<
    typeof DEFAULT_SEARCH_PARAMS
  >(DEFAULT_SEARCH_PARAMS);

  const {
    data: unionRegisters,
    isLoading,
    isError,
    error,
  } = useUnionRegistersQuery(unionSeq, searchParams);

  const [selectedValues, setSelectedValues] = useState<
    Pick<typeof DEFAULT_SEARCH_PARAMS, 'shareType' | 'ownerType'>
  >({
    shareType: '',
    ownerType: '',
  });

  const [openUnionRegisterDetailModal, setOpenUnionRegisterDetailModal] =
    useState<boolean>(false);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [selectUnionRegisterSeq, setSelectUnionRegisterSeq] = useState<
    number | undefined
  >(undefined);

  const { mutate: updateRecipients, isPending } =
    useElectronicConsentRecipientsUpdateMutation();

  const isMissed = (row: UnionRegisterInfo) => {
    const { birth, agent } = row;
    const name = row.name?.name;

    const hasName = name && name !== '이름없음';

    if (
      row?.gender &&
      birth &&
      (row?.mainPhone || row?.subPhones?.length) &&
      hasName
    ) {
      return false;
    }

    if (agent?.gender && agent.birth && agent.phoneNo && hasName) {
      return false;
    }

    return true;
  };

  const handleSearchParams = (
    values: Partial<typeof DEFAULT_SEARCH_PARAMS>,
  ) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...values,
      ...selectedValues,
    }));
  };

  const handleSearchReset = () => {
    setSelectedValues({ shareType: '', ownerType: '' });
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      ...DEFAULT_SEARCH_PARAMS,
    }));
  };

  const handleRowSelectedModelChange = useCallback(
    (rowSelectionModel: GridRowSelectionModel) => {
      setRowSelectionModel(rowSelectionModel);
    },
    [],
  );

  const handleClickName = useCallback((unionRegisterSeq: number) => {
    setSelectUnionRegisterSeq(unionRegisterSeq);
    setOpenUnionRegisterDetailModal(true);
  }, []);

  const handleSubmit = useCallback(() => {
    updateRecipients(
      {
        consentSeq,
        unionSeq,
        unionRegisterSeqs: rowSelectionModel as number[],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [`/v2/electronic-consents/${consentSeq}/recipients`],
          });
          onClose(false);
        },
        onError: (error) => {
          snackbar(error?.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  }, [
    rowSelectionModel,
    unionSeq,
    consentSeq,
    queryClient,
    updateRecipients,
    snackbar,
    onClose,
  ]);

  const { columns } = useCreateGridColumns(ELECTRONIC_CONSENT_TARGET_COLUMNS, {
    onClickName: handleClickName,
  });
  const rows = useMemo(
    () =>
      (unionRegisters?.data ?? []).filter(
        ({ unionRegisterSeq }) => !recipientSeqs?.includes(unionRegisterSeq),
      ) || [],
    [unionRegisters, recipientSeqs],
  );

  const { totalDataCount } = useMemo(
    () => unionRegisters?.pagination ?? { totalElements: 0, totalDataCount: 0 },
    [unionRegisters?.pagination],
  );

  const shareTypeCodeGroup = useMemo(
    () => getGroupCode('UNION_REGISTER_SHARE_DIVIDE'),
    [getGroupCode],
  );
  const ownerTypeCodeGroup = useMemo(
    () => getGroupCode('UNION_REGISTER_USER_DIVIDE'),
    [getGroupCode],
  );

  if (isError) throw error;

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalDialog>
          <DialogTitle sx={{ pb: 1.5 }}>
            <Stack gap={1.5}>
              <Typography fontSize="lg" fontWeight="xl" lineHeight="xl">
                대상자 추가
              </Typography>
              <Typography level="body-md" color="neutral">
                필수 정보가 누락된 조합원은 선택하실 수 없으니, 조합원 명부
                관리에서 정보를 입력해 주세요.
              </Typography>
            </Stack>
          </DialogTitle>

          <DialogContent sx={{ gap: 2.5 }}>
            <Search
              defaultValues={DEFAULT_SEARCH_PARAMS}
              onSubmit={handleSearchParams}
              onReset={handleSearchReset}
            >
              <Grid container gap={2}>
                <Grid xs={12} maxWidth={200}>
                  <Search.Field>
                    <TextField
                      label="이름"
                      name="name"
                      placeholder="이름 검색"
                      validateOptions={{
                        maxLength: 30,
                      }}
                      fullWidth
                    />
                  </Search.Field>
                </Grid>

                <Search.Field>
                  <Grid xs={12} maxWidth={200}>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>소유구분</FormLabel>

                      <Select
                        name="shareType"
                        value={selectedValues.shareType}
                        onChange={(_, value) =>
                          setSelectedValues((prevValues) => ({
                            ...prevValues,
                            shareType: value as unknown as string,
                          }))
                        }
                      >
                        <Option value="">전체</Option>

                        {shareTypeCodeGroup?.items.map(({ code, name }) => (
                          <Option key={`share_type_${code}`} value={code}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Search.Field>

                <Search.Field>
                  <Grid xs={12} maxWidth={200}>
                    <FormControl sx={{ width: '100%' }}>
                      <FormLabel>개인/법인</FormLabel>

                      <Select
                        name="ownerType"
                        value={selectedValues.ownerType}
                        onChange={(_, value) =>
                          setSelectedValues((prevValues) => ({
                            ...prevValues,
                            ownerType: value as unknown as string,
                          }))
                        }
                      >
                        <Option value="">전체</Option>

                        {ownerTypeCodeGroup?.items.map(({ code, name }) => (
                          <Option key={`owner_type_${code}`} value={code}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Search.Field>
              </Grid>
            </Search>

            <Stack gap={1}>
              <Stack flexDirection="row" gap={1}>
                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  전체{' '}
                  <Typography color="primary">
                    {commaizeNumber(
                      totalDataCount - (recipientSeqs?.length ?? 0),
                    )}
                  </Typography>
                </Typography>

                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  조회 목록{' '}
                  <Typography color="primary">
                    {commaizeNumber(rows.length)}
                  </Typography>
                </Typography>

                <Typography fontSize="md" fontWeight="lg" lineHeight="md">
                  선택{' '}
                  <Typography color="primary">
                    {commaizeNumber(rowSelectionModel.length)}
                  </Typography>
                </Typography>
              </Stack>

              <Stack height={442}>
                <StyledDataGrid
                  rows={rows}
                  columns={columns}
                  loading={isLoading}
                  rowSelectionModel={rowSelectionModel}
                  getRowId={(row) => row.unionRegisterSeq}
                  columnBufferPx={Infinity}
                  getRowHeight={(params) =>
                    (params?.model?.localAddresses?.length || 1) * 40 || 40
                  }
                  getRowClassName={(params) =>
                    isMissed(params.row) ? `theme--isMissed` : ''
                  }
                  isRowSelectable={(params) => !isMissed(params.row)}
                  onRowSelectionModelChange={handleRowSelectedModelChange}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button loading={isPending} onClick={handleSubmit}>
              추가
            </Button>
            <Button
              variant="outlined"
              color="neutral"
              disabled={isPending}
              onClick={() => onClose(false)}
            >
              취소
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      {selectUnionRegisterSeq && openUnionRegisterDetailModal && (
        <UnionRegisterInfoDetailModal
          params={{ unionSeq: 1, unionRegisterSeq: selectUnionRegisterSeq }}
          open={openUnionRegisterDetailModal}
          onClose={setOpenUnionRegisterDetailModal}
        />
      )}
    </>
  );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .theme--isMissed': {
    backgroundColor: theme.palette.danger[100],
    '&:hover': {
      backgroundColor: theme.palette.danger[200],
    },
  },
}));

export default ConsentRecipientAddModal;
