export const utf8Bytes = (text: string) => {
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(text);

  return byteArray.length;
};

export const eucKrBytes = (text: string) => {
  let byteLength = 0;

  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    if (charCode <= 0x007f) {
      byteLength += 1; // ASCII 문자
    } else if (charCode <= 0x07ff) {
      byteLength += 2; // 라틴 문자 등
    } else if (charCode <= 0xffff) {
      byteLength += 2; // 한글 등 BMP 문자
    } else {
      byteLength += 3; // 나머지 문자 (잘 사용되지 않음)
    }
  }
  return byteLength;
};

export const generateRandomString = (length: number) => {
  // 사용할 문자들을 정의
  const numbers = '0123456789';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const allChars = numbers + lowercase + uppercase;

  // 결과 문자열을 저장할 변수
  let result = '';

  // 지정된 길이만큼 랜덤 문자 선택
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    result += allChars[randomIndex];
  }

  return result;
};
