import {
  GridExcelExportOptions,
  gridFilteredSortedRowIdsSelector,
  GridInitialState,
  GridPaginationModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import * as _ from 'lodash-es';
import { useCallback, useLayoutEffect, useState } from 'react';

export interface UseGridUtilsProps {
  key: string;
  activeSaveSnapshot?: boolean;
  defaultInitialState?: GridInitialState;
}

const useGridUtils = (props: UseGridUtilsProps) => {
  const { key, activeSaveSnapshot, defaultInitialState = {} } = props;

  const localStorageKey = `data-grid-state-${key}`;

  const datagridApiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialState>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const exportExcel = useCallback(
    (options?: GridExcelExportOptions) => {
      datagridApiRef.current.exportDataAsExcel(options);
    },
    [datagridApiRef],
  );

  const getExcelData = useCallback(
    <V = unknown>(options?: { includeAllColumns: boolean }) => {
      if (!datagridApiRef.current) return { headers: [], rows: [] };

      let targetColumns = options?.includeAllColumns
        ? datagridApiRef.current.getAllColumns()
        : datagridApiRef.current.getVisibleColumns();
      targetColumns = targetColumns.filter((col) => col.field !== '__check__');

      // Select rows and columns
      const filteredSortedRowIds =
        gridFilteredSortedRowIdsSelector(datagridApiRef);

      const visibleColumnsField = targetColumns.map((col) => col.field);
      const headers: string[] = targetColumns.map(
        (col) => col.headerName || '',
      );
      const rows = [
        ...filteredSortedRowIds.map((id) => {
          return visibleColumnsField.map((field) => {
            const cell = datagridApiRef.current.getCellParams(id, field);
            const value =
              typeof cell.value === 'boolean'
                ? cell.value
                  ? 'O'
                  : ''
                : (cell.value as V);
            return _.isArray(value)
              ? value.map((v) =>
                  typeof v.value === 'boolean' ? (v.value ? 'O' : '') : v.value,
                )
              : value;
          });
        }),
      ];

      return { headers, rows };
    },
    [datagridApiRef],
  );

  const saveSnapshot = useCallback(() => {
    if (
      activeSaveSnapshot &&
      datagridApiRef?.current?.exportState &&
      localStorage
    ) {
      const currentState = datagridApiRef.current.exportState();
      localStorage.setItem(localStorageKey, JSON.stringify(currentState));
    }
  }, [datagridApiRef, localStorageKey, activeSaveSnapshot]);

  const cleanSnapshot = useCallback(() => {
    if (datagridApiRef?.current?.restoreState && localStorage) {
      datagridApiRef.current
        .getAllColumns()
        .forEach((col) =>
          datagridApiRef.current.setColumnWidth(col.field, col.minWidth || 100),
        );

      localStorage.removeItem(localStorageKey);
      setInitialState(defaultInitialState);
      datagridApiRef.current.restoreState(defaultInitialState);
    }
  }, [datagridApiRef, defaultInitialState, localStorageKey]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage.getItem(localStorageKey);
    const initailStateFromLocalStorage = stateFromLocalStorage
      ? JSON.parse(stateFromLocalStorage)
      : defaultInitialState;
    setInitialState(initailStateFromLocalStorage);
    datagridApiRef.current?.restoreState?.(initailStateFromLocalStorage);

    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datagridApiRef, localStorageKey, saveSnapshot]);

  return {
    datagridApiRef,
    initialState,
    saveSnapshot,
    cleanSnapshot,
    exportExcel,
    getExcelData,
    paginationModel,
    onPaginationModelChange: setPaginationModel,
  };
};

export default useGridUtils;
