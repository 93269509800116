import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import { deepFind } from '@wooriga/common-utils';
import {
  Button,
  Callout,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
  TextField,
  Typography,
} from '@wooriga/design-system';
import { ReactNode, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useReveal } from 'hooks/useReveal';
import { useMeetsPasswordVerifyMutation } from 'lim/generalMeetingHistoryDetail/apis';

const schema = yup.object().shape({
  isAgree: yup.boolean().required(),
  password: yup.string().required(),
});

interface MeetPasswordConfirmModalProps {
  meetSeq: number;
  title: ReactNode | ReactNode[];
  detail: ReactNode | ReactNode[];
  open: boolean;
  onSuccess: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

const MeetPasswordConfirmModal = ({
  meetSeq,
  title,
  detail,
  open,
  onSuccess,
  onCancel,
  onClose,
}: MeetPasswordConfirmModalProps) => {
  const { openReveal } = useReveal();

  const passwordVerify = useMeetsPasswordVerifyMutation(meetSeq);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { password: '' },
  });

  const handleConfirm = handleSubmit(
    (data) => {
      passwordVerify.mutate(
        { password: data.password },
        {
          onSuccess: () => {
            onSuccess();
            onClose?.();
          },
          onError: ({ response, message }) => {
            openReveal('alert', {
              detail: response?.data.message ?? message,
            });
          },
        },
      );
    },
    (errors) => {
      const message = deepFind('message', errors);
      return openReveal('alert', {
        detail: message,
      });
    },
  );

  const handleCancel = useCallback(() => {
    onCancel?.();
    onClose?.();
  }, [onCancel, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        maxWidth="sm"
        component="form"
        onSubmit={handleConfirm}
        onReset={handleCancel}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <Stack gap={2}>
            <Callout
              color="danger"
              variant="outlined"
              size="lg"
              startDecorator={<InfoIcon />}
            >
              <Typography textColor="danger.700">{detail}</Typography>
            </Callout>

            <Controller
              name="isAgree"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  color="primary"
                  label="위 사항을 모두 확인하였습니다."
                  sx={{ alignSelf: 'center' }}
                  checked={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  type="password"
                  label="총회 비밀번호"
                  placeholder="총회 비밀번호를 입력하세요."
                  required
                  {...field}
                />
              )}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button type="submit">다음</Button>
          <Button type="reset" variant="outlined" color="neutral">
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default MeetPasswordConfirmModal;
