import {
  ForwardedRef,
  forwardRef,
  Suspense,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import {
  RevealComponentHandler,
  RevealWrapperProps,
} from 'common-reveal/types';

const RevealWrapper = (
  { element: RevealElement, elementProps, onExit }: RevealWrapperProps,
  ref: ForwardedRef<RevealComponentHandler>,
) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => ({
      close: handleClose,
    }),
    [handleClose],
  );

  return (
    <Suspense>
      <RevealElement
        open={isOpen}
        onClose={handleClose}
        onExit={onExit}
        {...elementProps}
      />
    </Suspense>
  );
};

export default forwardRef(RevealWrapper);
