import {
  Stack,
  TextField,
  Typography,
  DataGrid,
  Button,
  FormControl,
  FormLabel,
  Select,
  Option,
  useGridUtils,
  Pagination,
  Grid,
} from '@wooriga/design-system';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  CertificateExtensionBody,
  CertificateMeetData,
  useCertificateExtensionMutation,
} from 'apis/meet/certificate/api';
import { CERTIFICATE_TABLE_COLUMNS } from 'apis/meet/certificate/fixtures';
import { useReadCertificateMeets } from 'apis/meet/certificate-meets/api';
import { useCommonCodes } from 'components/CommonCode/useCommonCodes';
import PasswordConfirmModal from 'components/pages/common/PasswordConfirmModal';
import ExtensionModal from 'components/pages/meet-management/certifications/modals/ExtensionModal';
import Search from 'components/Search';
import useCreateGridColumns from 'hooks/useCreateGridColumns_legacy';
import useFeedback from 'hooks/useFeedback';
import useQueryControls from 'hooks/useQueryControls';
import certificationDetail from 'pages/main/meet-management/certifications/detail';
import IndexTemplate from 'templates/IndexTemplate';
import { commaizeNumber } from 'utils/format';

const defaultSearchParams = {
  meetMethod: 'ALL',
  meetName: '',
};

const CertificationPage = () => {
  const { snackbar } = useFeedback();

  const params = useParams();
  const unionSeq = Number(params.unionSeq);

  const { getGroupCode } = useCommonCodes();

  const { methodGroup } = useMemo(
    () => ({
      methodGroup: getGroupCode('MEET_METHOD'),
    }),
    [getGroupCode],
  );

  const { datagridApiRef, exportExcel } = useGridUtils({
    key: 'certification-list',
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showExtensionModal, setShowExtensionModal] = useState(false);

  const [selectedData, setSelectedData] = useState<CertificateMeetData>();

  const [selectedId, setSelectedId] = useState(0);

  const {
    searchState,
    setSearchState,
    paginationState,
    setPaginationState,
    initState,
  } = useQueryControls({
    defaultSearchParams: { unionSeq, ...defaultSearchParams },
    initSearchParams: { unionSeq, ...defaultSearchParams },
  });

  const searchParams = useMemo(() => {
    const { meetMethod, ...other } = searchState;
    const params = {
      ...other,
      meetMethod: meetMethod === 'ALL' ? '' : meetMethod,
    };
    return params;
  }, [searchState]);

  const { data, isLoading, isError, error, refetch } =
    useReadCertificateMeets(searchParams);

  const { mutate } = useCertificateExtensionMutation(selectedId);

  const handleConfirmModal = useCallback((meetSeq: number) => {
    setShowConfirmModal(true);
    setSelectedId(meetSeq);
  }, []);

  const handleExtensionClick = useCallback(
    (meetSeq: number) => {
      setShowExtensionModal(true);
      setSelectedId(meetSeq);

      const selectedData = data?.data.find((row) => {
        return row.meet.meetSeq === meetSeq;
      });

      if (selectedData) setSelectedData(selectedData);
    },
    [data?.data],
  );

  const handleOnSubmit = (formData: CertificateExtensionBody) => {
    mutate(formData, {
      onSuccess: () => {
        snackbar('문서보간 기간 연장 신청이 완료되었습니다.', {
          color: 'success',
        });
        refetch();
      },
      onError: (e) => {
        snackbar(e.response?.data.message ?? e.message, {
          color: 'danger',
        });
      },
    });
  };

  const rows = useMemo(() => {
    return data?.data || [];
  }, [data?.data]);

  const handlers = useMemo(
    () => ({
      onClickName: handleConfirmModal,
      onClickExtension: handleExtensionClick,
    }),
    [handleConfirmModal, handleExtensionClick],
  );
  const { columns } = useCreateGridColumns({
    columns: CERTIFICATE_TABLE_COLUMNS,
    handlers,
  });

  const totalElements = data?.pagination?.totalElements || 0;
  const totalDataCount = data?.pagination?.totalDataCount || 0;
  const pageTotalCount =
    totalElements && Math.ceil(totalElements / paginationState.pageSize);

  if (isError) throw error;

  return (
    <>
      <IndexTemplate
        SearchComponent={
          <Search
            values={searchState}
            onSubmit={setSearchState}
            onReset={initState}
          >
            <Grid container gap={2}>
              <Grid xs={12} maxWidth={200}>
                <Search.Field>
                  <TextField
                    placeholder="총회명을 입력하세요."
                    label="총회명"
                    name="meetName"
                    fullWidth
                  />
                </Search.Field>
              </Grid>
              <Grid xs={12} maxWidth={200}>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>총회방식</FormLabel>
                  <Search.SelectField>
                    <Select
                      sx={{ width: '100%' }}
                      placeholder="총회방식을 선택하세요."
                      name="meetMethod"
                    >
                      <Option value="ALL">전체</Option>
                      {methodGroup?.items.map((item) => (
                        <Option key={item.code} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Search.SelectField>
                </FormControl>
              </Grid>
            </Grid>
          </Search>
        }
        TopPanelComponent={
          <Stack flexDirection="row" gap={1}>
            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              전체{' '}
              <Typography color="primary">
                {commaizeNumber(totalDataCount)}
              </Typography>
            </Typography>

            <Typography fontSize="md" fontWeight="lg" lineHeight="md">
              조회 목록{' '}
              <Typography color="primary">
                {commaizeNumber(totalElements)}
              </Typography>
            </Typography>
          </Stack>
        }
        IndexComponent={
          <DataGrid
            apiRef={datagridApiRef}
            rows={rows}
            columns={columns}
            loading={isLoading}
            getRowId={(row) => row.meet.meetSeq}
            disableRowSelectionOnClick
            pagination
            paginationModel={paginationState}
            onPaginationModelChange={setPaginationState}
          />
        }
        PaginationComponent={
          <Pagination
            color="neutral"
            variant="plain"
            size="md"
            orientation="horizontal"
            showFirstButton
            showLastButton
            hidePrevButton={false}
            hideNextButton={false}
            count={pageTotalCount}
            page={paginationState.page + 1}
            onChange={(_, page) => {
              page &&
                setPaginationState({
                  page: page - 1,
                  pageSize: paginationState.pageSize,
                });
            }}
          />
        }
        BottomPanelComponent={
          <Stack direction="row" gap={1} alignSelf="end">
            <Button
              color="neutral"
              variant="outlined"
              onClick={() => {
                exportExcel();
              }}
            >
              내역 다운
            </Button>
          </Stack>
        }
      />
      <PasswordConfirmModal
        meetSeq={selectedId}
        open={showConfirmModal}
        onClose={setShowConfirmModal}
      />

      <ExtensionModal
        name={selectedData?.meet.name}
        endAt={selectedData?.documentStorageEndAt}
        open={showExtensionModal}
        onClose={setShowExtensionModal}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

const certification = {
  path: '/unions/:unionSeq/meet-management/certifications',
  children: [
    {
      index: true,
      element: <CertificationPage />,
      handle: {
        getTitle: () => '증명서 발급',
      },
    },
    certificationDetail,
  ],
  handle: {
    getTitle: () => '증명서 발급',
    getMenuCode: () => 'M1005',
  },
};

export default certification;
