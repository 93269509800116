import { GridColDef } from '@mui/x-data-grid-premium';
import { Link } from '@wooriga/design-system';

import { MeetParticipantInfo } from 'apis/types/meet';
import MeetAttendanceCategoryChip from 'components/Chips/MeetAttendanceCategoryChip';
import MeetVoteCategoryChip from 'components/Chips/MeetVoteCategoryChip';
import { GridColumnsHandlers } from 'types/grid';

export const MEET_PARTICIPANTS_TABLE_COLUMNS = ({
  getCommonCode,
  onClickName,
}: GridColumnsHandlers<{
  onClickName: (row: MeetParticipantInfo) => void;
}>): GridColDef<MeetParticipantInfo>[] => [
  {
    field: 'unionRegisterNo',
    headerName: '연번',
    sortComparator: (v1, v2) => {
      const value1 = v1?.split('-');
      const value2 = v2?.split('-');

      if (value1 && value2) {
        const v1Main = Number(value1[0]);
        const v1Sub = Number(value1[1]);
        const v2Main = Number(value2[0]);
        const v2Sub = Number(value2[1]);

        if (v1Main - v2Main === 0) {
          return v1Sub - v2Sub;
        }

        return v1Main - v2Main;
      }

      return 1;
    },
  },
  {
    field: 'name',
    headerName: '조합원 이름',
    width: 160,
    minWidth: 160,
    valueGetter: (value?: MeetParticipantInfo['name']) =>
      value ? [value.name, value.nameClass].join('') : '이름 없음',
    renderCell: (params) => {
      const { value, row } = params;
      return (
        <Link
          display="inline"
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          onClick={() => onClickName(row)}
        >
          {value}
        </Link>
      );
    },
  },
  {
    field: 'position',
    headerName: '직책',
    valueGetter: (_, row) => row?.positionDescription || '',
  },
  {
    field: 'localAddressDong',
    headerName: '법정동',
    groupable: true,
    groupingValueGetter: (_, row) => row?.localAddresses?.[0]?.dong,
    valueGetter: (_, row) => row?.localAddresses?.[0]?.dong,
  },
  {
    field: 'localAddressJibun',
    headerName: '지번',
    groupable: true,
    groupingValueGetter: (_, row) => row?.localAddresses?.[0]?.jibun,
    valueGetter: (_, row) => row?.localAddresses?.[0]?.jibun,
  },
  {
    field: 'localAddressBuilding',
    headerName: '건물명',
    groupable: true,
    groupingValueGetter: (_, row) => row?.localAddresses?.[0]?.building,
    valueGetter: (_, row) => row?.localAddresses?.[0]?.building,
  },
  {
    field: 'localAddressApartmentDong',
    headerName: '동',
    groupable: true,
    groupingValueGetter: (_, row) => row?.localAddresses?.[0]?.apartmentDong,
    valueGetter: (_, row) => row?.localAddresses?.[0]?.apartmentDong,
  },
  {
    field: 'localAddressApartmentFloor',
    headerName: '층',
    groupable: true,
    groupingValueGetter: (_, row) => row?.localAddresses?.[0]?.apartmentFloor,
    valueGetter: (_, row) => row?.localAddresses?.[0]?.apartmentFloor,
  },
  {
    field: 'agent',
    headerName: '대리인',
    width: 160,
    minWidth: 160,
    valueGetter: (value: MeetParticipantInfo['agent']) =>
      value?.unionRegisterAgentSeq &&
      [value?.name?.name, value?.name?.nameClass].join(''),
  },
  {
    field: 'voteCategory',
    headerName: '의결 유형',
    valueGetter: (value: MeetParticipantInfo['voteCategory']) =>
      value && getCommonCode('VOTE_CATEGORY', value)?.name,
    renderCell: ({ row }) => <MeetVoteCategoryChip code={row.voteCategory} />,
  },
  {
    field: 'attendanceCategory',
    headerName: '직접 출석 유형',
    valueGetter: (value: MeetParticipantInfo['attendanceCategory']) =>
      value && getCommonCode('ATTENDANCE_CATEGORY', value)?.name,
    renderCell: ({ row }) => (
      <MeetAttendanceCategoryChip code={row.attendanceCategory} />
    ),
  },
];
