import {
  useTheme,
  Stack,
  Typography,
  Tabs,
  TabList,
  Tab,
  Chip,
  TabPanel,
} from '@wooriga/design-system';
import { useParams } from 'react-router-dom';

import { generateMeetStatus } from 'components/pages/meet-management/history/detail/utils';
import PromotionConnectionTab from 'components/pages/meet-management/promotion/TabPanels/PromotionConnectionTab';
import PromotionExecutionTab from 'components/pages/meet-management/promotion/TabPanels/PromotionExecutionTab';
import PromotionHeadTab from 'components/pages/meet-management/promotion/TabPanels/PromotionHeadTab';
import { useMeetsDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { CustomRouteObject } from 'types/route';

const PromotionDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const meetSeq = Number(id);

  const theme = useTheme();

  const { data, isError, error } = useMeetsDetailQuery(meetSeq);

  if (isError) throw error;

  return (
    <Stack gap={2}>
      <Stack>
        <Typography level="title-lg" marginBottom="15px">
          총회 내역 상세
        </Typography>
        <Stack>
          <Tabs
            defaultValue={0}
            selectedTabColor="primary"
            selectedTabVariant="plain"
            tabIndicatorInset
            sx={{
              marginBottom: '30px',
              background: theme.palette.background.body,
            }}
          >
            <TabList>
              <Tab value={0}>홍보 팀장 관리</Tab>
              <Tab value={1}>접속이력</Tab>
              <Tab value={2}>사용이력</Tab>
            </TabList>

            <Stack
              padding="16px"
              flexDirection="row"
              gap={1.75}
              alignItems="center"
            >
              <Typography level="title-md">{data?.data?.name}</Typography>
              <Chip color="primary">
                {generateMeetStatus(data?.data?.meetStatus || 'BEFORE')}
              </Chip>
            </Stack>
            <TabPanel value={0}>
              <PromotionHeadTab
                meetStatus={data?.data.meetStatus || 'BEFORE'}
                meetSeq={meetSeq}
              />
            </TabPanel>
            <TabPanel value={1}>
              <PromotionConnectionTab meetSeq={meetSeq} />
            </TabPanel>

            <TabPanel value={2}>
              <PromotionExecutionTab meetSeq={meetSeq} />
            </TabPanel>
          </Tabs>
        </Stack>
      </Stack>
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <PromotionDetailPage />,
  handle: {
    getTitle: () => '홍보 관리 상세',
  },
};

const promotionDetail: CustomRouteObject = {
  path: ':id',
  children: [route],
  handle: {
    getTitle: () => '홍보 관리 상세',
  },
};

export default promotionDetail;
