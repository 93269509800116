import {
  useTheme,
  PieCenterLabel,
  PieChart,
  PieChartProps,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { PropsWithChildren } from 'react';

import EmptyText from 'components/pages/meet-management/history/detail/tabs/VoteHomeTab/Charts/EmptyData';

import PercentLabel from './PercentLabel';
import RateLabel from './RateLabel';

interface PieChartWrapperProps {
  size?: 'lg' | 'md';
  title: string;
  series: PieChartProps['series'];
  labelHidden?: boolean;
}

const PieChartWrapper = ({
  size,
  title,
  series,
  labelHidden,
  children,
}: PropsWithChildren<PieChartWrapperProps>) => {
  const theme = useTheme();

  const chartContainerStyle = {
    background: theme.palette.background.surface,
    padding: '12px 20px',
    boxSizing: 'borderBox',
    width: labelHidden ? '238px' : '338px',
    height: '216px',
    scale: size === 'lg' ? 1.3 : 1,
  };

  const slotProps = labelHidden ? { legend: { hidden: true } } : undefined;

  return (
    <Stack gap={1} sx={chartContainerStyle}>
      <Typography level="body-md">{title}</Typography>
      {series[0].data.length > 0 ? (
        <PieChart
          series={series}
          width={298}
          height={160}
          slotProps={slotProps}
        >
          {children}
        </PieChart>
      ) : (
        <EmptyText />
      )}
    </Stack>
  );
};

const PercentLabelWrapper = ({
  dy,
  size,
  label,
}: {
  size?: 'lg' | 'md' | 'sm';
  dy?: string;
  label: string;
}) => {
  return (
    <PieCenterLabel>
      <PercentLabel size={size} text={label} dy={dy} />
    </PieCenterLabel>
  );
};

const RateLabelWrapper = ({
  size,
  label,
}: {
  size?: 'lg' | 'md' | 'sm';
  label: string;
}) => {
  return (
    <PieCenterLabel>
      <RateLabel size={size} text={label} />
    </PieCenterLabel>
  );
};

PieChartWrapper.PercentLabel = PercentLabelWrapper;
PieChartWrapper.RateLabel = RateLabelWrapper;

export default PieChartWrapper;
