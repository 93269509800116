import { useMemo } from 'react';

import { usePremiumServiceQuery } from 'apis/meet/live/api';
import { MeetMethods, MeetStatus } from 'apis/types/meet';
import ApplyingStep from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/steps/ApplyingStep';
import ConfirmedStep from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/steps/ConfirmedStep';
import CreatedStep from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/steps/CreatedStep';
import PendingStep from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/steps/PendingStep';

export interface LiveMeetProps<MeetMethod extends MeetMethods> {
  meetSeq: number;
  meetMethod: MeetMethod;
  meetStatus: MeetStatus;
  meetName: string;
  meetAt: string;
}

const LiveMeetTab = <MeetMethod extends MeetMethods>({
  meetSeq,
  meetMethod,
  meetStatus,
  meetName,
  meetAt,
}: LiveMeetProps<MeetMethod>) => {
  console.log(meetSeq, meetMethod, meetStatus, meetName, meetAt);

  const {
    data: premiumServiceQuery,
    refetch,
    isLoading,
    isPending,
  } = usePremiumServiceQuery(meetSeq);

  const premiumServiceStatus = useMemo(
    () => premiumServiceQuery?.data,
    [premiumServiceQuery?.data],
  );

  const renderLiveMeetStep = (status?: string) => {
    switch (status) {
      case 'PENDING':
        return <PendingStep meetSeq={meetSeq} refetch={refetch} />;
      case 'APPLYING':
        return <ApplyingStep meetSeq={meetSeq} refetch={refetch} />;
      case 'CONFIRMED':
        return <ConfirmedStep meetSeq={meetSeq} refetch={refetch} />;

      case 'CREATED':
        return <CreatedStep meetSeq={meetSeq} meetAt={meetAt} />;
    }
  };

  if (isLoading || isPending) return;
  return renderLiveMeetStep(premiumServiceStatus);
};

export default LiveMeetTab;
