import { Button, Stack } from '@wooriga/design-system';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { Form } from 'react-router-dom';

import { useFileDownloadMutation } from 'apis/common/apis';
import { MeetMethods, MeetStatus } from 'apis/types/meet';
import AgendaResultGrid from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ResultContent/AgendaResultGrid';
import StatusButton from 'components/pages/meet-management/history/detail/tabs/TallyResultTab/ResultContent/StatusButton';
import { useReveal } from 'hooks/useReveal';
import {
  MeetsDetailQuery,
  VoteResult,
} from 'lim/generalMeetingHistoryDetail/apis';

export interface ResultContentProps {
  control: Control;
  isMock: boolean;

  meetMethod: MeetMethods;
  meetStatus: MeetStatus;
  meetsDetailQuery: MeetsDetailQuery;
  result: VoteResult[];

  onSubmit?: (meetStatus: MeetStatus) => void;
  onReset?: () => void;
}

const ResultContent = ({
  control,
  isMock,

  meetMethod,
  meetStatus,
  meetsDetailQuery,
  result,

  onSubmit,
  onReset,
}: ResultContentProps) => {
  const { openReveal } = useReveal();
  const { mutateAsync: downloadFile } = useFileDownloadMutation();

  const isElectronic = meetMethod === 'ELECTRONIC';
  const isOnsite = meetMethod === 'ONSITE';
  const isOverall = meetMethod === 'ONSITE_ELECTRONIC';

  const electronicVoteEndTime =
    meetsDetailQuery.data?.data.electronicVote?.voteEndAt;

  const isElectronicVoteEnded = !!(
    electronicVoteEndTime &&
    new Date(electronicVoteEndTime).getTime() <= new Date().getTime()
  );

  const handlePDFDownload = () => {
    downloadFile(
      { fileSeq: meetsDetailQuery.data?.data.voteResultFileSeq },
      {
        onSuccess: (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;

          link.download = '총 투표결과.pdf';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          URL.revokeObjectURL(url);
        },
        onError: ({ response, message }) => {
          const errorMessage = response?.data.message ?? message;

          openReveal('snackbar', {
            color: 'danger',
            children: errorMessage,
          });
        },
      },
    );
  };

  const showGrid = useMemo(() => {
    if (isElectronic) {
      switch (meetStatus) {
        case 'COUNTING':
          return true;
        case 'COMPLETE':
          return true;
        default:
          return false;
      }
    }

    if (isOnsite || isOverall) {
      switch (meetStatus) {
        case 'PROCESS':
          return false;
        case 'COUNTING':
          return true;
        case 'COMPLETE':
          return true;
        default:
          return false;
      }
    }

    return false;
  }, [isElectronic, isOnsite, isOverall, meetStatus]);

  return (
    <Form onSubmit={() => onSubmit?.(meetStatus)}>
      <Stack gap={3}>
        {showGrid &&
          result.map((agenda, index) => (
            <AgendaResultGrid
              key={`agenda-${agenda.agendaSeq}`}
              name={`results.${index}`}
              control={control}
              meetMethod={meetMethod}
              meetStatus={meetStatus}
              data={agenda}
            />
          ))}

        <StatusButton
          isMock={isMock}
          isElectronicVoteEnded={isElectronicVoteEnded}
          meetMethod={meetMethod}
          meetStatus={meetStatus}
          onReset={onReset}
        />

        {!isMock && meetStatus === 'COMPLETE' && (
          <Button onClick={handlePDFDownload}>총 투표결과 PDF 다운로드</Button>
        )}
      </Stack>
    </Form>
  );
};

export default ResultContent;
