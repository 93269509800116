import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  useTheme,
} from '@wooriga/design-system';
import { useParams } from 'react-router-dom';

import CertificationTab from 'components/pages/consent-management/certifications/tabs/CertificationTab';
import { CustomRouteObject } from 'types/route';

const CertificationDetailPage = () => {
  const theme = useTheme();
  const params = useParams();

  const unionSeq = Number(params.unionSeq);
  const consentSeq = Number(params.consentSeq);

  return (
    <Stack gap={2}>
      <Tabs
        defaultValue={0}
        selectedTabColor="primary"
        selectedTabVariant="plain"
        tabIndicatorInset
        sx={{
          marginBottom: '30px',
          background: theme.palette.background.body,
        }}
      >
        <TabList>
          <Tab value={0}>제출 내역 증명서</Tab>
          <Tab value={1}>전자 문서 증명서</Tab>
        </TabList>

        <TabPanel value={0}>
          <CertificationTab unionSeq={unionSeq} consentSeq={consentSeq} />
        </TabPanel>
        <TabPanel value={1}>
          <CertificationTab unionSeq={unionSeq} consentSeq={consentSeq} />
        </TabPanel>
      </Tabs>
    </Stack>
  );
};

const route: CustomRouteObject = {
  path: '',
  index: true,
  element: <CertificationDetailPage />,
  handle: {
    getTitle: () => '증명서 발급 상세',
  },
};

const electronicConsentCertificationDetail = {
  path: ':consentSeq',
  children: [route],
  handle: {
    getTitle: () => '증명서 발급 상세',
  },
};

export default electronicConsentCertificationDetail;
