import {
  useTheme,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Sheet,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import DescriptionsRenderer from 'components/DescriptionsRenderer';
import { generateDescriptionsJSON } from 'components/DescriptionsRenderer/util';
import { MeetsParticipantHistoryDetailQuery } from 'lim/generalMeetingHistoryDetail/apis';
import { formatBirth, formatDateTime, formatTelephone } from 'utils/format';

import { combineValues, compareObjects } from './util';

interface DetailModalProps {
  meetsParticipantHistoryDetailQuery: MeetsParticipantHistoryDetailQuery;
  open: boolean;
  onClose: (open: false) => void;
}

const DetailModal = ({
  meetsParticipantHistoryDetailQuery,
  open,
  onClose,
}: DetailModalProps) => {
  const historyDetailData = meetsParticipantHistoryDetailQuery.data;

  const theme = useTheme();

  const {
    infoValues,
    agentValues,
    postAddressValues,
    realAddressValues,
    reasonValues,
    companyValues,
    publicValues,
  } = useMemo(() => {
    if (!historyDetailData?.data) {
      return {
        infoValues: [],
        companyValues: [],
        publicValues: [],
        agentValues: [],
        postAddressValues: [],
        realAddressValues: [],
        reasonValues: '',
      };
    }
    const { reason, participant, previousParticipant } = historyDetailData.data;
    const comparedObject = compareObjects(participant, previousParticipant);

    if (previousParticipant?.ownerType) {
      if (previousParticipant.ownerType === 'PERSON')
        previousParticipant.ownerType = '개인';
      if (previousParticipant.ownerType === 'COMPANY')
        previousParticipant.ownerType = '법인';
      if (previousParticipant.ownerType === 'PUBLIC')
        previousParticipant.ownerType = '국공유지';
      if (previousParticipant.ownerType === 'NONE')
        previousParticipant.ownerType = '미지정';
    }

    if (participant?.ownerType) {
      if (participant.ownerType === 'PERSON') participant.ownerType = '개인';
      if (participant.ownerType === 'COMPANY') participant.ownerType = '법인';
      if (participant.ownerType === 'PUBLIC')
        participant.ownerType = '국공유지';
      if (participant.ownerType === 'NONE') participant.ownerType = '미지정';
    }

    if (previousParticipant?.gender) {
      if (previousParticipant.gender === 'M')
        previousParticipant.gender = '남자';
      if (previousParticipant.gender === 'F')
        previousParticipant.gender = '여자';
    }

    if (participant?.gender) {
      if (participant.gender === 'M') participant.gender = '남자';
      if (participant.gender === 'F') participant.gender = '여자';
    }

    const differentData =
      combineValues(participant, comparedObject) || participant;
    const comparedParticipant = {
      ...participant,
      ...differentData,
    };
    const {
      unionRegisterNo,
      position,
      agent,
      name,
      gender,
      birth,
      ownerType,
      companyNo,
      phoneNo,
      postAddress,
      realAddress,
    } = comparedParticipant;
    const infoValues = [
      unionRegisterNo,
      position,
      name?.name,
      name?.nameClass,
      ownerType,
      formatBirth(birth),
      gender,
      formatTelephone(phoneNo),
    ];
    const companyValues = [
      unionRegisterNo,
      position,
      name?.name,
      name?.nameClass,
      ownerType,
      companyNo,
      formatTelephone(phoneNo),
    ];

    const publicValues = [
      unionRegisterNo,
      position,
      name?.name,
      name?.nameClass,
      ownerType,
      formatTelephone(phoneNo),
    ];

    const createAgentData = () => {
      if (agent) {
        const {
          name: aName,
          birth: aBirth,
          gender: aGender,
          phoneNo: aPhoneNoe,
        } = agent;
        const agentValues = [
          aName?.name,
          aName?.nameClass,
          formatBirth(aBirth),
          aGender,
          formatTelephone(aPhoneNoe),
        ];
        return agentValues;
      }
      return [];
    };
    const postAddressValues = [
      postAddress?.address,
      postAddress?.detailAddress,
    ];
    const realAddressValues = [
      realAddress?.address,
      realAddress?.detailAddress,
    ];
    return {
      infoValues,
      agentValues: createAgentData(),
      postAddressValues,
      realAddressValues,
      reasonValues: reason,
      companyValues,
      publicValues,
    };
  }, [historyDetailData?.data]);

  const infoJSON = generateDescriptionsJSON(
    [
      '연번',
      '직책',
      '이름',
      '이름구분',
      '구분',
      '생년월일',
      '성별',
      '조합원 연락처',
    ],
    infoValues,
  );
  const companyJSON = generateDescriptionsJSON(
    [
      '연번',
      '직책',
      '이름/법인명',
      '이름구분',
      '구분',
      '사업자 등록번호',
      '조합원 연락처',
    ],
    companyValues,
  );
  const publicJSON = generateDescriptionsJSON(
    ['연번', '직책', '이름/법인명', '이름구분', '구분', '조합원 연락처'],
    publicValues,
  );

  const agentJSON = generateDescriptionsJSON(
    ['이름', '이름구분', '생년월일', '성별', ' 연락처'],
    agentValues,
  );

  const addressJSON = generateDescriptionsJSON(
    ['주소', '상세 주소'],
    postAddressValues,
  );

  const realAddressJSON = generateDescriptionsJSON(
    ['주소', '상세주소'],
    realAddressValues,
  );

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <ModalDialog minWidth={640}>
        {/* <DialogTitle>선거인 변동 상세 이력</DialogTitle> */}
        <DialogTitle>
          {historyDetailData?.data?.actionType === 'ADD'
            ? '선거인 추가 이력'
            : historyDetailData?.data?.actionType === 'DELETE'
              ? '선거인 삭제 이력'
              : historyDetailData?.data?.actionType === 'EDIT'
                ? '선거인 수정 이력'
                : ''}
        </DialogTitle>
        <DialogContent>
          <Typography level="title-md" marginTop={4}>
            조합원 정보
          </Typography>
          {historyDetailData?.data?.participant?.ownerType === '법인' ? (
            <DescriptionsRenderer columns={2} json={companyJSON} />
          ) : historyDetailData?.data?.participant?.ownerType === '개인' ? (
            <DescriptionsRenderer columns={2} json={infoJSON} />
          ) : (
            <DescriptionsRenderer columns={2} json={publicJSON} />
          )}

          {agentValues?.length > 0 ? (
            <>
              <Typography level="title-md" marginTop={4}>
                대리인 정보
              </Typography>
              <DescriptionsRenderer columns={2} json={agentJSON} />
            </>
          ) : (
            ''
          )}

          <Typography level="title-md" marginTop={4}>
            실제 거주지
          </Typography>
          <DescriptionsRenderer columns={2} json={realAddressJSON} />

          <Typography level="title-md" marginTop={4}>
            우편물 수령지
          </Typography>
          <DescriptionsRenderer columns={2} json={addressJSON} />

          {historyDetailData?.data?.actionType === 'DELETE' ? (
            <>
              <Typography level="title-md" marginTop={4}>
                선거인 삭제 사유
              </Typography>
              {/* <DescriptionsRenderer columns={2} json={deleteJSON} /> */}
              <Sheet
                variant="outlined"
                color="neutral"
                sx={{
                  padding: 1.5,
                  borderRadius: theme.radius.sm,
                }}
              >
                <Typography>{reasonValues}</Typography>
              </Sheet>
            </>
          ) : (
            ''
          )}

          <Stack marginTop={3} alignSelf="end" direction="row">
            <Typography mr={1} color="neutral">
              변동일시{' '}
            </Typography>
            <Typography>
              {formatDateTime(
                historyDetailData?.data.modifiedAt,
                'yyyy.MM.dd HH:MM',
              )}{' '}
              <Chip variant="outlined" color="neutral" size="sm">
                {historyDetailData?.data.modifiedBy}
              </Chip>
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button fullWidth onClick={() => onClose(false)}>
            확인
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default DetailModal;
