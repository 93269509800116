import {
  useTheme,
  AspectRatio,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  ModalOverflow,
  ModalProps,
  Sheet,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFileDownloadMutation } from 'apis/common/apis';

const INITIAL_DATA = {
  title: '',
  message: '',
  files: [],
};

export interface MessagePreviewModalProps
  extends Omit<ModalProps, 'onClose' | 'children'> {
  data: {
    title: string | undefined | null;
    message: string | undefined | null;
    files?: (File | string)[];
  };
  onClose: (value: boolean) => void;
}

const MessagePreviewModal = (props: MessagePreviewModalProps) => {
  const { data = INITIAL_DATA, onClose, ...rest } = props;
  const { title, message, files } = data;

  const [images, setImages] = useState<string[]>();

  const theme = useTheme();

  const { mutateAsync: downloadFile } = useFileDownloadMutation();

  const isContentAvailable = useMemo(
    () => (title && title.length > 0) || (message && message.length > 0),
    [title, message],
  );

  const initialImages = useCallback(async () => {
    if (!files || files?.length === 0) {
      return;
    }

    const results = await Promise.all(
      files.map(async (file) => {
        if (typeof file === 'string') {
          const blobFile = await downloadFile({ filePath: file });
          return URL.createObjectURL(blobFile);
        }

        return URL.createObjectURL(file);
      }),
    );

    setImages(results);
  }, [files, downloadFile]);

  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose(false);
  };

  const handleLoadImage = (url: string) => () => {
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    initialImages();
  }, [initialImages]);

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalOverflow>
        <ModalDialog maxWidth={440}>
          <DialogTitle>미리보기</DialogTitle>

          <DialogContent sx={{ py: 1 }}>
            <Stack direction="column" gap={2.5}>
              <Typography level="body-md">
                보여지는 이미지와 문자 내용은 휴대폰 기종과 설정에 따라 다르게
                보일 수 있습니다. <br />
                실제 발송되는 이미지 순서는 보여지는 것과 다를 수 있습니다.
              </Typography>

              <Sheet
                variant="outlined"
                sx={{
                  height: '480px',
                  padding: '24px 16px',
                  borderRadius: theme.radius.sm,
                  overflowY: 'auto',
                }}
              >
                <Stack direction="column" gap={2}>
                  {images?.map((url) => {
                    return (
                      <AspectRatio
                        key={`message_image_${url}`}
                        ratio="1.4/1"
                        objectFit="fill"
                      >
                        <img
                          src={url}
                          alt="이미지"
                          onLoad={handleLoadImage(url)}
                        />
                      </AspectRatio>
                    );
                  })}

                  {isContentAvailable && (
                    <Sheet
                      variant="soft"
                      color="neutral"
                      sx={{
                        padding: 2,
                        borderRadius: theme.radius.sm,
                      }}
                    >
                      <Stack gap={1.25}>
                        <Typography level="title-md">{title}</Typography>
                        <Typography level="body-md">{message}</Typography>
                      </Stack>
                    </Sheet>
                  )}
                </Stack>
              </Sheet>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => onClose(false)}>닫기</Button>
          </DialogActions>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export default MessagePreviewModal;
