import {
  //Chip,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@wooriga/design-system';
import { useRef } from 'react';

import AgendaPanel from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/panels/AgendaPanel';
import AttendeePanel from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/panels/AttendeePanel';
import ConfigPanel from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/panels/ConfigPanel';
import NoticePanel from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/panels/NoticePanel';
import OpinionPanel from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/panels/OpinionPanel';
import SpeechPanel from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/panels/SpeechPanel';

export interface AttendeeControlPanelProps {
  meetSeq: number;
  onlineGeneralMeetingSeq: number;
  noticeMessage: string;
  accessUrl: string;
  meetAt: string;
}

const AttendeeControlPanel = ({
  meetSeq,
  onlineGeneralMeetingSeq,
  noticeMessage,
  accessUrl,
  meetAt,
}: AttendeeControlPanelProps) => {
  const tabListRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Stack flexGrow={1} gap={1.75}>
        <Stack>
          <Stack>
            <Tabs
              defaultValue={0}
              selectedTabColor="primary"
              selectedTabVariant="plain"
              tabIndicatorInset
            >
              <TabList ref={tabListRef}>
                <Tab name="발언 요청" value={0}>
                  발언 요청
                  {/* <Chip size="sm" variant="solid" color="neutral">
                    1
                  </Chip> */}
                </Tab>
                <Tab name="참석자 의견" value={1}>
                  참석자 의견
                  {/* <Chip size="sm" variant="solid" color="neutral">
                    0
                  </Chip> */}
                </Tab>
                <Tab name="참석자 현황" value={2}>
                  참석자 현황
                </Tab>
                <Tab name="안건 목록" value={3}>
                  안건 목록
                </Tab>
                <Tab name="공지 등록" value={4}>
                  공지 등록
                </Tab>
                <Tab name="온라인 총회 설정" value={5}>
                  온라인 총회 설정
                </Tab>
              </TabList>
              <TabPanel value={0}>
                <SpeechPanel
                  meetSeq={meetSeq}
                  onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
                />
              </TabPanel>
              <TabPanel value={1}>
                <OpinionPanel
                  onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
                />
              </TabPanel>
              <TabPanel value={2}>
                <AttendeePanel
                  meetSeq={meetSeq}
                  onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
                  accessUrl={accessUrl}
                  meetAt={meetAt}
                />
              </TabPanel>
              <TabPanel value={3}>
                <AgendaPanel meetSeq={meetSeq} />
              </TabPanel>
              <TabPanel value={4}>
                <NoticePanel
                  meetSeq={meetSeq}
                  onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
                  noticeMessage={noticeMessage}
                />
              </TabPanel>
              <TabPanel value={5}>
                <ConfigPanel
                  meetSeq={meetSeq}
                  onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
                />
              </TabPanel>
            </Tabs>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
export default AttendeeControlPanel;
