import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { ApiError, ApiResponseData } from 'types/api';

export interface OnsiteVote {
  onsiteVoteSeq: number;
  meetAt: string;
  submissionStartAt: string;
  submissionEndAt: string;
  jibunAddress: string;
  roadAddress: string;
  detailAddress: string;
  attendanceRate: number;
  contactNo: string;
}

export interface ElectronicVote {
  electronicVoteSeq: number;
  senderName: string;
  contactNo: string;
  description: string;
  documentNo: string;
  voteStartAt: string;
  voteEndAt: string;
}

export interface Meet {
  no: number;
  meetSeq: number;
  name: string;
  openType: string;
  meetType: string;
  meetMethod: string;
  meetStatus: string;
  meetAt: string;
  agendaCount: number;
  participantCount: number;
  moverName: string;
  createdAt: string;
  onsiteVote: OnsiteVote;
  electronicVote: ElectronicVote;
}

export interface CertificateMeetData {
  meet: Meet;
  documentStorageEndAt: string;
  documentStorageStatus: string;
  documentStorageExtensionStatus: string;
}

export interface ReadCertificateMeetsRequest {
  unionSeq: number;
  meetName?: string;
  meetMethod?: string;
}

export type ReadCertificateMeetsResponse = ApiResponseData<
  CertificateMeetData[]
>;

export const useReadCertificateMeets = (
  params: ReadCertificateMeetsRequest,
): UseQueryResult<ReadCertificateMeetsResponse, ApiError> =>
  useQuery({
    queryKey: ['/v1/certificate-meets', params],
  });
