import { GridColDef } from '@mui/x-data-grid-premium';
import { Link } from '@wooriga/design-system';

import { DocumentIssuance } from 'apis/types/document-issuance';
import { GridColumnsHandlers } from 'types/grid';
import { commaizeNumber, formatDateTime } from 'utils/format';

export const DOCUMENT_ISSUANCES_COLUMNS = ({
  getCommonCode,
}: GridColumnsHandlers) =>
  [
    // {
    //   field: 'no',
    //   headerName: 'No',
    //   width: 78,
    //   valueGetter: (_, row, __, apiRef) =>
    //     apiRef.current.getAllRowIds().indexOf(row?.documentIssuanceSeq) + 1 ||
    //     '',
    // },
    {
      field: 'issuedDocumentCodes',
      headerName: '발급자료',
      width: 260,
      valueGetter: (value: DocumentIssuance['issuedDocumentCodes']) =>
        value
          ?.sort()
          .map((code) => getCommonCode('DOCUMENT_CODE', code)?.name)
          .join(','),
      renderCell: ({ row, value, rowNode }) => {
        if (!value) return;

        const codes = value.split(',');
        const multipleText =
          codes.length > 1 ? ` 외 ${codes.length - 1}건` : '';

        if (rowNode.type === 'group') {
          return `${codes[0]}${multipleText}`;
        }

        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            href={`${row.documentIssuanceSeq}`}
          >
            {codes[0]}
            {multipleText}
          </Link>
        );
      },
    },
    {
      field: 'totalRequestCount',
      headerName: '신청 주소 수',
      valueGetter: (value) =>
        value !== undefined ? `${commaizeNumber(value)} 건` : '',
    },
    {
      field: 'addressCount',
      headerName: '총 주소 수',
      valueGetter: (value) =>
        value !== undefined ? `${commaizeNumber(value)} 건` : '',
    },
    {
      field: 'issuedCount',
      headerName: '발급/열람 완료',
      valueGetter: (value) =>
        value !== undefined ? `${commaizeNumber(value)} 건` : '',
    },
    {
      field: 'failCount',
      headerName: '발급/열람 실패',
      valueGetter: (value) =>
        value !== undefined ? `${commaizeNumber(value)} 건` : '',
    },
    {
      field: 'requestedAt',
      headerName: '신청일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'completedAt',
      headerName: '완료일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) => formatDateTime(value, 'yyyy-MM-dd HH:mm:ss'),
    },
    {
      field: 'requestStatus',
      headerName: '발급상태',
      width: 100,
      valueGetter: (value) =>
        getCommonCode('DOCUMENT_ISSUANCE_STATUS', value)?.name,
    },
  ] as GridColDef<DocumentIssuance>[];
