import { formatTelephone } from '@wooriga/common-utils';
import {
  Button,
  CircularProgress,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Stack,
} from '@wooriga/design-system';
import { useMemo } from 'react';

import { useViewerInfoQuery } from 'apis/meet/live/api';

interface ViewernModalProps {
  viewerSeq: number;
  open: boolean;
  onlineGeneralMeetingSeq: number;
  onClose: (open: false) => void;
}

const ViewerModal = ({
  open,
  onClose,
  onlineGeneralMeetingSeq,
  viewerSeq,
}: ViewernModalProps) => {
  const { data, isPending } = useViewerInfoQuery(
    onlineGeneralMeetingSeq,
    viewerSeq,
  );
  const viewerData = useMemo(() => {
    return data?.data;
  }, [data?.data]);

  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog sx={{ width: 480 }}>
          <DialogTitle>참석자 정보</DialogTitle>
          <DialogContent>
            <Stack gap={3} p={0.25}>
              {isPending && <CircularProgress sx={{ m: 'auto' }} />}
              {!isPending && (
                <Stack gap={1}>
                  <Descriptions
                    color="neutral"
                    size="md"
                    variant="outlined"
                    legacy={false}
                  >
                    <DescriptionsItem label="이름">
                      {viewerData?.name}
                    </DescriptionsItem>
                    <DescriptionsItem label="전화번호">
                      {viewerData?.phone &&
                        formatTelephone(String(viewerData?.phone))}
                    </DescriptionsItem>
                    {Array.isArray(viewerData?.localAddresses) &&
                      viewerData.localAddresses.map((item, index) => (
                        <DescriptionsItem
                          key={index}
                          label={`권리 소재지 ${index + 1}`}
                        >
                          {`${item?.dong || ''} 123 ${item.jibun || ''} ${item.building || ''} ${item.apartmentDong || ''} ${item.apartmentHosu || ''} ${item.jibun || ''}`}
                        </DescriptionsItem>
                      ))}
                  </Descriptions>
                </Stack>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" gap={1}>
              <Button
                size="md"
                color="neutral"
                variant="outlined"
                onClick={() => {
                  onClose(false);
                }}
              >
                확인
              </Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default ViewerModal;
