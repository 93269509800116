import { GridColDef } from '@mui/x-data-grid-premium';
import { Button, Link } from '@wooriga/design-system';

import { Counsel } from 'apis/types/counsel';
import { GridColumnsHandlers } from 'types/grid';
import { formatDateTime } from 'utils/format';

export const COUNSELS_GRID_COLUMNS = ({
  onClickName,
  getCommonCode,
}: GridColumnsHandlers<{ onClickName: (unionRegisterSeq: number) => void }>) =>
  [
    {
      field: 'unionRegisterNo',
      headerName: '연번',
      valueGetter: (_, row) => row?.unionRegister?.unionRegisterNo,
      sortComparator: (v1, v2) => {
        const value1 = v1?.split('-');
        const value2 = v2?.split('-');

        if (value1 && value2) {
          const v1Main = Number(value1[0]);
          const v1Sub = Number(value1[1]);
          const v2Main = Number(value2[0]);
          const v2Sub = Number(value2[1]);

          if (v1Main - v2Main === 0) {
            return v1Sub - v2Sub;
          }

          return v1Main - v2Main;
        }

        return 1;
      },
    },
    {
      field: 'name',
      headerName: '조합원 이름',
      width: 160,
      minWidth: 160,
      valueGetter: (_, row) =>
        [
          row?.unionRegister?.name?.name,
          row?.unionRegister?.name?.nameClass,
        ].join(''),
      renderCell: (params) => {
        const { value, row } = params;
        const { unionRegister } = row;
        return (
          <Link
            display="inline"
            width="100%"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            onClick={() => onClickName(unionRegister?.unionRegisterSeq)}
          >
            {value}
          </Link>
        );
      },
    },
    {
      field: 'position',
      headerName: '직책',
      width: 50,
      valueGetter: (_, row) => row?.unionRegister?.positionDescription || '',
    },
    {
      field: 'tendency',
      headerName: '우호도',
      valueGetter: (_, row) =>
        (row?.tendency && getCommonCode('TENDENCY_TYPE', row.tendency))?.name ||
        '',
    },
    {
      field: 'serviceType',
      headerName: '시스템',
    },
    {
      field: 'method',
      headerName: '상담 방식',
      valueGetter: (_, row) =>
        (row?.method &&
          getCommonCode('COUNSEL_METHOD_TYPE', row.method)?.name) ||
        '',
    },
    {
      field: 'counselor',
      headerName: '상담사',
      valueGetter: (_, row) => row?.counselor?.name || '',
    },
    {
      field: 'topics',
      headerName: '상담 주제',
      width: 150,
      valueGetter: (_, row) =>
        (row?.topics &&
          row?.topics
            .map((topic) => getCommonCode('COUNSEL_TOPIC_TYPE', topic)?.name)
            .join(', ')) ||
        '',
    },
    {
      field: 'counselAt',
      headerName: '상담 일시',
      width: 180,
      minWidth: 180,
      valueGetter: (value) =>
        (value && formatDateTime(value, 'yyyy-MM-dd HH:mm')) || '',
    },
    {
      field: 'detail',
      headerName: '',
      width: 100,
      type: 'actions',
      renderCell: (params) => {
        return (
          <Button variant="soft" component={Link} href={`${params.id}`}>
            상세보기
          </Button>
        );
      },
    },
  ] as GridColDef<Counsel>[];
