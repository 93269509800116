import { Box, DataGrid, Stack } from '@wooriga/design-system';
import { useMemo, useState } from 'react';

import { useOpinionsQuery } from 'apis/meet/live/api';
import { ONLINE_GENERAL_MEET_OPINIONS_COLUMNS } from 'apis/meet/live/fixtures';
import OpinionModal from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/components/OpinionModal';
import ViewerModal from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl/components/ViewerModal';
import useCreateGridColumns from 'hooks/useCreateGridColumns';

export interface OpinionPanelProps {
  onlineGeneralMeetingSeq: number;
}

const OpinionPanel = ({ onlineGeneralMeetingSeq }: OpinionPanelProps) => {
  const { data: opinionListData } = useOpinionsQuery(onlineGeneralMeetingSeq);
  const [open, setOpen] = useState(false);
  const [viewerSeq, setViewerSeq] = useState(0);
  const [showOpinion, setShowOpinion] = useState(false);
  const [opinionSeq, setOpinionSeq] = useState(0);

  const rows = useMemo(
    () => opinionListData?.data || [],
    [opinionListData?.data],
  );

  const handleLinkClick = (seq: number) => {
    console.log('name Click', viewerSeq);
    setViewerSeq(seq);
    setOpen(true);
  };
  const handleOpinionClick = (opinionSeq: number | string) => {
    setOpinionSeq(Number(opinionSeq));
    setShowOpinion(true);
  };

  const { columns } = useCreateGridColumns(
    ONLINE_GENERAL_MEET_OPINIONS_COLUMNS,
    {
      onOpinionClick: handleOpinionClick,
      onNameClick: handleLinkClick,
    },
  );

  return (
    <>
      <Stack>
        <Box
          sx={{
            height: 442,
          }}
        >
          <DataGrid
            rows={rows}
            getRowId={(row) => row.opinionSeq}
            columns={columns}
          />
        </Box>
      </Stack>
      {OpinionModal && (
        <OpinionModal
          onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
          opinionSeq={opinionSeq}
          open={showOpinion}
          onClose={() => {
            setShowOpinion(false);
          }}
        />
      )}
      {open && (
        <ViewerModal
          open={open}
          viewerSeq={viewerSeq}
          onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default OpinionPanel;
