import RefreshIcon from '@mui/icons-material/Refresh';
import { commaizeNumber, formatDateTime } from '@wooriga/common-utils';
import {
  useTheme,
  Callout,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@wooriga/design-system';
import { useEffect, useMemo, useRef, useState } from 'react';
import Player from 'video.js/dist/types/player';

import {
  useGeneralOnlineMeetsQuery,
  useNoticeQuery,
  useSettingsQuery,
} from 'apis/meet/live/api';
import AttendeeControlPanel from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/AttendeeControl';
import VideoPlayer from 'components/pages/meet-management/history/detail/tabs/LiveMeetTab/VideoPlayer';
import {
  calculateTimeMinuteGap,
  calculateTimeDifference,
} from 'components/pages/meet-management/history/detail/utils';

export interface CreatedStepProps {
  meetSeq: number;
  meetAt: string;
}

const CreatedStep = ({ meetSeq, meetAt }: CreatedStepProps) => {
  const playerRef = useRef<Player>(null);
  const theme = useTheme();
  const [elapsedTime, setElapsedTime] = useState('00:00:00');

  const { data: settingData, isLoading: isLoadingSettingData } =
    useSettingsQuery(meetSeq);

  const onlineGeneralMeetingSeq = useMemo(() => {
    return settingData?.data?.onlineGeneralMeetingSeq;
  }, [settingData?.data?.onlineGeneralMeetingSeq]);
  const accessUrl = useMemo(() => {
    return settingData?.data?.accessUrl || '';
  }, [settingData?.data?.accessUrl]);

  const {
    data: generalMeetData,
    isLoading,
    refetch,
    error,
    isError,
  } = useGeneralOnlineMeetsQuery(onlineGeneralMeetingSeq);

  const playUrl = useMemo(() => {
    console.log(
      'generalMeetData?.data?.playUrl',
      generalMeetData?.data?.playUrl,
    );
    return (
      generalMeetData?.data?.playUrl ||
      'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
    );
  }, [generalMeetData?.data?.playUrl]);

  const videoOptions = useMemo(() => {
    return {
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [
        {
          src: `${playUrl}`,
          type: 'application/x-mpegURL',
        },
      ],
    };
  }, [playUrl]);

  const { data: noticeData, refetch: refetchNotice } = useNoticeQuery(
    onlineGeneralMeetingSeq,
  );

  const noticeMessage = useMemo(() => {
    const firstNotice = noticeData?.data?.[0];
    return firstNotice?.message || '';
  }, [noticeData?.data]);

  const startAt = useMemo(
    () => generalMeetData?.data?.startAt,
    [generalMeetData?.data?.startAt],
  );
  const endAt = useMemo(
    () => generalMeetData?.data?.endAt,
    [generalMeetData?.data?.endAt],
  );
  const generalMeetingStreamingStatus = useMemo(
    () => generalMeetData?.data?.streamingStatus,
    [generalMeetData?.data?.streamingStatus],
  );

  const attendDataObject = useMemo(() => {
    return {
      totalParticipantCount: generalMeetData?.data?.totalParticipantCount || 0,
      cumulativeParticipantCount:
        generalMeetData?.data?.cumulativeParticipantCount || 0,
      currentParticipantCount:
        generalMeetData?.data?.currentParticipantCount || 0,
    };
  }, [generalMeetData?.data]);

  // 10초 폴링
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
      refetchNotice();
    }, 10000); // 60초마다 업데이트

    return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 interval 해제
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!startAt) return;

      setElapsedTime(calculateTimeMinuteGap(startAt));
    }, 1000); // 1초

    return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 interval 해제
  }, [elapsedTime, startAt]);
  if (isLoadingSettingData) return;
  if (isLoading) return;
  if (isError) throw error;

  return (
    <>
      <Stack gap={4}>
        <Stack flexDirection="row" flexWrap="wrap" gap={2}>
          <Stack>
            <VideoPlayer options={videoOptions} ref={playerRef} />
          </Stack>

          <Stack flex={1}>
            <Stack
              sx={{
                paddingTop: 1.5,
                paddingLeft: 2.5,
                paddingRight: 2.5,
                paddingBottom: 1.5,
              }}
              borderRadius={8}
              bgcolor={theme.palette.neutral[50]}
              border={`1px ${theme.palette.neutral[300]} solid`}
            >
              <Stack justifyContent="space-between" flexDirection="row">
                <Typography
                  fontSize="md"
                  fontWeight="lg"
                  lineHeight="md"
                  textColor="theme.neutral.500"
                >
                  온라인 총회 정보
                </Typography>
                <IconButton
                  size="md"
                  variant="outlined"
                  color="neutral"
                  onClick={() => {
                    refetch();
                  }}
                >
                  <RefreshIcon />
                  새로고침
                </IconButton>
              </Stack>

              <Stack
                flexDirection="row"
                justifyContent="space-between"
                paddingTop={2}
                paddingBottom={2}
              >
                <Stack gap={1} minWidth={220}>
                  <Chip variant="soft" color="neutral" size="md">
                    총회 진행 시간
                  </Chip>
                  <Typography
                    fontSize="xl2"
                    fontWeight="xl"
                    lineHeight="md"
                    textColor="danger.500"
                  >
                    {generalMeetingStreamingStatus === 'STOPPED' && endAt ? (
                      <>{calculateTimeDifference(String(startAt), endAt)}</>
                    ) : (
                      <>{elapsedTime}</>
                    )}
                  </Typography>
                  <Typography
                    fontSize="md"
                    fontWeight="md"
                    lineHeight="md"
                    textColor="neutral.500"
                  >
                    시작 {formatDateTime(startAt, 'yyyy-MM-dd HH:mm')}
                  </Typography>
                </Stack>

                <Stack gap={1} minWidth={220}>
                  <Chip variant="soft" color="neutral" size="md">
                    온라인 총회 실시간 참석자
                  </Chip>
                  <Typography
                    fontSize="xl2"
                    fontWeight="xl"
                    lineHeight="md"
                    textColor="text.secondary"
                  >
                    {commaizeNumber(attendDataObject.currentParticipantCount)}{' '}
                    명
                  </Typography>
                  <Typography
                    fontSize="md"
                    fontWeight="md"
                    lineHeight="md"
                    textColor="neutral.500"
                  >
                    (현재 시청중인 참석자 수)
                  </Typography>
                </Stack>

                <Stack gap={1} minWidth={220}>
                  <Chip variant="soft" color="neutral" size="md">
                    누적 참석자 (현장+온라인)
                  </Chip>
                  <Typography
                    fontSize="xl2"
                    fontWeight="xl"
                    lineHeight="md"
                    textColor="text.secondary"
                  >
                    {commaizeNumber(
                      attendDataObject.cumulativeParticipantCount,
                    )}{' '}
                    명 /{' '}
                    {commaizeNumber(attendDataObject.totalParticipantCount)} 명
                  </Typography>
                  <Typography
                    fontSize="md"
                    fontWeight="md"
                    lineHeight="md"
                    textColor="neutral.500"
                  >
                    (현장+온라인 참석자 수)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack mt={2}>
              <Callout variant="soft" size="lg" color="neutral">
                <Typography
                  fontSize="lg"
                  fontWeight="lg"
                  lineHeight="md"
                  textColor="neutral.700"
                >
                  공지
                </Typography>
                <Typography
                  fontSize="md"
                  fontWeight="md"
                  lineHeight="md"
                  textColor="neutral.500"
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    WebkitLineClamp: 2, // 두 줄까지만 표시 요구사항임
                    textOverflow: 'ellipsis',
                  }}
                >
                  {noticeMessage}
                </Typography>
              </Callout>
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <AttendeeControlPanel
            meetSeq={meetSeq}
            onlineGeneralMeetingSeq={onlineGeneralMeetingSeq}
            noticeMessage={noticeMessage}
            accessUrl={accessUrl}
            meetAt={meetAt}
          />
        </Stack>
      </Stack>
    </>
  );
};
export default CreatedStep;
