import {
  Button,
  CircularProgress,
  Descriptions,
  DescriptionsItem,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Sheet,
  Stack,
  Textarea,
  Typography,
} from '@wooriga/design-system';
import { ChangeEvent, useMemo, useState } from 'react';

import {
  useCreateCommentMutation,
  useOpinionDetailQuery,
} from 'apis/meet/live/api';
import useFeedback from 'hooks/useFeedback';

interface OpinionModalProps {
  opinionSeq: number;
  open: boolean;
  onlineGeneralMeetingSeq: number;
  onClose: (open: false) => void;
}

const OpinionModal = ({
  open,
  onClose,
  onlineGeneralMeetingSeq,
  opinionSeq,
}: OpinionModalProps) => {
  const { snackbar } = useFeedback();
  const [inputComment, setInputComment] = useState('');
  const { data, isPending, refetch } = useOpinionDetailQuery(
    opinionSeq,
    onlineGeneralMeetingSeq,
  );
  const opinionData = useMemo(() => {
    return data?.data;
  }, [data?.data]);
  const { mutate } = useCreateCommentMutation();

  const handleComment = () => {
    mutate(
      {
        opinionSeq,
        answer: inputComment,
        onlineGeneralMeetingSeq,
      },
      {
        onSuccess: () => {
          snackbar('답변이 등록되었습니다.', {
            color: 'success',
          });
          onClose(false);
          setInputComment('');
          refetch();
        },
        onError: (error) => {
          snackbar(error.response?.data.message ?? error.message, {
            color: 'danger',
          });
        },
      },
    );
  };

  const handleChangeValues = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputComment(event.target.value);
  };
  return (
    <>
      <Modal open={open} onClose={() => onClose(false)}>
        <ModalDialog sx={{ width: 480 }}>
          <DialogTitle>참석자 의견 답변</DialogTitle>
          <DialogContent>
            <Stack gap={3}>
              {isPending && <CircularProgress sx={{ m: 'auto' }} />}
              {!isPending && (
                <Stack gap={1}>
                  <Typography level="body-sm" textColor="text.primary">
                    참석자 정보
                  </Typography>
                  <Descriptions
                    color="neutral"
                    size="md"
                    variant="outlined"
                    legacy={false}
                  >
                    <DescriptionsItem label="이름">
                      {opinionData?.viewer.name}
                    </DescriptionsItem>
                    <DescriptionsItem label="전화번호">
                      {opinionData?.viewer.phone}
                    </DescriptionsItem>
                  </Descriptions>
                </Stack>
              )}
              <Stack gap={1}>
                <Typography level="body-sm" textColor="text.primary">
                  의견 내용
                </Typography>
                <Sheet
                  variant="outlined"
                  color="neutral"
                  sx={{
                    borderRadius: 8,
                    minHeight: 146,
                    px: 2,
                    py: 1.5,
                  }}
                >
                  {opinionData?.comment}
                </Sheet>
              </Stack>
              <Stack gap={1}>
                <Typography level="body-sm" textColor="text.primary">
                  답변 입력
                </Typography>
                <Textarea
                  size="md"
                  variant="soft"
                  color="neutral"
                  minRows={4}
                  validateOptions={{ maxLength: 150 }}
                  value={inputComment}
                  onChange={handleChangeValues}
                  placeholder="답변을 입력해 주세요"
                  endDecorator={
                    <Stack width="100%" direction="row">
                      <Stack ml="auto" mr={1}>
                        <Typography
                          fontSize="md"
                          fontWeight="md"
                          lineHeight="md"
                          textColor="primary.500"
                        >
                          {inputComment.length || 0}/150
                        </Typography>
                      </Stack>
                    </Stack>
                  }
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" gap={1}>
              <Button
                size="md"
                color="neutral"
                variant="outlined"
                onClick={() => {
                  onClose(false);
                  setInputComment('');
                }}
              >
                취소
              </Button>
              <Button
                size="md"
                color="primary"
                variant="solid"
                onClick={handleComment}
              >
                확인
              </Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default OpinionModal;
