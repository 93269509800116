import { TabPanel } from '@wooriga/design-system';
import { useEffect, useState } from 'react';

import { MeetMethods } from 'apis/types/meet';
import TallyResultTab from 'components/pages/meet-management/history/detail/tabs/TallyResultTab';
import VoteHomeTab from 'components/pages/meet-management/history/detail/tabs/VoteHomeTab';
import VoteManagementTab from 'components/pages/meet-management/history/detail/tabs/VoteManagementTab';
import VoterManagementTab from 'components/pages/meet-management/history/detail/tabs/VoterManagementTab';
import { useReveal } from 'hooks/useReveal';
import {
  CompleteMeetBody,
  MeetsParticipantsParams,
  useCompleteMeetMutation,
  useElectronicVoteSendStatusCountQuery,
  useElectronicVoteTendencyCountQuery,
  useElectronicVoteTimeSeriesQuery,
  useMeetsAgendasQuery,
  useMeetsDetailQuery,
  useMeetsParticipantsMutation,
  useMeetsParticipantsQuery,
  useMeetsPasswordVerifyMutation,
  useMeetsVoteResultQuery,
  useUnionRegistersQuery,
  VoterMeetsParticipantsParams,
} from 'lim/generalMeetingHistoryDetail/apis';

interface ElectronicTabPanelProps {
  unionSeq: number;
  meetSeq: number;
  currentTab: string;
}

const voterDefaultParams: VoterMeetsParticipantsParams = {
  unionRegisterNo: '',
  phoneNo: '',
  name: '',
  electronicVoteProgressStatus: '',
};

const electronicDefaultParams: MeetsParticipantsParams<'ELECTRONIC'> = {
  ...voterDefaultParams,
  isElectronicVoteResent: '',
  tendency: '',
  receivedAtFrom: '',
  receivedAtTo: '',
  openedAtTo: '',
  openedAtFrom: '',
  votedAtFrom: '',
  votedAtTo: '',
};

const ElectronicTabPanel = ({
  unionSeq,
  meetSeq,
  currentTab,
}: ElectronicTabPanelProps) => {
  const meetMethod: MeetMethods = 'ELECTRONIC';
  const currentParams =
    currentTab === meetMethod ? electronicDefaultParams : voterDefaultParams;

  const { openReveal } = useReveal();

  const [params, setParams] = useState<
    MeetsParticipantsParams<'ELECTRONIC'> | VoterMeetsParticipantsParams
  >(currentParams);

  const unionRegistersQuery = useUnionRegistersQuery(unionSeq);

  const meetsDetailQuery = useMeetsDetailQuery(meetSeq);
  const meetsAgendasQuery = useMeetsAgendasQuery(meetSeq);
  const meetsParticipantsQuery = useMeetsParticipantsQuery(meetSeq, params);
  const meetsVoteResultQuery = useMeetsVoteResultQuery(meetSeq);

  const electronicVoteSendStatusCountQuery =
    useElectronicVoteSendStatusCountQuery(meetSeq);
  const electronicVoteTendencyCountQuery =
    useElectronicVoteTendencyCountQuery(meetSeq);

  const electronicVoteTimeSeriesQuery =
    useElectronicVoteTimeSeriesQuery(meetSeq);

  const { mutate: passwordVerifyMutate } =
    useMeetsPasswordVerifyMutation(meetSeq);
  const { mutate: completeMeetMutate } = useCompleteMeetMutation(meetSeq);
  const { mutate } = useMeetsParticipantsMutation(meetSeq);

  const handleAddVoter = (unionRegisterSeqs: number[]) => {
    mutate(
      { unionRegisterSeqs },
      {
        onSuccess: () => {
          meetsParticipantsQuery.refetch();
          openReveal('snackbar', {
            color: 'success',
            children: '선거인 추가가 완료되었습니다.',
          });
        },
        onError: ({ response, message }) => {
          const errorMessage = response?.data.message ?? message;

          openReveal('snackbar', {
            color: 'danger',
            children: errorMessage,
          });
        },
      },
    );
  };

  const handleCompleteMeet = (formData: CompleteMeetBody) => {
    completeMeetMutate(formData, {
      onSuccess() {
        window.location.reload();

        openReveal('snackbar', {
          color: 'success',
          children: '총회가 성공적으로 종료되었습니다.',
        });
      },
      onError: ({ response, message }) => {
        const errorMessage = response?.data.message ?? message;

        openReveal('snackbar', {
          color: 'danger',
          children: errorMessage,
        });
      },
    });
  };

  useEffect(() => {
    setParams(currentParams);
  }, [currentTab]);

  return (
    <>
      <TabPanel value={0}>
        <VoteHomeTab
          meetMethod={meetMethod}
          meetsDetailQuery={meetsDetailQuery}
          meetsAgendasQuery={meetsAgendasQuery}
          electronicVoteSendStatusCountQuery={
            electronicVoteSendStatusCountQuery
          }
          electronicVoteTendencyCountQuery={electronicVoteTendencyCountQuery}
          electronicVoteTimeSeriesQuery={electronicVoteTimeSeriesQuery}
        />
      </TabPanel>

      {/* // --------------- 여기 부터 재 확인-------------- */}

      <TabPanel value={1}>
        <VoteManagementTab
          meetSeq={meetSeq}
          meetStatus={meetsDetailQuery.data?.data.meetStatus || 'BEFORE'}
          meetMethod={meetMethod}
          meetsParticipantsQuery={meetsParticipantsQuery}
          electronicVoteSendStatusCountQuery={
            electronicVoteSendStatusCountQuery
          }
          onSearch={setParams}
        />
      </TabPanel>

      <TabPanel value={2}>
        <TallyResultTab
          meetMethod={meetMethod}
          meetsDetailQuery={meetsDetailQuery}
          meetsVoteResultQuery={meetsVoteResultQuery}
          electronicVoteSendStatusCountQuery={
            electronicVoteSendStatusCountQuery
          }
          passwordVerifyMutate={passwordVerifyMutate}
          onMeetComplete={handleCompleteMeet}
        />
      </TabPanel>
      <TabPanel value={3}>
        <VoterManagementTab
          meetMethod={meetMethod}
          meetStatus={meetsDetailQuery.data?.data.meetStatus || 'BEFORE'}
          unionRegistersQuery={unionRegistersQuery}
          meetsParticipantsQuery={meetsParticipantsQuery}
          onAddVoter={handleAddVoter}
          onSearch={setParams}
        />
      </TabPanel>
    </>
  );
};

export default ElectronicTabPanel;
