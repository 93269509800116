import { SxProps } from '@mui/joy/styles/types';
import useControlled from '@mui/utils/useControlled';
import type {
  DateValidationError,
  PickerChangeHandlerContext,
  DatePickerProps as XDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import FormControl from 'design-system/components/inputs/FormControl';
import FormHelperText from 'design-system/components/inputs/FormHelperText';
import FormLabel from 'design-system/components/inputs/FormLabel';
import { Stack } from 'design-system/components/layout';
import { DateFieldProps } from 'design-system/components/pickers/date/DateField';
import DatePicker from 'design-system/components/pickers/date/DatePicker';
import {
  formatDateFns,
  getEarlierDate,
  getLaterDate,
} from 'design-system/components/pickers/utils';

export type DateRangePickerSlot = 'root' | 'label' | 'input' | 'helpText';

export interface DateRangePickerProps
  extends Omit<
    XDatePickerProps<Date, false>,
    | 'value'
    | 'defaultValue'
    | 'onChange'
    | 'onError'
    | 'sx'
    | 'minDate'
    | 'maxDate'
  > {
  variant?: DateFieldProps['variant'];
  color?: DateFieldProps['color'];
  size?: DateFieldProps['size'];
  fullWidth?: DateFieldProps['fullWidth'];
  required?: DateFieldProps['required'];
  label?: string;
  error?: boolean;
  helperText?: string;
  minDate?: string | number | null | Date;
  maxDate?: string | number | null | Date;
  value?: (string | number | null | Date | undefined)[];
  defaultValue?: (string | number | null | Date | undefined)[];
  onChange?: (
    value: (string | number | null | Date | undefined)[],
    context?: PickerChangeHandlerContext<DateValidationError>,
  ) => void;
  onError?: (
    error: DateValidationError,
    values: (string | number | null | Date | undefined)[],
  ) => void;
  sx?: SxProps & { field?: DateFieldProps['sx'] };
}

const DateRangePicker = (
  props: DateRangePickerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    fullWidth,
    required,
    label,
    error,
    helperText,
    disabled,
    value: valueProp,
    defaultValue,
    onChange,
    onError,
    minDate: minDateProp,
    maxDate: maxDateProp,
    sx,
    ...other
  } = props;
  const { field: fieldSx, ...otherSx } = sx || {};

  const [value, setValue] = useControlled<
    (string | number | null | Date | undefined)[] | undefined
  >({
    controlled: valueProp,
    default: defaultValue,
    name: 'DateRangePicker',
    state: 'value',
  });

  const startPickerRange = useMemo(() => {
    const minDate = minDateProp;
    const maxDate = getEarlierDate(value?.[1], maxDateProp);

    return { minDate, maxDate };
  }, [maxDateProp, minDateProp, value]);

  const endPickerRange = useMemo(() => {
    const minDate = getLaterDate(value?.[0], minDateProp);
    const maxDate = maxDateProp;

    return { minDate, maxDate };
  }, [maxDateProp, minDateProp, value]);

  const handleChange = useCallback(
    (
      values: (string | number | null | Date)[],
      context?: PickerChangeHandlerContext<DateValidationError>,
    ) => {
      const dates = [values?.[0] || null, values?.[1] || null];

      setValue(dates);
      return onChange?.(dates, context);
    },
    [onChange, setValue],
  );

  const handleError = useCallback(
    (error: DateValidationError, values: (string | number | null | Date)[]) => {
      setValue(values);
      handleChange(values);
      return onError?.(error, values);
    },
    [handleChange, onError, setValue],
  );

  return (
    <FormControl
      component={Stack}
      error={error}
      sx={otherSx}
      disabled={disabled}
      required={required}
    >
      {label && (
        <FormLabel sx={{ margin: '0 0 0.375rem 0' }}>{label}</FormLabel>
      )}

      <Stack
        flex={1}
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
        sx={{ ...(fullWidth && { width: '100%' }) }}
      >
        <DatePicker
          ref={ref}
          value={value?.[0] || null}
          onChange={(startDateValue, context) => {
            const date = startDateValue
              ? formatDateFns(new Date(startDateValue).setHours(0, 0, 0))
              : null;

            handleChange([date, value?.[1] || null], context);
          }}
          onError={(error, startDateValue) =>
            handleError(error, [startDateValue, value?.[1] || null])
          }
          minDate={startPickerRange.minDate}
          maxDate={startPickerRange.maxDate}
          disabled={disabled}
          error={error}
          formControlSx={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 200,
            ...fieldSx,
          }}
          required={required}
          {...other}
        />

        <DatePicker
          ref={ref}
          value={value?.[1] || null}
          onChange={(endDateValue, context) => {
            const date = endDateValue
              ? formatDateFns(new Date(endDateValue).setHours(23, 59, 59))
              : null;

            handleChange([value?.[0] || null, date], context);
          }}
          onError={(error, endDateValue) =>
            handleError(error, [value?.[0] || null, endDateValue])
          }
          minDate={endPickerRange.minDate}
          maxDate={endPickerRange.maxDate}
          disabled={disabled}
          error={error}
          formControlSx={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 200,
            ...fieldSx,
          }}
          required={required}
          {...other}
        />
      </Stack>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default forwardRef<HTMLDivElement, DateRangePickerProps>(
  DateRangePicker,
);
